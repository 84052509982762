import React, { useState, useEffect } from "react";
import Spinner from "../../Components/Loader/Spinner";
import { useParams, useLocation, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import debounce from "lodash.debounce";
import Pagination from "rc-pagination";
import Select from "react-select";

import API from "../../Api/Routes";
import moment from "moment";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Layout } from "../../Components/Layout";
import { showModalNotification } from "../../Utils/Functions";
import { callApi } from "../../Api"; // Used for api call
/******************* 
@Purpose : Used for view user details
@Parameter : props
@Author : INIC
******************/
const selectFieldOptions = [
  { value: "category", label: "Category" },
  { value: "brand", label: "Brand" },
  { value: "date", label: "Date" },
];
const selectFieldOptions1 = [
  { value: "category", label: "Category" },
  { value: "subcategory", label: "SubCategory" },
  { value: "brand", label: "Brand" },
  { value: "date", label: "Date" },
];

const selectContentOptions = [{ value: "Select Field", label: "Select Field" }];
function ViewCelebrity(props) {
  const [spin, setSpin] = useState(false);

  let { slug } = useParams();
  const location = useLocation();
  const [link, setLink] = useState("");
  let [loading, setLoading] = useState(false);
  const [total] = useState(1);
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  const [requests, setRequests] = useState([]);
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [pagesize, setPagesize] = useState(10);
  const [videoPageNumber] = useState(1);
  const [videoPageSize] = useState(10);
  const [filterpopup, openFilterpopup] = useState(false);
  const [searchText, setSearchtext] = useState("");
  const [videosList, setVideosList] = useState([]);
  const [, setImage] = useState("");
  const [brand, setBrand] = useState([]);
  const [celebFilter, setCelebFilter] = useState([]);

  const [errorsLicence, setErrorsLicence] = useState([
    {
      key: "",
      type: "",
      input: "",
      value: { startDate: "", endDate: "" },
    },
  ]);
  const [filterObj, setFilterObj] = useState([
    {
      key: "",
      type: "",
      input: "",
      value: { startDate: "", endDate: "" },
      condition: "$and",
    },
  ]);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    emailId: "",
    gender: "",
    dob: "",
    mobileNumber: "",
    status: "",
    address: "",
    city: "",
    twitter: "",
    instagramId: "",
    aadhar: "",
    state: "",
    pincode: "",
    fbId: "",
    youtubeId: "",
    creatorProfilephoto: "",
  });

  /******************* 
  @Purpose : Used for brand list
  @Parameter : {}
  @Author : INIC
  ******************/
  const getBrandList = async () => {
    try {
      let body = {
        searchText: "",
      };

      const response = await callApi(
        API.ALL_BRANDS,
        body,
        "post",
        "ALL_BRANDS",
        true,
        true,
        false
      );

      if (response) {
        const allBrandsList = Array.isArray(response.data)
          ? response.data.map((item) => ({
              ...item,
              label: item.organization,
              value: item._id,
            }))
          : [];
        setBrand(allBrandsList);
      }
    } catch (error) {
      console.error();
    }
  };
  /******************* 
  @Purpose : Used for filter 
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleFilterArray = () => {
    let selected = filterObj.map((item) => item.key.value);
    setSelectedFilterItems(selected);
  };
  /******************* 
  @Purpose : React hook used for get category list
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    getCategoryList();
    getBrandList();
  }, []);
  /******************* 
  @Purpose : Used for category and sub category
  @Parameter : {}
  @Author : INIC
  ******************/
  const getCategoryList = async () => {
    try {
      const response = await callApi(
        `/categories/getcategories`,
        "",
        "get",
        "GET_CATEGORY",
        true,
        true,
        false
      );

      if (response) {
        const categoryData = Array.isArray(response.data)
          ? response.data.map((item) => ({
              ...item,
              label: item.title,
              value: item.title,
            }))
          : [];
        setCategories(categoryData);
      }
    } catch (error) {
      console.error();
    }
  };

  /******************* 
  @Purpose : Used for add new filter records
  @Parameter : {e}
  @Author : INIC
  ******************/
  const addNewRow = (e) => {
    handleFilterArray();
    setErrorsLicence([
      ...errorsLicence,
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    setFilterObj([
      ...filterObj,
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
  };

  /******************* 
  @Purpose : Used for remove filter records
  @Parameter : {index}
  @Author : INIC
  ******************/
  const removeRow = (index) => {
    let array = [...filterObj];
    array.splice(index, 1);
    setFilterObj(array);
    array = [...errorsLicence];
    array.splice(index, 1);
    setErrorsLicence(array);
    setTimeout(function () {
      setLoadingFilter(true);
    }, 100);
  };
  /******************* 
  @Purpose : Used for sub category data
  @Parameter : {}
  @Author : INIC
  ******************/
  const getSubCategoryData = () => {
    let categoryItem = filterObj?.filter(
      (item) => item.key.label === "Category"
    );

    let id =
      categoryItem.length > 1
        ? categoryItem[categoryItem.length - 1]?.type?._id
        : categoryItem[0]?.type?._id;

    let categoryFilter = categories?.filter((item) => item._id === id)[0]
      ?.subCategoryDetails;
    let subcategoryData = Array.isArray(categoryFilter)
      ? categoryFilter.map((item) => ({
          ...item,
          label: item.title,
          value: item.title,
        }))
      : [];

    return subcategoryData;
  };

  /******************* 
  @Purpose : Used for pagination
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  function paginationChange(page, pagesize) {
    setPage(page);
    setPagesize(pagesize);
  }
  useEffect(() => {
    getRequestDetails(slug);
  }, [slug]);

  const getRequestDetails = async (data) => {
    setSpin(true);
    try {
      let body = {
        pageNumber: page,
        pageSize: pagesize,
        videoPageNumber: videoPageNumber,
        videoPageSize: videoPageSize,
        keyword: searchText || "",
        filter: celebFilter || [],
      };

      const response = await callApi(
        `/admin/getRequestHistoryOfCelebrityId/${slug}`,
        body,
        "post",
        null,
        true
      );
      if (response.status === 1) {
        setSpin(false);
        setRequests(response?.requests);
        setVideosList(response?.videos);
        let {
          firstname,
          lastname,
          emailId,
          gender,
          dob,
          mobileNumber,
          status,
          address,
          city,
          twitter,
          instagramId,
          aadhar,
          state,
          pincode,
          fbId,
          youtubeId,
          creatorProfilephoto,
        } = response?.celebrityDetail;

        setFormData({
          ...formData,
          firstname,
          lastname,
          emailId,
          gender,
          dob,
          mobileNumber,
          status,
          address,
          city,
          twitter,
          instagramId,
          aadhar,
          state,
          pincode,
          fbId,
          youtubeId,
          creatorProfilephoto,
        });
        setImage(response?.creatorProfilephoto);
      } else if (response?.message === 0) {
        setSpin(false);
        showModalNotification(response?.message, "error");
      }
    } catch (error) {
      console.error(error);
    }
  };
  // when the filter was reset this will be called
  const getRequestDetails1 = async (data) => {
    try {
      let body = {
        pageNumber: page,
        pageSize: pagesize,
        videoPageNumber: videoPageNumber,
        videoPageSize: videoPageSize,
        keyword: "",
        filter: [],
      };

      const response = await callApi(
        `/admin/getRequestHistoryOfCelebrityId/${slug}`,
        body,
        "post",
        null,
        true
      );
      if (response.status === 1) {
        setRequests(response?.requests);
        setVideosList(response?.videos);
        let {
          firstname,
          lastname,
          emailId,
          gender,
          dob,
          mobileNumber,
          status,
          address,
          city,
          twitter,
          instagramId,
          aadhar,
          state,
          pincode,
          fbId,
          youtubeId,
          creatorProfilephoto,
        } = response?.celebrityDetail;

        setFormData({
          ...formData,
          firstname,
          lastname,
          emailId,
          gender,
          dob,
          mobileNumber,
          status,
          address,
          city,
          twitter,
          instagramId,
          aadhar,
          state,
          pincode,
          fbId,
          youtubeId,
          creatorProfilephoto,
        });
        setImage(response?.creatorProfilephoto);
      }
    } catch (error) {
      console.error(error);
    }
  };
  /******************* 
  @Purpose : Used for get users all details
  @Parameter : id
  @Author : INIC
  ******************/

  const breadcrumb = [
    {
      title: "Celebrity",
      url: "/celebrityList",
      tab: location?.state,
    },
    {
      title: "Celebrity Details",
    },
  ];

  /******************* 
  @Purpose : Used for on change search
  @Parameter : status, timezoneId
  @Author : INIC
  ******************/

  const handleSearchChange = (e) => {
    if (e.target.value.length === 0) {
      clearInput();
    }
    e.persist();
    setLoading(true);
    setSearchtext(e.target.value);
    handleDebouncedChange(e.target.value);
  };
  /******************* 
  @Purpose : Used for debounce with memo
  @Parameter : status, timezoneId
  @Author : INIC
  ******************/
  const handleDebouncedChange = React.useMemo(
    () => debounce((evt) => searchField(evt), 1000),
    []
  );
  /******************* 
  @Purpose : Used forsearch the vids
  @Parameter : status, timezoneId
  @Author : INIC
  ******************/
  const searchField = async (evtKey) => {
    setLoading(true);

    try {
      if (evtKey) {
        let body = {
          pageNumber: page,
          pageSize: pagesize,
          keyword: evtKey,
          videoPageNumber: videoPageNumber,
          videoPageSize: videoPageSize,
        };

        const response = await callApi(
          `/admin/getRequestHistoryOfCelebrityId/${slug}`,
          body,
          "post",
          null,
          true
        );
        if (response.status === 1) {
          setRequests(response?.requests);
          setVideosList(response?.videos);
          let {
            firstname,
            lastname,
            emailId,
            gender,
            dob,
            mobileNumber,
            status,
            address,
            city,
            twitter,
            instagramId,
            aadhar,
            state,
            pincode,
            fbId,
            youtubeId,
            creatorProfilephoto,
          } = response?.celebrityDetail;

          setFormData({
            ...formData,
            firstname,
            lastname,
            emailId,
            gender,
            dob,
            mobileNumber,
            status,
            address,
            city,
            twitter,
            instagramId,
            aadhar,
            state,
            pincode,
            fbId,
            youtubeId,
            creatorProfilephoto,
          });
          setImage(response?.creatorProfilephoto);
          setPage(1);
          setPagesize(10);
        } else if (response.status === 0) {
          showModalNotification("no videos were found", "error");
        }
      }
    } catch (error) {
      setLoading(false);
      console.error();
    }
  };
  /******************* 
  @Purpose : Used for reset filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const resetFilter = () => {
    setFilterObj([
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    openFilterpopup(!filterpopup);
    setSelectedFilterItems([]);
    getRequestDetails1();
  };

  /******************* 
  @Purpose : Used for clear all form field value
  @Parameter : status, timezoneId
  @Author : INIC
  ******************/
  const clearInput = () => {
    setSearchtext("");
    getRequestDetails1();
    setPage(1);
  };
  /******************* 
  @Purpose : Used for applay filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const ApplyFilter = async () => {
    try {
      if (validateFiltersearch()) {
        let payload = filterObj.map((item) => {
          if (item.key.label === "SubCategory" || item.key.label === "Brand") {
            return item.type.map((val) => {
              return {
                key:
                  item.key.label === "SubCategory"
                    ? "subCategoryId"
                    : item.key.label === "Brand"
                    ? "brandId"
                    : "",
                type: "contains",
                value: val._id,
              };
            });
          }
          return {
            key: item.key.label === "Category" ? "categoryId" : "date",

            type: item.key.label === "Date" ? "date" : "contains",

            value:
              item.key.label === "Date"
                ? {
                    startDate: moment(item.value.startDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                    endDate: moment(item.value.endDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                  }
                : item.type._id,
          };
        });

        setLoading(true);

        const body = {
          videoPageNumber: videoPageNumber,
          videoPageSize: videoPageSize,
          pageNumber: page,
          pageSize: pagesize,
          keyword: "",
          filter: payload.flat(),
        };

        const response = await callApi(
          `/admin/getRequestHistoryOfCelebrityId/${slug}`,
          body,
          "post",
          null,
          true
        );
        if (response.status === 1) {
          setCelebFilter(payload.flat());
          setRequests(response?.requests);
          setVideosList(response?.videos);
          openFilterpopup(!filterpopup);
          setPage(1);
          setPagesize(10);

          setLoading(false);
          if (response?.videos.length === 0) {
            showModalNotification("No videos were found", "error");
          }
        } else if (response.status === 0) {
          showModalNotification("No videos were found", "error");
        }
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  /******************* 
  @Purpose : Used for validate filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateFiltersearch = () => {
    let isFormValid = true;

    let valid = filterObj.map((each) => {
      if (each.key.label === "Category" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the  category fields";
      } else if (each.key.label === "Category" && isEmpty(each.type) == false) {
        each.error = "";
        isFormValid = true;
      }

      if (each.key.label === "Brand" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the brand fields";
      } else {
        if (each.key.label === "Brand") {
          isFormValid = true;
          each.error = "";
        }
      }
      if (each.key.label === "SubCategory" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the fields";
      } else {
        if (each.key.label === "SubCategory") {
          isFormValid = true;
          each.error = "";
        }
      }
      if (
        each.key.label === "Date" &&
        (each.value.startDate === "" || each.value.endDate === "")
      ) {
        isFormValid = false;
        each.error = "Select the subCategory fields";
      } else {
        if (each.key.label === "Date") {
          isFormValid = true;
          each.error = "";
        }
      }
      return each;
    });

    setFilterObj(valid);
    return isFormValid;
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  const renderFilter = () => {
    return (
      <div className="published-videos-wrap">
        <div
          className="custom-dropdown-menu dropdown-icon card w-50"
          style={{ marginTop: "253px" }}
        >
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="form-repeat">
                  {filterObj &&
                    filterObj.length > 0 &&
                    filterObj.map((o, i) => {
                      let itemType = o.key.label;

                      return (
                        <form
                          key={o?.key?.label}
                          className="form repeater-default"
                          data-limit="5"
                        >
                          <div className="field-form">
                            <div className="target-form">
                              <div
                                className="target-details"
                                style={{ marginBottom: "10px" }}
                              >
                                <div className="row align-items-start">
                                  <div className="form-group col-md-5 col-lg-6 mb-2">
                                    <label className="form-label">
                                      Select Field
                                    </label>
                                    <Select
                                      filterOption={(option) =>
                                        !selectedFilterItems.includes(
                                          option.value
                                        )
                                      }
                                      showSearch
                                      placeholder="Enter Field"
                                      optionFilterProp="children"
                                      className="w-100 custom-input filter-select"
                                      value={o.key}
                                      onChange={(value) => {
                                        o.key = value;

                                        setFilterObj([...filterObj]);

                                        if (errorsLicence[i])
                                          delete errorsLicence[i].key;
                                      }}
                                      options={
                                        i === 0
                                          ? selectFieldOptions
                                          : selectFieldOptions1
                                      }
                                    />

                                    <span className="text-danger error-msg"></span>
                                  </div>
                                  {itemType === "Date" ? (
                                    <div className="col-md-5 col-lg-5 mb-2 ">
                                      <div className="input-daterange custom-daterange input-control">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label className="form-label">
                                              Select Start date
                                            </label>
                                            <DatePicker
                                              selected={
                                                o.value.startDate
                                                  ? new Date(o.value.startDate)
                                                  : ""
                                              }
                                              placeholderText="Select Start Date"
                                              dateFormat="MMM d yyyy"
                                              className="form-control"
                                              onChange={(evt) => {
                                                o.value.startDate = evt;
                                                setFilterObj([...filterObj]);
                                                if (errorsLicence[i])
                                                  delete errorsLicence[i].value
                                                    .startDate;
                                              }}
                                            />
                                            <span className="text-danger error-msg">
                                              {o.error}
                                            </span>
                                          </div>
                                          <div className="col-md-6">
                                            <label className="form-label">
                                              Select end date
                                            </label>

                                            <DatePicker
                                              selected={
                                                o.value.endDate
                                                  ? new Date(o.value.endDate)
                                                  : ""
                                              }
                                              minDate={
                                                o.value.startDate
                                                  ? new Date(o.value.startDate)
                                                  : new Date()
                                              }
                                              disabled={
                                                o.value.startDate ? false : true
                                              }
                                              dateFormat="MMM d yyyy"
                                              placeholderText="Select End Date"
                                              className="form-control"
                                              onChange={(evt) => {
                                                o.value.endDate = evt;
                                                setFilterObj([...filterObj]);
                                                if (errorsLicence[i])
                                                  delete errorsLicence[i].value
                                                    .endDate;
                                              }}
                                            />
                                            <span className="text-danger error-msg">
                                              {o.error}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="form-group col-md-5 col-lg-6 mb-2">
                                      <label className="form-label">
                                        Select Content
                                      </label>
                                      <Select
                                        closeMenuOnSelect={false}
                                        isMulti={
                                          o.key.label === "Category"
                                            ? false
                                            : true
                                        }
                                        showSearch={false}
                                        placeholder="Select Type"
                                        className="w-100 custom-input filter-select"
                                        optionFilterProp="children"
                                        value={o.type}
                                        onChange={(value) => {
                                          o.type = value;
                                          if (o.type === "date") {
                                            delete o.value;
                                            o.value = {
                                              startDate: "",
                                              endDate: "",
                                            };
                                          }
                                          setFilterObj([...filterObj]);
                                          handleFilterArray();
                                          if (errorsLicence[i])
                                            delete errorsLicence[i].type;
                                        }}
                                        options={
                                          o.key.label === "Category"
                                            ? categories
                                            : o.key.label === "SubCategory"
                                            ? getSubCategoryData()
                                            : o.key.label === "Brand"
                                            ? brand
                                            : selectContentOptions
                                        }
                                      />

                                      <span className="text-danger error-msg">
                                        {o.error}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                className="add-row col-lg-2 col-md-2"
                                style={{ display: "flex", gap: "10px" }}
                              >
                                {filterObj.length <= 3 && i === 0 ? (
                                  <span
                                    className="add-ic icon-plus text-success"
                                    onClick={(e) => addNewRow(e)}
                                    type="button"
                                  ></span>
                                ) : null}
                                {filterObj && filterObj.length >= 2 ? (
                                  <span
                                    className="icon-minus mr-1 text-danger"
                                    onClick={() => removeRow(i)}
                                    type="button"
                                  ></span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </form>
                      );
                    })}
                </div>
                {loadingFilter ? <div></div> : null}
                <div className="col-md-12 text-end pt-4">
                  <button
                    onClick={() => resetFilter()}
                    type="button"
                    className="btn btn-light me-2"
                  >
                    Clear
                  </button>
                  <button
                    onClick={(evt) => ApplyFilter()}
                    type="button"
                    className="btn btn-primary"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Manage Celebrity">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="card mb-5">
              <div className="card-header">
                <h5 className="card-title">Celebrity Information</h5>
              </div>
              <div className="card-body user-details">
                <div className="row">
                  {spin ? (
                    <div className="d-flex align-center justify-content-center">
                      <Spinner />
                    </div>
                  ) : null}
                  <div className="col-md-3 col-lg-3 col-xl-2">
                    <div className="user-image">
                      {" "}
                      <img
                        src={
                          formData?.creatorProfilephoto
                            ? formData?.creatorProfilephoto
                            : "/assets/images/no-user.png"
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-9 col-lg-9 col-xl-5">
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        NAME:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {formData?.firstname} {formData?.lastname}{" "}
                      </div>
                    </div>

                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        EMAIL ID:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {" "}
                        {formData?.emailId ? formData?.emailId : "-"}
                      </div>
                    </div>

                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        GENDER:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {" "}
                        {formData?.gender ? formData?.gender : "-"}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        ADDRESS:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {" "}
                        {formData?.address ? formData?.address : "-"}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        CITY:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {" "}
                        {formData?.city ? formData?.city : "-"}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        INSTAGRAM:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        <a href={formData?.instagramId} target="_blank">
                          {formData?.instagramId ? formData?.instagramId : "-"}
                        </a>
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        STATUS:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        <span
                          className={
                            formData?.status
                              ? "badge text-white bg-secondary"
                              : "badge text-white bg-secondary inactive-btn"
                          }
                        >
                          {" "}
                          {formData?.status ? "Active" : "Inactive"}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-9 offset-md-3 offset-xl-0 col-lg-9 col-xl-5">
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        PHONE NUMBER:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {formData?.mobileNumber ? formData?.mobileNumber : "-"}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        DOB:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {formData?.dob
                          ? moment(formData?.dob).format("L")
                          : "-"}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        STATE:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {formData?.state ? formData?.state : "-"}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        PINCODE:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {formData?.pincode ? formData?.pincode : "-"}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        YOUTUBE
                        <p>
                          <small>(Optional)</small>
                        </p>
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {formData?.youtubeId ? formData?.youtubeId : "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-5">
              <div className="card-header">
                <h5 className="card-title">Apply History</h5>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table
                    className="table row-border nowrap common-datatable"
                    id="master-country-listing"
                  >
                    <thead>
                      <tr>
                        <th>
                          <b>Action</b>
                        </th>

                        <th>
                          <b>Action By</b>
                        </th>

                        <th>
                          <b>Comment</b>
                        </th>

                        <th>
                          <b>Action Date</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {requests && requests?.length === 0 ? (
                        <tr className="text-center text-danger not-found-txt">
                          <td colSpan="6">
                            {!loading ? (
                              <h6
                                className="text-center text-danger not-found-txt"
                                colSpan="6"
                              >
                                {"No requested records are found "}
                              </h6>
                            ) : null}
                          </td>
                        </tr>
                      ) : (
                        requests &&
                        Array.isArray(requests) &&
                        requests?.map((list, key) => {
                          return (
                            <tr key={list?.actionByDetail?.name}>
                              <td>
                                <div className="eamil-col d-flex align-items-center">
                                  <span>{list?.status}</span>
                                </div>
                              </td>

                              <td>
                                <div className="eamil-col d-flex align-items-center">
                                  <span>{list?.actionByDetail?.name}</span>
                                </div>
                              </td>
                              <td>
                                <div className="eamil-col d-flex align-items-center">
                                  <span
                                    style={{
                                      width: "250px",

                                      overflow: "hidden",
                                      whiteSpace: "normal",
                                      wordBreak: "break-word !important",
                                      wordWrap: "break-word !important",
                                    }}
                                  >
                                    {list?.rejectedComment
                                      ? list?.rejectedComment
                                      : "-"}
                                  </span>
                                </div>
                              </td>

                              <td>
                                <div className="eamil-col d-flex align-items-center">
                                  <span>
                                    {moment(list?.actionDate).format(
                                      "l, h:mm:ss a"
                                    )}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="card mb-5">
              <div className="card-header">
                <h5 className="card-title">Published Videos</h5>
              </div>
              <div className="card-header d-flex justify-content-between">
                <div className="left-buttons d-flex ">
                  <div className="d-flex align-items-center">
                    <div className="search position-relative has-icon-left">
                      <input
                        type="search"
                        value={searchText}
                        className="form-control text-capitalize"
                        placeholder="search"
                        onChange={(evt) => {
                          handleSearchChange(evt);
                        }}
                      />

                      {searchText && searchText.length > 0 ? (
                        <div className="clear">
                          <span onClick={clearInput}>
                            <em className="icon-close"></em>
                          </span>
                        </div>
                      ) : null}

                      <div className="form-control-position">
                        <em className="icon-magnifier"></em>
                      </div>
                    </div>
                  </div>

                  <div
                    id="container1"
                    className={
                      filterpopup
                        ? "custom-dropdown filter-data-dropdown position-static ml-2 published-opened open"
                        : "custom-dropdown filter-data-dropdown position-static ml-2"
                    }
                  >
                    <button
                      onClick={() => {
                        openFilterpopup(!filterpopup);
                      }}
                      className="btn btn-bordered dropdown-toggle custom-filter-dropdown btn-bg-white d-flex align-items-center"
                      type="button"
                    >
                      <i className="bx bx-filter d-lg-none"></i>
                      <i className="bx bx-filter-alt align-text-top me-2"></i>
                      Filter
                    </button>
                    {renderFilter()}
                  </div>

                  <div className="form-control-position"></div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  {videosList?.map((video, id) => {
                    return (
                      <div
                        className="col-lg-4 col-xl-3 col-md-4 col-6"
                        key={video?.videoLink + video?._id}
                      >
                        <div className="video-card">
                          <div className="video-img position-relative mb-2">
                            <video
                              controls={true}
                              style={{
                                height: "170px",
                                width: "100%",
                                border: "1px solid grey",
                              }}
                            >
                              <source src={video?.videoLink} type="video/mp4" />
                            </video>
                          </div>
                          <div className="video-info">
                            <div className="d-flex justify-content-between">
                              <h6 className="video-title fw-semibold">
                                {video?.brands[0]?.organization}
                              </h6>
                              <p className="video-date">
                                {" "}
                                {moment(video.createdAt).format("L")}
                              </p>
                            </div>
                            <h6 className="video-title fw-semibold"></h6>
                            <p className="video-title-name">
                              {" "}
                              {video?.videoTitle}
                            </p>
                            <p className="video-title-name text-orange">
                              {video?.celebrityDetails[0]?.firstname}&nbsp;
                              {video?.celebrityDetails[0]?.lastname}
                            </p>
                            <p className="video-title-name"></p>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {videosList && videosList.length < 10 ? (
                    <div>
                      <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                        <p className="mb-0 align-self-center text-primary-light fs-14">
                          Showing <b>{videosList.length}</b> Out of{" "}
                          <b>{videosList.length}</b> videos
                        </p>
                        <div className="pagination-list ms-2">
                          <Pagination
                            className="ant-pagination"
                            pageSize={pagesize}
                            current={page}
                            total={videosList.length}
                            onChange={paginationChange}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                      <p className="mb-0 align-self-center text-primary-light fs-14">
                        Showing <b>10</b> Out of <b>{videosList.length}</b>{" "}
                        videos
                      </p>
                      <div className="pagination-list ms-2">
                        <Pagination
                          className="ant-pagination"
                          pageSize={pagesize}
                          current={page}
                          total={total}
                          onChange={paginationChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  // UserAccess: state.admin.adminData.staticRolePermission.userAccess,
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi })(ViewCelebrity);
