import { combineReducers } from "redux";
import Admin from "./Admin";
import Brand from "./Brand";
import User from "./User";
import Tabs from "./Tabs";
/******************* 
@Purpose : Used for combine all reducer in single place
@Parameter : {Admin}
@Author : INIC
******************/
export default combineReducers({
  admin: Admin,
  brand: Brand,
  tabs: Tabs,
  user: User,
});
