import React from "react";

import { Oval as Loader } from "react-loader-spinner";

const Spinners = ({ fallback = false, height = "100", width = "100" }) => {
  const color = "#1a233a";

  if (fallback) {
    return (
      <>
        <div className="loading-overlay">
          <Loader color={color} height={50} width={50} />
        </div>
      </>
    );
  } else {
    return (
      <div className="loader-wrap">
        <Loader color={color} height={height} width={width} />
      </div>
    );
  }
};

export default Spinners;
