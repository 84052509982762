import { Modal, Button, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import CkEditor from "../../Components/Editor/Ckeditor";

import "suneditor/dist/css/suneditor.min.css";
import {
  showMessageNotification,
  showModalNotification,
} from "../../Utils/Functions";
import {
  updateTheEmailTemplates,
  getEmailTemplatesList,
} from "../../Store/Actions/User";

function EmailTemplateEdit(props) {
  const { callBack } = props;
  let history = useHistory();
  const [spin, setSpin] = useState(false);
  const dispatch = useDispatch();
  const { show, closeModal, data1 } = props;
  const [page] = useState(1);
  const [pagesize] = useState(10);

  const [desc, setDesc] = useState(data1?.emailContent);
  const [, setLoading] = useState(true);
  const [, setTemplatesList] = useState([]);
  const handleChange = (e) => {
    setDesc(e);
  };

  useEffect(() => {
    getTemplatesList();
  }, []);

  const getTemplatesList = async (e) => {
    setLoading(true);
    let body = {
      page: page,
      pagesize: pagesize,
    };
    try {
      dispatch(getEmailTemplatesList(body)).then((response) => {
        if (response.status === 1) {
          setTemplatesList(response?.data?.listing);
        }
        setLoading(false);
      });


    } catch (error) {
      setLoading(false);
      throw error;
    }
  };
  // on change for the email temolates
  const UpdateDescription = async () => {
    setSpin(true);
    let body = {
      emailTitle: data1?.emailTitle,
      subject: data1?.subject,
      emailKey: data1?.emailKey,
      emailContent: desc,
    };
    try {
      dispatch(updateTheEmailTemplates(body)).then((response) => {
        if (response.status === 1) {
          setSpin(false);
          closeModal();
          callBack && callBack();

          showMessageNotification("Edited successfully", "success");
        } else if (response.status === 0) {
          setSpin(false);
          showModalNotification(response?.message, "error");
        }
      });


      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };
  const insertFunction = () => {
    history.push(`/brandaccount`);
  };

  return (
    <Modal show={show} size="lg" centered>
      <Modal.Header closeButton onClick={closeModal}>
        <Modal.Title>Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <h6 className="mb-4"> {data1?.emailTitle}</h6>
        {/* <CKEditor
          // toggleCodeView={handleCodeView}
          html={data1?.emailContent}
          
          // setContents={desc}
          // autoFocus={true}
          // placeholder="Enter the content here"
          // height="400"
          onChange={handleChange}
        /> */}
        <CkEditor html={data1?.emailContent} handleChange={handleChange} />
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button variant="primary"  onClick={insertFunction}>
          Insert Variable
        </Button>
        <div>
        <Button variant="light" className="me-2" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="primary" onClick={UpdateDescription}>
          {/* Save */}
          {spin ? (
            <>
              {" "}
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>{" "}
              Save...
            </>
          ) : (
            "Save"
          )}
        </Button>
        </div>
      
      </Modal.Footer>
    </Modal>
  );
}

export default EmailTemplateEdit;
