//Internal
import React, { useEffect, useState } from "react";
import { Layout, Footer } from "../Layout";
import { brandProfile } from "../../Store/Actions/Brand";
//External
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import moment from "moment";

/*******************
@purpose : used for get  Brand profile
@Parameter : {}
@Author : INIC
******************/
const BrandProfile = () => {
  const brandProfileData = useSelector((data) => data.brand.brandProfile);

  const [active, setActive] = useState("");
  useEffect(() => {
    if (brandProfileData?.status) {
      setActive("Active");
    } else {
      setActive("In-Active");
    }
  }, [brandProfileData]);

  const dispatch = useDispatch();
  const param = useParams();

  /*******************
@purpose : React hook used for get  Brand profile
@Parameter : {}
@Author : INIC
******************/
  useEffect(() => {
    dispatch(brandProfile(param.slug));
  }, []);

  /*******************
@purpose :  used for Brandprofile breadcrumb
@Parameter : {}
@Author : INIC
******************/
  const breadcrumb = [
    {
      key: "6",
      title: "Manage Brand",
      url: "/brandList",
    },
    {
      key: "6",
      title: "Brand Profile",
      url: "/managebrand",
    },
  ];
  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  console.log("brandProfileData", brandProfileData);
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Manage Brand">
      <>
        <div className="dashboard-container">
          <div className="main-content-area">
            <div className="overlay" />
            <div className="main-content-block ">
              <div className="card">
                <div className="card-body user-details">
                  <div className="row">
                    <div className="col-lg-3 col-xxl-2 col-md-12">
                      {brandProfileData?.photo ? (
                        <img
                          className="rounded-5 mx-4"
                          src={brandProfileData?.photo}
                          alt="avatar2"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/assets/images/user.png";
                          }}
                        />
                      ) : (
                        <img
                          className="rounded-5 mx-4"
                          src={"/assets/images/user.png"}
                          alt="avatar"
                        />
                      )}
                    </div>
                    <div className="col-lg-9 col-xxl-10">
                      <div className="row">
                        <div className="col-xxl-6 col-lg-12 col-sm-6">
                          <div className="input-group row g-0">
                            <div className="col-xl-4 col-lg-5 p-2 text-uppercase fw-bold request-title">
                              ORGANIZATION NAME
                            </div>
                            <div className="col-lg-7 col-xl-8 p-2 request-data">
                              {brandProfileData?.organization
                                ? brandProfileData?.organization
                                : "-"}
                            </div>
                          </div>
                          <div className="input-group row g-0">
                            <div className="col-xl-4 col-lg-5 p-2 text-uppercase fw-bold request-title">
                              EMAIL ID
                            </div>
                            <div className="col-lg-7 col-xl-8 p-2 request-data">
                              {brandProfileData?.emailId
                                ? brandProfileData?.emailId
                                : "-"}
                            </div>
                          </div>
                          <div className="input-group row g-0">
                            <div className="col-xl-4 col-lg-5 p-2 text-uppercase fw-bold request-title">
                              ADDRESS
                            </div>
                            <div className="col-lg-7 col-xl-8 p-2 request-data">
                              {brandProfileData?.address
                                ? brandProfileData?.address
                                : "-"}
                            </div>
                          </div>
                          <div className="input-group row g-0">
                            <div className="col-xl-4 col-lg-5 p-2 text-uppercase fw-bold request-title">
                              STATE
                            </div>
                            <div className="col-lg-7 col-xl-8 p-2 request-data">
                              {brandProfileData?.state
                                ? brandProfileData?.state
                                : "-"}
                            </div>
                          </div>
                          <div className="input-group row g-0">
                            <div className="col-xl-4 col-lg-5 p-2 text-uppercase fw-bold request-title">
                              STATUS
                            </div>
                            <div className="col-lg-7 col-xl-8 p-2 request-data">
                              {active ? active : "-"}
                            </div>
                          </div>
                          {/* buisness online */}
                          <div className="input-group row g-0">
                            <div className="col-xl-4 col-lg-5 p-2 text-uppercase fw-bold request-title">
                              Buisness
                            </div>
                            <div className="col-lg-7 col-xl-8 p-2 request-data">
                              {brandProfileData?.isBusinessOnline === undefined
                                ? "-"
                                : brandProfileData?.isBusinessOnline === true
                                ? "Online"
                                : "Offline"}
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-lg-12 col-sm-6">
                          <div className="input-group row g-0">
                            <div className="col-xl-4 col-lg-5 p-2 text-uppercase fw-bold request-title">
                              PHONE NUMBER
                            </div>
                            <div className="col-lg-7 col-xl-8 p-2 request-data">
                              {brandProfileData?.mobileNumber
                                ? brandProfileData?.mobileNumber
                                : "-"}
                            </div>
                          </div>
                          <div className="input-group row g-0">
                            <div className="col-xl-4 col-lg-5 p-2 text-uppercase fw-bold request-title">
                              CONTACT NAME
                            </div>

                            <div className="col-lg-7 col-xl-8 p-2 request-data">
                              {`${brandProfileData?.firstName}${brandProfileData?.lastName}`
                                ? `${brandProfileData?.firstName} ${brandProfileData?.lastName}`
                                : "-"}
                            </div>
                          </div>
                          <div className="input-group row g-0">
                            <div className="col-xl-4 col-lg-5 p-2 text-uppercase fw-bold request-title">
                              COUNTRY
                            </div>
                            <div className="col-lg-7 col-xl-8 p-2 request-data">
                              {brandProfileData?.country
                                ? brandProfileData?.country
                                : "-"}
                            </div>
                          </div>
                          <div className="input-group row g-0">
                            <div className="col-xl-4 col-lg-5 p-2 text-uppercase fw-bold request-title">
                              CITY
                            </div>
                            <div className="col-lg-7 col-xl-8 p-2 request-data">
                              {brandProfileData?.city
                                ? brandProfileData?.city
                                : "-"}
                            </div>
                          </div>
                          {/* current plan */}
                          <div className="input-group row g-0">
                            <div className="col-xl-4 col-lg-5 p-2 text-uppercase fw-bold request-title">
                              Current Plan
                            </div>
                            <div className="col-lg-7 col-xl-8 p-2 request-data">
                              {brandProfileData?.currentPlanData ? (
                                brandProfileData?.currentPlanData?.planDetalis
                                  ?.planTitle
                              ) : (
                                <span className="col-lg-7 col-xl-8 p-2 request-data">
                                  -
                                </span>
                              )}
                            </div>
                          </div>
                          {/* current plan ends */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {brandProfileData?.inactivationHistoryData?.length > 0 && (
                    <div className="row card mb-5 mt-3">
                      <div className="card-header">
                        <h5 className="card-title"> Historical Events</h5>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table row-border nowrap common-datatable"
                            id="master-country-listing"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Action By</b>
                                </th>

                                <th>
                                  <b>In-Activation Reasons</b>
                                </th>

                                <th>
                                  <b>Action Date </b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {brandProfileData?.inactivationHistoryData ===
                              0 ? (
                                <tr className="text-center text-danger not-found-txt">
                                  <td colSpan="6">"No data found"</td>
                                </tr>
                              ) : (
                                brandProfileData?.inactivationHistoryData &&
                                Array.isArray(
                                  brandProfileData?.inactivationHistoryData
                                ) &&
                                brandProfileData?.inactivationHistoryData?.map(
                                  (list) => {
                                    console.log("list", list);
                                    return (
                                      <tr key={list?.actionByDetail?.name}>
                                        <td>
                                          <div className="eamil-col d-flex align-items-center">
                                            <span>
                                              {
                                                list?.inactivatorDetails[0]
                                                  ?.name
                                              }
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="eamil-col d-flex align-items-center">
                                            <span
                                              style={{
                                                width: "250px",

                                                overflow: "hidden",
                                                whiteSpace: "normal",
                                                wordBreak:
                                                  "break-word !important",
                                                wordWrap:
                                                  "break-word !important",
                                              }}
                                            >
                                              {list?.reason
                                                ? list?.reason
                                                : "-"}
                                            </span>
                                          </div>
                                        </td>

                                        <td>
                                          <div className="eamil-col d-flex align-items-center">
                                            <span>
                                              {moment(list?.createdAt).format(
                                                "l, h:mm:ss a"
                                              )}
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
        <footer>
          <div className="footer-text d-flex flex-wrap align-items-center justify-content-between">
            <span className="mr-5">2023 © Vista Admin Panel</span>
            <span>All Rights Reserved</span>
          </div>
        </footer>
      </>
    </Layout>
  );
};

export default BrandProfile;
