import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import { editBrandEmail, searchData } from "../../Store/Actions/Brand";
import { editEmailSchema } from "../../Utils/ValidationSchema";

/******************* 
  @Purpose : Used for change brand email id
  @Parameter : {showModel, onHide, dataId, page, pageSize}
  @Author : INIC
  ******************/
function EditEmailModel({ showModel, onHide, dataId, page, pageSize }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  /******************* 
   @purpose : used for validate and api calling
   @Parameter : {}
   @Author :INIC
   ******************/
  const formik = useFormik({
    initialValues: { brandId: dataId, emailId: "" },
    enableReinitialize: true,
    validationSchema: editEmailSchema,
    onSubmit: async (values) => {
      let data = {
        searchText: "",
        page: page,
        isActive: true,
        pagesize: pageSize,
      };
      setLoading(true);
      dispatch(editBrandEmail(values)).then((res) => {
        if (res) {
          dispatch(searchData(data));
          onHide(false);
          formik.resetForm();
          setLoading(false);
        }
      });
    },
  });

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <>
      <Modal
        show={showModel}
        onHide={() => {
          onHide(false);
          formik.resetForm();
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Email"
                id="emailId"
                {...formik.getFieldProps("emailId")}
              />
            </Form.Group>

            {formik.touched.emailId && formik.errors.emailId ? (
              <span className="text-danger error-msg">
                {formik.errors.emailId}
              </span>
            ) : null}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => formik.handleSubmit()}
            disabled={loading}
          >
            {loading ? (
              <>
                {" "}
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{" "}
                Saving...
              </>
            ) : (
              "Save"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditEmailModel;
