import React from "react";

import { Breadcrumb } from "react-bootstrap";

function TermsAndConditions(props) {
  /******************* 
  @Purpose : Used for Bread crumb 
  @Parameter : {}
  @Author : Vista app
  ******************/

  const header = {
    fontWeight: 500,
  };

  return (
    <div>
      <header className="cms-header border-0">
        <div className="container">
          <div className="navigation-bars">
            <nav className="navbar d-flex navbar-expand bd-navbar justify-content-between">
              <div className="left-nav mr-auto d-flex align-items-center">
                <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                  <ul className=" nav navbar-nav">
                    <li>
                      <img
                        className="img-fluid"
                        src="/assets/images/logo.svg"
                        alt=""
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>

      <div className="cms-content-area">
        <div className="container">
          <Breadcrumb>
            <Breadcrumb.Item> TERMS AND CONDITIONS</Breadcrumb.Item>
            <Breadcrumb.Item>TERMS AND CONDITIONS</Breadcrumb.Item>
          </Breadcrumb>
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="card ">
                <div className="card-header">
                  Please read these Terms and Conditions ("Terms") carefully
                  before using the Vista App operated by KS Solutions ("us,"
                  "we," or "our").
                </div>
                <div className="card-body">
                  <ol className="ps-0">
                    <li>
                      1. <span style={header}> Acceptance of Terms:</span> By
                      accessing or using the App, you agree to be bound by these
                      Terms and all applicable laws and regulations. If you do
                      not agree to these Terms, please do not use the App.
                    </li>
                    <li>
                      2. <span style={header}> Use of the App:</span> (a) The
                      App is intended for personal and non-commercial use. You
                      may not use the App for any illegal or unauthorized
                      purpose. (b) You must provide accurate and complete
                      information when using the App. (c) You are responsible
                      for maintaining the confidentiality of your account
                      information, including your username and password.
                    </li>
                    <li>
                      3. <span style={header}>Intellectual Property:</span> (a)
                      All content and materials available on the App, including
                      but not limited to text, graphics, logos, images, audio
                      clips, videos, data compilations, and software, are the
                      property of KS Solutions or its licensors and are
                      protected by intellectual property laws. (b) You may not
                      modify, copy, distribute, transmit, display, perform,
                      reproduce, publish, license, create derivative works from,
                      transfer, or sell any information, software, products, or
                      services obtained from the App.
                    </li>
                    <li>
                      4. <span style={header}> Limitation of Liability: </span>
                      (a) KS Solutions shall not be liable for any direct,
                      indirect, incidental, consequential, or exemplary damages
                      arising from the use or inability to use the App. (b) We
                      make no warranties or representations regarding the
                      accuracy or completeness of the content on the App.
                    </li>
                    <li>
                      5. <span style={header}> Third-Party Links:</span> The App
                      may contain links to third-party websites or services that
                      are not owned or controlled by KS Solutions. We have no
                      control over and assume no responsibility for the content,
                      privacy policies, or practices of any third-party websites
                      or services.
                    </li>
                    <li>
                      6. <span style={header}> Indemnification:</span>
                      You agree to indemnify and hold KS Solutions harmless from
                      any claim or demand, including reasonable attorneys' fees,
                      made by any third party due to or arising out of your
                      breach of these Terms or your violation of any law or the
                      rights of a third party.
                    </li>

                    <li>
                      7. <span style={header}> Termination:</span>
                      We may terminate or suspend access to the App immediately,
                      without prior notice or liability, for any reason
                      whatsoever, including, without limitation, if you breach
                      these Terms.
                    </li>
                    <li>
                      8. <span style={header}> Governing Law:</span>
                      These Terms shall be governed by and construed in
                      accordance with the laws of Ahmedabad (Gujarat) India
                      Jurisdiction, without regard to its conflict of law
                      provisions.
                    </li>
                    <li>
                      9. <span style={header}>Changes to Terms:</span>
                      KS Solutions reserves the right, at its sole discretion,
                      to modify or replace these Terms at any time. We will
                      indicate the date of the most recent revision at the top
                      of these Terms.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
