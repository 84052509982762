import React from "react";
import { Breadcrumb } from "react-bootstrap";

function Marketing() {
  const divStyle = {
    fontSize: "18px",
    fontWeight: 500,
  };

  const header = {
    fontWeight: 500,
  };
  const footerStyle = {
    fontWeight: 500,
    fontSize: "16px",
    marginRight: "10px",
    color: "grey",
  };
  return (
    <div>
      <header className="cms-header border-0">
        <div className="container">
          <div className="navigation-bars">
            <nav className="navbar d-flex navbar-expand bd-navbar justify-content-between">
              <div className="left-nav mr-auto d-flex align-items-center">
                <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                  <ul className=" nav navbar-nav">
                    <li>
                      <img
                        className="img-fluid"
                        src="/assets/images/logo.svg"
                        alt=""
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>

      <div className="cms-content-area">
        <div className="container">
          <Breadcrumb>
            <Breadcrumb.Item> MARKETING</Breadcrumb.Item>
            <Breadcrumb.Item>MARKETING</Breadcrumb.Item>
          </Breadcrumb>

          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="card ">
                <div className="card-header">
                  Welcome to Vista App: Revolutionizing Reel Based Marketing!
                </div>
                <div className="card-body">
                  <ol className="ps-0">
                    {/*  */}

                    <li>
                      <span style={header}></span>
                      <div className="ml-1 mt-3">
                        <span style={header}>
                          Are you ready to take your marketing strategy to the
                          next level?{" "}
                        </span>
                        <div className="ml-1 mt-3">
                          Introducing Vista App, the cutting-edge mobile
                          application designed to transform the way you engage
                          with your audience. With our innovative Reel Based
                          Marketing concept, you'll captivate your customers
                          like never before.
                        </div>
                      </div>
                    </li>
                    <li>
                      <span style={header}>What is Vista App?</span>
                      <div className="ml-1 mt-3">
                        Vista App is a powerful mobile app that combines the
                        charm of storytelling with the impact of video
                        marketing. Our platform allows you to create stunning,
                        immersive marketing campaigns using reels. Reels are
                        short, captivating videos that engage your audience,
                        increase brand awareness, and drive conversions.
                      </div>
                    </li>
                    <li>
                      <span style={header}>
                        How Does Reel Based Marketing Work?
                      </span>{" "}
                      <div className="ml-1 mt-3">
                        Reel Based Marketing harnesses the power of visual
                        storytelling to create an emotional connection with your
                        audience. With Vista App, you can effortlessly craft
                        compelling reels that showcase your products or services
                        in a captivating way. Our intuitive editing tools and
                        vast library of templates make it easy for you to create
                        professional-looking content, even if you have no prior
                        video editing experience.
                        <div className="ml-3 mt-3">
                          Key Features of Vista App:
                        </div>
                        <ul
                          className="ml-3 mt-3"
                          style={{ marginLeft: "25px" }}
                        >
                          <li style={{ listStyleType: "disc" }}>
                            <span style={divStyle}>
                              Intuitive Reel Creation:
                            </span>
                            &nbsp;Effortlessly create stunning reels using our
                            user-friendly interface and customizable templates.
                            No technical skills required!
                          </li>
                          <li style={{ listStyleType: "disc" }}>
                            <span style={divStyle}>
                              Engaging Visual Effects:
                            </span>
                            Enhance your reels with eye-catching visual effects,
                            filters, and overlays. Grab your audience's
                            attention and make your brand stand out.
                          </li>
                          <li style={{ listStyleType: "disc" }}>
                            <span style={divStyle}>
                              {" "}
                              Music and Soundtracks:{" "}
                            </span>
                            Add the perfect soundtrack to your reels from our
                            extensive library of royalty-free music. Create the
                            right mood and evoke emotions in your viewers.
                          </li>
                          <li style={{ listStyleType: "disc" }}>
                            <span style={divStyle}>Seamless Sharing:</span>{" "}
                            Easily share your reels across multiple social media
                            platforms and reach a wider audience. Amplify your
                            brand's visibility and drive organic growth.
                          </li>
                          <li style={{ listStyleType: "disc" }}>
                            <span style={divStyle}>
                              Performance Analytics:{" "}
                            </span>{" "}
                            Gain valuable insights into the performance of your
                            reels. Track views, engagement, and conversion rates
                            to optimize your marketing campaigns.
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <span style={header}>Why Choose Vista App?</span>
                      <div className="ml-1 mt-3">
                        Vista App is not just another video editing app. We are
                        pioneers of Reel Based Marketing, revolutionizing the
                        way businesses connect with their customers. With our
                        user-friendly interface, powerful features, and
                        dedication to helping you succeed, Vista App is your
                        ultimate marketing companion.
                      </div>
                    </li>
                    <li>
                      <span> Get Started with Vista App Today!</span>
                      <div className="ml-1 mt-3">
                        {" "}
                        Ready to unlock the full potential of Reel Based
                        Marketing? Sign up for Vista App now and experience a
                        new era of captivating storytelling. Engage your
                        audience, drive conversions, and leave a lasting
                        impression with Vista App
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <footer>
              <div className="footer-text footerStyle">
                <span>
                  <a href="/support" target="_blank" style={footerStyle}>
                    Contact
                  </a>
                </span>
                <span style={footerStyle}>&nbsp;|&nbsp;</span>
                <span>
                  <a href="/faq" target="_blank" style={footerStyle}>
                    Frequently Asked Questions (FAQ)
                  </a>
                </span>
                <span style={footerStyle}>&nbsp;|&nbsp;</span>
                <span>
                  <a href="/privacypolicy" target="_blank" style={footerStyle}>
                    Privacy Policy
                  </a>{" "}
                </span>{" "}
                <span style={footerStyle}>&nbsp;| &nbsp;</span>
                <span>
                  <a
                    href="/terms-conditions"
                    target="_blank"
                    style={footerStyle}
                  >
                    Terms of Service
                  </a>
                </span>
                {/* Contact | FAQ | Privacy Policy | Terms of Service */}
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Marketing;
