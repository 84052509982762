import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector, connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import _ from "lodash";
import { IMG_URL } from "../../Config";
import { setItem, showMessageNotification } from "../../Utils/Functions";
import {
  changeLanguage,
  changeTheme,
  changeResize,
  sidebaropen,
  useWindowSize,
} from "../../Store/Actions";
import { callApi } from "../../Api";
import { setUserProfileData } from "../../Store/Actions/User";
import API from "../../Api/Routes";
import BreadCrum from "../BreadCrumb/BreadCrum";

/******************* 
@Purpose : Used for header view
@Parameter : {props}
@Author : INIC
******************/
function Header(props) {
  const profileRedux = useSelector((state) => state.user.profileData);
  const [, setUserPhoto] = useState("");
  const [, setUserName] = useState("");
  const [, setTogglesettings] = useState(false);
  const [, setResizeChecked] = useState(props.resize && props.resize.data);
  const [openSideBar, setOpenSideBar] = useState(
    props.sidebar && props.sidebar.data
  );
  const [profileImage, setProfileImage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    emailId: "",
    photo: "",
    errors: {},
  });

  const [showEditProfile, setShowEditProfile] = useState(false);
  const [, i18n] = useTranslation("language");
  const dispatch = useDispatch();
  const size = useWindowSize();
  const url = props.match.path;

  /******************* 
  @Purpose : React hook used for show edit profile
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (url === "/profile") {
      setShowEditProfile(false);
    } else {
      setShowEditProfile(true);
    }
    i18n.changeLanguage(props.language);
  }, []);

  /******************* 
  @Purpose : React hook used for header image
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (profileRedux) {
      const { name, emailId, mobile, photo } = profileRedux;
      setFormData({
        ...formData,
        name,
        emailId,
        mobile,
        photo,
      });
      if (photo && photo?.match(IMG_URL)) {
        setProfileImage(photo);
      } else {
        setProfileImage(IMG_URL + photo);
      }
    } else {
      getAdminProfile();
    }
  }, [profileRedux]);

  /******************* 
  @Purpose : React hook used for theme
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (props.theme && props.theme.data === true) {
      document.body.classList.add("dark");
      document.body.classList.remove("light");
    } else {
      document.body.classList.add("light");
      document.body.classList.remove("dark");
    }
  }, []);

  /******************* 
  @Purpose : React hook used for add classes
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (props.resize && props.resize.data === true) {
      document.body.classList.add("vertical");
      document.body.classList.remove("horizontal");
    } else {
      document.body.classList.add("horizontal");
      document.body.classList.remove("vertical");
    }
  }, []);

  /******************* 
  @Purpose : React hook used for set size
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (size.width <= 1440) {
      setResizeChecked(false);
      document.body.classList.remove("vertical");
      props.changeResize(false);
    }
  }, [size.width <= 1440]);

  /******************* 
  @Purpose : React hook used for admin data
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (props.admindata && !_.isEmpty(props.admindata)) {
      let { name, photo } = props.admindata;
      if (props.editadminprofile && !_.isEmpty(props.editadminprofile)) {
        let { name: name, photo: uPhoto } = props.editadminprofile;
        setUserName(name);
        setUserPhoto(uPhoto);
      } else {
        setUserName(name);
        setUserPhoto(photo);
      }
    }
  }, [props.admindata, props.editadminprofile]);

  /******************* 
  @Purpose : Used for outer click
  @Parameter : {callback}
  @Author : INIC
  ******************/
  function useOuterClick(callback) {
    const innerSettingsRef = useRef();
    const callbackRef = useRef();
    useEffect(() => {
      callbackRef.current = callback;
    });

    /******************* 
    @Purpose : React hook used for handle click
    @Parameter : {}
    @Author : INIC
    ******************/
    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);

      function handleClick(e) {
        if (
          innerSettingsRef.current &&
          callbackRef.current &&
          !innerSettingsRef.current.contains(e.target)
        ) {
          callbackRef.current(e);
        }
      }
    }, []);

    return innerSettingsRef;
  }

  /******************* 
  @Purpose : Used for outer click setting
  @Parameter : {}
  @Author : INIC
  ******************/
  const settingsRef = useOuterClick(() => {
    setTogglesettings(false);
  });

  /******************* 
  @Purpose : Used for sidebar open
  @Parameter : {}
  @Author : INIC
  ******************/
  const SidebarOpen = () => {
    document.body.classList.add("sidebar-open");
    setOpenSideBar(!openSideBar);
    props.sidebaropen(!openSideBar);
  };
  function profileRedirect() {
    props.history.push("/profile");
  }
  /******************* 
  @Purpose : Used for user logout
  @Parameter : {}
  @Author : INIC
  ******************/
  function logout() {
    callApi("/admin/logout", "", "", "LOGOUT", true);
    setItem("accessToken", "");
    localStorage.clear();
    showMessageNotification("Logged Out Successfully", "success");
    props.history.push("/");
  }

  /******************* 
  @Purpose : Used for change theme settings
  @Parameter : {}
  @Author : INIC
  ******************/

  /******************* 
  @Purpose : Used for get admin profile
  @Parameter : {}
  @Author : INIC
  ******************/
  const getAdminProfile = async () => {
    const response = await callApi(
      API.GET_PROFILE,
      "",
      "get",
      "GET_PROFILE",
      true
    );
    if (response.status === 1) {
      dispatch(setUserProfileData(response.data));
      let { data } = response,
        { name, emailId, mobile, photo } = data;
      setFormData({
        ...formData,
        name,
        emailId,
        mobile,
        photo,
      });
      if (photo === "" || photo === "https://d2lfc46helbwci.cloudfront.net/") {
        setProfileImage("/assets/images/no-user.png");
      } else if (photo && photo?.match(IMG_URL)) {
        setProfileImage(photo);
      } else {
        setProfileImage(IMG_URL + photo);
      }
    }
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
 
  return (
    <div ref={settingsRef} id="container1" className="App">
      <div className="main-content-area">
        <header>
          <div className="navigation-bar">
            <nav className="navbar d-flex navbar-expand bd-navbar fixed-top justify-content-between">
              <div className="left-nav mr-auto d-flex align-items-center">
                <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                  <ul className="nav navbar-nav">
                    <li className="nav-item mobile-menu">
                      <span
                        onClick={() => {
                          SidebarOpen();
                        }}
                        className="nav-link nav-menu-main menu-toggle hidden-xs"
                      >
                        <i className="bx bx-menu"></i>
                      </span>
                    </li>
                  </ul>
                  <ul className="horizontal-brand nav navbar-nav">
                    <li>
                      <Link to="/">
                        <img
                          className="img-fluid"
                          src="/assets/images/brand-logo.svg"
                          alt=""
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
                <BreadCrum
                  breadcrumb={props.breadcrumb}
                  breadcrumbTitle={props.breadcrumbTitle}
                />
              </div>

              <ul className="navbar-nav flex-row ml-md-auto d-md-flex">
                <li className="nav-item user-dropdown dropdown">
                  <Dropdown className="custome-dropdown">
                    <Dropdown.Toggle
                      id="dropdown-basic1"
                      className="custome-btn"
                    >
                      <Link
                        className="nav-link dropdown-toggle dropdown-user-link"
                        to="#"
                        id="userDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <div className="user-nav d-sm-flex d-none">
                          <span className="user-name text-capitalize flex-shrink-0 fw-semibold">
                            {formData.name}
                          </span>
                        </div>

                        <span className="flex-shrink-0">
                          {profileImage ? (
                            <img
                              className="round"
                              src={
                                profileImage
                                  ? profileImage 
                                  : "/assets/images/no-user.png"
                              }
                              height="28"
                              width="28"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/assets/images/no-user.png";
                              }}
                            ></img>
                          ) : (
                            <img
                              className="round"
                              src={"/assets/images/no-user.png"}
                              height="28"
                              width="28"
                            ></img>
                          )}
                        </span>
                      </Link>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {showEditProfile && (
                        <Dropdown.Item onClick={profileRedirect}>
                          <i className="icon-user me-2"></i>Edit Profile
                        </Dropdown.Item>
                      )}

                      <Dropdown.Item onClick={logout}>
                        <i className="icon-logout me-2"></i>Log out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </nav>
          </div>
          <div></div>
        </header>
      </div>
    </div>
  );
}

/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  language: state.admin.language,
  theme: state.admin.theme,
  resize: state.admin.resize,
  sidebar: state.admin.sidebar,
  admindata: state.admin.adminData,
  editadminprofile: state.admin.editAdminProfileData,
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, {
  changeTheme,
  changeLanguage,
  changeResize,
  callApi,
  sidebaropen,
})(withRouter(Header));
