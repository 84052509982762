const INTIAL_STATE = {
  brandData: "",
  inactivebrand: "",
  brandProfile: "",
};
/******************* 
@Purpose : Used for store data in redux
@Parameter : state, action
@Author : INIC
******************/
export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case "SET_BRAND_DATA":
      return Object.assign({}, state, {
        brandData: action.payload,
      });
    case "SET_IN_BRAND_DATA":
      return Object.assign({}, state, {
        inactivebrand: action.payload,
      });
    case "SET_BRAND_PROFILE":
      return Object.assign({}, state, {
        brandProfile: action.payload,
      });

    default:
      return state;
  }
};
