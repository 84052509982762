import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Pagination from "rc-pagination";
import moment from "moment";
import "moment-timezone";
import $ from "jquery";
import { getEmailTemplatesList } from "../../Store/Actions/User";
import { editEmail } from "../../Store/Actions";
import { callApi } from "../../Api";
import { Layout } from "../../Components/Layout";
import Loader from "../../Components/Loader/Loader";

import Spinner from "../../Components/Loader/Spinner";
import "rc-pagination/assets/index.css";
import "react-datepicker/dist/react-datepicker.css";
import "antd/dist/antd.css";
import EmailTemplateEdit from "./EmailTemplateEdit";
import { showModalNotification } from "../../Utils/Functions";

$.DataTable = require("datatables.net");
require("datatables.net-responsive");

/******************* 
@Purpose : Used for email tamplet list
@Parameter : props
@Author : INIC
******************/
function EmailsList(props) {
  const dispatch = useDispatch();
  const [lang] = useTranslation("language");
  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  const [showAddOption] = useState(false);
  const [templatesList, setTemplatesList] = useState([]);
  const [data1, setData1] = useState([]);
  const [show, setShow] = useState(false);
  const [spin, setSpin] = useState(false);
  /******************* 
  @Purpose : Used for Email template access
  @Parameter : {}
  @Author : INIC
  ******************/

  useEffect(() => {
    getTemplatesList();
  }, [page, pagesize]);

  const getTemplatesList = async (e) => {
    setSpin(true);
    setLoading(true);
    let body = {
      page: page,
      pagesize: pagesize,
    };
    try {
      dispatch(getEmailTemplatesList(body)).then((response) => {
        if (response.status === 1) {
          setSpin(false);
          setTemplatesList(response?.data?.listing);
          setTotal(response?.total);
        } else if (response.status === 0) {
          setSpin(false);
          showModalNotification(response?.message, "error");
        }
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  /******************* 
  @Purpose : Used for change pagination
  @Parameter : pageNo, pageSize
  @Author : INIC
  ******************/
  const paginationChange = (pageNo, pageSize) => {
    setPage(pageNo);
    setPagesize(pageSize);
  };
  const handleClose = () => setShow(false);

  /******************* 
  @Purpose : Used for Bread crumb
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      key: "4",
      title: "List Of Email Template",
      url: "/emailTemplates",
    },
  ];
  const handleAddSubCategory = async (user) => {
    setShow(true);
    setData1(user);
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Email Template">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="content-area position-relative card">
              <div className="grid">
                <div className="grid-content card-body">
                  <div className="email-notification-listing-filterOptions mb-2">
                    <div className="mb-2">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="left-buttons d-flex align-items-center">
                          <label className="mr-0 mr-sm-2 mb-0"></label>
                        </div>

                        {showAddOption ? (
                          <div className="right-buttons">
                            <Link
                              onClick={() => {
                                props.history.push("/addemailtemplate");
                                localStorage.removeItem("EmailTemplatesId");
                              }}
                              id="addNewTemplate"
                              type="button"
                              className="btn glow-primary btn-primary minW-0 mr-0"
                            >
                              <i className="icon-user-plus d-lg-none" />
                              <span className="d-none d-sm-none d-lg-inline-block">
                                Add New Template
                              </span>
                            </Link>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {spin ? (
                      <div className="d-flex align-center justify-content-center">
                        <Spinner />
                      </div>
                    ) : null}
                    <table
                      className="table row-border nowrap common-datatable mb-0"
                      id="email-notification-listing"
                    >
                      <thead>
                        <tr>
                          <th
                            className="all"
                          >
                            <b>{"Template"}</b>
                          </th>
                          <th >
                            <b>{"Updated By"}</b>
                          </th>
                          <th >
                            <b>{"Change Date"}</b>
                          </th>

                          <th className="all">
                            <b>{"Action"}</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {templatesList && templatesList.length === 0 ? (
                          <tr className="text-center text-danger not-found-txt">
                            <td colSpan="6">
                              {loading ? <Loader /> : null}

                              {!loading ? (
                                <h6
                                  className="text-center text-danger not-found-txt"
                                  colSpan="6"
                                >
                                  {lang("EmailList.noRecord")}
                                </h6>
                              ) : null}
                            </td>
                          </tr>
                        ) : (
                          templatesList &&
                          Array.isArray(templatesList) &&
                          templatesList.map((user, key) => {
                            return (
                              <tr key={user?._id + user.updatedBy}>
                                <td>
                                  <span> {user.emailTitle}</span>
                                </td>
                                <td>
                                  <span> {user.updatedBy}</span>
                                </td>
                                <td>
                                  <span className="d-block time">
                                    {moment(user?.updatedAt).format(
                                      "l, h:mm:ss a"
                                    )}
                                  </span>
                                </td>

                                <td>
                                  <OverlayTrigger
                                    placement="right"
                                    overlay={<Tooltip id="edit">Edit</Tooltip>}
                                  >
                                    <span
                                      className="icon-note"
                                      onClick={() => handleAddSubCategory(user)}
                                    ></span>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>

                  {templatesList && templatesList.length < 10 ? (
                    <div>
                      <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                        <p className="mb-0 align-self-center text-primary-light fs-14">
                          Showing <b> {templatesList.length} </b> Out of
                          <b> {total} </b> Emails
                        </p>
                        <div className="pagination-list ms-2">
                          <Pagination
                            className="ant-pagination"
                            pageSize={pagesize}
                            current={page}
                            total={total}
                            onChange={paginationChange}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                      <p className="mb-0 align-self-center text-primary-light fs-14">
                        Showing <b> 10 </b> Out of
                        <b> {total} </b> Emails
                      </p>
                      <div className="pagination-list ms-2">
                        <Pagination
                          className="ant-pagination"
                          pageSize={pagesize}
                          current={page}
                          total={total}
                          onChange={paginationChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
      <EmailTemplateEdit
        show={show}
        closeModal={handleClose}
        data1={data1}
        callBack={getTemplatesList}
      />
    </Layout>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  // EmailTemplateAccess:
  // state.admin.adminData.staticRolePermission.emailTemplateAccess,
  language: state.admin.language,
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default withRouter(
  connect(mapStateToProps, { callApi, editEmail })(EmailsList)
);
