import React, { useState, useEffect } from "react";
import Spinner from "../../Components/Loader/Spinner";
import { isEmpty } from "lodash";

import debounce from "lodash.debounce";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Layout from "../../Components/Layout/Layout";
import moment from "moment";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import Pagination from "rc-pagination";
import { useDispatch } from "react-redux";

import {
  showMessageNotification,
  showModalNotification,
} from "../../Utils/Functions";
import { callApi } from "../../Api";
import API from "../../Api/Routes";
import {
  commentedVideos,
  videoSearchReportersVideos,
  downloadReportVideosFormat,
} from "../../Store/Actions/User";

const ReportedComments = () => {
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [, setLoadingFilter] = useState(false);
  const [pagesize, setPagesize] = useState(10);
  const [page, setPage] = useState(1);
  const [brand, setBrand] = useState([]);
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  let [, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [filterpopup, openFilterpopup] = useState(false);
  const [reason, setReason] = useState("Inappropriate Comment");
  const [downloadSelect, setDownloadSelect] = useState("");
  const [videosList, setVideosList] = useState([]);
  const [activeCard, setActiveCard] = useState("Inappropriate Comment");
  const [cardArray, setCardArray] = useState();
  const [searchText, setSearchtext] = useState("");
  const [celebFilter, setCelebFilter] = useState([]);
  const [spin, setSpin] = useState(false);
  const [cardSpin, setCardSpin] = useState(false);
  const [errorsLicence, setErrorsLicence] = useState([
    {
      key: "",
      type: "",
      input: "",
      value: { startDate: "", endDate: "" },
    },
  ]);
  const [filterObj, setFilterObj] = useState([
    {
      key: "",
      type: "",
      input: "",
      value: { startDate: "", endDate: "" },
      condition: "$and",
    },
  ]);
  const dispatch = useDispatch();
  const options = [
    { value: "Excel", label: "Excel" },
    { value: "CSV", label: "CSV" },
  ];

  const clearInput = () => {
    setSearchtext("");
    videosListDisplay1();
  };
  const selectFieldOptions = [
    { value: "category", label: "Category" },
    { value: "brand", label: "Brand" },
    { value: "date", label: "Date" },
  ];
  const selectFieldOptions1 = [
    { value: "category", label: "Category" },
    { value: "subcategory", label: "SubCategory" },
    { value: "brand", label: "Brand" },
    { value: "date", label: "Date" },
  ];
  const selectContentOptions = [
    { value: "category", label: "Content" },
    { value: "date", label: "Date" },
  ];
  /******************* 
  @Purpose : Used for brand list
  @Parameter : {}
  @Author : INIC
  ******************/
  const getBrandList = async () => {
    try {
      let body = {
        searchText: "",
      };

      const response = await callApi(
        API.ALL_BRANDS,
        body,
        "post",
        "ALL_BRANDS",
        true,
        true,
        false
      );

      if (response) {
        const allBrandsList = Array.isArray(response.data)
          ? response.data.map((item) => ({
            ...item,
            label: item.organization,
            value: item._id,
          }))
          : [];
        setBrand(allBrandsList);
      }
    } catch (error) {
      console.error();
    }
  };
  /******************* 
  @Purpose : Used for add new filter records
  @Parameter : e
  @Author : INIC
  ******************/
  const addNewRow = (e) => {
    handleFilterArray();
    setErrorsLicence([
      ...errorsLicence,
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    setFilterObj([
      ...filterObj,
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
  };
  // reset filter
  const resetFilter = () => {
    setFilterObj([
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    setSelectedFilterItems([]);
    setCelebFilter([]);
    setPage(1);
    videosListDisplay1();
    openFilterpopup(!filterpopup);
  };
  /******************* 
@Purpose : Used for validate filter search
@Parameter : props
@Author : INIC
******************/
  function validateFiltersearch() {
    let isFormValid = true;

    let valid = filterObj.map((each) => {
      if (each.key.label === "Category" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the  category fields";
      } else {
        if (each.key.label === "Category") {
          isFormValid = true;
          each.error = "";
        }
      }

      if (each.key.label === "Brand" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the brand fields";
      } else {
        if (each.key.label === "Brand") {
          isFormValid = true;
          each.error = "";
        }
      }
      if (each.key.label === "SubCategory" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the fields";
      } else {
        if (each.key.label === "SubCategory") {
          isFormValid = true;
          each.error = "";
        }
      }
      if (
        each.key.label === "Date" &&
        (each.value.startDate === "" || each.value.endDate === "")
      ) {
        isFormValid = false;
        each.error = "Select the subCategory fields";
      } else {
        if (each.key.label === "Date") {
          isFormValid = true;
          each.error = "";
        }
      }
      return each;
    });

    setFilterObj(valid);
    return isFormValid;
  }

  /******************* 
  @Purpose : Used for remove filter records
  @Parameter : index
  @Author : INIC
  ******************/
  const removeRow = (index) => {
    let array = [...filterObj];
    array.splice(index, 1);
    setFilterObj(array);
    array = [...errorsLicence];
    array.splice(index, 1);
    setErrorsLicence(array);
    setTimeout(function () {
      setLoadingFilter(true);
    }, 100);
  };

  // sub filters
  const getSubCategoryData = () => {
    let categoryItem = filterObj?.filter(
      (item) => item.key.label === "Category"
    );

    let id =
      categoryItem.length > 1
        ? categoryItem[categoryItem.length - 1]?.type?._id
        : categoryItem[0]?.type?._id;

    let categoryFilter = categories?.filter((item) => item._id === id)[0]
      ?.subCategoryDetails;
    let subcategoryData = Array.isArray(categoryFilter)
      ? categoryFilter.map((item) => ({
        ...item,
        label: item.title,
        value: item.title,
      }))
      : [];

    return subcategoryData;
  };
  useEffect(() => {
    getCategoryList();
    getBrandList();
    getCardData();
  }, []);
  // brand data set

  const handleSearchChange = (e) => {
    e.persist();
    if (e.target.value.length === 0) {
      clearInput();
    } else {
      setLoading(true);
      setSearchtext(e.target.value);
      handleDebouncedChange(e.target.value, activeCard);
    }
  };
  const handleDebouncedChange = React.useMemo(
    () => debounce((evt, card) => searchField(evt, card), 1000),
    []
  );
  const searchField = async (evtKey, card) => {
    setLoading(true);

    try {
      if (evtKey) {
        let body = {
          pageNumber: page,
          pageSize: pagesize,
          searchText: evtKey,
          reportedReason: card,
        };
        dispatch(videoSearchReportersVideos(body)).then((response) => {
          if (response.status === 1) {
            setVideosList(response?.data);
            setPage(1);
            setPagesize(10);
          } else if (response.status === 0) {
            showModalNotification("no videos were found", "error");
          }
        });
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };
  const breadcrumb = [
    {
      title: "Reported Comments",
      url: "/ReportedComments",
    },
  ];
  function paginationChange(page, pagesize) {
    setPage(page);
    setPagesize(pagesize);
    videosListDisplay(reason, page, pagesize);
  }

  const videosListDisplay = async (cardReason, pageNo, pageSize) => {
    setLoading(true);
    setVideosList([]);
    setSpin(true);
    const body = {
      pageNumber: pageNo,
      pageSize: pageSize,
      reportedReason: cardReason,
      filter: celebFilter || [],
      searchText: "",
    };
    try {
      dispatch(commentedVideos(body)).then((response) => {
        setSpin(false);
        if (response.status === 1) {
          setVideosList(response?.data);
          setPagesize(10);
          setTotal(response.totalVideos);
          setLoading(false);
        } else if (response.status === 0) {
          showModalNotification(response.message, "error");
          setVideosList([]);
        }
      });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };
  // remove the filters
  const videosListDisplay1 = async (e) => {
    setLoading(true);
    const body = {
      pageNumber: 1,
      pageSize: pagesize,
      reportedReason: reason,
      filter: [],
      searchText: "",
    };
    try {
      dispatch(commentedVideos(body)).then((response) => {
        if (response.status === 1) {
          setVideosList(response?.data);
        } else if (response.status === 0) {
          showModalNotification(response?.message, "error");
          setVideosList([]);
        }
        setPagesize(10);
        setTotal(response.totalVideos);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };
  const onCardClick = (name) => {
    setSearchtext("");
    setFilterObj([
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    setReason(name);
    setActiveCard(name);
    videosListDisplay(name);
  };
  async function handleDownload(e) {
    setDownloadSelect(e);
    let requiredDownload = e.value;

    if (requiredDownload === "Excel") {
      const body = {
        format: "xls",
        reports: "commentreports",
      };

      try {
        dispatch(downloadReportVideosFormat(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showMessageNotification("Downloaded file successfully", "success");
          }
        });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
    if (requiredDownload === "CSV") {
      const body = {
        format: "csv",
        reports: "commentreports",
      };
      try {
        dispatch(downloadReportVideosFormat(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showMessageNotification("Downloaded file successfully", "success");
          }
        });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
  }
  // card data
  const getCardData = async () => {
    setCardSpin(true);
    try {
      const response = await callApi(
        API.REPORTED_COMMENTS_CARD_DATA,
        "",
        "get",
        "REPORTED_COMMENTS_CARD_DATA",
        true,
        true,
        false
      );

      if (response.status === 1) {
        setCardSpin(false);
        setCardArray(response?.data);
        setReason(response?.data[0]?._id.reason);
        setActiveCard(response?.data[0]?._id.reason);

        videosListDisplay(response?.data[0]?._id.reason, page, pagesize);
      } else if (response.status === 0) {
        setCardSpin(false);
        showModalNotification(response.message, "error");
      }
    } catch (error) {
      console.error();
    }
  };

  // render filter
  const renderFilter = () => {
    return (
      <div className="custom-dropdown-menu dropdown-icon card w-50">
        <div className="card-body" style={{ paddingRight: "70px" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-repeat">
                {filterObj &&
                  filterObj.length > 0 &&
                  filterObj.map((o, i) => {
                    let itemType = o.key.label;
                    return (
                      <form
                        key={o.key.label}
                        className="form repeater-default"
                        data-limit="5"
                      >
                        <div className="field-form">
                          <div className="target-form">
                            <div
                              className="target-details"
                              style={{ marginBottom: "10px" }}
                            >
                              <div className="row align-items-start">
                                <div className="form-group col-md-5 col-lg-6 mb-2">
                                  <label className="form-label">
                                    Select Field
                                  </label>

                                  <Select
                                    filterOption={(option) =>
                                      !selectedFilterItems.includes(
                                        option.value
                                      )
                                    }
                                    showSearch
                                    placeholder="Enter Field"
                                    optionFilterProp="children"
                                    className="w-100 custom-input filter-select"
                                    value={o.key}
                                    onChange={(value) => {
                                      o.key = value;
                                      o.type = null;
                                      setFilterObj([...filterObj]);

                                      if (errorsLicence[i])
                                        delete errorsLicence[i].key;
                                    }}
                                    options={
                                      i === 0
                                        ? selectFieldOptions
                                        : selectFieldOptions1
                                    }
                                  />

                                  <span className="text-danger error-msg"></span>
                                </div>
                                {itemType === "Date" ? (
                                  <div className="col-md-5 col-lg-6 mb-2">
                                    <div className="input-daterange custom-daterange input-control">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <label className="form-label">
                                            Select Start date
                                          </label>
                                          <DatePicker
                                            selected={
                                              o.value.startDate
                                                ? new Date(o.value.startDate)
                                                : ""
                                            }
                                            placeholderText="Select Start Date"
                                            dateFormat="MMM d yyyy"
                                            className="form-control w-100"
                                            onChange={(evt) => {
                                              o.value.startDate = evt;
                                              setFilterObj([...filterObj]);
                                              if (errorsLicence[i])
                                                delete errorsLicence[i].value
                                                  .startDate;
                                            }}
                                          />
                                          <span className="text-danger error-msg">
                                            {o.error}
                                          </span>
                                        </div>
                                        <div className="col-md-6">
                                          <label className="form-label">
                                            Select end date
                                          </label>

                                          <DatePicker
                                            selected={
                                              o.value.endDate
                                                ? new Date(o.value.endDate)
                                                : ""
                                            }
                                            minDate={
                                              o.value.startDate
                                                ? new Date(o.value.startDate)
                                                : new Date()
                                            }
                                            disabled={
                                              o.value.startDate ? false : true
                                            }
                                            dateFormat="MMM d yyyy"
                                            placeholderText="Select End Date"
                                            className="form-control w-100"
                                            onChange={(evt) => {
                                              o.value.endDate = evt;
                                              setFilterObj([...filterObj]);
                                              if (errorsLicence[i])
                                                delete errorsLicence[i].value
                                                  .endDate;
                                            }}
                                          />
                                          <span className="text-danger error-msg">
                                            {o.error}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="form-group col-md-6 col-lg-6 mb-2">
                                    <label className="text-left">
                                      {o.key.label === "Category"
                                        ? "Select Content "
                                        : "Select Content (max 5)"}
                                    </label>
                                    <Select
                                      closeMenuOnSelect={
                                        o.key.label === "Category"
                                          ? true
                                          : false
                                      }
                                      isMulti={
                                        o.key.label === "Category"
                                          ? false
                                          : true
                                      }
                                      showSearch={false}
                                      placeholder="Select Type"
                                      className="w-100 custom-input filter-select"
                                      optionFilterProp="children"
                                      value={o.type}
                                      isOptionDisabled={() =>
                                        o.type?.length >= 5
                                      }
                                      onChange={(value) => {
                                        o.type = value;
                                        if (o.type === "Date") {
                                          delete o.value;
                                          o.value = {
                                            startDate: "",
                                            endDate: "",
                                          };
                                        }
                                        setFilterObj([...filterObj]);
                                        handleFilterArray();
                                        if (errorsLicence[i])
                                          delete errorsLicence[i].type;
                                      }}
                                      options={
                                        o.key.label === "Category"
                                          ? categories
                                          : o.key.label === "SubCategory"
                                            ? getSubCategoryData()
                                            : o.key.label === "Brand"
                                              ? brand
                                              : selectContentOptions
                                      }
                                    />

                                    <span className="text-danger error-msg">
                                      {o.error}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className="add-row col-lg-2 col-md-2"
                              style={{ display: "flex", gap: "10px" }}
                            >
                              {filterObj.length <= 3 && i === 0 ? (
                                <span
                                  className="add-ic icon-plus text-success"
                                  onClick={(e) => addNewRow(e)}
                                  type="button"
                                ></span>
                              ) : null}
                              {filterObj && filterObj.length >= 2 ? (
                                <span
                                  className="icon-minus mr-1 text-danger"
                                  onClick={() => removeRow(i)}
                                  type="button"
                                ></span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </form>
                    );
                  })}
              </div>
            </div>

            <div className="col-md-12 text-end pt-4">
            <button
                onClick={() => resetFilter()}
                type="button"
                className="btn btn-light me-2"
              >
                Clear
              </button>
              <button
                onClick={(evt) => ApplyFilter()}
                type="button"
                className="btn btn-primary "
              >
                Search
              </button>
             
            </div>
          </div>
        </div>
      </div>
    );
  };
  //
  // filterArray
  const handleFilterArray = () => {
    let selected = filterObj.map((item) => item.key.value);
    setSelectedFilterItems(selected);
  };

  // apply filter data

  // apply filter

  const ApplyFilter = async () => {
    try {
      if (validateFiltersearch()) {
        let payload = filterObj.map((item) => {
          if (item.key.label === "SubCategory" || item.key.label === "Brand") {
            return item.type.map((val) => {
              return {
                key:
                  item.key.label === "SubCategory"
                    ? "subCategoryId"
                    : item.key.label === "Brand"
                      ? "brandId"
                      : "",
                type: "contains",
                value: val._id,
              };
            });
          }
          return {
            key:
              item.key.label === "Category"
                ? "categoryId"
                : "Date"
                  ? "date"
                  : "",
            type: item.key.label === "Date" ? "date" : "contains",

            value:
              item.key.label === "Date"
                ? {
                  startDate: moment(item.value.startDate).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  ),
                  endDate: moment(item.value.endDate).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  ),
                }
                : item.type._id,
          };
        });

        setLoading(true);

        const body = {
          pageNumber: page,
          pageSize: pagesize,
          searchText: "",
          filter: payload.flat(),
          reportedReason: activeCard,
        };

        dispatch(commentedVideos(body)).then((response) => {
          if (response.status === 1) {
            setVideosList(response?.data);
            setCelebFilter(payload.flat());
            openFilterpopup(!filterpopup);
            setPage(1);
            setPagesize(10);
            setTotal(response.totalVideos);
            setLoading(false);
          } else if (response.status === 0) {
            showModalNotification("No videos were found", "error");
            setVideosList([]);
            setTotal(0);
          }
        });
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };
  const getCategoryList = async () => {
    try {
      const response = await callApi(
        API.GET_CATEGORY,
        "",
        "get",
        "GET_CATEGORY",
        true,
        true,
        false
      );

      if (response) {
        const categoryData = Array.isArray(response.data)
          ? response.data.map((item) => ({
            ...item,
            label: item.title,
            value: item.title,
          }))
          : [];
        setCategories(categoryData);
      }
    } catch (error) {
      console.error();
    }
  };
  return (
    <>
      <Layout breadcrumb={breadcrumb} breadcrumbTitle="Reported Comments">
        <div className="dashboard-container">
          <div className="main-content-area">
            <div className="overlay" />
            <div className="main-content-block">
              <div className="mb-5 col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h2 className="card-title">Reported Reasons</h2>
                  </div>
                  <div className="card-body">
                    {cardSpin ? (
                      <div className="d-flex align-center justify-content-center">
                        <Spinner />
                      </div>
                    ) : null}
                    <Row className="g-4">
                      {cardArray?.map((data) => {
                        return (
                          <Col xs={12} sm={6} xl={4} xxl={3} key={data?._id}>
                            <div className="listed-box-view">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="view">
                                    Please click here to see the list of videos
                                    below
                                  </Tooltip>
                                }
                              >
                                <div
                                  // className="user-info-card d-flex align-items-center flex-column justify-content-center py-3 pointer"
                                  className={`user-info-card  ${activeCard === data?._id?.reason
                                      ? "highlight"
                                      : null
                                    } d-flex align-items-center flex-column justify-content-center py-3 pointer`}
                                  onClick={() => onCardClick(data?._id?.reason)}
                                >
                                  <h6 className="text-white mb-0">
                                    {data?._id?.reason}
                                  </h6>

                                  <h3 className="text-white m-0 pt-2 fw-bold">
                                    {data?.reportsCount}
                                  </h3>
                                </div>
                              </OverlayTrigger>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </div>
              </div>

              {/* video data card data */}
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="left-buttons d-flex">
                    {/* search opt */}
                    <div className="d-flex align-items-center">
                      <div className="search position-relative has-icon-left">
                        {/* search field */}
                        <input
                          type="text"
                          value={searchText}
                          className="form-control text-capitalize"
                          placeholder="search"
                          onChange={(evt) => {
                            handleSearchChange(evt);
                          }}
                        />

                        {searchText && searchText.length > 0 ? (
                          <div className="clear">
                            <span onClick={clearInput}>
                              <em className="icon-close"></em>
                            </span>
                          </div>
                        ) : null}

                        <div className="form-control-position">
                          <em className="icon-magnifier"></em>
                        </div>
                      </div>
                    </div>

                    {/* filter starts  */}
                    <div
                      id="container1"
                      className={
                        filterpopup
                          ? "custom-dropdown filter-data-dropdown position-static ml-2 open"
                          : "custom-dropdown filter-data-dropdown position-static ml-2"
                      }
                    >
                      <button
                        onClick={() => {
                          openFilterpopup(!filterpopup);
                        }}
                        className="btn btn-bordered dropdown-toggle custom-filter-dropdown btn-bg-white d-flex align-items-center"
                        type="button"
                      >
                        <i className="bx bx-filter d-lg-none"></i>
                        <i className="bx bx-filter-alt align-text-top me-2"></i>
                        Filter
                      </button>
                      {renderFilter()}
                    </div>
                    {/* filter ends */}
                    <div className="form-control-position"></div>
                  </div>
                  <Select
                    id="download"
                    name="download"
                    options={options}
                    placeholder="Download"
                    onChange={(e) => {
                      handleDownload(e);
                    }}
                    value={downloadSelect}
                    className="download-csv"
                  />
                </div>
                <div className="card-body">
                  <div className="row">
                    {spin ? (
                      <div className="d-flex align-center justify-content-center">
                        <Spinner />
                      </div>
                    ) : null}
                    {videosList && videosList.length === 0 ? (
                      <div className="text-center text-danger not-found-txt">
                        {" "}
                        {/* No videos Found! */}
                      </div>
                    ) : (
                      ""
                    )}

                    {/* videos list code  display*/}
                    {videosList?.map((video) => {
                      return (
                        <div
                          className="col-lg-4 col-xl-3 col-md-4 col-6"
                          key={video?._id + video?.videoTitle}
                        >
                          <Link
                            onClick={() =>
                              history.push(
                                `/reportedcommentdetail/${video._id}`
                              )
                            }
                          >
                            <div className="video-card">
                              <div className="video-img position-relative mb-2">
                                <video
                                  controls={true}
                                  width="100%;"
                                  // className="border border-secondary border-1 video-box"
                                  height="250"
                                >
                                  <source
                                    src={video?.videoLink}
                                    type="video/mp4"
                                  />
                                </video>
                              </div>
                              <div className="video-info">
                                <h6 className="video-title fw-bold text-secondary">
                                  {video?.brands &&
                                    video?.brands[0] &&
                                    video?.brands[0]?.organization
                                    ? video?.brands[0]?.organization
                                    : ""}
                                </h6>
                                <p className="video-date mb-1">
                                  Published At:{" "}
                                  {moment(video?.createdAt).format("L")}
                                </p>
                                <h6 className="video-title fw-semibold  text-secondary mb-1">
                                  {" "}
                                  {video?.videoTitle}
                                </h6>

                                <p className="video-title-name text-orange">
                                  {video?.postedByBrand ? "By : Brand" : ""}
                                </p>
                                {!video?.postedByBrand && (
                                  <p className="video-title-name text-orange">
                                    {video?.celebrityDetails &&
                                      video?.celebrityDetails[0]?.firstname
                                      ? ` By : ${video?.celebrityDetails[0]?.firstname}`
                                      : ""}
                                    &nbsp;
                                    {video?.celebrityDetails &&
                                      video?.celebrityDetails[0]?.lastname
                                      ? video?.celebrityDetails[0]?.lastname
                                      : ""}
                                  </p>
                                )}

                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}

                    {/* display ends here */}

                    {/* pagination */}
                    <div className="col-lg-12 text-end">
                      {videosList && videosList?.length < 10 ? (
                        <div>
                          {/* less than 10 logic */}
                          <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                            <p className="mb-0 align-self-center text-primary-light fs-14">
                              Showing <b>{total}</b> Out of <b>{total}</b>{" "}
                              videos
                            </p>
                            <div className="pagination-list ms-2">
                              <Pagination
                                className="ant-pagination"
                                pageSize={pagesize}
                                current={page}
                                total={total}
                                onChange={paginationChange}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                          <p className="mb-0 align-self-center text-primary-light fs-14">
                            Showing <b>10</b> Out of <b>{total}</b> videos
                          </p>
                          <div className="pagination-list ms-2">
                            <Pagination
                              className="ant-pagination"
                              pageSize={pagesize}
                              current={page}
                              total={total}
                              onChange={paginationChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer>
              <div className="footer-text d-flex align-items-centerf justify-content-between">
                <span className="">2023 © Vista Admin Panel</span>
                <span>All Rights Reserved</span>
              </div>
            </footer>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ReportedComments;
