import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { searchData, searchInActiveBrand } from "../../Store/Actions/Brand";

/******************* 
@Purpose : Used for Filter Model
@Parameter : {props}
@Author : Vista app
******************/
const FilterModel = (props) => {
  const { setFilterValue, filterValue } = props;
  const defaultFilter = {
    key: "",
    type: "contains",
    value: "",
    filterType: "",
    filterOptions: [],
  };
  const { tabs } = useSelector((data) => data?.tabs);
  const [filterData, setFilterData] = useState([defaultFilter]);
  const dispatch = useDispatch();
  const [number] = useState(1);
  const [size] = useState(10);
  const [filterType, setFilterType] = useState("Or");
  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState(true);
  const [brandDetail, setBrandDetail] = useState([]);
  const containes = [{ value: "contains", label: "contains" }];

  const BrandInActiveDetails = useSelector(
    (data) => data?.brand?.inactivebrand?.data
  );
  const BrandActiveDetails = useSelector(
    (data) => data?.brand?.brandData?.data
  );

  const [, setSelectedFilterItems] = useState([]);
  const [status, setStatus] = useState(true);
  const options = [
    { value: "organizationId", label: "Name" },
    { value: "state", label: "state" },
    { value: "city", label: "city" },
    { value: "isBusinessOnline", label: "Buisness" },
  ];

  /******************* 
  @Purpose : Used for brand listing
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (props.show) {
      if (tabs === "In-Active Brand") {
        setBrandDetail(BrandInActiveDetails);
        setStatus(false);
      } else if (tabs === "Activated Brand") {
        setBrandDetail(BrandActiveDetails);
        setStatus(true);
      } else {
        setStatus(true);
      }

      let body = {
        searchText: props.searchText,
        pageSize: "10",
        isActive: true,
        filter: filterData,
      };
      dispatch(searchData(body));
    }
  }, [tabs, props.show]);

  /******************* 
  @Purpose :  handle And/Or toggle condition
  @Parameter : {type=minus/Add,index}
  @Author : INIC
  ******************/
  const handleManageFilter = (index, type = "minus") => {
    handleFilterArray();
    const filterRow = filterData;
    if (type === "add") {
      defaultFilter.filterType = filterType;
      filterRow.push(defaultFilter);
    } else {
      filterRow.splice(index, 1);
    }
    setFilterData([...filterRow]);
    setFilterValue([...filterRow]);
  };

  /******************* 
  @Purpose : Used for handle filter data
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleFilterArray = () => {
    let selected = filterData.map((item) => item.key);
    setSelectedFilterItems(selected);
  };
  /******************* 
   @purpose : used for validate and api calling
   @Parameter : {}
   @Author :INIC
   ******************/
  const formik = useFormik({
    initialValues: filterData,
    enableReinitialize: true,
    validationSchema: "",
    onSubmit: async (values) => {
      let body = {
        searchText: "",
        isActive: true,
        filter: values,
      };

      if (status) {
        const res = await dispatch(searchData(body, number, size));
        if (res) {
          setFilterData([defaultFilter]);
          setSelectedFilterItems([]);
          props.setPage(1);
          props.onHide();
        } else {
          props.onHide();
        }
      } else {
        let data = {
          searchText: "",
          isActive: false,
          filter: values,
        };
        const res = await dispatch(searchInActiveBrand(data));
        if (res) {
          setFilterData([defaultFilter]);
          setSelectedFilterItems([]);
          props.onHide();
        } else {
          props.onHide();
        }
      }
    },
  });

  /******************* 
  @Purpose : Used for get brand data from redux
  @Parameter : {brandList}
  @Author : INIC
  ******************/
  const brandData = Array.isArray(brandDetail)
    ? brandDetail.map((item) => ({
      label: item?.organization,
      value: item._id,
    }))
    : [];

  let brandState = [];
  brandDetail?.filter(
    (item) =>
      item.state &&
      brandState.push({
        label: item?.state,
        value: item?.state,
      })
  );

  let brandCity = [];
  brandDetail?.filter((item) => {
    return (
      item.city &&
      brandCity.push({
        label: item?.city,
        value: item?.city,
      })
    );
  });

  const buisnessOnlineOptions = [
    { value: true, label: "Online" },
    { value: false, label: "Offline" },
  ];

  /******************* 
  @Purpose :  React hook used for filter search button disable and enable
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    filterData?.forEach((data) => {
      if ((data.key != "" && data.value != "") || data.value === false) {
        setIsSearchButtonDisabled(false);
      } else {
        setIsSearchButtonDisabled(true);
      }
    });
  }, [filterData]);

  /*******************
  @Purpose : Used for filter key selection and set value
  @Parameter : {filterField, index}
  @Author : INIC
  ******************/
  const handleFilterTypeFields = (filterField, index) => {
    const filterRow = filterData;
    filterRow[index].key = filterField;
    setFilterData([...filterRow]);
    switch (filterField) {
      case "organizationId":
        filterRow[index].filterOptions = brandData;
        setFilterData([...filterRow]);
        break;
      case "state":
        filterRow[index].filterOptions = brandState;
        setFilterData([...filterRow]);
        break;
      case "city":
        filterRow[index].filterOptions = brandCity;
        setFilterData([...filterRow]);
        break;

      case "isBusinessOnline":
        filterRow[index].filterOptions = buisnessOnlineOptions;
        setFilterData([...filterRow]);
        break;
    }
  };

  const handleValueChange = (fieldValue, index) => {
    handleFilterArray();
    const filterRow = filterData;

    filterRow[index].value = fieldValue;
    setFilterData([...filterRow]);
    setFilterValue([...filterRow]);
  };

  const handleFiltertags = (index) => {
    switch (filterData[index].key) {
      case "organizationId":
        return (
          <Select
            options={filterData[index].filterOptions}
            classNamePrefix="react-select"
            className="react-select-wrap"
            onChange={(e) => handleValueChange(e.value, index)}
          />
        );
      case "state":
        return (
          <Select
            options={filterData[index].filterOptions}
            classNamePrefix="react-select"
            className="react-select-wrap"
            onChange={(e) => handleValueChange(e.value, index)}
          />
        );
      case "city":
        return (
          <Select
            options={filterData[index].filterOptions}
            classNamePrefix="react-select"
            className="react-select-wrap"
            onChange={(e) => {
              handleValueChange(e.value, index);
            }}
          />
        );
      case "isBusinessOnline":
        return (
          <Select
            options={filterData[index].filterOptions}
            classNamePrefix="react-select"
            className="react-select-wrap"
            onChange={(e) => handleValueChange(e.value, index)}
          />
        );
      default:
        return (
          <Select
            classNamePrefix="react-select"
            className="react-select-wrap"
            onChange={(e) => handleValueChange(e.value, index)}
          />
        );
    }
  };

  const handleResetFilter = async () => {
    if (status) {
      let body = {
        searchText: "",
        pageNumber: "1",
        pageSize: "10",
        isActive: true,
        filter: "",
      };
      const res = await dispatch(searchData(body));
      if (res) {
        setFilterData([defaultFilter]);
        setSelectedFilterItems([]);
        props.onHide();
      }
    } else {
      let data = {
        searchText: "",
        pageNumber: "1",
        pageSize: "10",
        isActive: false,
        filter: "",
      };
      const res = await dispatch(searchInActiveBrand(data));
      if (res) {
        setFilterData([defaultFilter]);
        setSelectedFilterItems([]);
        props.onHide();
      }
    }
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="filter-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Filter</Modal.Title>
      </Modal.Header>
      <Modal.Body closeButton className="pb-2">
        <div className="dorpdown-inner">
          {filterData?.map((filter, index) => (
            <div key={filter.id}>
              <div className="row  mb-3 ">
                <div className="col-sm-6 mb-2">
                  <Select
                    options={options}
                    classNamePrefix="react-select"
                    className="react-select-wrap"
                    onChange={(e) => handleFilterTypeFields(e.value, index)}
                  />
                </div>
                <div className="col-sm-6 mb-2">
                  {handleFiltertags(index)}
                </div>
              </div>
              {filterData.length > 1 && index !== filterData.length - 1 && (
                <div className="or-group-wrap mb-5 d-flex align-items-center">
                  {/* {filter.filterType === "And" ? (
                    <Button variant="light">
                      <i className="icon-plus me-1"></i> And
                    </Button>
                  ) : (
                    <Button variant="light">
                      <i className="icon-plus me-1"></i> Or
                    </Button>
                  )} */}
                  <Button
                    variant="light"
                    onClick={() => handleManageFilter(index, "minus")}
                  >
                    <i className="icon-minus"></i>
                  </Button>
                </div>
              )}
            </div>
          ))}

          {filterData.length <= props.field && (
            <div className="or-group-wrap mb-5 d-flex align-items-center">
              <Button
                variant="light"
                onClick={() => setFilterType("And")}
                active={filterType === "And"}
              >
                <i className="icon-plus me-1"></i> And
              </Button>
              <Button
                variant="light"
                onClick={() => setFilterType("Or")}
                active={filterType === "Or"}
              >
                <i className="icon-plus me-1"></i> Or
              </Button>
              <Button
                variant="light"
                onClick={() => handleManageFilter(null, "add")}
              >
                <i className="icon-plus"></i>
              </Button>
            </div>
          )}


        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="text-end">
          <Button
            variant="light"
            className="me-2"
            onClick={() => handleResetFilter()}
          >
            Reset
          </Button>
          <Button
            variant="primary"

            onClick={() => formik.handleSubmit()}
            disabled={isSearchButtonDisabled}
          >
            Search
          </Button>


        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterModel;
