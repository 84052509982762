import React, { useState, useEffect } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Spinner from '../../Components/Loader/Spinner';
import { videoStatusChange, videoHide } from '../../Store/Actions/User';
import {
  showMessageNotification,
  showModalNotification,
} from '../../Utils/Functions';
import Layout from '../../Components/Layout/Layout';

/******************* 
@Purpose : Used for video detail page
@Parameter : {}
@Author : INIC
******************/
function VideoDetailPage() {
  const dispatch = useDispatch();
  const [spin, setSpin] = useState(false);
  const [hideVideo, setHideVideo] = useState([]);
  const [videoDetails, setVideoDetails] = useState([]);
  const { slug } = useParams();

  /******************* 
  @Purpose : Used for Bread crumb
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      title: 'Manage Videos',
      url: '/managevideos',
    },
    {
      title: 'Video Detail',
    },
  ];

  /******************* 
  @Purpose : Used for card data
  @Parameter : {}
  @Author : INIC
  ******************/
  const cardData = [
    {
      id: 1,
      name: 'Video Views',
      count: videoDetails?.viewCount,
      iconView: 'bx bx-video',
    },
    {
      id: 2,
      name: 'Product Views',
      count: videoDetails?.affiliateClickCount,
      iconView: 'bx bx-show',
    },
    // { id: 3, name: "Total Purchase", count: 0, iconView: "bx bx-rupee" },
  ];

  /******************* 
  @Purpose : React hook used for change status
  @Parameter : {slug}
  @Author : INIC
  ******************/
  useEffect(() => {
    changeStatus(slug);
  }, [slug]);

  /******************* 
  @Purpose : Used for change status api calling
  @Parameter : {id}
  @Author : INIC
  ******************/
  const changeStatus = async (id) => {
    setSpin(true);
    let body = {
      videoId: id,
    };
    try {
      dispatch(videoStatusChange(body)).then((response) => {
        if (response.status === 1) {
          setSpin(false);
          setVideoDetails(response?.data[0]);
          setHideVideo(response?.data[0]?.status);
        } else if (response.status === 0) {
          setSpin(false);
          showModalNotification(response.message, 'error');
        }
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  /******************* 
  @Purpose : Used for hide video
  @Parameter : {slug}
  @Author : INIC
  ******************/
  const hideManageVideo = async (slug) => {
    let body = {
      videoId: slug,
    };
    try {
      dispatch(videoHide(body)).then((response) => {
        if (response.status === 1) {
          setHideVideo(!hideVideo);
          showMessageNotification(response?.message, 'success');
        } else {
          showMessageNotification(response?.message, 'error');
        }
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Manage Videos ">
      <div>VideoDetailPage</div>
      <div className="main-content-area">
        <div className="main-content-block">
          <div className="card mb-4">
            <div className="card-header">
              <h5 className="card-title">Video Detail</h5>
            </div>
            <div className="card-body user-details">
              {spin ? (
                <div className="d-flex align-center justify-content-center">
                  <Spinner />
                </div>
              ) : null}
              <div className="row">
                <div className="col-lg-4 col-xxl-3 col-md-12">
                  <div className="video-img position-relative">
                    {videoDetails?.videoLink ? (
                      <video
                        controls={true}
                        className="br-12"
                        // poster="https://peach.blender.org/wp-content/uploads/title_anouncement.jpg?x11217"
                        style={{
                          height: '170px',
                          width: '100%',
                        }}
                      >
                        <source
                          src={videoDetails?.videoLink}
                          type="video/mp4"
                        />
                      </video>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-8 col-xxl-9">
                  <div className="row">
                    <div className="col-xl-8 col-xxl-6">
                      <div className="row g-0">
                        <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                          Title
                        </div>
                        <div className="col-md-8 p-2 request-data text-secondary">
                          {videoDetails?.videoTitle}
                        </div>
                      </div>
                      <div className="row g-0">
                        <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                          SUB-CATEGORY
                        </div>
                        <div className="col-md-8 p-2 request-data text-secondary">
                          {Array.isArray(videoDetails?.subcategories)
                            ? videoDetails?.subcategories[0]?.title
                            : ''}
                        </div>
                      </div>
                      <div className="row g-0">
                        <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                          PRODUCT LINK
                        </div>
                        <div className="col-md-8 p-2 request-data text-secondary">
                          <a
                            href={videoDetails?.affilietedProductUrl}
                            target="_blank"
                          >
                            {videoDetails?.affilietedProductUrl}
                          </a>
                          <span className="error-msg text-danger"></span>
                        </div>
                      </div>
                      <div className="row g-0">
                        <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                          HIDE VIDEO ?
                        </div>
                        <div className="col-md-8 p-2 request-data text-secondary">
                          <Form.Check
                            type="switch"
                            className="custom-control-input"
                            // onChange={() => {
                            //   hideManageVideo(videoDetails?._id);
                            // }}
                            onChange={function (e) {
                              hideManageVideo(videoDetails?._id);
                            }}
                            checked={hideVideo}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-8 col-xxl-6">
                      <div className="row g-0">
                        <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                          CATEGORY
                        </div>
                        <div className="col-md-8 p-2 request-data text-secondary">
                          {Array.isArray(videoDetails?.category)
                            ? videoDetails?.category[0]?.title
                            : ''}
                        </div>
                      </div>
                      <div className="row g-0">
                        <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                          CELEBRITY
                        </div>
                        <div className="col-md-8 p-2 request-data text-secondary">
                          {Array.isArray(videoDetails?.celebrityDetails)
                            ? (videoDetails?.celebrityDetails[0]?.firstname ||
                                '') +
                              '  ' +
                              (videoDetails?.celebrityDetails[0]?.lastname ||
                                '')
                            : ''}
                        </div>
                      </div>
                      <div className="row g-0">
                        <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                          PUBLISH DATE
                        </div>
                        <div className="col-md-8 p-2 request-data text-secondary">
                          {videoDetails?.createdAt
                            ? moment(videoDetails.createdAt).format('L')
                            : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* cards */}
          <div className="card mb-4">
            <div className="card-header ">
              <h5 className="card-title">Summary</h5>
            </div>
            <div className="card-body">
              <div className="row g-5">
                {cardData?.map((data, id) => {
                  return (
                    <Col md={6} xl={4} xxl={3} key={data?._id}>
                      <div className="user-info-card col d-flex flex-wrap align-items-center p-3">
                        <div className="d-flex align-items-center">
                          <div className="icon-box-wrap">
                            <i className={data?.iconView}></i>
                          </div>
                          <div className="w-100 font-32">
                            <h3 className="text-black m-0 fw-bold">
                              {data.count}
                            </h3>
                            <h6 className="text-orange mb-0 pt-1">
                              {data.name}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <footer>
          <div className="footer-text d-flex align-items-centerf justify-content-between">
            <span className="">2023 © Vista Admin Panel</span>
            <span>All Rights Reserved</span>
          </div>
        </footer>
      </div>
    </Layout>
  );
}

export default VideoDetailPage;
