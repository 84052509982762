import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { store, persistor } from "./Store";
import { getItem } from "./Utils/Functions";
import AdminLogin from "./Pages/Authentication/AdminLogin";
import AdminUsers from "./Pages/Users/AdminUsers";
import AddUser from "./Pages/Users/AddUser";
import AddRoles from "./Pages/RoleAccess/AddRole";
import AddPlan from "./Pages/Settings/AddPlan";
import AccessManagement from "./Pages/RoleAccess/AccessManagement";
import BrandList from "./Pages/Brands/BrandList";
import BrandAccount from "./Pages/BrandAccount/BrandAccount";
import BrandProfile from "./Components/BrandProfile/BrandProfile";
import CelebrityList from "./Pages/Celebrity/CelebrityList";
import ChangePassword from "./Pages/Authentication/ChangePassword";
import CMS from "./Pages/Cms/CMS";
import Dashboard from "./Pages/Dashboard/Dashboard";

import EmailsList from "./Pages/EmailsTemplates/EmailsList";
import EditRole from "./Pages/RoleAccess/EditRole";

import EditAdminUser from "./Pages/Users/EditAdminUser";
import ForgotPassword from "./Pages/Authentication/ForgotPassword";
import FourZeroFour from "./Pages/FourZeroFour/FourZeroFour";
import MasterManagement from "./Pages/Master/MasterManagement";
import ManageVideos from "./Pages/ManageVideos/ManageVideos";
import Profile from "./Pages/Profile/Profile";
import Payout from "./Pages/payout/Payout";
import Reports from "./Pages/Reports/Reports";
import Reportedvideos from "./Pages/ReportedVideos/Reportedvideos";
import ResetPassword from "./Pages/Authentication/ResetPassword";
import RequestDetailView from "./Pages/Celebrity/RequestDetailView";
import ViewRejected from "./Pages/Celebrity/ViewRejected";
import ReportedComments from "./Pages/ReportedComments/ReportedComments";
import ReportedCommentDetail from "./Pages/ReportedComments/ReportedCommentDetail";
import Sidebar from "./Components/Layout/SideBar";
import SettingsPlanConfig from "./Pages/Settings/SettingsPlanConfig";

import UsersList from "./Pages/Users/UsersList";
import ViewuserDetails from "./Pages/Users/ViewuserDetails";
import ViewReportedVideo from "./Pages/ReportedVideos/ViewReportedVideo";
import VideoDetailPage from "./Pages/ManageVideos/VideoDetailPage";
import ViewCelebrity from "./Pages/Celebrity/ViewCelebrity";

import "react-toastify/dist/ReactToastify.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./Scss/styles.scss";
import ViewAdminUser from "./Pages/Users/ViewAdminUser";
import UpdateAdminUser from "./Pages/Users/UpdateAdminUser";
import ViewPayoutRequestDetail from "./Pages/payout/ViewPayoutRequestDetail";
import ViewReportPaymentDetail from "./Pages/Reports/ViewReportPaymentDetail";
import Spinner from "./Components/Loader/Spinner";
import PrivacyPolicy from "./Pages/privacypolicy/PrivacyPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import Support from "./Pages/support/Support";
import Marketing from "./Pages/Marketing/Marketing";
import Faq from "./Pages/faqs/Faq";
/******************* 
@Purpose : This page is default page for our project
@Parameter : {}
@Author : INIC
******************/
function App() {
  /******************* 
  @Purpose : Used for token authorization
  @Parameter : {}
  @Author : INIC
  ******************/
  const Authorization = () => {
    return getItem("accessToken") ? true : false;
  };

  /******************* 
  @Purpose : Used for private routing
  @Parameter : {Component1, ...rest}
  @Author : INIC
  ******************/
  const PrivateRoute = ({ component: Component1, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Authorization() ? <Component1 {...props} /> : <Redirect to="/" />
      }
    />
  );

  const UnAuthRoute = ({ component: Component1, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Authorization() ? <Redirect to="/" /> : <Component1 {...props} />
      }
    />
  );

  /******************* 
  @Purpose : Used for check user is already login or not
  @Parameter : {Component2, ...rest }
  @Author : INIC
  ******************/
  const LoginCheckRoute = ({ component: Component2, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Authorization() ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component2 {...props} />
        )
      }
    />
  );

  /******************* 
  @Purpose : Used for check reset password
  @Parameter : {Component3, ...rest}
  @Author : INIC
  ******************/
  const ResetPasswordCheckRoute = ({ component: Component3, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Authorization() ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component3 {...props} />
        )
      }
    />
  );

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    // <Suspense fallback="loading">
    <Suspense fallback={<Spinner fallback={true} />}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Sidebar />
            <div style={{ textAlign: "center" }}>
              <ToastContainer />
            </div>
            <Switch>
              <LoginCheckRoute exact path="/" component={AdminLogin} />

              <ResetPasswordCheckRoute
                exact
                path="/resetpassword"
                component={ResetPassword}
              />
              <Route exact path="/changepassword" component={ChangePassword} />
              <Route exact path="/forgotpassword" component={ForgotPassword} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />

              <PrivateRoute
                exact
                path="/usermanagement"
                component={UsersList}
              />
              <PrivateRoute
                exact
                path="/brandaccount"
                component={BrandAccount}
              />
              <PrivateRoute exact path="/addPlan" component={AddPlan} />
              {/*  */}
              <UnAuthRoute
                exact
                path="/privacypolicy"
                component={PrivacyPolicy}
              />
              <UnAuthRoute
                exact
                path="/terms-conditions"
                component={TermsAndConditions}
              />
              <UnAuthRoute exact path="/support" component={Support} />
              <UnAuthRoute exact path="/marketing" component={Marketing} />
              <UnAuthRoute exact path="/faq" component={Faq} />
              {/*  */}

              <PrivateRoute
                exact
                path="/videodetails/:slug"
                component={VideoDetailPage}
              />
              <PrivateRoute
                exact
                path="/celebrityrequest/:slug"
                component={RequestDetailView}
              />
              <PrivateRoute
                exact
                path="/paymentRequest/:slug"
                component={ViewPayoutRequestDetail}
              />
              <PrivateRoute
                exact
                path="/reportPaymentDetail/:slug"
                component={ViewReportPaymentDetail}
              />
              <PrivateRoute
                exact
                path="/getRejectedRequestHistoryOfUser/:slug"
                component={ViewRejected}
              />

              <PrivateRoute
                exact
                path="/repotedvideodetails/:slug"
                component={ViewReportedVideo}
              />
              <PrivateRoute
                exact
                path="/reportedcommentdetail/:slug"
                component={ReportedCommentDetail}
              />
              <PrivateRoute exact path="/adminusers" component={AdminUsers} />
              <PrivateRoute
                exact
                path="/userdetails/:slug"
                component={ViewuserDetails}
              />
              <PrivateRoute
                exact
                path="/celebritydetails/:slug"
                component={ViewCelebrity}
              />
              <PrivateRoute
                exact
                path="/userProfile/:slug"
                component={ViewAdminUser}
              />
              <PrivateRoute
                exact
                path="/updateUser/:slug"
                component={UpdateAdminUser}
              />
              {/* VideoDetailPage */}

              <PrivateRoute exact path="/profile" component={Profile} />
              <PrivateRoute exact path="/adduser" component={AddUser} />
              <PrivateRoute
                exact
                path="/editAdminUser/:brandId"
                component={EditAdminUser}
              />

              <PrivateRoute
                exact
                path="/usermanagement"
                component={UsersList}
              />
              <PrivateRoute
                exact
                path="/reportedcomments"
                component={ReportedComments}
              />

              <PrivateRoute
                exact
                path="/rolesList"
                component={AccessManagement}
              />
              {/* brandlist route */}
              <PrivateRoute exact path="/brandList" component={BrandList} />
              {/* celebrity list */}
              <PrivateRoute
                exact
                path="/celebrityList"
                component={CelebrityList}
              />
              <PrivateRoute exact path="/addRole" component={AddRoles} />
              <PrivateRoute exact path="/editRole/:slug" component={EditRole} />

              <PrivateRoute
                exact
                path="/emailTemplates"
                component={EmailsList}
              />

              <PrivateRoute exact path="/master" component={MasterManagement} />

              {/* settings plan config */}
              <PrivateRoute
                exact
                path="/planConfiguration"
                component={SettingsPlanConfig}
              />

              <PrivateRoute
                exact
                path="/Reportedvideos"
                component={Reportedvideos}
              />
              <PrivateRoute
                exact
                path="/Managevideos"
                component={ManageVideos}
              />
              <PrivateRoute exact path="/Payout" component={Payout} />
              <PrivateRoute exact path="/Reports" component={Reports} />
              <PrivateRoute exact path="/CMS" component={CMS} />

              <PrivateRoute
                exact
                path="/brandprofile/:slug"
                component={BrandProfile}
              />

              <PrivateRoute path="/*" component={FourZeroFour} />
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    </Suspense>
  );
}

export default App;
