import React, { useState, useEffect, useRef, useCallback } from "react";
import swal from "sweetalert";
import { useDispatch, connect } from "react-redux";
import _, { isEmpty } from "lodash";

import Spinner from "../../Components/Loader/Spinner";
import Select from "react-select";
import $ from "jquery";
import debounce from "lodash.debounce";
import moment from "moment";
import Pagination from "rc-pagination";
import DatePicker from "react-datepicker";

import { callApi } from "../../Api";

import { useHistory } from "react-router-dom";
import { useOnClickOutside } from "../../Hooks";
import { editUser } from "../../Store/Actions";
import {
  downloadCustomersList,
  searchCustomersList,
} from "../../Store/Actions/User";
import {
  showMessageNotification,
  showModalNotification,
} from "../../Utils/Functions";
import API from "../../Api/Routes";
import { Layout } from "../../Components/Layout";
import { Image, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import "antd/dist/antd.css";
import "rc-pagination/assets/index.css";
import "react-datepicker/dist/react-datepicker.css";

$.DataTable = require("datatables.net");
require("datatables.net-responsive");

/******************* 
@Purpose : Used for users list view
@Parameter : props
@Author : INIC
******************/
function UsersList(props) {
  const history = useHistory();

  const dispatch = useDispatch();
  const [spin, setSpin] = useState(false);
  const [celebFilter, setCelebFilter] = useState([]);
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  const [downloadSelect, setDownloadSelect] = useState("");

  const [usersList, setUsersList] = useState([]);
  const [searchText, setSearchtext] = useState("");
  const [pagesize, setPagesize] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(true);

  const [filterObj, setFilterObj] = useState([
    {
      key: "",
      type: "",
      input: "",
      value: { startDate: "", endDate: "" },
      condition: "$and",
    },
  ]);

  const [templateSettings] = useState([
    {
      key: "photo",
      status: false,
    },
    { key: "age", status: false },
    { key: "name", status: false },
    { key: "emailId", status: false },
    { key: "mobile", status: false },
    { key: "gender", status: false },
    { key: "status", status: false },
  ]);

  const [, setUserStatus] = useState("");

  const [city, setCity] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [loadingcheckbox, setLoadingcheckbox] = useState(false);

  const [filterpopup, openFilterpopup] = useState(false);
  const [, openDownloadpopup] = useState(false);
  const [toggleSelect, setToggleSelect] = useState("");

  const [errorsLicence, setErrorsLicence] = useState([
    {
      key: "",
      type: "",
      input: "",
      value: { startDate: "", endDate: "" },
    },
  ]);
  // selected options
  const selectFieldOptions = [
    { value: "dob", label: "Age" },
    { value: "city", label: "City" },
    { value: "gender", label: "Gender" },
  ];
  const refFilter = useRef();
  const refDownload = useRef();
  const onCloseFilter = useCallback(() => openFilterpopup(false), []);
  useOnClickOutside(refFilter, onCloseFilter);
  const onCloseDownload = useCallback(() => openDownloadpopup(false), []);
  useOnClickOutside(refDownload, onCloseDownload);
  const [, setCustomersTotalData] = useState([]);
  /******************* 
  @Purpose : onchange 
  @Parameter : {}
  @Author : Vista app
  ******************/
  const handleSearchChange = (e) => {
    if (e.target.value.length === 0) {
      getAllUsers1();
    }
    e.persist();
    setLoading(true);
    setSearchtext(e.target.value);
    handleDebouncedChange(e.target.value);
  };
  /******************* 
  @Purpose : Used for on selectContentOptions
  @Parameter : {e}
  @Author : INIC
  ******************/

  const selectContentOptions = [
    { value: "Please Select Field", label: "Please Select Field" },
  ];
  // handleFilterArray
  const handleFilterArray = () => {
    let selected = filterObj.map((item) => item.key.value);
    setSelectedFilterItems(selected);
  };
  /******************* 
  @Purpose : Debounce for searcg celeb req
  @Parameter : {}
  @Author : Vista app
  ******************/
  const handleDebouncedChange = React.useMemo(
    () => debounce((evt) => searchField(evt), 1000),
    []
  );

  const downloadOptions = [
    { value: "Excel", label: "Excel" },
    { value: "CSV", label: "CSV" },
  ];

  /******************
  @Purpose : Used forDOWNLOAD  the requests
  @Parameter : e
  @Author : INIC
  ******************/
  const handleDownload = async (e) => {
    setDownloadSelect(e);
    let requiredDownload = e.value;

    if (requiredDownload === "Excel") {
      let body = {
        format: "xls",
      };

      try {
        dispatch(downloadCustomersList(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showMessageNotification(response.message, "success");
          } else {
            showMessageNotification(response.message, "error");
          }
        });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
    if (requiredDownload === "CSV") {
      let body = {
        format: "csv",
      };
      try {
        dispatch(downloadCustomersList(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showMessageNotification(response.message, "success");
          } else {
            showMessageNotification(response.message, "error");
          }
        });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
  };
  useEffect(() => {
    getAllUsers1();
  }, [page, pagesize]);
  useEffect(() => {
    compleateCustomersData();
    getCitiesList();
  }, []);
  /******************* 
  @Purpose : Used for get all users
  @Parameter : filterObj
  @Author : INIC
  ******************/

  const getAllUsers1 = async (filterObj) => {
    setSpin(true);
    let body = {
      keyword: "",
      pageNumber: page,
      pageSize: pagesize,
      filter: celebFilter || [],
    };
    const response = await callApi(
      API.SEARCH_CUSTOMERS,
      body,
      "post",
      null,
      true,
      "",
      "",
      history
    );
    if (response.status === 1) {
      setSpin(false);
      setAllChecked(false);
      setUsersList(response?.celebrityList);

      setTotal(response?.totalData);

      setLoading(false);
    } else if (response.status === 0) {
      setSpin(false);
      showModalNotification(response?.message, "error");
    }
  };

  //
  const compleateCustomersData = async (filterObj) => {
    let body = {
      searchText: "",
    };
    const response = await callApi(
      API.CUSTOMERS_LIST_ALL,
      body,
      "post",
      null,
      true,
      "",
      "",
      history
    );
    if (response.status === 1) {
      setCustomersTotalData(response?.celebrityList);
    }
  };

  /******************* 
  @Purpose : Used for reset filter
  @Parameter : filterObj
  @Author : INIC
  ******************/

  const getAllUsers11 = async (filterObj) => {
    let body = {
      keyword: "",
      pageNumber: page,
      pageSize: pagesize,
      filter: [],
    };
    const response = await callApi(
      API.SEARCH_CUSTOMERS,
      body,
      "post",
      null,
      true,
      "",
      "",
      history
    );
    if (response.status === 1) {
      setUsersList(response?.celebrityList);
      setTotal(response?.totalData);

      setLoading(false);
    }
  };
  /******************* 
  @Purpose : Used for brand list
  @Parameter : {}
  @Author : INIC
  ******************/
  /******************* 
  @Purpose : Used for get categories
  @Parameter : props
  @Author : INIC
  ******************/
  const getCitiesList = async () => {
    try {
      const response = await callApi(
        API.GET_CITIES,
        "",
        "get",
        "GET_CITY",
        true,
        true,
        false
      );

      if (response.status === 1) {
        setCity(
          response?.cities.map((el) => {
            return {
              value: el,
              label: el,
            };
          })
        );
      } else if (response?.status === 0) {
        setCity([]);
      }
    } catch (error) {
      console.error();
    }
  };

  /******************* 
  @Purpose : Used for pagination
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  function paginationChange(page, pagesize) {
    setPage(page);
    setPagesize(pagesize);
  }
  /******************* 
  @Purpose : Used for user status change
  @Parameter : status, userId
  @Author : INIC
  ******************/

  const userStatusChange = async (status, userId) => {
    if (!status && userId) {
      swal({
        title: "Are you sure,You Want change status?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let body = { userIds: [userId], status };
          const response = await callApi(
            API.USERS_STATUS,
            body,
            "post",
            null,
            true,
            "",
            "",
            history
          );

          if (response) {
            getAllUsers1();
            showMessageNotification(response.message, "success");
          } else if (response.status === 0) {
            showMessageNotification(response.message, "error");
          }
        }
      });
    }
  };
  /******************* 
  @Purpose : Used for user state change
  @Parameter : {status, userId}
  @Author : INIC
  ******************/
  const userStatusChange1 = async (status, userId) => {
    if (userId) {
      let body = { userIds: [userId], status: true };
      const response = await callApi(
        API.USERS_STATUS,
        body,
        "post",
        null,
        true,
        "",
        "",
        history
      );
      if (response) {
        getAllUsers1();
        showModalNotification(response.message, "success");
      } else if (response.status === 0) {
        showModalNotification(response.message, "error");
      }
    }
  };
  /******************* 
  @Purpose : Used for bulk actions
  @Parameter : {}
  @Author : INIC
  ******************/
  const applyBulkActions = async (e) => {
    setToggleSelect(e);

    let data = usersList.filter((rec) => rec.isChecked == true);
    let delArr = data.map(({ _id }) => _id);
    let body;

    if (delArr.length > 0) {
      delArr = _.compact(delArr);

      if (e.value === true) {
        body = { userIds: delArr, status: true };

        const response = await callApi(
          API.USERS_STATUS,
          body,
          "post",
          null,
          true,
          "",
          "",
          history
        );
        if (response.status === 1) {
          showModalNotification(response.message, "success");
          setToggleSelect("");
          setTotal(response?.totalData);

          getAllUsers1();
        } else {
          showModalNotification(response.message, "error");
        }
      } else if (e.value === false) {
        body = { userIds: delArr, status: false };

        const response = await callApi(
          API.USERS_STATUS,
          body,
          "post",
          null,
          true,
          "",
          "",
          history
        );
        if (response.status === 1) {
          showModalNotification(response.message, "success");
          setToggleSelect("");
          setTotal(response?.totalData);

          getAllUsers1();
        } else {
          showModalNotification(response.message, "error");
        }
      }
    } else {
      showMessageNotification(
        "Please select Records to change status",
        "error"
      );
    }
  };

  /******************* 
  @Purpose : Used for apply filter
  @Parameter : tab
  @Author : INIC
  ******************/

  const ApplyFilter = async () => {
    try {
      if (validateFiltersearch()) {
        let payload = filterObj.map((item) => {
          if (item.key.label === "City") {
            return item?.type.map((val) => {
              return {
                key:
                  item.key.label === "Age"
                    ? "dob"
                    : item.key.label === "City"
                    ? "city"
                    : item.key.label === "Gender"
                    ? "gender"
                    : "",
                type: "contains",
                value:
                  item.key.label === "Age"
                    ? item?.type?.value
                    : item.key.label === "City"
                    ? item?.type[0]?.value
                    : item.key.label === "Gender"
                    ? item?.type[0]?.value
                    : "",
              };
            });
          }
          return {
            key:
              item.key.label === "Gender"
                ? "gender"
                : "Age"
                ? "age"
                : "City"
                ? "city"
                : "date",
            type: item.key.label === "date" ? "date" : "contains",

            value:
              item.key.label === "date"
                ? {
                    startDate: moment(item.value.startDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                    endDate: moment(item.value.endDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                  }
                : item.key.label === "City"
                ? item?.type[0]?.city
                : item?.type?.value,
          };
        });

        setLoading(true);

        let body = {
          pageNumber: 1,
          pageSize: pagesize,
          keyword: "",
          filter: payload.flat(),
        };

        dispatch(searchCustomersList(body)).then((response) => {
          if (response.status === 1) {
            setCelebFilter(payload.flat());
            setUsersList(response?.celebrityList);
            setPage(1);
            setTotal(response?.totalData);
            openFilterpopup(!filterpopup);
          } else if (response.status === 0) {
            setUsersList([]);
            showModalNotification(response.message, "error");
            setTotal(0);
          }
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  /******************* 
  @Purpose : Used for validateFiltersearch
  @Parameter : 
  @Author : INIC
  ******************/
  const validateFiltersearch = () => {
    let isFormValid = true;

    let valid = filterObj.map((each) => {
      if (each.key.label === "State" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the fields";
      } else {
        if (each.key.label === "State") {
          isFormValid = true;
          each.error = "";
        }
      }
      if (each.key.label === "City" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the fields";
      } else {
        if (each.key.label === "City") {
          isFormValid = true;
          each.error = "";
        }
      }

      if (each.key.label === "Gender" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the fields";
      } else {
        if (each.key.label === "Gender") {
          isFormValid = true;
          each.error = "";
        }
      }
      if (each.key.label === "date" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the fields";
      } else {
        if (each.key.label === "date") {
          isFormValid = true;
          each.error = "";
        }
      }
      return each;
    });

    setFilterObj(valid);
    return isFormValid;
  };

  /******************* 
  @Purpose : Used for serch filter
  @Parameter : tab, i
  @Author : INIC
  ******************/

  const resetFilter = () => {
    setFilterObj([
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    setSelectedFilterItems([]);
    openFilterpopup(!filterpopup);
    setCelebFilter([]);
    setPage(1);
    getAllUsers11();
  };

  /******************* 
  @Purpose : Used for search value
  @Parameter : keyword
  @Author : INIC
  ******************/
  const searchField = async (evtKey) => {
    setLoading(true);
    try {
      if (evtKey) {
        let body = {
          pageNumber: page,
          pageSize: pagesize,
          keyword: evtKey,
        };
        dispatch(searchCustomersList(body)).then((response) => {
          if (response.status === 1) {
            setUsersList(response?.celebrityList);
            setTotal(response?.totalData);

            setLoading(false);
          } else if (response.status === 0) {
            showModalNotification(response.message, "error");
          }
        });
      }
    } catch {
      console.error();
    }
  };
  /******************* 
  @Purpose : Used for add new filter records
  @Parameter : e
  @Author : INIC
  ******************/
  const addNewRow = (e) => {
    setErrorsLicence([
      ...errorsLicence,
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    setFilterObj([
      ...filterObj,
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
  };
  /******************* 
  @Purpose : Used for remove filter records
  @Parameter : index
  @Author : INIC
  ******************/
  const removeRow = (index) => {
    var array = [...filterObj];
    array.splice(index, 1);
    setFilterObj(array);
    var array = [...errorsLicence];
    array.splice(index, 1);
    setErrorsLicence(array);
    setTimeout(function () {}, 100);
  };

  /******************* 
  @Purpose : Used for handle checkbox
  @Parameter : e
  @Author : INIC
  ******************/

  const handleChangeCheckbox2 = (e) => {
    let list = usersList;
    var checkedALL = allChecked;

    if (e.target.value === "checkAll") {
      list.forEach((item) => {
        item.isChecked = e.target.checked;
        checkedALL = e.target.checked;
      });
    } else {
      list.find((item) => item._id === e.target.name).isChecked =
        e.target.checked;

      let magenicVendors = list.filter(
        (vendor) => vendor["isChecked"] === true
      );
      if (magenicVendors.length === list?.length) {
        checkedALL = true;
      } else {
        checkedALL = false;
      }
    }

    setUsersList(list);
    setAllChecked(checkedALL);
    setTimeout(function () {
      setLoadingcheckbox(!loadingcheckbox);
    }, 100);
  };
  let dateVal;
  // const approveCelebList = Array.isArray(customersTotalData)
  //   ? customersTotalData
  //       // .filter((item) => item.dob)
  //       .filter(
  //         (item, index) =>
  //           index ===
  //           customersTotalData.findIndex((other) => item?.dob === other?.dob)
  //       )
  //       .map((item) => ({
  //         ...item,

  //         label: moment(item?.dob).format("L"),
  //         value: item.dob,
  //       }))
  //   : [];

  const approveCelebList = [
    {
      value: {
        minAge: 0,
        maxAge: 9,
      },
      label: "< 10",
    },
    {
      value: {
        minAge: 10,
        maxAge: 18,
      },
      label: "10 - 18",
    },
    {
      value: {
        minAge: 18,
        maxAge: 35,
      },
      label: "18 - 35",
    },
    {
      value: {
        minAge: 35,
        maxAge: 150,
      },
      label: "35+",
    },
  ];

  const approveCelebListsGender = [
    { value: "MALE", label: "MALE" },
    { value: "FEMALE", label: "FEMALE" },
  ];
  //

  /******************* 
  @Purpose : Used for display filtered data
  @Parameter : {}
  @Author : Vista app
  ******************/
  const renderFilter = () => {
    return (
      <div className="custom-dropdown-menu w-50 dropdown-icon">
        <div className="card-body" style={{ paddingRight: "70px" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-repeat">
                {filterObj &&
                  filterObj.length > 0 &&
                  filterObj.map((o, i) => {
                    let itemType = o.key.label;
                    return (
                      <form
                        key={itemType}
                        className="form repeater-default"
                        data-limit="5"
                      >
                        <div className="field-form">
                          <div className="target-form">
                            <div
                              className="target-details"
                              style={{ marginBottom: "10px" }}
                            >
                              <div className="row align-items-start">
                                <div className="form-group col-md-5 col-lg-6 mb-2">
                                  <label className="form-label">
                                    Select Field
                                  </label>
                                  <Select
                                    filterOption={(option) =>
                                      !selectedFilterItems.includes(
                                        option.value
                                      )
                                    }
                                    showSearch
                                    placeholder="Enter Field"
                                    optionFilterProp="children"
                                    className="w-100 custom-input filter-select"
                                    value={o.key}
                                    onChange={(value) => {
                                      o.key = value;
                                      o.type = null;
                                      setFilterObj([...filterObj]);

                                      if (errorsLicence[i])
                                        delete errorsLicence[i].key;
                                    }}
                                    options={selectFieldOptions}
                                  />

                                  <span className="text-danger error-msg"></span>
                                </div>
                                {itemType === "date" ? (
                                  <div className="col-md-5 col-lg-5 mb-2 ">
                                    <div className="input-daterange custom-daterange input-control">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <label className="form-label">
                                            Select Start date
                                          </label>
                                          <DatePicker
                                            selected={
                                              o.value.startDate
                                                ? new Date(o.value.startDate)
                                                : ""
                                            }
                                            placeholderText="Select Start Date"
                                            dateFormat="MMM d yyyy"
                                            className="form-control"
                                            onChange={(evt) => {
                                              o.value.startDate = evt;
                                              setFilterObj([...filterObj]);
                                              if (errorsLicence[i])
                                                delete errorsLicence[i].value
                                                  .startDate;
                                            }}
                                          />
                                          <span className="text-danger error-msg">
                                            {o.error}
                                          </span>
                                        </div>
                                        <div className="col-md-6">
                                          <label className="form-label">
                                            Select end date
                                          </label>

                                          <DatePicker
                                            selected={
                                              o.value.endDate
                                                ? new Date(o.value.endDate)
                                                : ""
                                            }
                                            minDate={
                                              o.value.startDate
                                                ? new Date(o.value.startDate)
                                                : new Date()
                                            }
                                            disabled={
                                              o.value.startDate ? false : true
                                            }
                                            dateFormat="MMM d yyyy"
                                            placeholderText="Select End Date"
                                            className="form-control"
                                            onChange={(evt) => {
                                              o.value.endDate = evt;
                                              setFilterObj([...filterObj]);
                                              if (errorsLicence[i])
                                                delete errorsLicence[i].value
                                                  .endDate;
                                            }}
                                          />
                                          <span className="text-danger error-msg">
                                            {o.error}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="form-group col-md-5 col-lg-6 mb-2">
                                    <label className="form-label">
                                      Select Content (Max 1)
                                    </label>
                                    <Select
                                      // disabled={true}
                                      closeMenuOnSelect={
                                        o.key.label === "City" ? true : false
                                      }
                                      // closeMenuOnSelect={false}
                                      isMulti={
                                        o.key.label === "City" ? true : false
                                      }
                                      showSearch={false}
                                      placeholder="Select Type"
                                      className="w-100 custom-input filter-select"
                                      optionFilterProp="children"
                                      value={o.type}
                                      isOptionDisabled={() =>
                                        o.type?.length >= 1
                                      }
                                      onChange={(value) => {
                                        o.type = value;
                                        if (o.type === "date") {
                                          delete o.value;
                                          o.value = {
                                            startDate: "",
                                            endDate: "",
                                          };
                                        }
                                        setFilterObj([...filterObj]);
                                        handleFilterArray();
                                        if (errorsLicence[i])
                                          delete errorsLicence[i].type;
                                      }}
                                      options={
                                        o.key.label === "Age"
                                          ? approveCelebList
                                          : o.key.label === "City"
                                          ? city
                                          : o.key.label === "Gender"
                                          ? approveCelebListsGender
                                          : selectContentOptions
                                      }
                                    />

                                    <span className="text-danger error-msg">
                                      {o.error}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className="add-row col-lg-2 col-md-2"
                              style={{ display: "flex", gap: "10px" }}
                            >
                              {filterObj.length <= 3 && i === 0 ? (
                                <span
                                  className="add-ic icon-plus text-success"
                                  onClick={(e) => addNewRow(e)}
                                  type="button"
                                ></span>
                              ) : null}
                              {filterObj && filterObj.length >= 2 ? (
                                <span
                                  className="icon-minus mr-1 text-danger"
                                  onClick={() => removeRow(i)}
                                  type="button"
                                ></span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </form>
                    );
                  })}
              </div>
            </div>
            <div className="col-md-12 text-end pt-4">
              <button
                onClick={() => resetFilter()}
                type="button"
                className="btn btn-light me-2"
              >
                Clear
              </button>
              <button
                onClick={(evt) => ApplyFilter()}
                type="button"
                className="btn btn-primary"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  /******************* 
  @Purpose : Used for bulk actions options
  @Parameter : props
  @Author : INIC
  ******************/
  const options1 = [
    { value: true, label: "Active" },
    { value: false, label: "InActive" },
  ];

  /******************* 
  @Purpose :clear celebrity search
  @Parameter : {}
  @Author : Vista app
 ******************/
  const clearInput = () => {
    setSearchtext("");
    getAllUsers1();
  };
  /******************* 
  @Purpose : Download File
  @Parameter : {}
  @Author : Vista app
  ******************/

  let validCheckbox = templateSettings.filter(
    (vendor) => vendor["status"] === true
  );

  let isSelected = usersList.filter((rec) => rec.isChecked === true);
  let isCheckboxSelected = isSelected.map(({ _id }) => _id);
  const numbers = [2, 5, 10, 15, 25, 50, 100, 200, 500].filter(
    (number) => number < total
  );
  const breadcrumb = [
    {
      key: "4",
      title: "Customers",
    },
  ];

  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Customers">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="card">
              <div className="card-header d-flex justify-content-between user-listing-filterOptions">
                <div className="search-with-filter d-flex">
                  <div className="search-input-wrapper position-relative">
                    <i className="icon-magnifier"></i>{" "}
                    <input
                      type="search"
                      value={searchText}
                      className="form-control text-capitalize"
                      placeholder="search"
                      onChange={(evt) => {
                        handleSearchChange(evt);
                      }}
                    />
                    {searchText && searchText.length > 0 ? (
                      <div className="clear">
                        <span
                          onClick={clearInput}
                          style={{ marginRight: "20px" }}
                        >
                          <em className="icon-close"></em>
                        </span>
                      </div>
                    ) : null}
                  </div>

                  <div
                    id="container1"
                    className={
                      filterpopup
                        ? "custom-dropdown filter-data-dropdown position-static ml-2 open"
                        : "custom-dropdown filter-data-dropdown position-static ml-2"
                    }
                  >
                    <button
                      onClick={() => {
                        openFilterpopup(!filterpopup);
                        openDownloadpopup(false);
                      }}
                      className="btn btn-bordered dropdown-toggle custom-filter-dropdown btn-bg-white d-flex align-items-center"
                      type="button"
                    >
                      <i className="bx bx-filter-alt align-text-top me-2"></i>
                      Filter
                    </button>
                    {renderFilter()}
                  </div>

                  {isCheckboxSelected.length > 0 ? (
                    <>
                      <div className="form-group">
                        <Select
                          id="toggleSelect"
                          name="toggleSelect"
                          options={options1}
                          placeholder="Bulk Actions"
                          onChange={(e) => {
                            applyBulkActions(e);
                          }}
                          className="download-csv me-4"
                          value={toggleSelect}
                        />
                        <span className="text-danger error-msg"></span>
                      </div>
                    </>
                  ) : null}
                </div>

                <div className="col-sm-4">
                  <div
                    className="right-buttons d-flex justify-content-end"
                    style={{ gap: "10px" }}
                  >
                    <div className="form-group  col-sm-4  d-flex">
                      <div className="w-100">
                        <Select
                          id="download"
                          name="download"
                          options={downloadOptions}
                          placeholder="Download"
                          onChange={(e) => {
                            handleDownload(e);
                          }}
                          value={downloadSelect}
                        />
                      </div>
                      <span
                        className="error-msg"
                        style={{ color: "red" }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {spin ? (
                  <div className="d-flex align-center justify-content-center">
                    <Spinner />
                  </div>
                ) : null}
                <div className="table-responsive">
                  <table
                    className="table row-border nowrap common-datatable"
                    id="user-listing"
                  >
                    <thead>
                      <tr>
                        <th className="checkbox-table">
                          <div className="custom-checkbox">
                            <label>
                              <input
                                type="checkbox"
                                value="checkAll"
                                checked={allChecked || false}
                                onChange={handleChangeCheckbox2}
                                name="checkAll"
                              />
                              <span></span>
                            </label>
                          </div>
                        </th>

                        <th>
                          <b>Name</b>
                        </th>

                        <th>
                          <b>Email</b>
                        </th>
                        <th>
                          <b>Phone Number</b>
                        </th>
                        <th>
                          <b>Gender</b>
                        </th>
                        <th>
                          <b>Status</b>
                        </th>
                        <th className="all">
                          <b>Action</b>
                          <b></b>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {usersList && usersList.length === 0 ? (
                        <tr className="text-center text-danger not-found-txt">
                          <td colSpan="6">
                            {!loading ? (
                              <h6
                                className="text-center text-danger not-found-txt"
                                colSpan="6"
                              >
                                <Image
                                  src="https://vistaadmindev.devpress.net/assets/images/no_data_found.png"
                                  width={200}
                                  height={200}
                                />
                              </h6>
                            ) : null}
                          </td>
                        </tr>
                      ) : (
                        usersList &&
                        Array.isArray(usersList) &&
                        usersList.map((user) => {
                          return (
                            <tr key={user?._id}>
                              <td>
                                <div className="custom-checkbox">
                                  <label>
                                    <input
                                      key={user._id}
                                      type="checkbox"
                                      checked={user?.isChecked || false}
                                      onChange={handleChangeCheckbox2}
                                      name={user._id}
                                    />
                                    <span />
                                  </label>
                                </div>
                              </td>

                              <td>
                                <div className="eamil-col d-flex align-items-center">
                                  <span className="avtar-md">
                                    <img
                                      src={
                                        user?.creatorProfilephoto
                                          ? user?.creatorProfilephoto
                                          : "/assets/images/no-user.png"
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="ps-3">
                                    {user?.firstname + " " + user?.lastname}
                                  </span>
                                </div>
                              </td>

                              <td>
                                <div>
                                  <span>
                                    {user.emailId ? user.emailId : "-"}
                                  </span>
                                </div>
                              </td>

                              <td>
                                <div>
                                  <span>
                                    {user?.mobileNumber
                                      ? user?.mobileNumber
                                      : "-"}
                                  </span>
                                </div>
                              </td>

                              <td>
                                <div>
                                  <span>{user.gender ? user.gender : "-"}</span>
                                </div>
                              </td>

                              <td>
                                <div className="custom-control custom-switch light">
                                  <Form.Check
                                    type="switch"
                                    className="custom-control-input"
                                    // onChange={() => {
                                    //   user.status = !user.status;
                                    //   setUserStatus({ usersList });
                                    //   userStatusChange(user.status, user._id);
                                    // }}
                                    // checked={user.status}
                                    // id={user._id}
                                    onChange={() => {
                                      if (user.status) {
                                        userStatusChange(
                                          !user.status,
                                          user._id
                                        );
                                      } else if (!user.status) {
                                        userStatusChange1(
                                          user.status,
                                          user._id
                                        );
                                      }
                                    }}
                                    checked={user.status}
                                    id={user?._id}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={user._id}
                                  ></label>
                                </div>
                              </td>

                              <td>
                                <div className="d-flex ml-3">
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="view">View</Tooltip>}
                                  >
                                    <a
                                      onClick={() =>
                                        props.history.push(
                                          `/userdetails/${user._id}`
                                        )
                                      }
                                      className="cursor-pointer me-3"
                                    >
                                      <i className="icon-eye"></i>
                                    </a>
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>

                {usersList && usersList.length < 10 ? (
                  <div>
                    <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                      <p className="mb-0 align-self-center text-primary-light fs-14">
                        Showing <b> {usersList.length} </b> Out of{" "}
                        <b> {total} </b> Users
                      </p>
                      <div className="pagination-list ms-2">
                        <Pagination
                          className="ant-pagination"
                          pageSize={pagesize}
                          current={page}
                          total={total}
                          onChange={paginationChange}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                    <p className="mb-0 align-self-center text-primary-light fs-14">
                      Showing <b> 10 </b> Out of <b> {total} </b> Users
                    </p>
                    <div className="pagination-list ms-2">
                      <Pagination
                        className="ant-pagination"
                        pageSize={pagesize}
                        current={page}
                        total={total}
                        onChange={paginationChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  UserAccess:
    state.admin.adminData &&
    state.admin.adminData.staticRolePermission &&
    state.admin.adminData.staticRolePermission.userAccess,
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi, editUser })(UsersList);
