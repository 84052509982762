const INTIAL_STATE = {
  videos: {},
  videoStatus: {},
  searchVideos: {},
  celebrityRequests: {},
  reportesVideos: {},
  hideVideo: {},
  reportedSearch: {},
  reporters: {},
  reportersSearch: {},
  commentsVids: {},
  searchCommentedVids: {},
  commentedList: {},
  hideComments: {},
  manageDownload: {},
  downloadReports: {},
  downloadReporters: {},
  celebApprove: {},
  reqHistory: {},
  reject: {},
  profileData: null,
  cardData: {},
  commentsCard: {},
  becomeCeleb: {},
  celebDownload: {},
  celebrityDownload: {},
  celebList: {},
  cmsList: {},
  cmsLists: {},
  updateCms: {},
  downloadCustomers: {},
  searchCustomers: {},
  rejectedLists: {},
  downloadRejected: {},
  vistaRoles: {},
  permissions: {},
  templates: {},
  updateTemplates: {},
  customers: {},
  AdminUsers: {},
  downloadAdmin: {},
  adminPayout: {},
  downloadPayout: {},
  rejectPaymentRequest: {},
  userProfile: "",
  filter: {},
  reportsDownload: {},
  reportsSearchPayment: {},
  reportsVideoViews: {},
  downloadReportsVideoDetails: {},
  dashboardVids: {},
  monthlyVids: {},
  subscriptionFee: {},
  allCeleb: {},
  productClicks: {},
  reportsProdClicks: {},
  filterReports: {},
  downloadReportsVids: {},
  searchVidsReports: {},
  payoutPurchaseData: {},
};
// GET_SEARCH_VIDEOS

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case "GET_VIDEOS":
      return Object.assign({}, state, {
        videos: action.payload,
      });
    case "CHANGE_VIDEO_STATUS":
      return Object.assign({}, state, {
        videoStatus: action.payload,
      });
    case "CELEBRITY_REQUESTS":
      return Object.assign({}, state, {
        celebrityRequests: action.payload,
      });
    case "GET_SEARCH_VIDEOS":
      return Object.assign({}, state, {
        searchVideos: action.payload,
      });
    case "HIDE_VIDEO":
      return Object.assign({}, state, {
        hideVideo: action.payload,
      });
    case "REPORTED_VIDEO":
      return Object.assign({}, state, {
        reportesVideos: action.payload,
      });
    case "REPORTED_SEARCH":
      return Object.assign({}, state, {
        reportedSearch: action.payload,
      });
    case "VIDEO_REPORTERS":
      return Object.assign({}, state, {
        reporters: action.payload,
      });
    case "VIDEO_REPORTERS_SEARCH":
      return Object.assign({}, state, {
        reportersSearch: action.payload,
      });
    case "COMMENT_VIDS_LIST":
      return Object.assign({}, state, {
        commentsVids: action.payload,
      });
    case "COMMENT_SEARCH_VIDS_LIST":
      return Object.assign({}, state, {
        searchCommentedVids: action.payload,
      });
    // COMMENT_LIST
    case "COMMENT_LIST":
      return Object.assign({}, state, {
        commentedList: action.payload,
      });
    case "HIDE_COMMENT":
      return Object.assign({}, state, {
        hideComments: action.payload,
      });
    case "DOWNLOAD_VIDS":
      return Object.assign({}, state, {
        manageDownload: action.payload,
      });
    // DOWNLOAD_REPORT_VIDS
    case "DOWNLOAD_REPORT_VIDS":
      return Object.assign({}, state, {
        downloadReports: action.payload,
      });
    case "DOWNLOAD_REPORT_VIDEO_LIST":
      return Object.assign({}, state, {
        downloadReporters: action.payload,
      });
    case "CELEBRITY_APPROVE":
      return Object.assign({}, state, {
        celebApprove: action.payload,
      });
    case "CELEBRITY_REQ_HISTORY":
      return Object.assign({}, state, {
        reqHistory: action.payload,
      });
    // CELEBRITY_REJECT
    case "CELEBRITY_REJECT":
      return Object.assign({}, state, {
        reqHistory: action.payload,
      });

    case "PROFILE_DATA":
      return Object.assign({}, state, {
        profileData: action.payload,
      });
    case "REPORTED_VID_CARDS":
      return Object.assign({}, state, {
        cardData: action.payload,
      });
    case "REPORTED_COMMENTS_CARDS":
      return Object.assign({}, state, {
        commentsCard: action.payload,
      });
    case "CELEBRITIES":
      return Object.assign({}, state, {
        becomeCeleb: action.payload,
      });
    case "CELEBRITIES_DOWNLOAD":
      return Object.assign({}, state, {
        celebDownload: action.payload,
      });
    case "CELEB_DOWNLOAD":
      return Object.assign({}, state, {
        celebrityDownload: action.payload,
      });
    case "ALL_CELEB":
      return Object.assign({}, state, {
        celebList: action.payload,
      });
    case "ALL_CMS":
      return Object.assign({}, state, {
        cmsList: action.payload,
      });
    case "UPDATE_CMS":
      return Object.assign({}, state, {
        cmsLists: action.payload,
      });
    case "DOWNLOAD_CUSTOMERS":
      return Object.assign({}, state, {
        downloadCustomers: action.payload,
      });
    case "SEARCH_CUSTOMERS":
      return Object.assign({}, state, {
        searchCustomers: action.payload,
      });
    case "REJECTED_LISTS":
      return Object.assign({}, state, {
        rejectedLists: action.payload,
      });
    case "DOWNLOAD_REJECTED_LISTS":
      return Object.assign({}, state, {
        downloadRejected: action.payload,
      });
    case "ROLES_LISTS":
      return Object.assign({}, state, {
        vistaRoles: action.payload,
      });
    case "PERMS_LISTS":
      return Object.assign({}, state, {
        permissions: action.payload,
      });
    case "TEMP_LISTS":
      return Object.assign({}, state, {
        templates: action.payload,
      });
    case "TEMP_UPDATE":
      return Object.assign({}, state, {
        updateTemplates: action.payload,
      });
    case "USERS":
      return Object.assign({}, state, {
        cusomers: action.payload,
      });
    case "ADMIN_USERS":
      return Object.assign({}, state, {
        AdminUsers: action.payload,
      });
    case "ADMIN_DOWNLOAD":
      return Object.assign({}, state, {
        downloadAdmin: action.payload,
      });
    case "ADMIN_PAYOUT":
      return Object.assign({}, state, {
        adminPayout: action.payload,
      });
    case "DOWNLOAD_PAYOUT":
      return Object.assign({}, state, {
        downloadPayout: action.payload,
      });
    case "REJECT_PAYOUTDOWNLOAD_PAYOUT":
      return Object.assign({}, state, {
        rejectPaymentRequest: action.payload,
      });
    case "SET_LOGIN_DATA":
      return Object.assign({}, state, {
        userProfile: action.payload,
      });
    case "REMOVE_LOGIN_DATA":
      return Object.assign({}, state, {
        userProfile: {},
      });
    case "FILTER_PAYOUT":
      return Object.assign({}, state, {
        filter: {},
      });
    case "DOWNLOAD_PAYOUT":
      return Object.assign({}, state, {
        downloadReports: {},
      });
    case "PAYMENT_LIST":
      return Object.assign({}, state, {
        reportsSearchPayment: {},
      });
    case "REPORTS_VIDEO_VIEWS":
      return Object.assign({}, state, {
        reportsVideoViews: {},
      });

    case "DOWNLOAD_VIDEO_REPORTS":
      return Object.assign({}, state, {
        downloadReportsVideoDetails: {},
      });
    case "DASHBOARD_VIDS":
      return Object.assign({}, state, {
        dashboardVids: {},
      });
    case "MONTH_VID_VIEW":
      return Object.assign({}, state, {
        monthlyVids: {},
      });
    // SUBSCRIPTION_DATA
    case "SUBSCRIPTION_DATA":
      return Object.assign({}, state, {
        subscriptionFee: {},
      });
    case "CELEB_LIST":
      return Object.assign({}, state, {
        subscriptionFee: {},
      });
    case "PRODUCT_CLICKS":
      return Object.assign({}, state, {
        productClicks: {},
      });
    case "PRODUCT_CLICKS_REPORTS":
      return Object.assign({}, state, {
        reportsProdClicks: {},
      });
    case "PRODUCT_CLICKS_REPORTS_FILTER":
      return Object.assign({}, state, {
        filterReports: {},
      });
    // PRODUCT_VIDS_DOWNLOAD
    case "PRODUCT_VIDS_DOWNLOAD":
      return Object.assign({}, state, {
        downloadReportsVids: {},
      });
    // SEARCH_VIDS
    case "SEARCH_VIDS":
      return Object.assign({}, state, {
        searchVidsReports: {},
      });
    case "VID_PURCHASE_DATA":
      return Object.assign({}, state, {
        payoutPurchaseData: {},
      });
    default:
      return state;
  }
};
