import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import Select from "react-select";

import moment from "moment";
import Pagination from "rc-pagination";
import debounce from "lodash.debounce";
import DatePicker from "react-datepicker";
import Spinner from "../../Components/Loader/Spinner";
import { Link, useHistory } from "react-router-dom";
import { callApi } from "../../Api";
import { Layout } from "../../Components/Layout";
import {
  getVideos,
  getSearchVideos,
  downloadManageVids,
} from "../../Store/Actions/User";
import {
  showModalNotification,
  showMessageNotification,
} from "../../Utils/Functions";
import API from "../../Api/Routes";

import "react-datepicker/dist/react-datepicker.css";

const selectFieldOptions = [
  { value: "category", label: "Category" },
  { value: "brand", label: "Brand" },
  { value: "date", label: "Date" },
];
const selectFieldOptions1 = [
  { value: "category", label: "Category" },
  { value: "subcategory", label: "SubCategory" },
  { value: "brand", label: "Brand" },
  { value: "date", label: "Date" },
];
const selectContentOptions = [{ value: "Select Field", label: "Select Field" }];

/******************* 
@Purpose :  used for manage videos
@Parameter : {}
@Author : INIC
******************/
const ManageVideos = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [spin, setSpin] = useState(false);
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brand, setBrand] = useState([]);
  const [filterpopup, openFilterpopup] = useState(false);
  const [searchText, setSearchtext] = useState("");
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [downloadSelect, setDownloadSelect] = useState("");
  const [videosList, setVideosList] = useState([]);
  let [, setLoading] = useState(false);
  const [pagesize, setPagesize] = useState(10);
  const [celebFilter, setCelebFilter] = useState([]);
  const [errorsLicence, setErrorsLicence] = useState([
    {
      key: "",
      type: "",
      input: "",
      value: { startDate: "", endDate: "" },
    },
  ]);
  const [filterObj, setFilterObj] = useState([
    {
      key: "",
      type: "",
      input: "",
      value: { startDate: "", endDate: "" },
      condition: "$and",
    },
  ]);

  /******************* 
  @Purpose : React hook used for videos fist display
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    videosListDisplay();
  }, []);

  /******************* 
  @Purpose : React hook used for get category list
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    getCategoryList();
    getBrandList();
  }, []);

  /******************* 
  @Purpose : Used for bread crum
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      title: "Manage Videos",
      url: "/managevideos",
    },
  ];

  /******************* 
  @Purpose : Used for download options
  @Parameter : status, timezoneId
  @Author : INIC
  ******************/
  const options = [
    { value: "Excel", label: "Excel" },
    { value: "CSV", label: "CSV" },
  ];

  /******************* 
  @Purpose : Used for category and sub category
  @Parameter : {}
  @Author : INIC
  ******************/
  const getCategoryList = async () => {
    try {
      const response = await callApi(
        API.GET_CATEGORY,
        "",
        "get",
        "GET_CATEGORY",
        true,
        true,
        false
      );

      if (response) {
        const categoryData = Array.isArray(response.data)
          ? response.data.map((item) => ({
              ...item,
              label: item.title,
              value: item.title,
            }))
          : [];
        setCategories(categoryData);
      }
    } catch (error) {
      console.error();
    }
  };
  /******************* 
  @Purpose : Used for brand list
  @Parameter : {}
  @Author : INIC
  ******************/
  const getBrandList = async () => {
    try {
      let body = {
        searchText: "",
      };

      const response = await callApi(
        API.ALL_BRANDS,
        body,
        "post",
        "ALL_BRANDS",
        true,
        true,
        false
      );

      if (response) {
        const allBrandsList = Array.isArray(response.data)
          ? response.data.map((item) => ({
              ...item,
              label: item.organization,
              value: item._id,
            }))
          : [];
        setBrand(allBrandsList);
      }
    } catch (error) {
      console.error();
    }
  };

  /******************* 
  @Purpose : Used for download apis
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleDownload = async (e) => {
    setDownloadSelect(e);
    let requiredDownload = e.value;

    if (requiredDownload === "Excel") {
      let body = {
        format: "xls",
      };

      try {
        dispatch(downloadManageVids(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showMessageNotification(response?.message, "success");
          } else {
            showMessageNotification(response?.message, "error");
          }
        });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
    if (requiredDownload === "CSV") {
      let body = {
        format: "csv",
      };
      try {
        dispatch(downloadManageVids(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showMessageNotification(response?.message, "success");
          } else {
            showMessageNotification(response?.message, "error");
          }
        });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
  };

  /******************* 
  @Purpose : Used for clear all form field value
  @Parameter : {}
  @Author : INIC
  ******************/
  const clearInput = () => {
    setSearchtext("");
    setPage(1);
    videosListDisplay1();
  };

  /******************* 
  @Purpose : Used for on change search
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleSearchChange = (e) => {
    if (e.target.value.length === 0) {
      videosListDisplay1();
    }
    e.persist();
    setLoading(true);
    setSearchtext(e.target.value);
    handleDebouncedChange(e.target.value);
  };

  /******************* 
  @Purpose : Used for pagination
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  function paginationChange(page, pagesize) {
    setPage(page);
    setPagesize(pagesize);
    videosListDisplay(page, pagesize);
  }
  /******************* 
  @Purpose : Used for debounce with memo
  @Parameter : status, timezoneId
  @Author : INIC
  ******************/
  const handleDebouncedChange = React.useMemo(
    () => debounce((evt) => searchField(evt), 1000),
    []
  );
  /******************* 
  @Purpose : Used for get all the vids
  @Parameter : status, timezoneId
  @Author : INIC
  ******************/
  const videosListDisplay = async (
    propspage = page,
    propspagesize = pagesize
  ) => {
    setLoading(true);
    setSpin(true);
    let body = {
      pageNumber: propspage,
      pageSize: 10,
      searchText: searchText || "",
      filter: celebFilter || [],
    };

    try {
      dispatch(getVideos(body)).then((response) => {
        if (response.status === 1) {
          setSpin(false);
          setVideosList(response?.data);
          setPagesize(10);
          setTotal(response.totalVideos);
          setLoading(false);
        } else {
          setSpin(false);
          showModalNotification(response?.message, "error");
        }
      });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };
  /******************* 
  @Purpose : Used for reset filter
  @Parameter : status, timezoneId
  @Author : INIC
  ******************/
  const videosListDisplay1 = async (e) => {
    setLoading(true);

    let body = {
      pageNumber: 1,
      pageSize: pagesize,
      filter: [],
    };

    try {
      dispatch(getVideos(body)).then((response) => {
        if (response.status === 1) {
          setVideosList(response?.data);
          setPagesize(10);
          setTotal(response.totalVideos);
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };
  /******************* 
  @Purpose : Used forsearch the vids
  @Parameter : status, timezoneId
  @Author : INIC
  ******************/
  const searchField = async (evtKey) => {
    setLoading(true);

    try {
      if (evtKey) {
        let body = {
          pageNumber: page,
          pageSize: pagesize,
          searchText: evtKey,
        };
        dispatch(getSearchVideos(body)).then((response) => {
          if (response.status === 1) {
            setVideosList(response?.data);
            setTotal(response.totalVideos);
            setPage(1);

            setTotal(response.totalVideos);
          } else if (response.status === 0) {
            showModalNotification("no videos were found", "error");
          }
        });
      }
    } catch (error) {
      setLoading(false);
      console.error();
    }
  };

  /******************* 
  @Purpose : Used for add new filter records
  @Parameter : {e}
  @Author : INIC
  ******************/
  const addNewRow = (e) => {
    handleFilterArray();
    setErrorsLicence([
      ...errorsLicence,
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    setFilterObj([
      ...filterObj,
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
  };

  /******************* 
  @Purpose : Used for remove filter records
  @Parameter : {index}
  @Author : INIC
  ******************/
  const removeRow = (index) => {
    let array = [...filterObj];
    array.splice(index, 1);
    setFilterObj(array);
    array = [...errorsLicence];
    array.splice(index, 1);
    setErrorsLicence(array);
    setTimeout(function () {
      setLoadingFilter(true);
    }, 100);
  };

  /******************* 
  @Purpose : Used for sub category data
  @Parameter : {}
  @Author : INIC
  ******************/
  const getSubCategoryData = () => {
    let categoryItem = filterObj?.filter(
      (item) => item.key.label === "Category"
    );

    let id =
      categoryItem.length > 1
        ? categoryItem[categoryItem.length - 1]?.type?._id
        : categoryItem[0]?.type?._id;

    let categoryFilter = categories?.filter((item) => item._id === id)[0]
      ?.subCategoryDetails;
    let subcategoryData = Array.isArray(categoryFilter)
      ? categoryFilter.map((item) => ({
          ...item,
          label: item.title,
          value: item.title,
        }))
      : [];

    return subcategoryData;
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  const renderFilter = () => {
    return (
      <div className="custom-dropdown-menu dropdown-icon card w-50">
        <div className="card-body" style={{ paddingRight: "70px" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-repeat">
                {filterObj &&
                  filterObj.length > 0 &&
                  filterObj.map((o, i) => {
                    let itemType = o.key.label;

                    return (
                      <form
                        key={o?.key?.label}
                        className="form repeater-default"
                        data-limit="5"
                      >
                        <div className="field-form">
                          <div className="target-form">
                            <div
                              className="target-details"
                              style={{ marginBottom: "10px" }}
                            >
                              <div className="row align-items-start">
                                <div className="form-group col-md-5 col-lg-6 mb-2">
                                  <label className="form-label">
                                    Select Field
                                  </label>
                                  <Select
                                    filterOption={(option) =>
                                      !selectedFilterItems.includes(
                                        option.value
                                      )
                                    }
                                    showSearch
                                    placeholder="Enter Field"
                                    optionFilterProp="children"
                                    className="w-100 custom-input filter-select"
                                    value={o.key}
                                    onChange={(value) => {
                                      o.key = value;
                                      o.type = null;
                                      setFilterObj([...filterObj]);

                                      if (errorsLicence[i])
                                        delete errorsLicence[i].key;
                                    }}
                                    options={
                                      i === 0
                                        ? selectFieldOptions
                                        : selectFieldOptions1
                                    }
                                  />

                                  <span className="text-danger error-msg"></span>
                                </div>
                                {itemType === "Date" ? (
                                  <div className="col-md-5 col-lg-5 mb-2 ">
                                    <div className="input-daterange custom-daterange input-control">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <label className="form-label">
                                            Select Start date
                                          </label>
                                          <DatePicker
                                            selected={
                                              o.value.startDate
                                                ? new Date(o.value.startDate)
                                                : ""
                                            }
                                            placeholderText="Select Start Date"
                                            dateFormat="MMM d yyyy"
                                            className="form-control"
                                            onChange={(evt) => {
                                              o.value.startDate = evt;
                                              setFilterObj([...filterObj]);
                                              if (errorsLicence[i])
                                                delete errorsLicence[i].value
                                                  .startDate;
                                            }}
                                          />
                                          <span className="text-danger error-msg">
                                            {o.error}
                                          </span>
                                        </div>
                                        <div className="col-md-6">
                                          <label className="form-label">
                                            Select end date
                                          </label>

                                          <DatePicker
                                            selected={
                                              o.value.endDate
                                                ? new Date(o.value.endDate)
                                                : ""
                                            }
                                            minDate={
                                              o.value.startDate
                                                ? new Date(o.value.startDate)
                                                : new Date()
                                            }
                                            disabled={
                                              o.value.startDate ? false : true
                                            }
                                            dateFormat="MMM d yyyy"
                                            placeholderText="Select End Date"
                                            className="form-control"
                                            onChange={(evt) => {
                                              o.value.endDate = evt;
                                              setFilterObj([...filterObj]);
                                              if (errorsLicence[i])
                                                delete errorsLicence[i].value
                                                  .endDate;
                                            }}
                                          />
                                          <span className="text-danger error-msg">
                                            {o.error}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="form-group col-md-5 col-lg-6 mb-2">
                                    <label className="form-label">
                                      {o.key.label === "Category"
                                        ? "Select Content "
                                        : "Select Content (max 5)"}
                                    </label>
                                    <Select
                                      closeMenuOnSelect={
                                        o.key.label === "Category"
                                          ? true
                                          : false
                                      }
                                      isMulti={
                                        o.key.label === "Category"
                                          ? false
                                          : true
                                      }
                                      showSearch={false}
                                      placeholder="Select Type"
                                      className="w-100 custom-input filter-select"
                                      optionFilterProp="children"
                                      value={o.type}
                                      isOptionDisabled={() =>
                                        o.type?.length >= 5
                                      }
                                      onChange={(value) => {
                                        o.type = value;

                                        if (o.type === "date") {
                                          delete o.value;
                                          o.value = {
                                            startDate: "",
                                            endDate: "",
                                          };
                                        }
                                        setFilterObj([...filterObj]);
                                        handleFilterArray();
                                        if (errorsLicence[i])
                                          delete errorsLicence[i].type;
                                      }}
                                      options={
                                        o.key.label === "Category"
                                          ? categories
                                          : o.key.label === "SubCategory"
                                          ? getSubCategoryData()
                                          : o.key.label === "Brand"
                                          ? brand
                                          : selectContentOptions
                                      }
                                    />

                                    <span className="text-danger error-msg">
                                      {o.error}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className="add-row col-lg-2 col-md-2"
                              style={{ display: "flex", gap: "10px" }}
                            >
                              {filterObj.length <= 3 && i === 0 ? (
                                <span
                                  className="add-ic icon-plus text-success"
                                  onClick={(e) => addNewRow(e)}
                                  type="button"
                                ></span>
                              ) : null}
                              {filterObj && filterObj.length >= 2 ? (
                                <span
                                  className="icon-minus mr-1 text-danger"
                                  onClick={() => removeRow(i)}
                                  type="button"
                                ></span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </form>
                    );
                  })}
              </div>
              {loadingFilter ? <div></div> : null}
              <div className="col-md-12 text-end pt-4">
                <button
                  onClick={() => resetFilter()}
                  type="button"
                  className="btn btn-light me-2"
                >
                  Clear
                </button>
                <button
                  onClick={(evt) => ApplyFilter()}
                  type="button"
                  className="btn btn-primary"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /******************* 
  @Purpose : Used for filter 
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleFilterArray = () => {
    let selected = filterObj.map((item) => item.key.value);
    setSelectedFilterItems(selected);
  };

  /******************* 
  @Purpose : Used for applay filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const ApplyFilter = async () => {
    try {
      if (validateFiltersearch()) {
        let payload = filterObj.map((item) => {
          if (item.key.label === "SubCategory" || item.key.label === "Brand") {
            return item.type.map((val) => {
              return {
                key:
                  item.key.label === "SubCategory"
                    ? "subCategoryId"
                    : item.key.label === "Brand"
                    ? "brandId"
                    : "",
                type: "contains",
                value: val._id,
              };
            });
          }
          return {
            key: item.key.label === "Category" ? "categoryId" : "date",

            type: item.key.label === "Date" ? "date" : "contains",

            value:
              item.key.label === "Date"
                ? {
                    startDate: moment(item.value.startDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                    endDate: moment(item.value.endDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                  }
                : item.type._id,
          };
        });

        setLoading(true);

        let body = {
          pageNumber: 1,
          pageSize: pagesize,
          searchText: "",
          filter: payload.flat(),
        };

        dispatch(getVideos(body)).then((response) => {
          if (response.status === 1) {
            setCelebFilter(payload.flat());
            setVideosList(response?.data);
            openFilterpopup(!filterpopup);
            setPage(1);
            setPagesize(10);
            setTotal(response.totalVideos);
            setLoading(false);
          } else if (response.status === 0) {
            showModalNotification("No videos were found", "error");
            setVideosList([]);
            setTotal(0);
          }
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  /******************* 
  @Purpose : Used for reset filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const resetFilter = () => {
    setFilterObj([
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    setSelectedFilterItems([]);
    setCelebFilter([]);
    setPage(1);
    openFilterpopup(!filterpopup);
    videosListDisplay1();
  };

  /******************* 
  @Purpose : Used for validate filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateFiltersearch = () => {
    let isFormValid = true;

    let valid = filterObj.map((each) => {
      if (each.key.label === "Category" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the  category fields";
      } else {
        if (each.key.label === "Category") {
          isFormValid = true;
          each.error = "";
        }
      }

      if (each.key.label === "Brand" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the brand fields";
      } else {
        if (each.key.label === "Brand") {
          isFormValid = true;
          each.error = "";
        }
      }
      if (each.key.label === "SubCategory" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the fields";
      } else {
        if (each.key.label === "SubCategory") {
          isFormValid = true;
          each.error = "";
        }
      }
      if (
        each.key.label === "Date" &&
        (each.value.startDate === "" || each.value.endDate === "")
      ) {
        isFormValid = false;
        each.error = "Select the subCategory fields";
      } else {
        if (each.key.label === "Date") {
          isFormValid = true;
          each.error = "";
        }
      }
      return each;
    });

    setFilterObj(valid);
    return isFormValid;
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Manage Videos">
      ManageVideos
      <div className="main-content-area">
        <div className="main-content-block">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <div className="left-buttons d-flex ">
                <div className="d-flex align-items-center">
                  <div className="search position-relative has-icon-left">
                    <input
                      type="search"
                      value={searchText}
                      className="form-control text-capitalize"
                      placeholder="search"
                      onChange={(evt) => {
                        handleSearchChange(evt);
                      }}
                    />

                    {searchText && searchText.length > 0 ? (
                      <div className="clear">
                        <span onClick={clearInput}>
                          <em className="icon-close"></em>
                        </span>
                      </div>
                    ) : null}

                    <div className="form-control-position">
                      <em className="icon-magnifier"></em>
                    </div>
                  </div>
                </div>

                {/* filter starts  */}
                <div
                  id="container1"
                  className={
                    filterpopup
                      ? "custom-dropdown filter-data-dropdown position-static ml-2 open"
                      : "custom-dropdown filter-data-dropdown position-static ml-2"
                  }
                >
                  <button
                    onClick={() => {
                      openFilterpopup(!filterpopup);
                    }}
                    className="btn btn-bordered dropdown-toggle custom-filter-dropdown btn-bg-white d-flex align-items-center"
                    type="button"
                  >
                    <i className="bx bx-filter d-lg-none"></i>
                    <i className="bx bx-filter-alt align-text-top me-2"></i>
                    Filter
                  </button>
                  {renderFilter()}
                </div>
                {/* filter ends */}

                <div className="form-control-position"></div>
              </div>
              <div className="right-btns">
                <Select
                  id="download"
                  name="download"
                  options={options}
                  placeholder="Download"
                  onChange={(e) => {
                    handleDownload(e);
                  }}
                  value={downloadSelect}
                  className="download-csv"
                />
                <span className="error-msg" style={{ color: "red" }}></span>
              </div>
            </div>

            <div className="card-body">
              {spin ? (
                <div className="d-flex align-center justify-content-center">
                  <Spinner />
                </div>
              ) : null}
              <div className="row">
                {videosList && videosList?.length === 0 ? (
                  <div className="text-center text-danger not-found-txt">
                    {" "}
                    {/* No videos Found! */}
                  </div>
                ) : (
                  ""
                )}

                {videosList?.map((video, id) => {
                  return (
                    <div
                      className="col-lg-4 col-xl-3 col-md-4 col-6"
                      key={video?._id}
                    >
                      <Link
                        onClick={() =>
                          history.push(`/videodetails/${video._id}`)
                        }
                      >
                        <div className="video-card">
                          <div className="video-img position-relative mb-2">
                            <video
                              controls={true}
                              width="100%;"
                              // className="border border-secondary border-1 video-box"
                              height="250"
                            >
                              <source src={video?.videoLink} type="video/mp4" />
                            </video>
                          </div>
                          <div className="video-info">
                            <h6 className="video-title fw-bold text-secondary">
                              {video?.brands &&
                              video?.brands[0] &&
                              video?.brands[0]?.organization
                                ? video?.brands[0]?.organization
                                : ""}
                            </h6>
                            <p className="video-date mb-1">
                              {" "}
                              Published At:{" "}
                              {moment(video?.createdAt).format("L")}
                            </p>
                            <h6 className="video-title fw-semibold  text-secondary mb-1">
                              {" "}
                              {video?.videoTitle ? video?.videoTitle : ""}
                            </h6>
                            {!video?.postedByBrand && (
                              <p className="video-title-name text-orange">
                                {video?.celebrityDetails &&
                                video?.celebrityDetails[0]?.firstname
                                  ? ` By : ${video?.celebrityDetails[0]?.firstname}`
                                  : ""}
                                &nbsp;
                                {video?.celebrityDetails &&
                                video?.celebrityDetails[0]?.lastname
                                  ? video?.celebrityDetails[0]?.lastname
                                  : ""}
                              </p>
                            )}
                            <p className="video-title-name text-orange">
                              {video?.postedByBrand ? "By : Brand" : ""}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}

                {videosList && videosList?.length < 10 ? (
                  <div>
                    <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                      <p className="mb-0 align-self-center text-primary-light fs-14">
                        Showing <b>{videosList?.length}</b> Out of{" "}
                        <b>{total}</b> videos
                      </p>
                      <div className="pagination-list ms-2">
                        <Pagination
                          className="ant-pagination"
                          pageSize={pagesize}
                          current={page}
                          total={total}
                          onChange={paginationChange}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                    <p className="mb-0 align-self-center text-primary-light fs-14">
                      Showing <b>10</b> Out of <b>{total}</b> videos
                    </p>
                    <div className="pagination-list ms-2">
                      <Pagination
                        className="ant-pagination"
                        pageSize={pagesize}
                        current={page}
                        total={total}
                        onChange={paginationChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card-body ">
            <div className="row">
              {/* pagination starts */}
              <div className="row" style={{ display: "contents" }}></div>
              {/* pagination ends */}
            </div>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  );
};

export default ManageVideos;
