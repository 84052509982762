import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { showMessageNotification } from "../../Utils/Functions";
import * as _ from "lodash";
import Loader from "../../Components/Loader/Loader";
import { callApi } from "../../Api";
import API from "../../Api/Routes";
import Layout from "../../Components/Layout/Layout";
import errorMessages from "../../Utils/ErrorMessages";

/******************* 
@Purpose : Used for edit user role
@Parameter : props
@Author : INIC
******************/
function EditRole(props) {
  const [columnChecked, setColumnChecked] = useState([]);

  const [loadingcheckbox, setLoadingcheckbox] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [ids, setIds] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [description, setDescription] = useState("");
  const [permissionsList, setPermissionsList] = useState([]);

  const [loading, setLoading] = useState(false);
  let [errors, setErrors] = useState({});
  const columnNames = [
    "View List",
    "Delete",
    "Status Update",
    "Download",
    "Create",
    "Edit",
    "approve_request",
    "reject_request",
  ];
  const roleId = props.match.params.slug;

  /******************* 
  @Purpose : React hook used get permission details
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    getPermissionDetails(roleId);
  }, [roleId]);

  /******************* 
  @Purpose : Used for handle permisiion
  @Parameter : {id}
  @Author : INIC
  ******************/
  const getPermissionDetails = async (id) => {
    setLoading(false);
    const response = await callApi(
      API.GET_ROLE_PERMISSION + id,
      "",
      "get",
      null,
      true
    );
    let { role, description, permissions } = response.data;
    if (response.status === 1) {
      let responsePermission = response?.data?.categoryPermissions;

      let newResponse = responsePermission.map(function (item) {
        return {
          permissions: columnNames.map(function (elem, i) {
            // Grab the object keys
            const keys = item?.permissions
              ? Object.values(item?.permissions)
              : [];

            return !keys.includes(elem)
              ? {
                  permission: elem,
                  _id: keys.filter((f) => f?.permission === elem)[0]?._id || "",
                  isSelected:
                    keys.filter((f) => f?.permission === elem)[0]?.isSelected ||
                    false,
                }
              : item;
          }),
          category: item.category,
        };
      });

      setPermissionsList(newResponse);

      setRoleName(role);
      setDescription(description);
      const selectedIds = [];

      response?.data?.categoryPermissions.map((item) => {
        item.permissions.map((subData) => {
          if (subData.isSelected) {
            selectedIds.push(subData?._id);
          }
        });
      });

      setIds(selectedIds);
    } else {
      showMessageNotification("Something went wrong", "error");
    }
    setLoading(true);
  };

  /******************* 
  @Purpose : Used for validate form
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateForm = () => {
    let errors = { roleName: "", description: "" };
    let isFormValid = true;

    if (_.isEmpty(roleName)) errors.roleName = errorMessages.PROVIDE_ROLENAME;
    else errors.roleName = "";

    // if (_.isEmpty(description))
    //   errors.description = errorMessages.PROVIDE_DESCRIPTION;
    // else errors.description = "";

    if (errors.roleName !== "") isFormValid = false;
    else isFormValid = true;
    setErrors(errors);
    return isFormValid;
  };

  //  handle check box
  const handleChangeCheckbox2 = (e, item, subList) => {
    let list = subList;

    var checkedALL = allChecked;
    if (e.target.value === "checkAll") {
      list.forEach((item) => {
        item.isChecked = e.target.checked;
        checkedALL = e.target.checked;
      });
    } else {
      let exists = list.find((item) => item._id === e.target.name);
      const idsTemp = ids?.slice();
      // Find index
      const findIds = idsTemp.indexOf(e.target.name);

      // Index > -1 means that the item exists and that the checkbox is checked
      // and in that case we want to remove it from the array and uncheck it
      if (findIds > -1) {
        idsTemp.splice(findIds, 1);
      } else {
        idsTemp.push(e.target.name);
      }
      setIds(idsTemp);

      const inputName =
        exists?.permission === "approve_request"
          ? "Approve Request"
          : exists?.permission === "reject_request"
          ? "Reject Request"
          : exists?.permission;

      let updatedSubList = [];

      if (exists?.isSelected) {
        updatedSubList = item.permissions.map((x) => {
          if (exists._id === x._id) {
            x.isSelected = false;
          }
          return x;
        });
      } else {
        updatedSubList = item.permissions.map((x) => {
          if (exists._id === x._id) {
            x.isSelected = true;
          }
          return x;
        });
      }

      let magenicVendors = list.filter(
        (vendor) => vendor["isChecked"] === false
      );

      if (magenicVendors.length <= 0) {
        checkedALL = true;
      } else {
        checkedALL = false;
      }
      const checkedColumnTemp = columnChecked?.slice();
      // Find index
      const findIdx = checkedColumnTemp.indexOf(inputName);

      // Index > -1 means that the item exists and that the checkbox is checked
      // and in that case we want to remove it from the array and uncheck it
      if (findIdx > -1) {
        checkedColumnTemp.splice(findIdx, 1);
      }

      setColumnChecked(checkedColumnTemp);
    }

    setAllChecked(checkedALL);
    setTimeout(function () {
      setLoadingcheckbox(!loadingcheckbox);
    }, 100);
  };

  /******************* 
  @Purpose : Used for check box
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleChangeCheckbox1 = (e) => {
    const checkedColumnTemp = columnChecked?.slice();
    // Find index
    const findIdx = checkedColumnTemp.indexOf(e.target.name);

    // Index > -1 means that the item exists and that the checkbox is checked
    // and in that case we want to remove it from the array and uncheck it
    if (findIdx > -1) {
      checkedColumnTemp.splice(findIdx, 1);
    } else {
      checkedColumnTemp.push(e.target.name);
    }

    setColumnChecked(checkedColumnTemp);

    const pList = [...permissionsList];
    const inputName =
      e.target.name === "Approve Request"
        ? "approve_request"
        : e.target.name === "Reject Request"
        ? "reject_request"
        : e.target.name;

    let allChecked = pList.map((x) => {
      if (Array.isArray(x.permissions)) {
        x.permissions.map(
          (y) =>
            (y.isSelected =
              y.permission === inputName
                ? e.target.checked
                  ? true
                  : false
                : y.isSelected || false)
        );
      }
      return x;
    });
    let allIds = allChecked
      ?.map((x) => {
        if (Array.isArray(x.permissions)) {
          return x.permissions.filter((y) => y.permission === inputName);
        }
        return null;
      })
      ?.flat()
      ?.map((z) => z?._id)
      ?.filter((f) => f);

    let totalIds = [...ids, ...allIds];
    let finalIds = [];
    if (findIdx > -1) {
      finalIds = totalIds?.filter((t) => !allIds.includes(t));
    } else {
      finalIds = totalIds;
    }
    setPermissionsList(allChecked);
    setIds(finalIds);
  };

  /******************* 
  @Purpose : Used for update data
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleUpdateChanges = async () => {
    if (validateForm()) {
      let body = {
        id: roleId,
        role: roleName,
        description,
        permissions: [...ids],
      };
      const response = await callApi(API.ADD_ROLE, body, "POST", null, true);
      if (response.status === 1) {
        showMessageNotification("Role udpated successfully", "success");
        props.history.push("/rolesList");
      } else {
        showMessageNotification("Something went wrong", "error");
      }
    }
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      key: "20",
      title: "Vista Roles",
      url: "/rolesList",
    },
    {
      key: "20",
      title: "Edit Role",
    },
  ];

  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Roles & Permissions">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="card role-card mb-md-5 mb-3 py-4 px-1">
              <form>
                <div
                  className="form-group mb-md-4 mb-3"
                  style={{ marginLeft: "20px" }}
                >
                  <label htmlFor="Name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Name"
                    placeholder="Give role a name"
                    value={roleName}
                    onChange={(e) => {
                      setRoleName(e.target.value);
                      let error = Object.assign(errors, { roleName: "" });
                      setErrors(error);
                    }}
                  />
                  <span className="error-msg" style={{ color: "red" }}>
                    {errors.roleName}
                  </span>
                </div>
                <div className="form-group mb-2" style={{ marginLeft: "20px" }}>
                  <label htmlFor="Description">Description </label>
                  <textarea
                    rows={3}
                    className="form-control"
                    id="Description"
                    placeholder="Describe this role a bit"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                      let error = Object.assign(errors, { description: "" });
                      setErrors(error);
                    }}
                  />
                  <span className="error-msg" style={{ color: "red" }}>
                    {errors.description}
                  </span>
                </div>
              </form>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="role-table w-100">
                    <thead>
                      <tr>
                        <th>Module Permission</th>
                        <th>
                          <div className="custom-checkbox">
                            <label htmlFor="View List">
                              <input
                                type="checkbox"
                                name="View List"
                                id="View List"
                                checked={columnChecked.includes("View List")}
                                onChange={(e) => handleChangeCheckbox1(e)}
                              />
                              <span />
                              View List
                            </label>
                          </div>
                        </th>
                        <th>
                          <div className="custom-checkbox">
                            <label htmlFor="Delete">
                              <input
                                type="checkbox"
                                name="Delete"
                                id="Delete"
                                checked={columnChecked.includes("Delete")}
                                onChange={(e) => handleChangeCheckbox1(e)}
                              />
                              <span />
                              Delete
                            </label>
                          </div>
                        </th>
                        <th>
                          <div className="custom-checkbox">
                            <label htmlFor="Status Update">
                              <input
                                type="checkbox"
                                name="Status Update"
                                id="Status Update"
                                checked={columnChecked.includes(
                                  "Status Update"
                                )}
                                onChange={(e) => handleChangeCheckbox1(e)}
                              />
                              <span />
                              Status Update
                            </label>
                          </div>
                        </th>
                        <th>
                          <div className="custom-checkbox">
                            <label htmlFor="Download">
                              <input
                                type="checkbox"
                                name="Download"
                                id="Download"
                                checked={columnChecked.includes("Download")}
                                onChange={(e) => handleChangeCheckbox1(e)}
                              />
                              <span />
                              Download
                            </label>
                          </div>
                        </th>
                        <th>
                          <div className="custom-checkbox">
                            <label htmlFor="Create">
                              <input
                                type="checkbox"
                                name="Create"
                                id="Create"
                                checked={columnChecked.includes("Create")}
                                onChange={(e) => handleChangeCheckbox1(e)}
                              />
                              <span />
                              Create
                            </label>
                          </div>
                        </th>
                        <th>
                          <div className="custom-checkbox">
                            <label htmlFor="Edit">
                              <input
                                type="checkbox"
                                name="Edit"
                                id="Edit"
                                checked={columnChecked.includes("Edit")}
                                onChange={(e) => handleChangeCheckbox1(e)}
                              />
                              <span />
                              Edit
                            </label>
                          </div>
                        </th>
                        <th>
                          <div className="custom-checkbox">
                            <label htmlFor="Approve Request">
                              <input
                                type="checkbox"
                                name="Approve Request"
                                id="Approve Request"
                                checked={columnChecked.includes(
                                  "Approve Request"
                                )}
                                onChange={(e) => handleChangeCheckbox1(e)}
                              />
                              <span />
                              Approve Request
                            </label>
                          </div>
                        </th>
                        <th>
                          <div className="custom-checkbox">
                            <label htmlFor="Reject Request">
                              <input
                                type="checkbox"
                                name="Reject Request"
                                id="Reject Request"
                                checked={columnChecked.includes(
                                  "Reject Request"
                                )}
                                onChange={(e) => handleChangeCheckbox1(e)}
                              />
                              <span />
                              Reject Request
                            </label>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {permissionsList && permissionsList?.length === 0 ? (
                        <tr className="text-center text-danger not-found-txt">
                          <td colSpan="6">
                            {loading ? <Loader /> : null}{" "}
                            {!loading ? (
                              <h6
                                className="text-center text-danger not-found-txt"
                                colSpan="6"
                              >
                                {"No permissions  are found "}
                              </h6>
                            ) : null}
                          </td>
                        </tr>
                      ) : (
                        permissionsList &&
                        Array.isArray(permissionsList) &&
                        permissionsList?.map((list, key) => {
                          let subList = list?.permissions;

                          return (
                            <tr key={list.category}>
                              <td>
                                <div className="eamil-col d-flex align-items-center">
                                  <span>{list.category}</span>
                                </div>
                              </td>

                              {list?.permissions &&
                                Array.isArray(list?.permissions) &&
                                list?.permissions.map((newlist, key) => {
                                  return (
                                    <td
                                      key={newlist?._id + newlist?.permission}
                                    >
                                      {newlist?._id ? (
                                        <input
                                          type="checkbox"
                                          checked={
                                            newlist.isSelected === true
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleChangeCheckbox2(
                                              e,
                                              list,
                                              subList
                                            )
                                          }
                                          name={newlist?._id}
                                        />
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                  );
                                })}
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="text-end mt-4">
                  <button
                    className="btn btn-light me-2"
                    onClick={() => props.history.push("/rolesList")}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={handleUpdateChanges}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="d-block">2020 © IndiaNIC</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  );
}
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(null, { callApi })(EditRole);
