import React, { useState, useEffect } from "react";
import { Tab, Tabs, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import Spinner from "../../Components/Loader/Spinner";
import Pagination from "rc-pagination";
import { callApi } from "../../Api";
import { Layout } from "../../Components/Layout";
import {
  showMessageNotification,
  validateIDSecret,
  validateMobileNumber,
  validateEmail,
  showModalNotification,
} from "../../Utils/Functions";
import API from "../../Api/Routes";

import errorMessages from "../../Utils/ErrorMessages";

/******************* 
@Purpose : Used for setting plan config
@Parameter : {}
@Author : INIC
******************/
function SettingsPlanConfig() {
  const [spin, setSpin] = useState(false);
  const [eventKeys, setEventKeys] = useState("Plan Configuration");
  const [planTableData, setPlanTableData] = useState([]);
  const [, setPlanStatus] = useState("");

  const [total, setTotal] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [companyFormData, setComapanyFormData] = useState({
    emailId: "",
    mobileNumber: "",
    headOfficeaddress: "",
    fbUrl: "",
    instagramUrl: "",
    twitterUrl: "",
    linkedinUrl: "",
    _id: "",
    createdBy: "",
    error: {},
  });
  const [paymentFormData, setPaymentFormData] = useState({
    environmentUrl: "",
    publicAPILoginId: "",
    testTranKey: "",
    publicTranKey: "",
    errorsPayment: {},
  });
  const [miscFormData, setMiscFormData] = useState({
    videoAd_1_dayPrice: "",
    videoAd_7_dayPrice: "",
    videoAd_15_dayPrice: "",
    priorityOneVideo: "",
    priorityTwoVideo: "",
    priorityThreeVideo: "",
    pricePerVideoView: "",
    pricePerProductClick_View: "",
    commissionPerProductPurchase: "",
    promoVideoGap: "",
    promoVideoCount: "",
    priorityFourVideo: "",
    minimumPaymentAmount: "",
    perVideoVistaCharge: "",
    _id: "",
    errorsMisc: {},
  });

  let {
    emailId,
    mobileNumber,
    headOfficeaddress,
    fbUrl,
    instagramUrl,
    twitterUrl,
    linkedinUrl,
    error,
  } = companyFormData;
  let {
    environmentUrl,
    publicAPILoginId,
    testTranKey,
    publicTranKey,
    errorsPayment,
  } = paymentFormData;
  let {
    videoAd_1_dayPrice,
    videoAd_7_dayPrice,
    videoAd_15_dayPrice,
    priorityOneVideo,
    priorityTwoVideo,
    priorityFourVideo,
    priorityThreeVideo,
    pricePerVideoView,
    pricePerProductClick_View,
    commissionPerProductPurchase,
    promoVideoGap,
    promoVideoCount,
    minimumPaymentAmount,
    perVideoVistaCharge,
    errorsMisc,
  } = miscFormData;

  const [transactionRadio, setTranactioRadio] = useState("test");
  /******************* 
  @Purpose : React hook used for get planconfig,companyinfo,paymentgateway,miscinfo,smtpinfo
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    getPlanConfig();
    getComapnyInfo();
    getPaymentGateway();
    getMiscInfo();
  }, [pageNumber, pageSize]);

  /******************* 
  @Purpose : Used for Bread crumb 
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      title: "Settings",
      url: "/planConfiguration",
    },
    {
      key: "31",
      title: eventKeys,
      url: "/planConfiguration",
    },
  ];

  /******************* 
  @Purpose : Used for validate company form
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateCompanyForm = () => {
    if (companyFormData.hasOwnProperty("emailId")) {
      if (isEmpty(emailId)) error.emailId = errorMessages.PROVIDE_EMAIL;
      else if (!validateEmail(emailId))
        error.emailId = errorMessages.PROVIDE_VALID_EMAIL;
      else delete error.emailId;
    }
    if (companyFormData.hasOwnProperty("mobileNumber")) {
      if (isEmpty(mobileNumber))
        error.mobileNumber = errorMessages.PROVIDE_MOBILE_NUMBER;
      else if (!validateMobileNumber(mobileNumber))
        error.mobileNumber = errorMessages.PROVIDE_VALID_MOBILE_NUMBER;
      else delete error.mobileNumber;
    }
    if (companyFormData.hasOwnProperty("headOfficeaddress")) {
      if (isEmpty(headOfficeaddress)) {
        error.headOfficeaddress = errorMessages.PROVIDE_HEAD_OFFICE_ADDRESS;
      } else delete error.headOfficeaddress;
    }

    if (companyFormData.hasOwnProperty("fbUrl")) {
      delete error.fbUrl;
    }
    if (companyFormData.hasOwnProperty("instagramUrl")) {
      delete error.instagramUrl;
    }
    if (companyFormData.hasOwnProperty("twitterUrl")) {
      delete error.twitterUrl;
    }
    if (companyFormData.hasOwnProperty("linkedinUrl")) {
      delete error.linkedinUrl;
    }

    const isFormvalidCompany = Object.keys(error).length !== 0 ? false : true;
    setComapanyFormData({
      ...companyFormData,
      error: error,
    });

    return isFormvalidCompany;
  };

  /******************* 
  @Purpose : Used for validate payment form
  @Parameter : {}
  @Author : INIC
  ******************/
  const validatePaymentForm = () => {
    if (paymentFormData.hasOwnProperty("environmentUrl")) {
      if (isEmpty(environmentUrl))
        errorsPayment.environmentUrl = errorMessages.PROVIDE_ENVIRONMENT;
      else if (!validateIDSecret(environmentUrl))
        errorsPayment.environmentUrl = errorMessages.PROVIDE_VALID_ENVIRONMENT;
      else delete errorsPayment.environmentUrl;
    }
    if (paymentFormData.hasOwnProperty("publicAPILoginId")) {
      if (isEmpty(publicAPILoginId))
        errorsPayment.publicAPILoginId = errorMessages.PROVIDE_PUBLIC_API_ID;
      else if (!validateIDSecret(publicAPILoginId))
        errorsPayment.publicAPILoginId =
          errorMessages.PROVIDE_VALID_ENVIRONMENT;
      else delete errorsPayment.publicAPILoginId;
    }
    if (paymentFormData.hasOwnProperty("testTranKey")) {
      if (isEmpty(testTranKey))
        errorsPayment.testTranKey = errorMessages.PROVIDE_TEST_TRANSACTION_KEY;
      else if (!validateIDSecret(testTranKey))
        errorsPayment.testTranKey =
          errorMessages.PROVIDE_VALID_TEST_TRANSACTION_KEY;
      else delete errorsPayment.testTranKey;
    }
    if (paymentFormData.hasOwnProperty("publicTranKey")) {
      if (isEmpty(publicTranKey))
        errorsPayment.publicTranKey =
          errorMessages.PROVIDE_PUBLIC_TRANSACTION_KEYS;
      else if (!validateIDSecret(publicTranKey))
        errorsPayment.publicTranKey =
          errorMessages.PROVIDE_VALID_PUBLIC_TRANSACTION_KEY;
      else delete errorsPayment.publicTranKey;
    }

    const isFormvalidPayment =
      Object.keys(errorsPayment).length !== 0 ? false : true;
    setPaymentFormData({
      ...paymentFormData,
      errorsPayment: errorsPayment,
    });
    return isFormvalidPayment;
  };

  /******************* 
  @Purpose : Used for validate miscForm
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateMiscForm = () => {
    if (miscFormData.hasOwnProperty("videoAd_1_dayPrice")) {
      if (isEmpty(videoAd_1_dayPrice + ""))
        errorsMisc.videoAd_1_dayPrice = errorMessages.PROVIDE_VIDEO_DAY_1_PRICE;
      else delete errorsMisc.videoAd_1_dayPrice;
    }
    if (miscFormData.hasOwnProperty("videoAd_7_dayPrice")) {
      if (isEmpty(videoAd_7_dayPrice + ""))
        errorsMisc.videoAd_7_dayPrice = errorMessages.PROVIDE_VIDEO_DAY_7_PRICE;
      else delete errorsMisc.videoAd_7_dayPrice;
    }
    if (miscFormData.hasOwnProperty("videoAd_15_dayPrice")) {
      if (isEmpty(videoAd_15_dayPrice + ""))
        errorsMisc.videoAd_15_dayPrice =
          errorMessages.PROVIDE_VIDEO_DAY_15_PRICE;
      else delete errorsMisc.videoAd_15_dayPrice;
    }
    if (miscFormData.hasOwnProperty("priorityOneVideo")) {
      if (isEmpty(priorityOneVideo + ""))
        errorsMisc.priorityOneVideo = errorMessages.priorityOneVideo;
      else delete errorsMisc.priorityOneVideo;
    }
    if (miscFormData.hasOwnProperty("priorityTwoVideo")) {
      if (isEmpty(priorityTwoVideo + ""))
        errorsMisc.priorityTwoVideo = errorMessages.priorityTwoVideo;
      else delete errorsMisc.priorityTwoVideo;
    }
    if (miscFormData.hasOwnProperty("priorityThreeVideo")) {
      if (isEmpty(priorityThreeVideo + ""))
        errorsMisc.priorityThreeVideo = errorMessages.priorityThreeVideo;
      else delete errorsMisc.priorityThreeVideo;
    }
    if (miscFormData.hasOwnProperty("pricePerVideoView")) {
      if (isEmpty(pricePerVideoView + ""))
        errorsMisc.pricePerVideoView =
          errorMessages.PROVIDE_PRICE_PER_VIDEO_VIEW;
      else delete errorsMisc.pricePerVideoView;
    }
    if (miscFormData.hasOwnProperty("pricePerProductClick_View")) {
      if (isEmpty(pricePerProductClick_View + ""))
        errorsMisc.pricePerProductClick_View =
          errorMessages.PROVIDE_PRICE_PER_PRODUCT_CLICK;
      else delete errorsMisc.pricePerProductClick_View;
    }
    if (miscFormData.hasOwnProperty("priorityFourVideo")) {
      if (isEmpty(priorityFourVideo + ""))
        errorsMisc.priorityFourVideo = errorMessages.priorityFourVideo;
      else delete errorsMisc.priorityFourVideo;
    }
    if (miscFormData.hasOwnProperty("perVideoVistaCharge")) {
      if (isEmpty(perVideoVistaCharge + ""))
        errorsMisc.perVideoVistaCharge = errorMessages.perVideoVistaCharge;
      else delete errorsMisc.perVideoVistaCharge;
    }
    // minimumPaymentAmount
    if (miscFormData.hasOwnProperty("minimumPaymentAmount")) {
      if (isEmpty(minimumPaymentAmount + ""))
        errorsMisc.minimumPaymentAmount = errorMessages.minimumPaymentAmount;
      else delete errorsMisc.minimumPaymentAmount;
    }

    if (miscFormData.hasOwnProperty("promoVideoGap")) {
      if (isEmpty(promoVideoGap + ""))
        errorsMisc.promoVideoGap = errorMessages.PROVIDE_PROMOTIONAL_VIDEO_GAP;
      else delete errorsMisc.promoVideoGap;
    }
    if (miscFormData.hasOwnProperty("promoVideoCount")) {
      if (isEmpty(promoVideoCount + ""))
        errorsMisc.promoVideoCount =
          errorMessages.PROVIDE_PROMOTIONAL_VIDEO_COUNT;
      else delete errorsMisc.promoVideoCount;
    }
    delete errorsMisc?.commissionPerProductPurchase;
    const isFormvalidMisc = Object.keys(errorsMisc).length !== 0 ? false : true;
    setMiscFormData({
      ...miscFormData,
      errorsMisc: errorsMisc,
    });

    return isFormvalidMisc;
  };

  /******************* 
  @Purpose : Used for handle change payment
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleChangePayment = (e) => {
    if (e.target.value) {
      setPaymentFormData({
        ...paymentFormData,
        [e.target.name]: e.target.value,
        errorsPayment: Object.assign(paymentFormData.errorsPayment, {
          [e.target.name]: "",
        }),
      });
    } else {
      setPaymentFormData({
        ...paymentFormData,
        [e.target.name]: "",
      });
    }
  };

  /******************* 
  @Purpose : Used for get payment gateway
  @Parameter : {}
  @Author : INIC
  ******************/
  const getPaymentGateway = async () => {
    const res = await callApi(
      API.GET_PAYMENT,
      "",
      "get",
      "API.GET_PAYMENT",
      true
    );
    if (res.status === 1) {
      let {
        environmentUrl,
        publicAPILoginId,
        publicTranKey,
        testTranKey,
        _id,
      } = res.data[0];
      setPaymentFormData({
        ...paymentFormData,
        environmentUrl,
        publicAPILoginId,
        publicTranKey,
        testTranKey,
        _id,
      });
    }
  };

  /******************* 
  @Purpose : Used for handle change misc
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleChangeMisc = (e) => {
    if (e.target.value) {
      setMiscFormData({
        ...miscFormData,
        [e.target.name]: e.target.value,
        errorsMisc: Object.assign(miscFormData.errorsMisc, {
          [e.target.name]: "",
        }),
      });
    } else {
      setMiscFormData({
        ...miscFormData,
        [e.target.name]: "",
      });
    }
  };

  /******************* 
  @Purpose : Used for form data change
  @Parameter : {event, _id, price, idx}
  @Author : INIC
  ******************/

  /******************* 
  @Purpose : Used for get plan config
  @Parameter : {}
  @Author : INIC
  ******************/
  const getPlanConfig = async () => {
    setSpin(true);
    let body = {
      pageNumber,
      pageSize,
    };
    const response = await callApi(
      API.GET_PLAN,
      body,
      "post",
      "GET_PLAN",
      true
    );
    if (response.status === 1) {
      setSpin(false);
      setPlanTableData(response?.data);
      setTotal(response?.totalData);
    } else if (response.status === 0) {
      setSpin(false);
      showModalNotification(response?.message, "error");
    }
  };

  /******************* 
  @Purpose : Used for handle change company form
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleChangeCompanyForm = (e) => {
    if (e.target.value) {
      setComapanyFormData({
        ...companyFormData,
        [e.target.name]: e.target.value,
        error: Object.assign(companyFormData.error, { [e.target.name]: "" }),
      });
    } else {
      setComapanyFormData({
        ...companyFormData,
        [e.target.name]: "",
      });
    }
  };

  /******************* 
  @Purpose : Used for get company info
  @Parameter : {}
  @Author : INIC
  ******************/
  const getComapnyInfo = async () => {
    const response = await callApi(
      API.GET_COMPANY_INFO,
      "",
      "get",
      "GET_COMPANY_INFO",
      true
    );
    if (response.status === 1) {
      let {
        emailId,
        mobileNumber,
        headOfficeaddress,
        fbUrl,
        instagramUrl,
        twitterUrl,
        linkedinUrl,
        _id,
        createdBy,
      } = response?.data[0];

      setComapanyFormData({
        ...companyFormData,
        emailId,
        headOfficeaddress,
        mobileNumber,
        fbUrl,
        instagramUrl,
        twitterUrl,
        linkedinUrl,
        _id,
        createdBy,
      });
    }
  };

  /******************* 
  @Purpose : Used for get Miscinfo
  @Parameter : {}
  @Author : INIC
  ******************/
  const getMiscInfo = async () => {
    const resMisc = await callApi(
      API.GET_MISC_INFO,
      "",
      "get",
      "GET_MISC_INFO",

      true
    );
    if (resMisc.status === 1) {
      let {
        videoAd_1_dayPrice,
        videoAd_7_dayPrice,
        videoAd_15_dayPrice,
        priorityOneVideo,
        priorityTwoVideo,
        priorityFourVideo,
        priorityThreeVideo,
        pricePerVideoView,
        pricePerProductClick_View,
        commissionPerProductPurchase,
        promoVideoGap,
        promoVideoCount,
        minimumPaymentAmount,
        perVideoVistaCharge,
        _id,
      } = resMisc.data[0];
      setMiscFormData({
        ...miscFormData,
        videoAd_1_dayPrice,
        videoAd_7_dayPrice,
        videoAd_15_dayPrice,
        priorityOneVideo,
        priorityTwoVideo,
        priorityFourVideo,
        priorityThreeVideo,
        pricePerVideoView,
        pricePerProductClick_View,
        commissionPerProductPurchase,
        promoVideoGap,
        promoVideoCount,
        minimumPaymentAmount,
        perVideoVistaCharge,
        _id,
      });
    }
  };

  /******************* 
  @Purpose : Used for update company info
  @Parameter : {e}
  @Author : INIC
  ******************/
  async function updateCompanyInfo() {
    try {
      if (validateCompanyForm()) {
        let responseCompany;
        let bodyCompany = {
          companyId: companyFormData._id,
          emailId: emailId,
          mobileNumber: mobileNumber,
          headOfficeaddress: headOfficeaddress,
          fbUrl: fbUrl,
          instagramUrl: instagramUrl,
          twitterUrl: twitterUrl,
          linkedinUrl: linkedinUrl,
          updateBy: localStorage.getItem("id"),
        };
        responseCompany = await callApi(
          API.UPDATE_COMPANY_INFO,
          bodyCompany,
          "put",
          "UPDATE_COMPANY_INFO",
          true,
          false,
          true
        );

        if (responseCompany.status === 1) {
          showMessageNotification("Details updated successfully", "success");
          getComapnyInfo();
        } else if (responseCompany.status === 0) {
          showModalNotification(responseCompany?.message, "error");
        }
      }
    } catch (error) {
      console.error();
    }
  }

  /******************* 
  @Purpose : Used for update payment
  @Parameter : {e}
  @Author : INIC
  ******************/
  async function updatePayment() {
    try {
      if (validatePaymentForm()) {
        let responsePayment;
        let bodyPayment = {
          id: paymentFormData._id,
          publicAPILoginId: publicAPILoginId,
          environmentUrl: environmentUrl,
          publicTranKey: publicTranKey,
          testTranKey: testTranKey,
        };
        responsePayment = await callApi(
          API.UPDATE_PAYMENT,
          bodyPayment,
          "put",
          "UPDATE_PAYMENT",
          true,
          false,
          true
        );
        if (responsePayment.status === 1) {
          showMessageNotification("Details updated successfully", "success");
          getPaymentGateway();
        } else if (responsePayment.status === 0) {
          showModalNotification(responsePayment.message, "error");
        }
      }
    } catch (error) {
      console.error();
    }
  }

  /******************* 
  @Purpose : Used for update misc
  @Parameter : {e}
  @Author : INIC
  ******************/
  async function updateMisc() {
    try {
      if (validateMiscForm()) {
        let responseMisc;
        let bodyMisc = {
          id: miscFormData._id,
          videoAd_1_dayPrice: Number(videoAd_1_dayPrice),
          videoAd_7_dayPrice: Number(videoAd_7_dayPrice),
          videoAd_15_dayPrice: Number(videoAd_15_dayPrice),
          priorityOneVideo: Number(priorityOneVideo),
          priorityTwoVideo: Number(priorityTwoVideo),
          priorityFourVideo: Number(priorityFourVideo),
          priorityThreeVideo: Number(priorityThreeVideo),
          pricePerVideoView: Number(pricePerVideoView),
          pricePerProductClick_View: Number(pricePerProductClick_View),
          commissionPerProductPurchase: Number(commissionPerProductPurchase),
          promoVideoGap: Number(promoVideoGap),
          promoVideoCount: Number(promoVideoCount),
          minimumPaymentAmount: Number(minimumPaymentAmount),
          perVideoVistaCharge: Number(perVideoVistaCharge),
        };
        responseMisc = await callApi(
          API.UPDATE_MISC_INFO,
          bodyMisc,
          "put",
          "UPDATE_MISC_INFO",
          true,
          false,
          true
        );

        if (responseMisc.status === 1) {
          showMessageNotification("Details updated successfully", "success");
          getMiscInfo();
        } else if (responseMisc.status === 0) {
          showModalNotification(responseMisc?.message, "error");
          getMiscInfo();
        }
      }
    } catch (error) {
      console.error();
    }
  }

  /******************* 
  @Purpose : Used for change plan status
  @Parameter : {_id}
  @Author : INIC
  ******************/
  const changePlanStatus = async (_id) => {
    let body = {
      planId: _id,
    };
    const response = await callApi(
      API.PLAN_CHANGE_STATUS,
      body,
      "post",
      "PLAN_CHANGE_STATUS",
      true,
      false,
      true
    );
    if (response?.status === 1) {
      showMessageNotification("Status updated successfully", "success");
    }
  };

  /******************* 
  @Purpose : Used for clear company form data
  @Parameter : {}
  @Author : INIC
  ******************/
  function clearCompanyFormData() {
    setComapanyFormData({
      emailId: "",
      mobileNumber: "",
      headOfficeaddress: "",
      fbUrl: "",
      instagramUrl: "",
      twitterUrl: "",
      linkedinUrl: "",
      _id: "",
      createdBy: "",
      error: {},
    });
  }

  /******************* 
  @Purpose : Used for clear payment form data
  @Parameter : {}
  @Author : INIC
  ******************/
  function clearPaymentFormData() {
    setPaymentFormData({
      environmentUrl: "",
      publicAPILoginId: "",
      testTranKey: "",
      publicTranKey: "",
      errorsPayment: {},
    });
  }

  /******************* 
  @Purpose : Used for clear misc form data
  @Parameter : {}
  @Author : INIC
  ******************/
  function clearMiscFormData() {
    setMiscFormData({
      videoAd_1_dayPrice: "",
      videoAd_7_dayPrice: "",
      videoAd_15_dayPrice: "",
      priorityOneVideo: "",
      priorityTwoVideo: "",
      priorityFourVideo: "",
      priorityThreeVideo: "",
      pricePerVideoView: "",
      pricePerProductClick_View: "",
      commissionPerProductPurchase: "",
      promoVideoGap: "",
      promoVideoCount: "",
      minimumPaymentAmount: "",
      _id: "",
      errorsMisc: {},
    });
  }
  /******************* 
  @Purpose : Used for pagination
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  function paginationChange(pageNumber, pageSize) {
    setPageNumber(pageNumber);
    setPageSize(pageSize);
  }
  /******************* 
  @Purpose : Used for get company info
  @Parameter : {}
  @Author : INIC
  ******************/

  //
  const handleRadio = (e) => {
    setTranactioRadio(e.target.value);
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Settings">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <form>
              <div className="card mb-5">
                <div className="card-header">
                  <Tabs
                    defaultActiveKey="Plan Configuration"
                    id="uncontrolled-tab-example"
                    onSelect={(eventKey) => setEventKeys(eventKey)}
                  >
                    <Tab
                      eventKey="Plan Configuration"
                      title={"Plan Configuration"}
                      className="pt-4"
                    >
                      <div className="row mb-3">
                        <div className="col-sm-12 text-end ">
                          <Link to="/addPlan">
                            <Button
                              type="button"
                              className="btn btn-primary glow-primary"
                            >
                              {"Add Plan"}
                            </Button>
                          </Link>
                        </div>
                      </div>

                      {/* plan table code starts here */}
                      <div className="content">
                        <div className="table-responsive">
                          {spin ? (
                            <div className="d-flex align-center justify-content-center">
                              <Spinner />
                            </div>
                          ) : null}
                          <table
                            className="table row-border nowrap common-datatable"
                            id="master-country-listing"
                          >
                            <thead>
                              <tr>
                                {/* Plan Title */}

                                <th>
                                  <b>Plan Title</b>
                                </th>

                                {/* Plan type */}
                                <th>
                                  <b>Plan Type</b>
                                </th>
                                {/*plan amount */}
                                <th>
                                  <b>Plan Amount (₹)</b>
                                </th>
                                {/* No of months */}
                                <th>
                                  <b>No. Of Months</b>
                                </th>
                                {/* No. of Videos per Month */}
                                <th>
                                  <b>No. Of Videos Per Month</b>
                                </th>
                                {/* total videos */}
                                <th>
                                  <b>Total Videos</b>
                                </th>
                                {/* status */}
                                <th>
                                  <b>Status</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {planTableData && planTableData?.length === 0 ? (
                                <tr className="text-center text-danger not-found-txt">
                                  <td colSpan="6">
                                    <h6
                                      className="text-center text-danger not-found-txt"
                                      colSpan="6"
                                    >
                                      {"No plans data was found "}
                                    </h6>
                                  </td>
                                </tr>
                              ) : (
                                planTableData &&
                                Array.isArray(planTableData) &&
                                planTableData?.map((list, key) => {
                                  return (
                                    <tr key={list?._id}>
                                      <td>
                                        <div className="eamil-col d-flex align-items-center">
                                          <span>{list.planTitle}</span>
                                        </div>
                                      </td>

                                      {/* sub categories  */}

                                      <td>
                                        <div className="email-col d-flex align-items-center">
                                          <div> {list.planType}</div>
                                        </div>
                                      </td>

                                      <td>
                                        <div
                                          className="eamil-col d-flex align-items-center justify-content-start"
                                          style={{ marginLeft: "15px" }}
                                        >
                                          {list.planAmount}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          className="eamil-col d-flex align-items-center justify-content-start"
                                          style={{ marginLeft: "30px" }}
                                        >
                                          <span>{list.noOfMonths}</span>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          className="eamil-col d-flex align-items-center justify-content-start"
                                          style={{ marginLeft: "45px" }}
                                        >
                                          <span>{list.videosPerMonth}</span>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          className="eamil-col d-flex align-items-center justify-content-start"
                                          style={{ marginLeft: "30px" }}
                                        >
                                          <span>{list.totalVideos}</span>
                                        </div>
                                      </td>
                                      {/* status */}

                                      <td>
                                        <div className="custom-control custom-switch light">
                                          <Form.Check
                                            type="switch"
                                            onChange={() => {
                                              list.status = !list.status;
                                              setPlanStatus({ planTableData });
                                              changePlanStatus(list._id);
                                            }}
                                            checked={list.status}
                                            id={list._id}
                                          />

                                          <label
                                            className="custom-control-label"
                                            for={list._id}
                                          ></label>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {planTableData && planTableData.length < 10 ? (
                        <div>
                          <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                            <p className="mb-0 align-self-center text-primary-light fs-14">
                              Showing <b>{planTableData.length}</b> Out of{" "}
                              <b>{total}</b> plans
                            </p>
                            <div className="pagination-list ms-2">
                              <Pagination
                                className="ant-pagination"
                                pageSize={pageSize}
                                current={pageNumber}
                                total={total}
                                onChange={paginationChange}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                          <p className="mb-0 align-self-center text-primary-light fs-14">
                            Showing <b>10</b> Out of <b>{total}</b> plans
                          </p>
                          <div className="pagination-list ms-2">
                            <Pagination
                              className="ant-pagination"
                              pageSize={pageSize}
                              current={pageNumber}
                              total={total}
                              onChange={paginationChange}
                            />
                          </div>
                        </div>
                      )}
                      {/* plan table code ends here */}
                    </Tab>

                    {/* company information */}
                    <Tab
                      eventKey="Company Information"
                      title={"Company Information"}
                    >
                      <div className="pt-4">
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <div className="form-group mb-3">
                              <label className="form-label" htmlFor="email">
                                Email <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="email"
                                name="emailId"
                                placeholder="Enter Official Email"
                                value={emailId}
                                onChange={(e) => handleChangeCompanyForm(e)}
                              />

                              <span className="error-msg text-danger">
                                {error.emailId}
                              </span>
                            </div>
                            <div className="form-group mb-3">
                              <label className="form-label" htmlFor="mobile">
                                Mobile <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="mobile"
                                name="mobileNumber"
                                value={mobileNumber}
                                placeholder="Enter Official Mobile"
                                onChange={(e) => handleChangeCompanyForm(e)}
                              />
                              <span className="error-msg text-danger ">
                                {error.mobileNumber}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12 mb-3">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="head-office-address"
                              >
                                Head Office Address
                                <span className="text-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                id="headOfficeAddress"
                                name="headOfficeaddress"
                                placeholder="Enter your Head Office Address here"
                                rows="6"
                                value={headOfficeaddress}
                                onChange={(e) => handleChangeCompanyForm(e)}
                              />
                              <span className="error-msg text-danger">
                                {error.headOfficeaddress}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12 mb-3">
                            <div className="form-group">
                              <label className="form-label" htmlFor="instagram">
                                Instagram URL
                              </label>
                              <input
                                className="form-control"
                                id="instagram"
                                name="instagramUrl"
                                placeholder="Enter Instagram URL"
                                type="text"
                                value={instagramUrl}
                                onChange={(e) => handleChangeCompanyForm(e)}
                              />
                              <span className="error-msg text-danger"></span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12 mb-3">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="facebook url"
                              >
                                Facebook URL
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="facebook"
                                name="fbUrl"
                                placeholder="Enter facebook URL"
                                value={fbUrl}
                                onChange={(e) => handleChangeCompanyForm(e)}
                              />
                              <span className="error-msg text-danger"></span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12 mb-3">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="twitter url"
                              >
                                Twitter URL
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="twitter"
                                name="twitterUrl"
                                value={twitterUrl}
                                placeholder="Enter twitter URL"
                                onChange={(e) => handleChangeCompanyForm(e)}
                              />
                              <span className="error-msg text-danger" />
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12 mb-3">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="linkedin url"
                              >
                                LinkedIn URL
                              </label>
                              <input
                                className="form-control"
                                id="linkedin"
                                name="linkedinUrl"
                                placeholder="Enter LinkedIn URL"
                                type="text"
                                value={linkedinUrl}
                                onChange={(e) => handleChangeCompanyForm(e)}
                              />
                              <span className="error-msg text-danger"></span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 text-end">
                          <Button variant="light" className="me-2" onClick={clearCompanyFormData}>
                            Clear
                          </Button>
                          <Button
                            variant="primary"
                            onClick={updateCompanyInfo}
                          >
                            Update Changes
                          </Button>
                        </div>
                      </div>
                    </Tab>
                    {/* company Information */}

                    {/* payment gateway */}
                    <Tab eventKey="Payment Gateway" title={"Payment Gateway"}>
                      <div className="pt-4">
                        <div className="row">
                          <div className="col-lg-6 col-md-12 mb-3">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="environment"
                              >
                                Environment
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="environmentUrl"
                                name="environmentUrl"
                                placeholder="Enter here"
                                value={environmentUrl}
                                onChange={(e) => handleChangePayment(e)}
                              />
                              <span className="error-msg text-danger">
                                {errorsPayment.environmentUrl}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12 mb-3">
                            <div className="form-group">
                              <label className="form-label" htmlFor="FirstName">
                                Public API Login ID
                              </label>
                              <input
                                className="form-control"
                                id="publicAPILoginId"
                                name="publicAPILoginId"
                                placeholder="Enter here"
                                value={publicAPILoginId}
                                onChange={(e) => handleChangePayment(e)}
                              />
                              <span className="error-msg text-danger">
                                {errorsPayment.publicAPILoginId}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12 mb-3">
                            <div className="form-group">
                              <label className="form-label" htmlFor="tApi key">
                                Test Transaction Key
                              </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <input
                                      type="radio"
                                      name="Transaction Radio"
                                      id="testTransaction"
                                      value="test"
                                      onChange={handleRadio}
                                      checked={transactionRadio === "test"}
                                      aria-label="Radio button for following text input"
                                    />
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  aria-label="testTranKey"
                                  id="testTranKey"
                                  name="testTranKey"
                                  placeholder="Enter Official Mobile"
                                  value={testTranKey}
                                  onChange={(e) => handleChangePayment(e)}
                                />
                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                >
                                  {errorsPayment.testTranKey}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12 mb-3">
                            <div className="form-group">
                              <label className="form-label" htmlFor="tApi key">
                                Public Transaction Key
                              </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <input
                                      type="radio"
                                      name="Transaction Radio"
                                      id="publicTransaction"
                                      value="public"
                                      onChange={handleRadio}
                                      checked={transactionRadio === "public"}
                                      aria-label="transaction key"
                                    />
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  aria-label="publictestTranKey"
                                  id="publictestTranKey"
                                  name="publicTranKey"
                                  placeholder="Enter Public Transaction Key"
                                  value={publicTranKey}
                                  onChange={(e) => handleChangePayment(e)}
                                />
                                <span className="error-msg text-danger">
                                  {errorsPayment.publicTranKey}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 text-end">
                          <Button
                            variant="light"
                            className="me-2"
                            onClick={clearPaymentFormData}
                          >
                            Clear
                          </Button>
                          <Button

                            variant="primary"
                            onClick={updatePayment}
                          >
                            Update Changes
                          </Button>

                        </div>
                      </div>
                    </Tab>

                    {/* misc information */}

                    <Tab
                      eventKey="Misc. Information"
                      title={"Misc. Information"}
                    >
                      <div className="pt-4">
                        <div className="row">
                          <div className="col-lg-4 col-md-6 mb-3">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="video price"
                              >
                                Video Ad 1 Day Price
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">₹</div>
                                </div>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="email"
                                  name="videoAd_1_dayPrice"
                                  placeholder="500"
                                  value={videoAd_1_dayPrice}
                                  onChange={(e) => handleChangeMisc(e)}
                                />
                              </div>
                            </div>
                            <span className="error-msg text-danger">
                              {errorsMisc.videoAd_1_dayPrice}
                            </span>
                          </div>

                          <div className="col-lg-4 col-md-6 mb-3">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="video 7 price"
                              >
                                Video Ad 7 Day Price
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">₹</div>
                                </div>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="videoAd_7_dayPrice"
                                  name="videoAd_7_dayPrice"
                                  placeholder="1400"
                                  rows="6"
                                  style={{ width: "50%" }}
                                  value={videoAd_7_dayPrice}
                                  onChange={(e) => handleChangeMisc(e)}
                                />
                              </div>
                              <span
                                className="error-msg"
                                style={{ color: "red" }}
                              >
                                {errorsMisc.videoAd_7_dayPrice}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 mb-3">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="video 7 price"
                              >
                                Video Ad 15 Day Price
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">₹</div>
                                </div>
                                <input
                                  className="form-control"
                                  id="videoAd_15_dayPrice"
                                  name="videoAd_15_dayPrice"
                                  placeholder="5000"
                                  type="number"
                                  style={{ width: "50%" }}
                                  value={videoAd_15_dayPrice}
                                  onChange={(e) => handleChangeMisc(e)}
                                />
                              </div>
                              <span
                                className="error-msg"
                                style={{ color: "red" }}
                              >
                                {errorsMisc.videoAd_15_dayPrice}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 mb-3">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="Price Per Video View*"
                              >
                                Price Per Video View
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">₹</div>
                                </div>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="mobile"
                                  name="pricePerVideoView"
                                  style={{ width: "50%" }}
                                  value={pricePerVideoView}
                                  placeholder="0.001"
                                  onChange={(e) => handleChangeMisc(e)}
                                />
                              </div>
                              <span
                                className="error-msg"
                                style={{ color: "red" }}
                              >
                                {errorsMisc.pricePerVideoView}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 mb-3">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="Price Per Product Click/View"
                              >
                                Price Per Product Click/View
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">₹</div>
                                </div>
                                <input
                                  className="form-control"
                                  id="pricePerProductClick_View"
                                  name="pricePerProductClick_View"
                                  placeholder="0.01"
                                  type="number"
                                  style={{ width: "50%" }}
                                  value={pricePerProductClick_View}
                                  onChange={(e) => handleChangeMisc(e)}
                                />
                              </div>
                              <span
                                className="error-msg"
                                style={{ color: "red" }}
                              >
                                {errorsMisc.pricePerProductClick_View}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 mb-3">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="Price Per Product Click/View"
                              >
                                Comission % Per Product Purchase &nbsp;
                                <span className="form-label">(Optional)</span>
                              </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">%</div>
                                </div>
                                <input
                                  className="form-control"
                                  id="commissionPerProductPurchase"
                                  name="commissionPerProductPurchase"
                                  placeholder="Enter commissionPerProductPurchase"
                                  type="number"
                                  style={{ width: "50%" }}
                                  value={commissionPerProductPurchase}
                                  onChange={(e) => handleChangeMisc(e)}
                                />
                              </div>
                              <span
                                className="error-msg"
                                style={{ color: "red" }}
                              ></span>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 mb-3">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="video-priority-1"
                              >
                                % of videos of Priority 1
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="facebook"
                                name="priorityOneVideo"
                                placeholder="3"
                                value={priorityOneVideo}
                                onChange={(e) => handleChangeMisc(e)}
                              />
                              <span className="error-msg text-danger">
                                {errorsMisc.priorityOneVideo}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 mb-3">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="video-priority-2"
                              >
                                % of videos of Priority 2{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                id="priorityTwoVideo"
                                name="priorityTwoVideo"
                                placeholder="1"
                                type="number"
                                value={priorityTwoVideo}
                                onChange={(e) => handleChangeMisc(e)}
                              />
                              <span className="error-msg text-danger">
                                {errorsMisc.priorityTwoVideo}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 mb-3">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="video-priority-3"
                              >
                                % of videos of Priority 3{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                id="priorityThreeVideo"
                                name="priorityThreeVideo"
                                placeholder="1"
                                type="number"
                                value={priorityThreeVideo}
                                onChange={(e) => handleChangeMisc(e)}
                              />
                              <span className="error-msg text-danger">
                                {errorsMisc.priorityThreeVideo}
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 mb-3">
                            <label className="form-label" htmlFor="video-count">
                              % Of Videos Of Priority 4
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              id="priorityFourVideo"
                              name="priorityFourVideo"
                              placeholder="1"
                              type="number"
                              value={priorityFourVideo}
                              onChange={(e) => handleChangeMisc(e)}
                            />
                            <span className="error-msg text-danger">
                              {errorsMisc.priorityFourVideo}
                            </span>
                          </div>
                          <div className="col-lg-4 col-md-6 mb-3">
                            <label
                              className="form-label"
                              htmlFor="promotion-gap"
                            >
                              Promotional Video GAP
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="promoVideoGap"
                              name="promoVideoGap"
                              value={promoVideoGap}
                              placeholder="3"
                              onChange={(e) => handleChangeMisc(e)}
                            />
                            <span className="error-msg text-danger">
                              {errorsMisc.promoVideoGap}
                            </span>
                          </div>

                          <div className="col-lg-4 col-md-6 mb-3">
                            <label className="form-label" htmlFor="video-count">
                              Promotional Video Count
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              id="promoVideoCount"
                              name="promoVideoCount"
                              placeholder="1"
                              type="number"
                              value={promoVideoCount}
                              onChange={(e) => handleChangeMisc(e)}
                            />
                            <span className="error-msg text-danger">
                              {errorsMisc.promoVideoCount}
                            </span>
                          </div>
                          <div className="col-lg-4 col-md-6 mb-3">
                            <label className="form-label" htmlFor="video-count">
                              Minumum Payment
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              id="minimumPaymentAmount"
                              name="minimumPaymentAmount"
                              placeholder="Minumum Payment in Number"
                              type="number"
                              value={minimumPaymentAmount}
                              onChange={(e) => handleChangeMisc(e)}
                            />
                            <span className="error-msg text-danger">
                              {errorsMisc.minimumPaymentAmount}
                            </span>
                          </div>
                          {/*
                           */}

                          <div className="col-lg-4 col-md-6 mb-3">
                            <label className="form-label" htmlFor="video-count">
                              perVideoVistaCharge
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              id="perVideoVistaCharge"
                              name="perVideoVistaCharge"
                              placeholder="vista charge per video"
                              type="number"
                              value={perVideoVistaCharge}
                              onChange={(e) => handleChangeMisc(e)}
                            />
                            <span className="error-msg text-danger">
                              {errorsMisc.perVideoVistaCharge}
                            </span>
                          </div>
                          {/* video  */}

                          <div className="col-lg-12 text-end">
                            <Button variant="light"
                              className="me-2"
                              onClick={clearMiscFormData}>
                              Clear
                            </Button>
                            <Button
                              variant="primary"
                              onClick={updateMisc}
                            >
                              Update Changes
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </form>
          </div>

          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  );
}

/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi })(SettingsPlanConfig);
