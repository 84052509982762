import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import Select from "react-select";

import { showModalNotification } from "../../Utils/Functions";
import { callApi } from "../../Api";
import API from "../../Api/Routes";
import errorMessages from "../../Utils/ErrorMessages";
import Layout from "../../Components/Layout/Layout";

import "react-datepicker/dist/react-datepicker.css";

/******************* 
@Purpose : Used for app plan
@Parameter : {props}
@Author : INIC
******************/

function AddPlan(props) {
  const [planType, setPlanType] = useState("");
  const [noOfMonths, setNoOfMonths] = useState("");
  const [isDeleted] = useState(false);
  const [errors] = useState({});
  const [addPlanForm, setAddPlanForm] = useState({
    planTitle: "",

    noOfMonths: "",
    description: "",
    planAmount: "",
    totalVideos: "",
    videosPerMonth: "",
    errorsPlan: {},
  });

  let {
    planTitle,

    description,
    planAmount,

    videosPerMonth,
    errorsPlan,
  } = addPlanForm;
  let isFormValid;
  const validateAddPlanForm = () => {
    delete errorsPlan.description;

    if (addPlanForm.hasOwnProperty("planTitle")) {
      if (isEmpty(planTitle))
        errorsPlan.planTitle = errorMessages.PROVIDE_PLAN_TITLE;
      else delete errorsPlan.planTitle;
    }
    if (isEmpty(planType?.value))
      errors.planType = errorMessages.PROVIDE_PLAN_TYPE;

    if (isEmpty(noOfMonths?.value))
      errors.noOfMonths = errorMessages.PROVIDE_PLAN_MONTHS;

    if (addPlanForm.hasOwnProperty("planAmount")) {
      if (isEmpty(planAmount + ""))
        errorsPlan.planAmount = errorMessages.PROVIDE_PLAN_FEE;
      else delete errorsPlan.planAmount;
    }
    if (isEmpty(planType)) errors.planType = errorMessages.PROVIDE_PLAN_TYPE;
    else delete errors.planType;

    if (addPlanForm.hasOwnProperty("videosPerMonth")) {
      if (isEmpty(videosPerMonth + ""))
        errorsPlan.videosPerMonth = errorMessages.PROVIDE_VIDEOS_PER_MONTH;
      else delete errorsPlan.videosPerMonth;
    }

    const isFormvalidPlan =
      Object.keys(errorsPlan).length !== 0
        ? false
        : Object.keys(errorsPlan).length !== 0
          ? false
          : true;
    setAddPlanForm({
      ...addPlanForm,
      errorsPlan: errorsPlan,
    });

    return isFormvalidPlan;
  };

  /******************* 
  @Purpose : Used for handle change plan
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleChangePlan = (e) => {
    if (e.target.value) {
      setAddPlanForm({
        ...addPlanForm,
        [e.target.name]: e.target.value,
        errorsPlan: Object.assign(addPlanForm.errorsPlan, {
          [e.target.name]: "",
        }),
      });
    } else {
      setAddPlanForm({
        ...addPlanForm,
        [e.target.name]: "",
      });
    }
  };

  /******************* 
  @Purpose : Used for Bread crumb
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      key: "6",
      title: "Settings",
      url: "/planConfiguration",
    },
    {
      key: "31",
      title: "Add Plan",
    },
  ];

  /******************* 
  @Purpose : Used for month and year options
  @Parameter : {}
  @Author : INIC
  ******************/
  const options = [
    { value: "Month", label: "Month" },
    { value: "Year", label: "Year" },
  ];

  /******************* 
  @Purpose : Used for month options
  @Parameter : {}
  @Author : INIC
  ******************/
  const options1 = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
  ];

  /******************* 
  @Purpose : Used for handle donwload
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleDownload = (e) => {
    setPlanType(e);
  };

  /******************* 
  @Purpose : Used for handle month
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleMonth = (e) => {
    setNoOfMonths(e);
  };
  let totalVideosAmount = videosPerMonth * noOfMonths.value;
  let numberOfMonths = parseInt(noOfMonths.value);
  let planAmountValue = parseInt(planAmount);
  let videosPerMonthValue = parseInt(videosPerMonth);

  /******************* 
  @Purpose : Used for create plan
  @Parameter : {e}
  @Author : INIC
  ******************/
  const createPlan = async (e) => {
    e.preventDefault();
    try {
      if (validateAddPlanForm()) {
        let responsePlan;
        let bodyPlan = {
          planTitle: planTitle,
          planType: planType?.value,
          noOfMonths: numberOfMonths,
          planAmount: planAmountValue,
          description: description,
          totalVideos: totalVideosAmount,
          videosPerMonth: videosPerMonthValue,
          isDeleted: isDeleted,
          noOfBrandssubscribers: 0,
          createdBy: localStorage.getItem("id"),
          status: true,
        };

        responsePlan = await callApi(
          API.SAVE_PLAN,
          bodyPlan,
          "post",
          "SAVE_PLAN",
          true,
          false,
          true
        );

        if (responsePlan.status === 1) {
          showModalNotification("Plan created successfully", "success");
          props.history.push("/planConfiguration");
        }
      }
    } catch (error) {
      console.error();
    }
  };

  /******************* 
  @Purpose : Used for clear plan data
  @Parameter : {}
  @Author : INIC
  ******************/
  const clearPlanData = () => {
    setAddPlanForm({
      planTitle: "",

      noOfMonths: "",
      description: "",
      planAmount: "",
      totalVideos: "",
      videosPerMonth: "",
      errorsPlan: {},
    });
    setPlanType("");
    setNoOfMonths("");
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Settings">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="card">
              <div className="card-header pb-0">
                <h5 className="card-title">Add Plan</h5>
              </div>
              <form>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 mb-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Plan Title">
                          Plan Title
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="videosPerMonth"
                          name="planTitle"
                          placeholder="Plan Title"
                          value={planTitle}
                          onChange={(e) => handleChangePlan(e)}
                        />
                        <span className="error-msg text-danger">
                          {errorsPlan.planTitle}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 mb-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Plan Type">
                          Plan Type
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          id="planType"
                          name="planType"
                          options={options}
                          placeholder="Select Plan"
                          onChange={(e) => {
                            handleDownload(e);
                          }}
                          value={planType}
                        />
                        <span className="error-msg text-danger">
                          {errors.planType}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 mb-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="plan amount">
                          Plan Amount (₹)
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="planAmount"
                          name="planAmount"
                          value={planAmount}
                          placeholder="Plan Amount"
                          onChange={(e) => handleChangePlan(e)}
                        />
                        <span className="error-msg text-danger">
                          {errorsPlan.planAmount}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 mb-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="no. of months">
                          No. of months
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          id="months"
                          name="noOfMonths"
                          options={options1}
                          placeholder="Select months"
                          onChange={(e) => {
                            handleMonth(e);
                          }}
                          value={noOfMonths}
                        />
                        <span
                          className="text-danger error-msg"
                          style={{ color: "red" }}
                        >
                          {errors.noOfMonths}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 mb-3">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="Videos per month"
                        >
                          No. of Videos Per Month
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="videosPerMonth"
                          name="videosPerMonth"
                          placeholder="No. Of Videos Per Month"
                          value={videosPerMonth}
                          onChange={(e) => handleChangePlan(e)}
                        />
                        <span className="text-danger error-msg">
                          {errorsPlan.videosPerMonth}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 mb-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Total Videos">
                          Total Videos
                        </label>
                        <input
                          className="form-control form-control-videos"
                          id="totalVideos"
                          name="totalVideos"
                          placeholder="Total Videos"
                          type="number"
                          value={totalVideosAmount}
                          disabled
                        />
                        <span
                          className="text-danger error-msg"
                          style={{ color: "red" }}
                        >
                          {errorsPlan.totalVideos}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 mb-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="dsc">
                          Description
                        </label>
                        <textarea
                          className="form-control"
                          id="description"
                          name="description"
                          rows="3"
                          placeholder="Description"
                          type="text"
                          value={description}
                          onChange={(e) => handleChangePlan(e)}
                        />
                        <span className="text-danger error-msg"></span>
                      </div>
                    </div>
                    <div className="col-md-12 text-end">
                      <Link to="/planConfiguration">
                        <Button className="me-2" variant="light">
                          Cancel
                        </Button>
                      </Link>
                      <Button
                        variant="primary"
                        onClick={createPlan}
                      >
                        Save
                      </Button>

                      {/* <Button variant="light" onClick={clearPlanData}>
                        Clear
                      </Button> */}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
      <div className="row"> </div>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  plan: state.admin.plan,
});
export default connect(mapStateToProps, { callApi })(AddPlan);
