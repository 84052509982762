import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Image, Tab, Tabs } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, connect } from "react-redux";
import debounce from "lodash.debounce";
import Pagination from "rc-pagination";
import moment from "moment";
import DatePicker from "react-datepicker";

import { isEmpty } from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import { Layout } from "../../Components/Layout";

import {
  showModalNotification,
  showMessageNotification,
} from "../../Utils/Functions";
import {
  paymentFilterVideos,
  productClickForPayment,
  getPayoutVidPurchaseData,
} from "../../Store/Actions/User";
import API from "../../Api/Routes";
import { callApi } from "../../Api"; // Used for api call

/******************* 
@Purpose : Used for view user details
@Parameter : props
@Author : INIC
******************/
const options = [
  { value: "Excel", label: "Excel" },
  { value: "CSV", label: "CSV" },
];
const selectFieldOptions = [
  { value: "category", label: "Category" },
  { value: "brand", label: "Brand" },
  { value: "date", label: "Date" },
];
const selectFieldOptions1 = [
  { value: "category", label: "Category" },
  { value: "subcategory", label: "SubCategory" },
  { value: "brand", label: "Brand" },
  { value: "date", label: "Date" },
];

const selectContentOptions = [{ value: "Select Field", label: "Select Field" }];
function ViewPayoutRequestDetail(props) {
  let history = useHistory();

  const dispatch = useDispatch();

  let { slug } = useParams();
  const [filterObj, setFilterObj] = useState([
    {
      key: "",
      type: "",
      input: "",
      value: { startDate: "", endDate: "" },
      condition: "$and",
    },
  ]);
  const [errorsLicence, setErrorsLicence] = useState([
    {
      key: "",
      type: "",
      input: "",
      value: { startDate: "", endDate: "" },
    },
  ]);
  const [categories, setCategories] = useState([]);
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [downloadSelect, setDownloadSelect] = useState("");
  const [eventKey, setEventKeys] = useState("Video Views");
  const [transcationData, setTransactionData] = useState("");
  let [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  const [total, setTotal] = useState(0);
  const [filterpopup, openFilterpopup] = useState(false);
  const [searchText, setSearchtext] = useState("");
  const [viewRequests, setViewRequests] = useState("");
  const [videoViewsListing, setVideoViewsListing] = useState([]);
  const [celebFilter, setCelebFilter] = useState([]);
  const [celebFilter1, setCelebFilter1] = useState([]);
  const [brand, setBrand] = useState([]);
  const [prodPage, setProdPage] = useState(1);
  const [prodPageSize, setProdPageSize] = useState(10);
  const [prodTotal, setProdTotal] = useState(0);
  const [productClickList, setProductClickList] = useState([]);
  const [downloadSelect1, setDownloadSelect1] = useState("");
  const [downloadSelect3, setDownloadSelect3] = useState("");

  const [searchText1, setSearchText1] = useState("");
  const [vidPage, setVidPage] = useState(1);
  const [vidPageSize, setVidPageSize] = useState(10);
  const [vidTotal, setVidTotal] = useState(0);
  const [vidData, setVidData] = useState([]);
  const [searchText3, setSearchText3] = useState("");
  const [celebFilter3, setCelebFilter3] = useState([]);
  /******************* 
  @Purpose : Used for pagination
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  /******************* 
  @Purpose : Used for brand list
  @Parameter : {}
  @Author : INIC
  ******************/
  const getBrandList = async () => {
    try {
      let body = {
        searchText: "",
      };

      const response = await callApi(
        API.ALL_BRANDS,
        body,
        "post",
        "ALL_BRANDS",
        true,
        true,
        false
      );

      if (response) {
        const allBrandsList = Array.isArray(response.data)
          ? response.data.map((item) => ({
              ...item,
              label: item.organization,
              value: item._id,
            }))
          : [];
        setBrand(allBrandsList);
      }
    } catch (error) {
      console.error();
    }
  };
  // payout purchase data
  const getAllPurchaseData = async (e) => {
    let body = {
      requestId: slug,
      pageNumber: vidPage,
      pageSize: vidPageSize,
      searchText: "",
      filter: celebFilter3 || [],
    };

    try {
      dispatch(getPayoutVidPurchaseData(body)).then((response) => {
        if (response.status === 1) {
          setVidData(response?.data);
          setVidPage(1);
          setVidTotal(response?.totalNoOfOrders);
        } else if (response.status === 0) {
          setVidData([]);
          setVidTotal(0);
        }
      });
    } catch (error) {
      console.error();
    }
  };
  const getAllPurchaseData1 = async (e) => {
    let body = {
      requestId: slug,
      pageNumber: vidPage,
      pageSize: vidPageSize,
      searchText: "",
      filter: [],
    };

    try {
      dispatch(getPayoutVidPurchaseData(body)).then((response) => {
        if (response.status === 1) {
          setVidData(response?.data);
          setVidPage(1);
          setVidTotal(response?.totalNoOfOrders);
        } else if (response.status === 0) {
          setVidData([]);
          setVidTotal(0);
        }
      });
    } catch (error) {
      console.error();
    }
  };
  /******************* 
  @Purpose : Used for get all the vids
  @Parameter : status, timezoneId
  @Author : INIC
  ******************/
  const getAllProductClicks = async (
    propspage = prodPage,
    propspagesize = prodPageSize
  ) => {
    let body = {
      requestId: slug,
      pageNumber: propspage,
      pageSize: propspagesize,
      searchText: "",
      filter: celebFilter1 || [],
    };

    try {
      dispatch(productClickForPayment(body)).then((response) => {
        if (response.status === 1) {
          setProductClickList(response?.data);

          setProdPageSize(10);
          setProdTotal(response.totalViews);
        } else if (response.status === 0) {
          setProductClickList([]);
          setProdTotal(0);
        }
      });
    } catch (error) {
      console.error();
    }
  };
  const getAllProductClicks1 = async (e) => {
    let body = {
      requestId: slug,
      pageNumber: prodPage,
      pageSize: prodPageSize,
      searchText: "",
      filter: [],
    };

    try {
      dispatch(productClickForPayment(body)).then((response) => {
        if (response.status === 1) {
          setProductClickList(response?.data);

          setPagesize(10);
          setProdTotal(response.totalViews);
        } else if (response.status === 0) {
          showModalNotification(response?.message, "error");
          setProductClickList([]);
          setProdTotal(0);
        }
      });
    } catch (error) {
      console.error();
    }
  };
  /******************* 
  @Purpose : Used forDOWNLOAD  the requests
  @Parameter : e
  @Author : INIC
  ******************/

  const handleDownload = async (e) => {
    setDownloadSelect(e);

    let requiredDownload = e.value;
    if (requiredDownload === "Excel") {
      let body = {
        requestId: slug,
        format: "xls",
      };
      try {
        const response = await callApi(
          `/payout/downloadVideoViews`,
          body,
          "post",
          null,
          true,
          "",
          "",
          history
        );

        if (response.status === 1) {
          window.open(response?.data);
          showMessageNotification("Downloaded file successfully", "success");
        } else if (response.status === 0) {
          showMessageNotification("Something went wrong", "error");
        }
      } catch (error) {
        console.error();
      }
    }
    if (requiredDownload === "CSV") {
      let body = {
        requestId: slug,
        format: "csv",
      };
      try {
        const response = await callApi(
          `/payout/downloadVideoViews`,
          body,
          "post",
          null,
          true,
          "",
          "",
          history
        );

        if (response.status === 1) {
          window.open(response.data);
          showMessageNotification("Downloaded file successfully", "success");
        } else if (response.status === 0) {
          showMessageNotification("Something went wrong", "error");
        }
      } catch (error) {
        console.error();
      }
    }
  };
  const handleDownload1 = async (e) => {
    setDownloadSelect1(e);

    let requiredDownload = e.value;
    if (requiredDownload === "Excel") {
      let body = {
        requestId: slug,
        format: "xls",
      };
      try {
        const response = await callApi(
          `/payout/downloadProductViews`,
          body,
          "post",
          null,
          true,
          "",
          "",
          history
        );

        if (response.status === 1) {
          window.open(response?.data);
          showMessageNotification("Downloaded file successfully", "success");
        } else if (response.status === 0) {
          showMessageNotification(response?.message, "error");
        }
      } catch (error) {
        console.error();
      }
    }
    if (requiredDownload === "CSV") {
      let body = {
        requestId: slug,
        format: "csv",
      };
      try {
        const response = await callApi(
          `/payout/downloadProductViews`,
          body,
          "post",
          null,
          true,
          "",
          "",
          history
        );

        if (response.status === 1) {
          window.open(response.data);
          showMessageNotification("Downloaded file successfully", "success");
        } else if (response.status === 0) {
          showMessageNotification(response?.message, "error");
        }
      } catch (error) {
        console.error();
      }
    }
  };
  const handleDownload3 = async (e) => {
    setDownloadSelect3(e);

    let requiredDownload = e.value;
    if (requiredDownload === "Excel") {
      let body = {
        requestId: slug,
        format: "xls",
      };
      try {
        const response = await callApi(
          `/payout/downloadVidPurchasedData`,
          body,
          "post",
          null,
          true,
          "",
          "",
          history
        );

        if (response.status === 1) {
          window.open(response?.data);
          showMessageNotification("Downloaded file successfully", "success");
        } else if (response.status === 0) {
          showMessageNotification(response?.message, "error");
        }
      } catch (error) {
        console.error();
      }
    }
    if (requiredDownload === "CSV") {
      let body = {
        requestId: slug,
        format: "csv",
      };
      try {
        const response = await callApi(
          `/payout/downloadVidPurchasedData`,
          body,
          "post",
          null,
          true,
          "",
          "",
          history
        );

        if (response.status === 1) {
          window.open(response.data);
          showMessageNotification("Downloaded file successfully", "success");
        } else if (response.status === 0) {
          showMessageNotification(response?.message, "error");
        }
      } catch (error) {
        console.error();
      }
    }
  };
  function paginationChange(page, pagesize) {
    setPage(page);
    setPagesize(pagesize);
  }
  function paginationChange1(page, pagesize) {
    setProdPage(page);
    setProdPageSize(pagesize);
    getAllProductClicks(page, pagesize);
  }
  function paginationChange3(vidPage, vidPageSize) {
    setVidPageSize(vidPageSize);
    setVidPage(vidPage);
  }
  useEffect(() => {
    getRequestDetails(slug);
    getAllProductClicks();
  }, [slug]);

  useEffect(() => {
    getAllPurchaseData();
  }, [vidPage, vidPageSize]);
  useEffect(() => {
    celebrities();
  }, [page, pagesize]);
  const getRequestDetails = async (data) => {
    try {
      let body = {
        requestId: slug,
      };

      const response = await callApi(
        `/payout/getPayoutRequestDetails`,
        body,
        "post",
        null,
        true,
        "",
        "",
        history
      );
      if (response.status === 1) {
        setViewRequests(response?.data[0]?.celebrityDetails[0]);
        setTransactionData(response?.data[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /******************* 
  @Purpose : Used for get users all details
  @Parameter : id
  @Author : INIC
  ******************/

  const breadcrumb = [
    {
      title: "List Of Requests",
      url: "/payout",
    },
    {
      title: eventKey,
    },
  ];
  /******************* 
  @Purpose : Used for category and sub category
  @Parameter : {}
  @Author : INIC
  ******************/
  const getCategoryList = async () => {
    try {
      const response = await callApi(
        `/categories/getcategories`,
        "",
        "get",
        "GET_CATEGORY",
        true,
        true,
        false
      );

      if (response) {
        const categoryData = Array.isArray(response.data)
          ? response.data.map((item) => ({
              ...item,
              label: item.title,
              value: item.title,
            }))
          : [];
        setCategories(categoryData);
      }
    } catch (error) {
      console.error();
    }
  };

  /******************* 
  @Purpose : Used for on change search
  @Parameter : status, timezoneId
  @Author : INIC
  ******************/

  const handleSearchChange = (e) => {
    e.persist();
    if (e.target.value.length === 0) {
      clearInput();
    }
    setLoading(true);
    setSearchtext(e.target.value);
    handleDebouncedChange(e.target.value);
  };
  const handleSearchChange1 = (e) => {
    e.persist();
    if (e.target.value.length === 0) {
      clearInput();
    }
    setLoading(true);
    setSearchText1(e.target.value);
    handleDebouncedChange1(e.target.value);
  };
  const handleSearchChange3 = (e) => {
    if (e.target.value.length === 0) {
      clearInput1();
    }
    e.persist();
    setLoading(true);
    setSearchText3(e.target.value);
    handleDebouncedChange3(e.target.value);
  };
  /******************* 
  @Purpose : Used for debounce with memo
  @Parameter : status, timezoneId
  @Author : INIC
  ******************/
  const handleDebouncedChange = React.useMemo(
    () => debounce((evt) => searchField(evt), 1000),
    []
  );
  const handleDebouncedChange1 = React.useMemo(
    () => debounce((evt) => searchField1(evt), 1000),
    []
  );
  const handleDebouncedChange3 = React.useMemo(
    () => debounce((evt) => searchField3(evt), 1000),
    []
  );
  /******************* 
  @Purpose : Used forsearch the vids
  @Parameter : status, timezoneId
  @Author : INIC
  ******************/
  const searchField = async (evtKey) => {
    setLoading(true);

    try {
      if (evtKey) {
        let body = {
          pageNumber: 1,
          pageSize: pagesize,
          searchText: evtKey,
          requestId: slug,
          filter: [],
        };

        const response = await callApi(
          `/payout/getVideoViews`,
          body,
          "post",
          null,
          true,
          "",
          "",
          history
        );
        if (response.status === 1) {
          setVideoViewsListing(response?.data);
          setPage(1);
          setTotal(response?.totalViews);
        } else if (response.status === 0) {
          showModalNotification("no videos were found", "error");
        }
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };
  // search fields for product
  const searchField1 = async (evtKey) => {
    setLoading(true);

    try {
      if (evtKey) {
        let body = {
          pageNumber: 1,
          pageSize: prodPageSize,
          searchText: evtKey,
          requestId: slug,
          filter: [],
        };

        const response = await callApi(
          `/payout/getProductViews`,
          body,
          "post",
          null,
          true,
          "",
          "",
          history
        );
        if (response.status === 1) {
          setProductClickList(response?.data);
          setProdPage(1);
          setProdTotal(response?.totalViews);
        } else if (response.status === 0) {
          showModalNotification(response?.message, "error");
        }
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };
  // vid data
  const searchField3 = async (evtKey) => {
    setLoading(true);

    try {
      if (evtKey) {
        let body = {
          pageNumber: 1,
          pageSize: vidPageSize,
          searchText: evtKey,
          requestId: slug,
          filter: [],
        };

        const response = await callApi(
          `/payout/getVidPurchasedData`,
          body,
          "post",
          null,
          true,
          "",
          "",
          history
        );
        if (response.status === 1) {
          setVidData(response?.data);
          setVidTotal(response?.totalNoOfOrders);
        } else if (response.status === 0) {
          showModalNotification(response?.message, "error");
        }
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };
  /******************* 
  @Purpose : Used for celebrities list
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const celebrities = async () => {
    let body = {
      requestId: slug,
      pageNumber: page,
      pageSize: pagesize,
      searchText: searchText || "",
      filter: celebFilter || [],
    };
    const response = await callApi(
      `/payout/getVideoViews`,
      body,
      "post",
      null,
      true,
      "",
      "",
      history
    );
    if (response.status === 1) {
      setVideoViewsListing(response?.data);
      setTotal(response?.totalViews);
    } else if (response.status === 0) {
      setVideoViewsListing([]);
      setTotal(0);
    }
  };
  /******************* 
  @Purpose : Used for celebrities list
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const celebrities1 = async () => {
    let body = {
      requestId: slug,
      pageNumber: page,
      pageSize: pagesize,
      searchText: "",
      filter: [],
    };
    const response = await callApi(
      `/payout/getVideoViews`,
      body,
      "post",
      null,
      true,
      "",
      "",
      history
    );
    if (response.status === 1) {
      setVideoViewsListing(response?.data);
      setTotal(response?.totalViews);
    }
  };
  /******************* 
  @Purpose : Used for clear all form field value
  @Parameter : status, timezoneId
  @Author : INIC
  ******************/
  const clearInput = () => {
    setPage(1);

    setSearchtext("");
    setSearchText1("");
    celebrities1();
    getAllProductClicks1();
  };
  const clearInput1 = () => {
    setSearchText3("");
    getAllPurchaseData1();
  };
  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  /******************* 
  @Purpose : Used for sub category data
  @Parameter : {}
  @Author : INIC
  ******************/
  const getSubCategoryData = () => {
    let categoryItem = filterObj?.filter(
      (item) => item.key.label === "Category"
    );

    let id =
      categoryItem.length > 1
        ? categoryItem[categoryItem.length - 1]?.type?._id
        : categoryItem[0]?.type?._id;

    let categoryFilter = categories?.filter((item) => item._id === id)[0]
      ?.subCategoryDetails;
    let subcategoryData = Array.isArray(categoryFilter)
      ? categoryFilter.map((item) => ({
          ...item,
          label: item.title,
          value: item.title,
        }))
      : [];

    return subcategoryData;
  };
  const renderFilter = () => {
    return (
      <div className="custom-dropdown-menu dropdown-icon card w-50">
        <div className="card-body" style={{ paddingRight: "70px" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-repeat">
                {filterObj &&
                  filterObj.length > 0 &&
                  filterObj.map((o, i) => {
                    let itemType = o.key.label;

                    return (
                      <form
                        key={itemType}
                        className="form repeater-default"
                        data-limit="5"
                      >
                        <div className="field-form">
                          <div className="target-form">
                            <div
                              className="target-details"
                              style={{ marginBottom: "10px" }}
                            >
                              <div className="row align-items-start">
                                <div className="form-group col-md-5 col-lg-6 mb-2">
                                  <label className="form-label">
                                    Select Field
                                  </label>
                                  <Select
                                    filterOption={(option) =>
                                      !selectedFilterItems.includes(
                                        option.value
                                      )
                                    }
                                    showSearch
                                    placeholder="Enter Field"
                                    optionFilterProp="children"
                                    className="w-100 custom-input filter-select"
                                    value={o.key}
                                    onChange={(value) => {
                                      o.key = value;

                                      o.type = null;
                                      setFilterObj([...filterObj]);

                                      if (errorsLicence[i])
                                        delete errorsLicence[i].key;
                                    }}
                                    options={
                                      i === 0
                                        ? selectFieldOptions
                                        : selectFieldOptions1
                                    }
                                  />

                                  <span className="text-danger error-msg"></span>
                                </div>
                                {itemType === "Date" ? (
                                  <div className="col-md-5 col-lg-5 mb-2 ">
                                    <div className="input-daterange custom-daterange input-control">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <label className="form-label">
                                            Select Start date
                                          </label>
                                          <DatePicker
                                            selected={
                                              o.value.startDate
                                                ? new Date(o.value.startDate)
                                                : ""
                                            }
                                            placeholderText="Select Start Date"
                                            dateFormat="MMM d yyyy"
                                            className="form-control"
                                            onChange={(evt) => {
                                              o.value.startDate = evt;
                                              setFilterObj([...filterObj]);
                                              if (errorsLicence[i])
                                                delete errorsLicence[i].value
                                                  .startDate;
                                            }}
                                          />
                                          <span className="text-danger error-msg">
                                            {o.error}
                                          </span>
                                        </div>
                                        <div className="col-md-6">
                                          <label className="form-label">
                                            Select end date
                                          </label>

                                          <DatePicker
                                            selected={
                                              o.value.endDate
                                                ? new Date(o.value.endDate)
                                                : ""
                                            }
                                            minDate={
                                              o.value.startDate
                                                ? new Date(o.value.startDate)
                                                : new Date()
                                            }
                                            disabled={
                                              o.value.startDate ? false : true
                                            }
                                            dateFormat="MMM d yyyy"
                                            placeholderText="Select End Date"
                                            className="form-control"
                                            onChange={(evt) => {
                                              o.value.endDate = evt;
                                              setFilterObj([...filterObj]);
                                              if (errorsLicence[i])
                                                delete errorsLicence[i].value
                                                  .endDate;
                                            }}
                                          />
                                          <span className="text-danger error-msg">
                                            {o.error}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="form-group col-md-5 col-lg-6 mb-2">
                                    <label className="form-label">
                                      Select Content
                                    </label>
                                    <Select
                                      closeMenuOnSelect={false}
                                      isMulti={
                                        o.key.label === "Category"
                                          ? false
                                          : true
                                      }
                                      showSearch={false}
                                      placeholder="Select Type"
                                      className="w-100 custom-input filter-select"
                                      optionFilterProp="children"
                                      value={o.type}
                                      onChange={(value) => {
                                        o.type = value;
                                        if (o.type === "date") {
                                          delete o.value;
                                          o.value = {
                                            startDate: "",
                                            endDate: "",
                                          };
                                        }
                                        setFilterObj([...filterObj]);
                                        handleFilterArray();
                                        if (errorsLicence[i])
                                          delete errorsLicence[i].type;
                                      }}
                                      options={
                                        o.key.label === "Category"
                                          ? categories
                                          : o.key.label === "SubCategory"
                                          ? getSubCategoryData()
                                          : o.key.label === "Brand"
                                          ? brand
                                          : selectContentOptions
                                      }
                                    />

                                    <span className="text-danger error-msg">
                                      {o.error}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className="add-row col-lg-2 col-md-2"
                              style={{ display: "flex", gap: "10px" }}
                            >
                              {filterObj.length <= 3 && i === 0 ? (
                                <span
                                  className="add-ic icon-plus text-success"
                                  onClick={(e) => addNewRow(e)}
                                  type="button"
                                ></span>
                              ) : null}
                              {filterObj && filterObj.length >= 2 ? (
                                <span
                                  className="icon-minus mr-1 text-danger"
                                  onClick={() => removeRow(i)}
                                  type="button"
                                ></span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </form>
                    );
                  })}
              </div>
              {loadingFilter ? <div></div> : null}
              <div className="col-md-12 text-end pt-4">
                <button
                  onClick={() => resetFilter()}
                  type="button"
                  className="btn btn-light me-2"
                >
                  Clear
                </button>
                <button
                  onClick={(evt) => ApplyFilter()}
                  type="button"
                  className="btn btn-primary"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  // render filter for product clicks
  const renderFilter1 = () => {
    return (
      <div className="custom-dropdown-menu dropdown-icon card w-50">
        <div className="card-body" style={{ paddingRight: "70px" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-repeat">
                {filterObj &&
                  filterObj.length > 0 &&
                  filterObj.map((o, i) => {
                    let itemType = o.key.label;

                    return (
                      <form
                        l
                        key={itemType}
                        className="form repeater-default"
                        data-limit="5"
                      >
                        <div className="field-form">
                          <div className="target-form">
                            <div
                              className="target-details"
                              style={{ marginBottom: "10px" }}
                            >
                              <div className="row align-items-start">
                                <div className="form-group col-md-5 col-lg-6 mb-2">
                                  <label className="form-label">
                                    Select Field
                                  </label>
                                  <Select
                                    filterOption={(option) =>
                                      !selectedFilterItems.includes(
                                        option.value
                                      )
                                    }
                                    showSearch
                                    placeholder="Enter Field"
                                    optionFilterProp="children"
                                    className="w-100 custom-input filter-select"
                                    value={o.key}
                                    onChange={(value) => {
                                      o.key = value;

                                      o.type = null;
                                      setFilterObj([...filterObj]);

                                      if (errorsLicence[i])
                                        delete errorsLicence[i].key;
                                    }}
                                    options={
                                      i === 0
                                        ? selectFieldOptions
                                        : selectFieldOptions1
                                    }
                                  />

                                  <span className="text-danger error-msg"></span>
                                </div>
                                {itemType === "Date" ? (
                                  <div className="col-md-5 col-lg-5 mb-2 ">
                                    <div className="input-daterange custom-daterange input-control">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <label className="form-label">
                                            Select Start date
                                          </label>
                                          <DatePicker
                                            selected={
                                              o.value.startDate
                                                ? new Date(o.value.startDate)
                                                : ""
                                            }
                                            placeholderText="Select Start Date"
                                            dateFormat="MMM d yyyy"
                                            className="form-control"
                                            onChange={(evt) => {
                                              o.value.startDate = evt;
                                              setFilterObj([...filterObj]);
                                              if (errorsLicence[i])
                                                delete errorsLicence[i].value
                                                  .startDate;
                                            }}
                                          />
                                          <span className="text-danger error-msg">
                                            {o.error}
                                          </span>
                                        </div>
                                        <div className="col-md-6">
                                          <label className="form-label">
                                            Select end date
                                          </label>

                                          <DatePicker
                                            selected={
                                              o.value.endDate
                                                ? new Date(o.value.endDate)
                                                : ""
                                            }
                                            minDate={
                                              o.value.startDate
                                                ? new Date(o.value.startDate)
                                                : new Date()
                                            }
                                            disabled={
                                              o.value.startDate ? false : true
                                            }
                                            dateFormat="MMM d yyyy"
                                            placeholderText="Select End Date"
                                            className="form-control"
                                            onChange={(evt) => {
                                              o.value.endDate = evt;
                                              setFilterObj([...filterObj]);
                                              if (errorsLicence[i])
                                                delete errorsLicence[i].value
                                                  .endDate;
                                            }}
                                          />
                                          <span className="text-danger error-msg">
                                            {o.error}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="form-group col-md-5 col-lg-6 mb-2">
                                    <label className="form-label">
                                      Select Content
                                    </label>
                                    <Select
                                      closeMenuOnSelect={false}
                                      isMulti={
                                        o.key.label === "Category"
                                          ? false
                                          : true
                                      }
                                      showSearch={false}
                                      placeholder="Select Type"
                                      className="w-100 custom-input filter-select"
                                      optionFilterProp="children"
                                      value={o.type}
                                      onChange={(value) => {
                                        o.type = value;
                                        if (o.type === "date") {
                                          delete o.value;
                                          o.value = {
                                            startDate: "",
                                            endDate: "",
                                          };
                                        }
                                        setFilterObj([...filterObj]);
                                        handleFilterArray();
                                        if (errorsLicence[i])
                                          delete errorsLicence[i].type;
                                      }}
                                      options={
                                        o.key.label === "Category"
                                          ? categories
                                          : o.key.label === "SubCategory"
                                          ? getSubCategoryData()
                                          : o.key.label === "Brand"
                                          ? brand
                                          : selectContentOptions
                                      }
                                    />

                                    <span className="text-danger error-msg">
                                      {o.error}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className="add-row col-lg-2 col-md-2"
                              style={{ display: "flex", gap: "10px" }}
                            >
                              {filterObj.length <= 3 && i === 0 ? (
                                <span
                                  className="add-ic icon-plus text-success"
                                  onClick={(e) => addNewRow(e)}
                                  type="button"
                                ></span>
                              ) : null}
                              {filterObj && filterObj.length >= 2 ? (
                                <span
                                  className="icon-minus mr-1 text-danger"
                                  onClick={() => removeRow(i)}
                                  type="button"
                                ></span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </form>
                    );
                  })}
              </div>
              {loadingFilter ? <div></div> : null}
              <div className="col-md-12 text-end pt-4">
                <button
                  onClick={() => resetFilter()}
                  type="button"
                  className="btn btn-light me-2"
                >
                  Clear
                </button>
                <button
                  onClick={(evt) => ApplyFilter1()}
                  type="button"
                  className="btn btn-primary"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderFilter3 = () => {
    return (
      <div className="custom-dropdown-menu dropdown-icon card w-50">
        <div className="card-body" style={{ paddingRight: "70px" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-repeat">
                {filterObj &&
                  filterObj.length > 0 &&
                  filterObj.map((o, i) => {
                    let itemType = o.key.label;

                    return (
                      <form
                        l
                        key={itemType}
                        className="form repeater-default"
                        data-limit="5"
                      >
                        <div className="field-form">
                          <div className="target-form">
                            <div
                              className="target-details"
                              style={{ marginBottom: "10px" }}
                            >
                              <div className="row align-items-start">
                                <div className="form-group col-md-5 col-lg-6 mb-2">
                                  <label className="form-label">
                                    Select Field
                                  </label>
                                  <Select
                                    filterOption={(option) =>
                                      !selectedFilterItems.includes(
                                        option.value
                                      )
                                    }
                                    showSearch
                                    placeholder="Enter Field"
                                    optionFilterProp="children"
                                    className="w-100 custom-input filter-select"
                                    value={o.key}
                                    onChange={(value) => {
                                      o.key = value;
                                      // let newFiterObj=[{...filterObj,}]
                                      o.type = null;
                                      setFilterObj([...filterObj]);

                                      if (errorsLicence[i])
                                        delete errorsLicence[i].key;
                                    }}
                                    options={
                                      i === 0
                                        ? selectFieldOptions
                                        : selectFieldOptions1
                                    }
                                  />

                                  <span className="text-danger error-msg"></span>
                                </div>
                                {itemType === "Date" ? (
                                  <div className="col-md-5 col-lg-5 mb-2 ">
                                    <div className="input-daterange custom-daterange input-control">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <label className="form-label">
                                            Select Start date
                                          </label>
                                          <DatePicker
                                            selected={
                                              o.value.startDate
                                                ? new Date(o.value.startDate)
                                                : ""
                                            }
                                            placeholderText="Select Start Date"
                                            dateFormat="MMM d yyyy"
                                            className="form-control"
                                            onChange={(evt) => {
                                              o.value.startDate = evt;
                                              setFilterObj([...filterObj]);
                                              if (errorsLicence[i])
                                                delete errorsLicence[i].value
                                                  .startDate;
                                            }}
                                          />
                                          <span className="text-danger error-msg">
                                            {o.error}
                                          </span>
                                        </div>
                                        <div className="col-md-6">
                                          <label className="form-label">
                                            Select end date
                                          </label>

                                          <DatePicker
                                            selected={
                                              o.value.endDate
                                                ? new Date(o.value.endDate)
                                                : ""
                                            }
                                            minDate={
                                              o.value.startDate
                                                ? new Date(o.value.startDate)
                                                : new Date()
                                            }
                                            disabled={
                                              o.value.startDate ? false : true
                                            }
                                            dateFormat="MMM d yyyy"
                                            placeholderText="Select End Date"
                                            className="form-control"
                                            onChange={(evt) => {
                                              o.value.endDate = evt;
                                              setFilterObj([...filterObj]);
                                              if (errorsLicence[i])
                                                delete errorsLicence[i].value
                                                  .endDate;
                                            }}
                                          />
                                          <span className="text-danger error-msg">
                                            {o.error}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="form-group col-md-5 col-lg-6 mb-2">
                                    <label className="form-label">
                                      Select Content
                                    </label>
                                    <Select
                                      closeMenuOnSelect={false}
                                      isMulti={
                                        o.key.label === "Category"
                                          ? false
                                          : true
                                      }
                                      showSearch={false}
                                      placeholder="Select Type"
                                      className="w-100 custom-input filter-select"
                                      optionFilterProp="children"
                                      value={o.type}
                                      onChange={(value) => {
                                        o.type = value;
                                        if (o.type === "date") {
                                          delete o.value;
                                          o.value = {
                                            startDate: "",
                                            endDate: "",
                                          };
                                        }
                                        setFilterObj([...filterObj]);
                                        handleFilterArray();
                                        if (errorsLicence[i])
                                          delete errorsLicence[i].type;
                                      }}
                                      options={
                                        o.key.label === "Category"
                                          ? categories
                                          : o.key.label === "SubCategory"
                                          ? getSubCategoryData()
                                          : o.key.label === "Brand"
                                          ? brand
                                          : selectContentOptions
                                      }
                                    />

                                    <span className="text-danger error-msg">
                                      {o.error}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className="add-row col-lg-2 col-md-2"
                              style={{ display: "flex", gap: "10px" }}
                            >
                              {filterObj.length <= 3 && i === 0 ? (
                                <span
                                  className="add-ic icon-plus text-success"
                                  onClick={(e) => addNewRow(e)}
                                  type="button"
                                ></span>
                              ) : null}
                              {filterObj && filterObj.length >= 2 ? (
                                <span
                                  className="icon-minus mr-1 text-danger"
                                  onClick={() => removeRow(i)}
                                  type="button"
                                ></span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </form>
                    );
                  })}
              </div>
              {loadingFilter ? <div></div> : null}
              <div className="col-md-12 text-end pt-4">
                <button
                  onClick={() => resetFilter1()}
                  type="button"
                  className="btn btn-light me-2"
                >
                  Clear
                </button>
                <button
                  onClick={(evt) => ApplyFilter3()}
                  type="button"
                  className="btn btn-primary"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  /******************* 
  @Purpose : Used for get brand data from redux
  @Parameter : {brandList}
  @Author : INIC
  ******************/

  /******************* 
  @Purpose : Used for add new filter records
  @Parameter : {e}
  @Author : INIC
  ******************/
  const addNewRow = (e) => {
    handleFilterArray();
    setErrorsLicence([
      ...errorsLicence,
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    setFilterObj([
      ...filterObj,
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
  };

  /******************* 
  @Purpose : Used for remove filter records
  @Parameter : {index}
  @Author : INIC
  ******************/
  const removeRow = (index) => {
    let array = [...filterObj];
    array.splice(index, 1);
    setFilterObj(array);
    array = [...errorsLicence];
    array.splice(index, 1);
    setErrorsLicence(array);
    setTimeout(function () {
      setLoadingFilter(true);
    }, 100);
  };
  /******************* 
  @Purpose : Used for filter 
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleFilterArray = () => {
    let selected = filterObj.map((item) => item.key.value);
    setSelectedFilterItems(selected);
  };
  /******************* 
  @Purpose : Used for reset filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const resetFilter = () => {
    setFilterObj([
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);

    setSelectedFilterItems([]);
    openFilterpopup(!filterpopup);
    setCelebFilter([]);
    setCelebFilter3([]);
    setCelebFilter1([]);
    setPage(1);
    celebrities1();
    getAllProductClicks1();
    setProdPage(1);
    getAllPurchaseData1();
    setVidPage(1);
  };
  // reset filter for vid data
  const resetFilter1 = () => {
    setFilterObj([
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);

    setSelectedFilterItems([]);
    openFilterpopup(!filterpopup);

    getAllPurchaseData1();
    setVidPage(1);
  };
  /******************* 
  @Purpose : Used for validate filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateFiltersearch = () => {
    let isFormValid = true;

    let valid = filterObj.map((each) => {
      if (each.key.label === "Category" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the  category fields";
      } else if (each.key.label === "Category" && isEmpty(each.type) == false) {
        each.error = "";
        isFormValid = true;
      }

      if (each.key.label === "Brand" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the brand fields";
      } else {
        if (each.key.label === "Brand") {
          isFormValid = true;
          each.error = "";
        }
      }
      if (each.key.label === "SubCategory" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the fields";
      } else {
        if (each.key.label === "SubCategory") {
          isFormValid = true;
          each.error = "";
        }
      }
      if (
        each.key.label === "Date" &&
        (each.value.startDate === "" || each.value.endDate === "")
      ) {
        isFormValid = false;
        each.error = "Select the subCategory fields";
      } else {
        if (each.key.label === "Date") {
          isFormValid = true;
          each.error = "";
        }
      }
      return each;
    });

    setFilterObj(valid);
    return isFormValid;
  };
  /******************* 
  @Purpose : Used for applay filter
  @Parameter : {}
  @Author : INIC
  ******************/

  const ApplyFilter = async () => {
    try {
      if (validateFiltersearch()) {
        let payload = filterObj.map((item) => {
          if (item.key.label === "SubCategory" || item.key.label === "Brand") {
            return item.type.map((val) => {
              return {
                key:
                  item.key.label === "SubCategory"
                    ? "subCategoryId"
                    : item.key.label === "Brand"
                    ? "brandId"
                    : "",
                type: "contains",
                value: val._id,
              };
            });
          }
          return {
            key:
              item.key.label === "Category"
                ? "categoryId"
                : "Date"
                ? "date"
                : "",
            type: item.key.label === "Date" ? "date" : "contains",

            value:
              item.key.label === "Date"
                ? {
                    startDate: moment(item.value.startDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                    endDate: moment(item.value.endDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                  }
                : item.type._id,
          };
        });

        setLoading(true);

        let body = {
          requestId: slug,
          pageNumber: 1,
          pageSize: pagesize,
          searchText: "",
          filter: payload.flat(),
        };

        dispatch(paymentFilterVideos(body)).then((response) => {
          if (response.status === 1) {
            setCelebFilter(payload.flat());
            setVideoViewsListing(response?.data);
            setPage(1);

            setPagesize(10);
            setTotal(response?.totalViews);
            openFilterpopup(!filterpopup);

            setLoading(false);
          } else if (response.status === 0) {
            showModalNotification(response?.message, "error");
            setVideoViewsListing([]);
            setTotal(0);
          }
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  // apply filter
  const ApplyFilter1 = async () => {
    try {
      if (validateFiltersearch()) {
        let payload = filterObj.map((item) => {
          if (item.key.label === "SubCategory" || item.key.label === "Brand") {
            return item.type.map((val) => {
              return {
                key:
                  item.key.label === "SubCategory"
                    ? "subCategoryId"
                    : item.key.label === "Brand"
                    ? "brandId"
                    : "",
                type: "contains",
                value: val._id,
              };
            });
          }
          return {
            key:
              item.key.label === "Category"
                ? "categoryId"
                : "Date"
                ? "date"
                : "",
            type: item.key.label === "Date" ? "date" : "contains",

            value:
              item.key.label === "Date"
                ? {
                    startDate: moment(item.value.startDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                    endDate: moment(item.value.endDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                  }
                : item.type._id,
          };
        });

        setLoading(true);

        let body = {
          requestId: slug,
          pageNumber: 1,
          pageSize: 10,
          searchText: "",
          filter: payload.flat(),
        };

        dispatch(productClickForPayment(body)).then((response) => {
          if (response.status === 1) {
            setCelebFilter1(payload.flat());
            setProductClickList(response?.data);
            setProdPage(1);
            setProdPageSize(10);
            setProdTotal(response.totalViews);
            openFilterpopup(!filterpopup);
          } else {
            showModalNotification(response?.message, "error");
            setProductClickList([]);
            setProdTotal(0);
          }
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  // applyFilter3
  const ApplyFilter3 = async () => {
    try {
      if (validateFiltersearch()) {
        let payload = filterObj.map((item) => {
          if (item.key.label === "SubCategory" || item.key.label === "Brand") {
            return item.type.map((val) => {
              return {
                key:
                  item.key.label === "SubCategory"
                    ? "subCategoryId"
                    : item.key.label === "Brand"
                    ? "brandId"
                    : "",
                type: "contains",
                value: val._id,
              };
            });
          }
          return {
            key:
              item.key.label === "Category"
                ? "categoryId"
                : "Date"
                ? "date"
                : "",
            type: item.key.label === "Date" ? "date" : "contains",

            value:
              item.key.label === "Date"
                ? {
                    startDate: moment(item.value.startDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                    endDate: moment(item.value.endDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                  }
                : item.type._id,
          };
        });

        setLoading(true);

        let body = {
          requestId: slug,
          pageNumber: 1,
          pageSize: 10,
          searchText: "",
          filter: payload.flat(),
        };

        dispatch(getPayoutVidPurchaseData(body)).then((response) => {
          if (response.status === 1) {
            setCelebFilter3(payload.flat());
            openFilterpopup(!filterpopup);
            setVidPage(1);
            setVidPageSize(10);
            setVidData(response?.data);

            setVidTotal(response?.totalNoOfOrders);
          } else if (response?.status === 0) {
            showModalNotification(response?.message, "error");
            setVidData([]);
            openFilterpopup(!filterpopup);
            setVidTotal(0);
          }
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  /******************* 
  @Purpose : React hook used for get category list
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    getCategoryList();
    getBrandList();
  }, []);
  function handleTabs(e) {
    setEventKeys(e);
    setFilterObj([
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    openFilterpopup(false);
  }
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Payout">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="card mb-5">
              <div className="card-header right-buttons d-flex align-items-center justify-content-between p-3">
                <h5 className="card-title">Celebrity Information</h5>
                <Button>Release Payment</Button>
              </div>
              <div className="card-body user-details">
                <div className="row">
                  <div className="col-md-3 col-lg-3 col-xl-2">
                    <div className="user-image">
                      {" "}
                      <img
                        src={
                          viewRequests?.creatorProfilephoto
                            ? viewRequests?.creatorProfilephoto
                            : "/assets/images/no-image.png"
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="ccol-md-9 col-lg-9 col-xl-5">
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        NAME:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {viewRequests?.firstname}
                        {viewRequests?.lastname}
                      </div>
                    </div>

                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        EMAIL ID:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {" "}
                        {viewRequests?.emailId}
                      </div>
                    </div>

                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        GENDER:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {" "}
                        {viewRequests?.gender}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        ADDRESS:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {" "}
                        {viewRequests?.address}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        CITY:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {" "}
                        {viewRequests?.city}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        INSTAGRAM:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        <a href={viewRequests?.instagramId} target="_blank">
                          {viewRequests?.instagramId}
                        </a>
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        STATUS:
                      </div>
                      <div className="col-md-8 p-2">
                        <span className="badge text-white bg-secondary">
                          {" "}
                          {viewRequests?.status ? "Active" : "Inactive"}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-9 offset-md-3 offset-xl-0 col-lg-9 col-xl-5">
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        PHONE NUMBER:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {viewRequests?.mobileNumber}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        DOB:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {moment(viewRequests?.dob).format("L")}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        STATE:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {viewRequests?.state}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        PINCODE:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {viewRequests?.pincode}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        YOUTUBE:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {viewRequests?.youtubeId}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-5">
              <div className="card-header right-buttons d-flex align-items-center justify-content-between">
                <h5 className="card-title">Transaction Information</h5>
                <span className="badge badge-md badge-light-success py-2">
                  Total:₹ {transcationData?.payment}
                </span>
              </div>

              <div className="card-body user-details">
                <div className="row">
                  <div className="col-12 col-xl-6">
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        REQUEST DATE:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {transcationData?.createdAt
                          ? moment(transcationData?.createdAt).format(
                              "l, h:mm:ss a"
                            )
                          : "-"}
                      </div>
                    </div>

                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        TRANSACTION FROM:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {transcationData?.paymentFromDate
                          ? moment(transcationData?.paymentFromDate).format(
                              "l, h:mm:ss a"
                            )
                          : "-"}
                      </div>
                    </div>

                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        TOTAL VIDEO VIEW:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {" "}
                        {transcationData?.videoViews}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        TOTAL PRODUCT CLICKS:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {" "}
                        {transcationData?.productViews}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        TOTAL PURCHASE:
                      </div>
                      <div className="col-md-8 p-2 request-data"> </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        VID PURCHASE:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {transcationData?.noOfVideosPublished}{" "}
                      </div>
                    </div>
                  </div>
                  {/* second col */}
                  <div className="col-12 col-xl-6">
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        TOTAL AMOUNT:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        ₹ {transcationData?.payment}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        TRANSACTION TO:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {transcationData?.paymentDate
                          ? moment(transcationData?.paymentDate).format(
                              "l, h:mm:ss a"
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        VIDEO VIEW AMOUNT:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {" "}
                        ₹ {transcationData?.videoViewAmount}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        PRODUCT CLICK AMOUNT:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        ₹ {transcationData?.productViewAmount}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        PURCHASE AMOUNT:
                      </div>
                      <div className="col-md-8 p-2 request-data"></div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        VID DUE AMOUNT:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        ₹ {transcationData?.vidPurchaseAmount}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* celebrity videos published */}
            <div className="card mb-5">
              <div className="card-body">
                <Tabs
                  defaultActiveKey="Video Views"
                  id="uncontrolled-tab-example"
                  onSelect={(eventKey) => handleTabs(eventKey)}
                >
                  <Tab
                    eventKey="Video Views"
                    title={`Video Views `}
                    className="pt-4"
                  >
                    <div className="content-area position-relative">
                      <div className="user-listing-filterOptions mb-2 d-block">
                        <div className="row mb-2">
                          <div className="col-sm-8 position-static">
                            <div className="left-buttons d-flex ">
                              <label>
                                <div className="search-input-wrapper position-relative">
                                  <i className="icon-magnifier"></i>{" "}
                                  <input
                                    type="search"
                                    value={searchText}
                                    className="form-control text-capitalize"
                                    placeholder="search"
                                    onChange={(evt) => {
                                      handleSearchChange(evt);
                                    }}
                                  />
                                  {searchText && searchText.length > 0 ? (
                                    <div className="clear">
                                      <span
                                        onClick={clearInput}
                                        style={{ marginRight: "20px" }}
                                      >
                                        <em className="icon-close"></em>
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              </label>

                              <div
                                id="container1"
                                className={
                                  filterpopup
                                    ? "custom-dropdown filter-data-dropdown position-static ml-2 open"
                                    : "custom-dropdown filter-data-dropdown position-static ml-2"
                                }
                              >
                                <button
                                  onClick={() => {
                                    openFilterpopup(!filterpopup);
                                  }}
                                  className="btn btn-bordered dropdown-toggle custom-filter-dropdown btn-bg-white d-flex align-items-center"
                                  type="button"
                                >
                                  <i className="bx bx-filter-alt align-text-top me-2"></i>
                                  Filter
                                </button>
                                {renderFilter()}
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div
                              className="right-buttons d-flex justify-content-end"
                              style={{ gap: "10px" }}
                            >
                              <div className="form-group  col-sm-4  d-flex">
                                <div className="w-100">
                                  <Select
                                    id="download"
                                    name="download"
                                    options={options}
                                    placeholder="Download"
                                    onChange={(e) => {
                                      handleDownload(e);
                                    }}
                                    value={downloadSelect}
                                  />
                                </div>
                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                ></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table
                        className="table row-border nowrap common-datatable"
                        id="user-listing"
                      >
                        <thead>
                          <tr>
                            <th className="all">
                              <b>View Date & Time</b>
                            </th>

                            <th>
                              <b>Video Title</b>
                            </th>
                            <th>
                              <b>User</b>
                            </th>
                            <th>
                              <b>Brand</b>
                            </th>
                            <th>
                              <b>Category</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {videoViewsListing &&
                          videoViewsListing.length === 0 ? (
                            <tr className="text-center text-danger not-found-txt">
                              <td colSpan="6">
                                {!loading ? (
                                  <h6
                                    className="text-center text-danger not-found-txt"
                                    colSpan="6"
                                  >
                                    <Image
                                      src="https://vistaadmindev.devpress.net/assets/images/no_data_found.png"
                                      width={200}
                                      height={200}
                                    />
                                  </h6>
                                ) : null}
                              </td>
                            </tr>
                          ) : (
                            videoViewsListing &&
                            Array.isArray(videoViewsListing) &&
                            videoViewsListing.map((user) => {
                              return (
                                <tr key={user?._id}>
                                  {/* check box */}

                                  <td>
                                    <div className="d-flex">
                                      {moment(user?.createdAt).format(
                                        "l, h:mm:ss a"
                                      )}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="eamil-col d-flex align-items-center">
                                      <span className="avtar-md">
                                        <img
                                          src={
                                            user?.videoDetails[0]?.videoThumb
                                              ? user?.videoDetails[0]
                                                  ?.videoThumb
                                              : "/assets/images/no-user.png"
                                          }
                                        />
                                      </span>
                                      <span className="ps-3">
                                        {" "}
                                        {user?.videoDetails[0]?.videoTitle}
                                      </span>
                                    </div>
                                  </td>

                                  <td>
                                    <div className="eamil-col d-flex align-items-center">
                                      <span className="avtar-md">
                                        <img
                                          src={
                                            user?.viewerDetails[0]
                                              ?.creatorProfilephoto
                                              ? user?.viewerDetails[0]
                                                  ?.creatorProfilephoto
                                              : "/assets/images/no-user.png"
                                          }
                                        />
                                      </span>
                                      {user?.viewerDetails.length > 0 && (
                                        <span className="ps-3">
                                          {user?.viewerDetails[0]?.firstname +
                                            " " +
                                            user?.viewerDetails[0]?.lastname}
                                        </span>
                                      )}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="eamil-col d-flex align-items-center">
                                      <span>
                                        {" "}
                                        {
                                          user?.videoDetails[0]?.brands[0]
                                            ?.organization
                                        }
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="custom-control custom-switch light">
                                      {
                                        user?.videoDetails[0]?.categoryName[0]
                                          ?.title
                                      }
                                      <label
                                        className="custom-control-label"
                                        for={user?._id}
                                      ></label>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                    {videoViewsListing && videoViewsListing.length < 10 ? (
                      <div>
                        <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                          <p className="mb-0 align-self-center text-primary-light fs-14">
                            Showing <b>{videoViewsListing.length}</b> Out of{" "}
                            <b>{total}</b> Entries
                          </p>
                          <div className="pagination-list ms-2">
                            <Pagination
                              className="ant-pagination"
                              pageSize={pagesize}
                              current={page}
                              total={total}
                              onChange={paginationChange}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                        <p className="mb-0 align-self-center text-primary-light fs-14">
                          Showing <b> 10 </b> Out of <b> {total}</b> Entries
                        </p>
                        <div className="pagination-list ms-2">
                          <Pagination
                            className="ant-pagination"
                            pageSize={pagesize}
                            current={page}
                            total={total}
                            onChange={paginationChange}
                          />
                        </div>
                      </div>
                    )}
                  </Tab>

                  <Tab
                    eventKey="Product Views"
                    title={`Product Views`}
                    className="pt-4"
                  >
                    <div className="content-area position-relative">
                      <div className="user-listing-filterOptions mb-2 d-block">
                        <div className="row mb-2">
                          <div className="col-sm-8 position-static">
                            <div className="left-buttons d-flex ">
                              <label>
                                <div className="search-input-wrapper position-relative">
                                  <i className="icon-magnifier"></i>{" "}
                                  <input
                                    type="search"
                                    value={searchText1}
                                    className="form-control text-capitalize"
                                    placeholder="search"
                                    onChange={(evt) => {
                                      handleSearchChange1(evt);
                                    }}
                                  />
                                  {searchText1 && searchText1.length > 0 ? (
                                    <div className="clear">
                                      <span
                                        onClick={clearInput}
                                        style={{ marginRight: "20px" }}
                                      >
                                        <em className="icon-close"></em>
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              </label>

                              <div
                                id="container1"
                                className={
                                  filterpopup
                                    ? "custom-dropdown filter-data-dropdown position-static ml-2 open"
                                    : "custom-dropdown filter-data-dropdown position-static ml-2"
                                }
                              >
                                <button
                                  onClick={() => {
                                    openFilterpopup(!filterpopup);
                                  }}
                                  className="btn btn-bordered dropdown-toggle custom-filter-dropdown btn-bg-white d-flex align-items-center"
                                  type="button"
                                >
                                  <i className="bx bx-filter-alt align-text-top me-2"></i>
                                  Filter
                                </button>
                                {renderFilter1()}
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div
                              className="right-buttons d-flex justify-content-end"
                              style={{ gap: "10px" }}
                            >
                              <div className="form-group  col-sm-4  d-flex">
                                <div className="w-100">
                                  <Select
                                    id="download"
                                    name="download"
                                    options={options}
                                    placeholder="Download"
                                    onChange={(e) => {
                                      handleDownload1(e);
                                    }}
                                    value={downloadSelect1}
                                  />
                                </div>
                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                ></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table
                        className="table row-border nowrap common-datatable"
                        id="user-listing"
                      >
                        <thead>
                          <tr>
                            <th className="all">
                              <b>View Date & Time</b>
                            </th>

                            <th>
                              <b>Video Title</b>
                            </th>
                            <th>
                              <b>User</b>
                            </th>
                            <th>
                              <b>Brand</b>
                            </th>
                            <th>
                              <b>Category</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {productClickList && productClickList.length === 0 ? (
                            <tr className="text-center text-danger not-found-txt">
                              <td colSpan="6">
                                {!loading ? (
                                  <h6
                                    className="text-center text-danger not-found-txt"
                                    colSpan="6"
                                  >
                                    {/* No Records Found! */}
                                    <Image
                                      src="https://vistaadmindev.devpress.net/assets/images/no_data_found.png"
                                      width={200}
                                      height={200}
                                    />
                                  </h6>
                                ) : null}
                              </td>
                            </tr>
                          ) : (
                            productClickList &&
                            Array.isArray(productClickList) &&
                            productClickList?.map((user) => {
                              return (
                                <tr key={user?._id}>
                                  {/* check box */}

                                  <td>
                                    <div className="d-flex">
                                      {moment(user?.createdAt).format(
                                        "l, h:mm:ss a"
                                      )}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="eamil-col d-flex align-items-center">
                                      <span className="avtar-md">
                                        <img
                                          src={
                                            user?.videoDetails[0]?.videoThumb
                                              ? user?.videoDetails[0]
                                                  ?.videoThumb
                                              : "/assets/images/no-user.png"
                                          }
                                        />
                                      </span>
                                      <span className="ps-3">
                                        {" "}
                                        {user?.videoDetails[0]?.videoTitle}
                                      </span>
                                    </div>
                                  </td>

                                  <td>
                                    <div className="eamil-col d-flex align-items-center">
                                      <span className="avtar-md">
                                        <img
                                          src={
                                            user?.viewerDetails[0]
                                              ?.creatorProfilephoto
                                              ? user?.viewerDetails[0]
                                                  ?.creatorProfilephoto
                                              : "/assets/images/no-user.png"
                                          }
                                        />
                                      </span>
                                      {user?.viewerDetails?.length > 0 && (
                                        <span className="ps-3">
                                          {user?.viewerDetails[0]?.firstname +
                                            " " +
                                            user?.viewerDetails[0]?.lastname}
                                        </span>
                                      )}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="eamil-col d-flex align-items-center">
                                      <span>
                                        {" "}
                                        {
                                          user?.videoDetails[0]?.brands[0]
                                            ?.organization
                                        }
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="custom-control custom-switch light">
                                      {
                                        user?.videoDetails[0]?.categoryName[0]
                                          ?.title
                                      }
                                      <label
                                        className="custom-control-label"
                                        for={user?._id}
                                      ></label>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                    {productClickList && productClickList.length < 10 ? (
                      <div>
                        <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                          <p className="mb-0 align-self-center text-primary-light fs-14">
                            Showing <b> {productClickList?.length} </b> Out of{" "}
                            <b> {prodTotal} </b> Entries
                          </p>
                          <div className="pagination-list ms-2">
                            <Pagination
                              className="ant-pagination"
                              pageSize={prodPageSize}
                              current={prodPage}
                              total={prodTotal}
                              onChange={paginationChange1}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                        <p className="mb-0 align-self-center text-primary-light fs-14">
                          Showing <b> 10 </b> Out of <b> {prodTotal} </b>{" "}
                          Entries
                        </p>

                        <div className="pagination-list ms-2">
                          <Pagination
                            className="ant-pagination"
                            pageSize={prodPageSize}
                            current={prodPage}
                            total={prodTotal}
                            onChange={paginationChange1}
                          />
                        </div>
                      </div>
                    )}
                  </Tab>
                  {/* <Tab
                  eventKey="Product Purchased"
                  title={`Product Purchased`}
                  className="card-body"
                ></Tab> */}
                  {/* video purchase */}
                  <Tab
                    eventKey="Vid Purchased"
                    title={`Vid Purchased`}
                    className="pt-4"
                  >
                    <div className="content-area position-relative">
                      <div className="user-listing-filterOptions mb-2 d-block">
                        <div className="row mb-2">
                          <div className="col-sm-8 position-static">
                            <div className="left-buttons d-flex ">
                              <label>
                                <div className="search-input-wrapper position-relative">
                                  <i className="icon-magnifier"></i>{" "}
                                  <input
                                    type="search"
                                    value={searchText3}
                                    className="form-control text-capitalize"
                                    placeholder="search"
                                    onChange={(evt) => {
                                      handleSearchChange3(evt);
                                    }}
                                  />
                                  {searchText3 && searchText3.length > 0 ? (
                                    <div className="clear">
                                      <span
                                        onClick={clearInput1}
                                        style={{ marginRight: "20px" }}
                                      >
                                        <em className="icon-close"></em>
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              </label>

                              <div
                                id="container1"
                                className={
                                  filterpopup
                                    ? "custom-dropdown filter-data-dropdown position-static ml-2 open"
                                    : "custom-dropdown filter-data-dropdown position-static ml-2"
                                }
                              >
                                <button
                                  onClick={() => {
                                    openFilterpopup(!filterpopup);
                                  }}
                                  className="btn btn-bordered dropdown-toggle custom-filter-dropdown btn-bg-white d-flex align-items-center"
                                  type="button"
                                >
                                  <i className="bx bx-filter-alt align-text-top me-2"></i>
                                  Filter
                                </button>
                                {renderFilter3()}
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div
                              className="right-buttons d-flex justify-content-end"
                              style={{ gap: "10px" }}
                            >
                              <div className="form-group  col-sm-4  d-flex">
                                <div className="w-100">
                                  <Select
                                    id="download"
                                    name="download"
                                    options={options}
                                    placeholder="Download"
                                    onChange={(e) => {
                                      handleDownload3(e);
                                    }}
                                    value={downloadSelect3}
                                  />
                                </div>
                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                ></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table
                        className="table row-border nowrap common-datatable"
                        id="user-listing"
                      >
                        <thead>
                          <tr>
                            <th className="all">
                              <b>TransactionId</b>
                            </th>

                            <th>
                              <b>Category</b>
                            </th>
                            <th>
                              <b>Brand</b>
                            </th>
                            <th>
                              <b>No. Of Vid</b>
                            </th>
                            <th>
                              <b>Published Vid</b>
                            </th>
                            <th>
                              <b>Price/Vid</b>
                            </th>
                            <th>
                              <b>Total Price</b>
                            </th>

                            <th>
                              <b>Payment Released</b>
                            </th>
                            <th className="all">
                              <b>Purchase Date & Time</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {vidData && vidData.length === 0 ? (
                            <tr className="text-center text-danger not-found-txt">
                              <td colSpan="6">
                                {!loading ? (
                                  <h6
                                    className="text-center text-danger not-found-txt"
                                    colSpan="6"
                                  >
                                    {/* No Records Found! */}
                                    <Image
                                      src="https://vistaadmindev.devpress.net/assets/images/no_data_found.png"
                                      width={200}
                                      height={200}
                                    />
                                  </h6>
                                ) : null}
                              </td>
                            </tr>
                          ) : (
                            Array.isArray(vidData) &&
                            vidData?.map((user) => {
                              return (
                                <tr key={user._id}>
                                  <td>
                                    <div className="eamil-col d-flex align-items-center">
                                      <span> {user?.transactionId}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="eamil-col d-flex align-items-center">
                                      <span> {user?.category[0]?.title}</span>
                                    </div>
                                  </td>

                                  <td>
                                    <div className="eamil-col d-flex align-items-center">
                                      <span>
                                        {user?.brands[0]?.organization}
                                      </span>
                                    </div>
                                  </td>

                                  <td>
                                    <div className="eamil-col d-flex align-items-center">
                                      <span> {user?.noOfVideosOrdered}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="custom-control custom-switch light">
                                      {user?.noOfVideoPublished}
                                      <label
                                        className="custom-control-label"
                                        for={user?._id}
                                      ></label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="custom-control custom-switch light">
                                      {user?.pricePerVideo}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="custom-control custom-switch light">
                                      {user?.price}
                                    </div>
                                  </td>

                                  <td>
                                    {console.log(
                                      "user",
                                      user?.paymentReleasedFromAdmin
                                    )}
                                    <div className="custom-control custom-switch light">
                                      {user?.paymentReleasedFromAdmin
                                        ? "Yes"
                                        : "No"}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex">
                                      {moment(user?.createdAt).format(
                                        "l, h:mm:ss a"
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                    {vidData && vidData.length < 10 ? (
                      <div>
                        <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                          <p className="mb-0 align-self-center text-primary-light fs-14">
                            Showing <b> {vidData?.length} </b> Out of{" "}
                            <b> {vidTotal} </b> Entries
                          </p>

                          <div className="pagination-list ms-2">
                            <Pagination
                              className="ant-pagination"
                              pageSize={vidPageSize}
                              current={vidPage}
                              total={vidTotal}
                              onChange={paginationChange3}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                        <p className="mb-0 align-self-center text-primary-light fs-14">
                          Showing <b> 10 </b> Out of <b> {vidTotal} </b> Entries
                        </p>
                        <div className="pagination-list ms-2">
                          <Pagination
                            className="ant-pagination"
                            pageSize={vidPageSize}
                            current={vidPage}
                            total={vidTotal}
                            onChange={paginationChange3}
                          />
                        </div>
                      </div>
                    )}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  // UserAccess: state.admin.adminData.staticRolePermission.userAccess,
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi })(ViewPayoutRequestDetail);
