import React, { useState, useEffect } from "react";
import { callApi } from "../../Api"; // Used for api call
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import Spinner from "../../Components/Loader/Spinner";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../Components/Loader/Loader";
import { Layout } from "../../Components/Layout";
import { showModalNotification } from "../../Utils/Functions";

/******************* 
@Purpose : Used for view user details
@Parameter : props
@Author : INIC
******************/
function RequestDetailView() {
  let { slug } = useParams();
  const [spin, setSpin] = useState(false);
  let [loading] = useState(false);
  const [requests, setRequests] = useState([]);
  const [page] = useState(1);
  const [pagesize] = useState(10);

  const [, setImage] = useState("");
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    emailId: "",
    gender: "",
    dob: "",
    mobileNumber: "",
    status: "",
    address: "",
    city: "",
    twitter: "",
    instagramId: "",
    aadhar: "",
    state: "",
    pincode: "",
    fbId: "",
    youtubeId: "",
    creatorProfilephoto: "",
  });

  useEffect(() => {
    getRequestDetails(slug);
  }, [slug]);

  const getRequestDetails = async (data) => {
    setSpin(true);
    try {
      let body = {
        pageNumber: page,
        pageSize: pagesize,
        userId: slug,
      };

      const response = await callApi(
        `/admin/getRequestHistoryOfUser/${slug}`,
        body,
        "post",
        null,
        true
      );
      if (response.status === 1) {
        setSpin(false);
        setRequests(response?.requests);

        let {
          firstname,
          lastname,
          emailId,
          gender,
          dob,
          mobileNumber,
          status,
          address,
          city,
          twitter,
          instagramId,
          aadhar,
          state,
          pincode,
          fbId,
          youtubeId,
          creatorProfilephoto,
        } = response?.userDetail;

        setFormData({
          ...formData,
          firstname,
          lastname,
          emailId,
          gender,
          dob,
          mobileNumber,
          status,
          address,
          city,
          twitter,
          instagramId,
          aadhar,
          state,
          pincode,
          fbId,
          youtubeId,
          creatorProfilephoto,
        });
        setImage(response?.creatorProfilephoto);
      } else if (response?.message === 0) {
        setSpin(false);
        showModalNotification(response?.message, "error");
      }
    } catch (error) {
      console.error(error);
    }
  };
  /******************* 
  @Purpose : Used for get users all details
  @Parameter : id
  @Author : INIC
  ******************/

  const breadcrumb = [
    {
      title: "Requests",
      url: "/celebrityList",
    },
    {
      title: "Request Details",
    },
  ];

  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Manage Celebrity">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="card mb-5">
              <div className="card-header">
                <h5 className="card-title">Request Information</h5>
              </div>
              <div className="card-body">
                <form>
                  <div className="row">
                    {spin ? (
                      <div className="d-flex align-center justify-content-center">
                        <Spinner />
                      </div>
                    ) : null}
                    <div className="col-lg-2 col-md-12">
                      <div className="user-image d-block mx-auto">
                        <img
                          src={
                            formData?.creatorProfilephoto
                              ? formData?.creatorProfilephoto
                              : "/assets/images/no-user.png"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6 user-title-info user-details">
                      <div className="form-group mb-4">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-md-6">
                            <span className="text-capitalize fw-bold request-title">
                              NAME
                            </span>
                          </div>
                          <div className="col-xl-8 col-lg-6 col-md-6">
                            <span className="request-data">
                              {formData?.firstname} {formData?.lastname}{" "}
                            </span>
                            <span className="error-msg text-danger"></span>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-4">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-md-6">
                            <span className="text-capitalize fw-bold request-title">
                              EMAIL ID
                            </span>
                          </div>
                          <div className="col-xl-8 col-lg-6 col-md-6">
                            <span className="request-data">
                              {formData?.emailId ? formData?.emailId : "-"}
                            </span>
                            <span className="error-msg text-danger"></span>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-4">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-md-6">
                            <span className="text-capitalize fw-bold request-title">
                              GENDER
                            </span>
                          </div>
                          <div className="col-xl-8 col-lg-6 col-md-6">
                            <div className="request-data">
                              {formData?.gender ? formData?.gender : "-"}
                            </div>
                            <span className="error-msg text-danger"></span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-md-6">
                            <span className="text-capitalize fw-bold request-title">
                              ADDRESS
                            </span>
                          </div>
                          <div className="col-xl-8 col-lg-6 col-md-6">
                            <span className="request-data">
                              {formData?.address ? formData?.address : "-"}
                            </span>
                            <span className="text-danger error-msg"></span>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-4">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-md-6">
                            <span className="text-capitalize fw-bold request-title">
                              CITY
                            </span>
                          </div>
                          <div className="col-xl-8 col-lg-6 col-md-6">
                            <span className="request-data">
                              {formData?.city ? formData?.city : "-"}
                            </span>
                            <span className="text-danger error-msg"></span>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-4">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-md-6">
                            <span className="text-capitalize fw-bold request-title">
                              INSTAGRAM
                            </span>
                          </div>
                          <div className="col-xl-8 col-lg-6 col-md-6">
                            <span className="request-data">
                              <a href={formData?.instagramId} target="_blank">
                                {formData?.instagramId
                                  ? formData?.instagramId
                                  : "-"}
                              </a>
                              {/* {formData?.instagramId
                                ? formData?.instagramId
                                : "-"} */}
                            </span>
                            <span className="text-danger error-msg"></span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-md-6">
                            <span className="text-capitalize fw-bold request-title">
                              STATUS
                            </span>
                          </div>
                          <div className="col-xl-8 col-lg-6 col-md-6">
                            <span
                              className={
                                formData?.status
                                  ? "badge text-white bg-secondary"
                                  : "badge text-white bg-secondary inactive-btn"
                              }
                            >
                              {formData?.status ? "Active" : "Inactive"}
                            </span>
                            <span className="text-danger error-msg"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 user-title-info user-details">
                      <div className="form-group mb-4">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-md-6">
                            <span
                              className="text-capitalize fw-bold request-title"
                              htmlFor="Name"
                            >
                              PHONE NUMBER
                            </span>
                          </div>
                          <div className="col-xl-8 col-lg-6 col-md-6">
                            <span className="request-data">
                              {formData?.mobileNumber
                                ? formData?.mobileNumber
                                : "-"}
                            </span>
                            <span
                              className="text-danger error-msg"
                              style={{ color: "red" }}
                            ></span>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-4">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-md-6">
                            <span className="text-capitalize fw-bold request-title">
                              DOB{" "}
                            </span>
                          </div>
                          <div className="col-xl-8 col-lg-6 col-md-6">
                            <span className="request-data">
                              {formData?.dob
                                ? moment(formData?.dob).format("L")
                                : "-"}
                            </span>
                            <span className="text-danger error-msg"></span>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-4">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-md-6">
                            <span className="text-capitalize fw-bold request-title">
                              STATE
                            </span>
                          </div>
                          <div className="col-xl-8 col-lg-6 col-md-6">
                            <span className="request-data">
                              {formData?.state ? formData?.state : "-"}
                            </span>
                            <span className="text-danger error-msg"></span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-md-6">
                            <span className="text-capitalize fw-bold request-title">
                              PINCODE
                            </span>
                          </div>
                          <div className="col-xl-8 col-lg-6 col-md-6">
                            <span className="request-data">
                              {formData?.pincode ? formData?.pincode : "-"}
                            </span>
                            <span
                              className="error-msg"
                              style={{ color: "red" }}
                            ></span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-md-6">
                            <span className="text-capitalize fw-bold request-title">
                              YOUTUBE
                            </span>
                            <p>
                              <small>(OPTIONAL)</small>
                            </p>
                          </div>
                          <div className="col-xl-8 col-lg-6 col-md-6">
                            <span className="request-data">
                              {formData?.youtubeId ? formData?.youtubeId : "-"}
                            </span>
                            <span
                              className="error-msg"
                              style={{ color: "red" }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="card mb-5">
              <div className="card-header">
                <h5 className="card-title">Request History</h5>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table
                    className="table row-border nowrap common-datatable"
                    id="master-country-listing"
                  >
                    <thead>
                      <tr>
                        <th>
                          <b>Action</b>
                          <i aria-hidden="true" className="icon-arrow-down"></i>
                        </th>

                        <th>
                          <b>Action By</b>
                          <i aria-hidden="true" className="icon-arrow-down"></i>
                        </th>

                        <th>
                          <b>Comment</b>
                          <i aria-hidden="true" className="icon-arrow-down"></i>
                        </th>

                        <th>
                          <b>Action Date</b>
                          <i aria-hidden="true" className="icon-arrow-down"></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {requests && requests?.length === 0 ? (
                        <tr className="text-center text-danger not-found-txt">
                          <td colSpan="6">
                            {loading ? <Loader /> : null}{" "}
                            {!loading ? (
                              <h6
                                className="text-center text-danger not-found-txt"
                                colSpan="6"
                              >
                                {"No requested records are found "}
                              </h6>
                            ) : null}
                          </td>
                        </tr>
                      ) : (
                        requests &&
                        Array.isArray(requests) &&
                        requests?.map((list, key) => {
                          return (
                            <tr key={list?._id}>
                              <td>
                                <div className="eamil-col d-flex align-items-center">
                                  <span>
                                    {list?.status ? list?.status : "-"}
                                  </span>
                                </div>
                              </td>

                              {/* sub categories  */}

                              <td>
                                <div className="eamil-col d-flex align-items-center">
                                  <span>{list?.actionByDetail?.name}</span>
                                </div>
                              </td>
                              <td>
                                <div className="eamil-col d-flex align-items-center">
                                  <span
                                    style={{
                                      width: "250px",

                                      overflow: "hidden",
                                      whiteSpace: "normal",
                                      wordBreak: "break-word !important",
                                      wordWrap: "break-word !important",
                                    }}
                                  >
                                    {list?.rejectedComment
                                      ? list?.rejectedComment
                                      : "-"}
                                  </span>
                                </div>
                              </td>

                              <td>
                                <div className="eamil-col d-flex align-items-center">
                                  <span>
                                    {moment(list?.actionDate).format(
                                      "l, h:mm:ss a"
                                    )}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  // UserAccess: state.admin.adminData.staticRolePermission.userAccess,
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi })(RequestDetailView);
