import axios from "axios";

import { API_URL } from "../Config";
import { getItem, removeItem } from "../Utils/Functions";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {}
);

/******************* 
@Purpose : We can use following api calls and can change api name easily
@Parameters : url, data, method, type, isAuthorized, isForm
@Author : INIC
******************/

export function callApi(
  url,
  data,
  method,
  Type = null,
  isAuthorized = false,
  isForm = false,
  id = false,
  history
) {
  let headers = {
    "Content-Type": "application/json",
  };
  if (isAuthorized) headers.Authorization = getItem("accessToken");
  if (id) headers.id = localStorage.getItem("id");
  if (isForm) headers["Content-Type"] = "multipart/form-data";

  let config = {
    method,
    url,
    baseURL: API_URL,
    headers,
    data,
    validateStatus: function (status) {
      return status;
    },
  };

  return new Promise(function (resolve, reject) {
    axios(config)
      .then(function (response) {
        if (response?.status === 200 && Type !== null) {
          data = response?.data;
        }
        if (
          response.status === 400
          // ||
          // response.status === 401 ||
          // response.status === 404
        ) {
          removeItem("accessToken");

          localStorage.clear();
          sessionStorage.clear();

          history.push("/");
        }
        resolve(response?.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  });
}
