import React from "react";

const Footer = () => {
  return (
    <div className="footer-text d-flex align-items-centerf justify-content-between">
      <span className="">2023 © Vista Admin Panel</span>
      <span>All Rights Reserved</span>
    </div>
  );
};

export default Footer;
