import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setDashboardData } from "../../Store/Actions/User";
import { showModalNotification } from "../../Utils/Functions";
import Spinner from "../../Components/Loader/Spinner";
function CardData() {
  const dispatch = useDispatch();
  const [spin, setSpin] = useState(false);
  const [cardData, setCardData] = useState([
    {
      id: 1,
      name: "Total Users",
      count: 0,
      iconClass: "text-orange bx bx-user-circle",
    },
    {
      id: 2,
      name: "Total Celebrity",
      count: 0,
      iconClass: "text-orange bx bx-user-check",
    },
    {
      id: 3,
      name: "Total Brands",
      count: 0,
      iconClass: "text-orange bx bx bx-braille",
    },
    {
      id: 4,
      name: "Total Videos",
      count: 0,
      iconClass: "text-orange bx bx-video",
    },
    {
      id: 5,
      name: "Total Earning",
      count: 0,
      iconClass: "text-orange bx bx-rupee",
    },
  ]);
  //

  useEffect(() => {
    getHighestViewdVids();
  }, []);
  const getHighestViewdVids = async (e) => {
    setSpin(true);
    try {
      await dispatch(setDashboardData()).then((response) => {
        if (response.status === 1) {
          setSpin(false);
          setCardData([
            {
              id: 1,
              name: "Total Users",
              count: response?.noOfCustomers,
              iconClass: "text-orange bx bx-user-circle",
            },
            {
              id: 2,
              name: "Total Celebrity",
              count: response?.noOfCelebrity,
              iconClass: "text-orange bx bx bx-user-check",
            },
            {
              id: 3,
              name: "Total Brands",
              count: response?.brandCount,
              iconClass: "text-orange bx bx bx-braille",
            },
            {
              id: 4,
              name: "Total Videos",
              count: response?.videosCount,
              iconClass: "text-orange bx bx-video",
            },
            {
              id: 5,
              name: "Total Earning",
              count: response?.totalEarnings,
              iconClass: "text-orange bx bx-rupee",
            },
          ]);
        } else if (response?.message === 0) {
          setSpin(false);
          showModalNotification(response?.message);
        }
      });


    } catch (error) {
      console.error();
    }
  };
  return (
    <>
      <Row className="gx-5">
        {spin ? (
          <div className="d-flex align-center justify-content-center">
            <Spinner />
          </div>
        ) : null}
        {cardData?.map((data) => {
          return (
            <Col
              xs={12}
              sm={6}
              xl={4}
              xxl={3}
              key={data?.iconClass + data?.count + data?._id}
              className="mb-4"
            >
              <div className="user-info-card col d-flex flex-wrap align-items-center p-3">
                <div className="d-flex align-items-center">
                  <div className="icon-box-wrap">
                    <i className={data?.iconClass}></i>
                  </div>
                  <div className="w-100 font-32">
                    <h3 className="text-black m-0 fw-bold">{data?.count}</h3>
                    <h6 className="text-orange mb-0 pt-1">{data?.name}</h6>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </>
  );
}

export default CardData;
