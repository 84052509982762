import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import EditContent from "../../Components/Cms/EditContent";
import { Layout } from "../../Components/Layout";
import Spinner from "../../Components/Loader/Spinner";
import { cmsListing } from "../../Store/Actions/User";
import "suneditor/dist/css/suneditor.min.css";
import { showModalNotification } from "../../Utils/Functions";
function CMS() {
  const dispatch = useDispatch();
  const [cmsList, setCmsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [spin, setSpin] = useState(false);
  /******************* 
  @Purpose : Used for breadcrumb
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      title: "CMS",
      url: "/cms",
    },
  ];

  useEffect(() => {
    getListing();
  }, []);

  function handleClose() {
    setShow(false);
  }

  /******************* 
  @Purpose : Used for get all cms
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  async function getListing() {
    setSpin(true);

    try {
      dispatch(cmsListing()).then((response) => {
        if (response.status === 1) {
          setSpin(false);
          setCmsList(response?.data);
        } else if (response.status === 0) {
          setSpin(false);
          showModalNotification(response?.message, "error");
        }
      });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }
  const handleAddSubCategory = async (user) => {
    setShow(true);
    setData(user);
  };
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="CMS">
      <div className="content-wrapper">
        <div className="main-content-area">
          <div className="main-content-block">
            <div className="card">
              <div className="card-body">
                {spin ? (
                  <div className="d-flex align-center justify-content-center">
                    <Spinner />
                  </div>
                ) : null}
                <div className="table-responsive">
                  <table className="table row-border nowrap common-datatable">
                    <thead>
                      <tr>
                        <th>
                          <b>Title</b>
                        </th>
                        <th>
                          <b>Updated By</b>
                        </th>
                        <th>
                          <b>Change Date</b>
                        </th>
                        <th>
                          <b>Action</b>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {cmsList && cmsList.length === 0 ? (
                        <tr className="text-center text-danger not-found-txt">
                          <td colSpan="6">
                            {!loading ? (
                              <h6
                                className="text-center text-danger not-found-txt"
                                colSpan="6"
                              >
                                No Data Found!
                              </h6>
                            ) : null}
                          </td>
                        </tr>
                      ) : (
                        cmsList &&
                        Array.isArray(cmsList) &&
                        cmsList.map((user, key) => {
                          return (
                            <tr key={user?.pageTitle}>
                              <td>{user?.pageTitle}</td>
                              <td>{user?.updatedBy ? user?.updatedBy : "-"}</td>
                              <td>
                                {" "}
                                {moment(user?.updatedAt).format("l, h:mm:ss a")}
                              </td>
                              <td>
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip id="edit">Edit</Tooltip>}
                                >
                                  <span
                                    className="icon-note"
                                    onClick={() => handleAddSubCategory(user)}
                                  ></span>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
      <EditContent
        show={show}
        closeModal={handleClose}
        data={data}
        callBack={getListing}
      />
    </Layout>
  );
}

export default CMS;
