import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";

import { callApi } from "../../Api";
import { validateEmail, showMessageNotification } from "../../Utils/Functions";
import { Link } from "react-router-dom";
import API from "../../Api/Routes";
import errorMessages from "../../Utils/ErrorMessages";
import Theme from "../../Components/Layout/Theme";

/******************* 
@Purpose : Used for forgot password
@Parameter : {}
@Author : INIC
******************/
function ForgotPassword() {
  let [errors, setErrors] = useState({ emailId: "" });
  const [emailId, setEmailId] = useState("");
  const [, setIsFormValid] = useState(true);
  let [loading, setLoading] = useState(false);

  /******************* 
  @Purpose : Used for form validation
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateForm = () => {
    let error = { emailId: "" };
    let isFormValid = true;
    if (!emailId.trim()) {
      error.emailId = errorMessages.PROVIDE_EMAIL;
    } else if (!validateEmail(emailId)) {
      error.emailId = errorMessages.PROVIDE_VALID_EMAIL;
    } else {
      error.emailId = false;
      isFormValid = true;
    }
    setErrors(error);
    setIsFormValid(isFormValid);
    return isFormValid;
  };

  /******************* 
  @Purpose : Used for forgot password actions
  @Parameter : {e}
  @Author : INIC
  ******************/
  async function forgotPassword(e) {
    e.preventDefault();
    const body = { emailId: emailId };
    try {
      if (validateForm()) {
        setLoading(true);
        const response = await callApi(
          API.FORGOT,
          body,
          "post",
          "ADMIN_FORGOT"
        );
        setLoading(false);
        if (response.status === 1) {
          showMessageNotification(response.message, "success");
          setEmailId("");
          setErrors("");
        }
      }
    } catch (error) {
      console.error();
    }
  }

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <div className="App">
      <Theme />
      <div
        className="login-wrapper"
        style={{
          background:
            "url(../assets/images/login-bg.jpg) no-repeat center center",
        }}
      >
        <div className="login-body">
          <div className="login-container row m-0">
            <div className="col-xl-7 col-11">
              <div className="card bg-login-card">
                <div className="row g-0">
                  <div className="col-md-7 col-12 pl-0 pr-0">
                    <div className="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
                      <div className="card-header mb-4">
                        <h5 className="card-title text-center">
                          Forgot Password?
                        </h5>
                        <div className="text-center">
                          <p className="common-small-text">
                            Enter the email you used and we will send you
                            temporary link
                          </p>
                        </div>
                      </div>
                      <div className="card-content">
                        <div className="card-body pb-0">
                          <form onSubmit={(evt) => forgotPassword(evt)}>
                            <div className="form-group mb-4">
                              <label className="form-label" for="EmailAddress">
                                Email
                              </label>
                              <input
                                type="email"
                                className={
                                  errors.emailId
                                    ? "form-control input-error"
                                    : "form-control"
                                }
                                id="EmailAddress"
                                aria-describedby="emailHelp"
                                placeholder="Email "
                                value={emailId}
                                onChange={(e) => {
                                  setEmailId(e.target.value);
                                  let error = Object.assign(errors, {
                                    emailId: "",
                                  });
                                  setErrors(error);
                                }}
                              />
                              <span className="error-msg">
                                {errors.emailId}
                              </span>
                            </div>
                            <Button
                              type="button"
                              className="btn w-100 btn-primary glow mb-3 btn-block"
                              onClick={(evt) => forgotPassword(evt)}
                            >
                              {loading ? "Loading" : "Send Link"}
                              <i className="ms-2 align-middle text-white icon-arrow-right"></i>
                            </Button>
                            <Link
                              type="button"
                              to="/login"
                              className="card-link w-100 btn btn-light glow position-relative btn-block"
                            >
                              Cancel
                            </Link>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 d-md-block d-none text-center align-self-center p-7">
                    <div className="card-content">
                      <img
                        className="img-fluid"
                        src="assets/images/forgot-password.png"
                        alt="sign up"
                        width="300"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(null, { callApi })(ForgotPassword);
