import React from "react";
import { Breadcrumb } from "react-bootstrap";
function Support() {
  const divStyle = {
    fontSize: "18px",
    fontWeight: 500,
  };

  const header = {
    fontWeight: 500,
  };
  return (
    <div>
      <header className="cms-header border-0">
        <div className="container">
          <div className="navigation-bars">
            <nav className="navbar d-flex navbar-expand bd-navbar justify-content-between">
              <div className="left-nav mr-auto d-flex align-items-center">
                <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                  <ul className=" nav navbar-nav">
                    <li>
                      <img
                        className="img-fluid"
                        src="/assets/images/logo.svg"
                        alt=""
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>

      <div className="cms-content-area">
        <div className="container">
          <Breadcrumb>
            <Breadcrumb.Item> SUPPORT</Breadcrumb.Item>
            <Breadcrumb.Item>SUPPORT</Breadcrumb.Item>
          </Breadcrumb>

          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="card ">
                <div className="card-header">
                  Support Information for Vista App
                </div>
                <div className="card-body">
                  <ol className="ps-0">
                    <li>
                      <span style={header}></span>
                      <div className="ml-1 mt-3">
                        At Vista App, we are committed to providing exceptional
                        support and assistance to our valued users. If you have
                        any questions, concerns, or require technical
                        assistance, our dedicated support team is here to help.
                        Please find below the support information for Vista App:
                      </div>
                    </li>
                    {/*  */}
                    <li>
                      <span style={header}>Contact Information:</span>
                      <div className="ml-1 mt-3">
                        <ul
                          className="ml-3 mt-3"
                          style={{ marginLeft: "25px" }}
                        >
                          <li style={{ listStyleType: "disc" }}>
                            <span style={divStyle}>
                              Support Email: support@vistareels.com
                            </span>
                          </li>
                          <li style={{ listStyleType: "disc" }}>
                            <span style={divStyle}>
                              Support Hours: Our support team is available to
                              assist you from Monday to Friday, 9:00 AM to 5:00
                              PM (GMT).
                            </span>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <span style={header}>How to Reach Us:</span>{" "}
                      <div className="ml-1 mt-3">
                        For prompt assistance, please send an email to our
                        support team at support@vistareels.com. Our team strives
                        to respond to all inquiries within 24 hours during
                        business days.
                        <div className="ml-3 mt-3">
                          When Contacting Support:
                        </div>
                        <div>
                          To ensure a smooth and efficient support experience,
                          please provide the following details when contacting
                          our support team:
                        </div>
                        <ul
                          className="ml-3 mt-3"
                          style={{ marginLeft: "25px" }}
                        >
                          <li style={{ listStyleType: "disc" }}>
                            <span style={divStyle}>
                              Your full name and contact information.
                            </span>
                          </li>
                          <li style={{ listStyleType: "disc" }}>
                            <span style={divStyle}>
                              A clear and concise description of the issue or
                              question you're facing.
                            </span>
                          </li>
                          <li style={{ listStyleType: "disc" }}>
                            <span style={divStyle}>
                              {" "}
                              Any relevant screenshots or error messages that
                              can help us understand the problem.
                            </span>
                          </li>
                          <li style={{ listStyleType: "disc" }}>
                            <span style={divStyle}>
                              If applicable, provide the steps to replicate the
                              issue so that we can better assist you.
                            </span>{" "}
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <span style={header}>
                        Frequently Asked Questions (FAQ):
                      </span>
                      <div className="ml-1 mt-3">
                        Before reaching out to our support team, you may find it
                        helpful to browse through our Frequently Asked Questions
                        (FAQ) section on our website. We have compiled a
                        comprehensive list of common inquiries and their answers
                        to provide you with quick solutions. Visit our FAQ page
                        at [insert FAQ page URL here].
                      </div>
                    </li>
                    <li>
                      <span>User Guides and Tutorials:</span>
                      <div className="ml-1 mt-3">
                        {" "}
                        To assist you in navigating and utilizing the full
                        potential of Vista App, we have created user guides and
                        tutorials. These resources cover various aspects of the
                        app, including reel creation, editing features, and
                        sharing options. You can access our user guides and
                        tutorials on our website's support section at
                        admin.vistareels.com/support.
                      </div>
                    </li>
                    {/*  */}
                    <li>
                      <span>Stay Updated:</span>
                      <div className="ml-1 mt-3">
                        For the latest news, updates, and tips on utilizing
                        Vista App effectively, be sure to follow us on social
                        media platforms. We regularly share insights, best
                        practices, and feature announcements to help you make
                        the most of your Reel Based Marketing strategies.
                        Connect with us on [list your social media platforms and
                        handles]. We appreciate your trust in Vista App, and our
                        support team is dedicated to ensuring your experience
                        with our application is seamless and successful. If you
                        have any questions or need assistance, please don't
                        hesitate to reach out to us at support@vistareels.com.
                      </div>
                    </li>
                  </ol>
                  <div className="ml-1 mt-3 text-center">
                    Thank you for choosing Vista App!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Support;
