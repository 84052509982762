//Internal
import React from "react";
//External
import { useDispatch } from "react-redux";
import Select from "react-select";
import { downloadBrandData } from "../../Store/Actions/Brand";

/******************* 
  @Purpose : Used for Download 
  @Parameter : {}
  @Author : INIC
  ******************/
const DownloadButton = () => {
  const dispatch = useDispatch();

  const options = [
    { value: "xls", label: "Excel" },
    { value: "CSV", label: "CSV" },
  ];

  /******************* 
  @Purpose : Used for Download 
  @Parameter : {e}
  @Author : INIC
  ******************/
  const downloadData = (e) => {
    dispatch(downloadBrandData(e.value));
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Select
      id="download"
      name="download"
      options={options}
      placeholder="Download"
      onChange={(e) => {
        downloadData(e);
      }}
      className="download-csv"
    />
  );
};

export default DownloadButton;
