import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Spinner from "../../Components/Loader/Spinner";
import moment from "moment";
import { monthwiseVideosData } from "../../Store/Actions/User";
import { showModalNotification } from "../../Utils/Functions";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
    },
  },
};

const Chart = (props) => {
  const [spin, setSpin] = useState(false);
  const { viewData, setViewData, setClearTrendData } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    getVideoViewData();
  }, []);
  useEffect(() => {
    if (props.clearData === true) {
      clearVideoData();
    }
  }, [props.clearData]);

  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString([], { month: "short" });
  }

  const labels = viewData?.map((data) => getMonthName(data?.month ?? null));

  const dataset = viewData?.map((data) => data?.videoViewCount ?? null);

  const data = {
    labels,
    datasets: [
      {
        label: "Video View",
        data: dataset,
        backgroundColor: "rgb(9, 55, 95)",
        borderRadius: 10,
        barThickness: 30,
      },

      // {
      //   label: "Product View",
      //   data: [100, 290, 350, 380, 150, 100],
      //   backgroundColor: "rgb(18, 109, 186)",
      //   borderRadius: 10,
      //   barThickness: 30,
      // },
      // {
      //   label: "Product Purchased",
      //   data: [150, 200, 320, 400, 200, 100],
      //   backgroundColor: "rgb(162, 207, 246)",
      //   borderRadius: 10,
      //   barThickness: 30,
      // },
    ],
  };
  const getVideoViewData = async (e) => {
    setSpin(true);
    try {
      let body = {
        year: Number(moment(props.date).format("YYYY")),
        filter: props?.filterValues || [],
      };

      dispatch(monthwiseVideosData(body)).then((response) => {
        if (response.status === 1) {
          setSpin(false);
          setViewData(response?.viewCountByMonth);
        } else {
          setSpin(false);
          setViewData([]);
          showModalNotification(response?.message, "error");
        }
      });


    } catch (error) {
      console.error();
    }
  };

  // clear get video data
  const clearVideoData = async (e) => {
    try {
      setClearTrendData(false);
      let body = {
        year: Number(moment(props.date).format("YYYY")),
        filter: [],
      };

      dispatch(monthwiseVideosData(body)).then((response) => {
        if (response.status === 1) {
          setViewData(response?.viewCountByMonth);

        } else {
          showModalNotification(response?.message, "error");
        }
      });


    } catch (error) {
      console.error();
    }
  };
  return (
    <>
      {/* <Filter/>      */}
      {spin ? (
        <div className="d-flex align-center justify-content-center">
          <Spinner />
        </div>
      ) : null}
      <Bar options={options} data={data} />
    </>
  );
};

export default Chart;
