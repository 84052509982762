import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { callApi } from '../../Api';
import { Layout } from '../../Components/Layout';
import { IMG_URL } from '../../Config';
import API from '../../Api/Routes';
import Spinner from '../../Components/Loader/Spinner';
import 'react-datepicker/dist/react-datepicker.css';
import { showModalNotification } from '../../Utils/Functions';

/******************* 
@Purpose : Used for view user details
@Parameter : {props}
@Author : INIC
******************/
function ViewuserDetails(props) {
  const [spin, setSpin] = useState(false);
  const [photo, setPhoto] = useState('');
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    emailId: '',
    gender: '',
    dob: '',
    mobileNumber: '',
    status: '',
    creatorProfilephoto: '',
    city: '',
  });
  const [, setUserId] = useState('');
  let {
    firstname,
    lastname,
    emailId,
    gender,
    dob,
    mobileNumber,
    status,
    creatorProfilephoto,
    city,
  } = formData;

  /******************* 
  @Purpose : React hook used for user access
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (props.UserAccess && props.UserAccess.viewDetails === false) {
      props.history.push('/dashboard');
    }
    let id = props.match.params;
    if (id) {
      getDetails(id.slug);
      setUserId(id.slug);
    }
  }, []);

  /******************* 
  @Purpose : Used for get users all details
  @Parameter : {id}
  @Author : INIC
  ******************/
  let response;
  const getDetails = async (id) => {
    setSpin(true);
    response = await callApi(API.GET_USERS_PROFILE + id, '', 'get', null, true);
    if (response.status === 1) {
      setSpin(false);
      let { data } = response,
        {
          lastname: lastname,
          firstname: firstname,
          emailId: emailId,
          mobileNumber: mobileNumber,
          photo: userPhoto,
          dob,
          gender: genders,
          status: status,
          creatorProfilephoto: creatorProfilephoto,
          city: city,
        } = data;
      setFormData({
        ...formData,
        firstname: firstname,
        lastname: lastname,
        emailId: emailId,
        mobileNumber: mobileNumber,
        photo: userPhoto,
        dob,
        gender: genders,
        status: status,
        creatorProfilephoto: creatorProfilephoto,
        city: city,
      });

      setPhoto(userPhoto);
    } else if (response?.status === 0) {
      setSpin(false);
      showModalNotification(response?.message, 'error');
    }
  };

  /******************* 
  @Purpose : Used for get image preview
  @Parameter : {image}
  @Author : INIC
  ******************/

  let imagePreview = null;
  if (photo) {
    imagePreview = <img src={photo ? IMG_URL + photo : ''} alt="Icon" />;
  } else {
    imagePreview = <img src={'/assets/images/no-user.png'} alt="Icon" />;
  }

  /******************* 
  @Purpose : Used for Bread crumb
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      title: 'Customers',
      url: '/usermanagement',
    },
    {
      title: 'Customer Details',
    },
  ];

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Customers">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="main-content-block">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">Customer Information</h5>
              </div>
              <div className="card-body user-details">
                {spin ? (
                  <div className="d-flex align-center justify-content-center">
                    <Spinner />
                  </div>
                ) : null}
                <div className="row">
                  <div className="col-xxl-2 col-xl-2 col-lg-3">
                    <div className="col-lg-2 col-md-12">
                      <div className="user-image d-block mx-auto">
                        <img
                          src={
                            creatorProfilephoto
                              ? creatorProfilephoto
                              : '/assets/images/no-user.png'
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-5 col-xl-4 col-lg-9">
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        NAME:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {firstname + lastname
                          ? firstname + '  ' + lastname
                          : '-'}
                      </div>
                    </div>

                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        EMAIL ID:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {emailId ? emailId : '-'}
                      </div>
                    </div>

                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        GENDER:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {gender ? gender : '-'}
                      </div>
                    </div>
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        CITY:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {city ? city : '-'}
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-5 col-xl-6 offset-xl-0 offset-lg-3 col-lg-9 mt--15">
                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        PHONE NUMBER:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {mobileNumber ? mobileNumber : '-'}
                      </div>
                    </div>

                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        DOB:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        {dob ? moment(dob).format('L') : '-'}
                      </div>
                    </div>

                    <div className="input-group row g-0">
                      <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                        STATUS:
                      </div>
                      <div className="col-md-8 p-2 request-data">
                        <span
                          className={
                            status
                              ? 'badge text-white bg-secondary'
                              : 'badge text-white bg-secondary inactive-btn'
                          }
                        >
                          {status ? 'Active' : 'Inactive'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  language: state.admin.language,
  UserId: state.admin.edituserId,
  // UserAccess: state.admin.adminData.staticRolePermission.userAccess,
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi })(ViewuserDetails);
