import React from "react";
import { Link } from "react-router-dom";

const BreadCrum = ({ breadcrumb, breadcrumbTitle }) => {


  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb p-0">
        <li className="breadcrumb-item">
          <span className="link">{breadcrumbTitle}</span>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          <Link to="/dashboard">
            <i className="icon-home icon" />
          </Link>
          {breadcrumb?.map(({ url, title, tab }, index) => (
            <React.Fragment key={url + title}>
              <span className="icon-arrow-right icon fs-10"></span>
              {breadcrumb.length - 1 === index ? (
                title
              ) : (

                <Link
                  // to={url}
                  to={{ pathname: url, state: { tab: tab } }}
                  className="link"
                  style={{ color: "rgb(90,141,238" }}
                  key={title}
                >
                  {title}
                </Link>

              )}
            </React.Fragment>
          ))}
        </li>
      </ol>
    </nav>
  );
};

export default BreadCrum;
