import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';

import { Layout } from '../../Components/Layout';
import { callApi } from '../../Api';
/******************* 
@Purpose : Used for view user details
@Parameter : props
@Author : INIC
******************/
function ViewAdminUser(props) {
  let { slug } = useParams();

  const [, setImage] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    emailId: '',
    mobile: '',
    role: '',
    photo: '',
  });
  const [status, setStatus] = useState('');
  useEffect(() => {
    getAdminUserDetails(slug);
  }, [slug]);

  const getAdminUserDetails = async (data) => {
    try {
      const response = await callApi(
        `/admin/userProfile/${slug}`,
        '',
        'get',
        null,
        true
      );
      if (response.status === 1) {
        let {
          name,

          emailId,
          mobile,

          role,
          photo,
        } = response?.data;
        setFormData({
          ...formData,
          name,
          emailId,
          mobile,
          role,
          photo,
        });
        setImage(response?.data?.photo);
        setStatus(response?.data?.status);
      }
    } catch (error) {
      console.error(error);
    }
  };
  /******************* 
  @Purpose : Used for get users all details
  @Parameter : id
  @Author : INIC
  ******************/

  const breadcrumb = [
    {
      title: 'Vista Users',
      url: '/adminusers',
    },
    {
      title: 'View User',
    },
  ];
  const imageValidator = (str) => {
    let isImageValid = false;
    let checkPng = str.indexOf('.png');
    let checkJpeg = str.indexOf('.jpeg');
    let checkJpg = str.indexOf('.jpg');
    let checkWep = str.indexOf('.webp');
    if (
      checkPng !== -1 ||
      checkJpeg !== -1 ||
      checkJpg !== -1 ||
      checkWep !== -1
    ) {
      return (isImageValid = true);
    } else {
      return (isImageValid = false);
    }
  };
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Vista Users">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="card mb-5">
              <div className="card-header">
                <h5 className="card-title">User Information</h5>
              </div>
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-xxl-2 col-xl-2 col-lg-3 mb-5 mb-lg-0">
                      <div className="user-image d-block mx-auto">
                        <img
                          src={
                            formData?.photo && imageValidator(formData?.photo)
                              ? formData?.photo
                              : '/assets/images/no-user.png'
                          }
                        />
                      </div>
                    </div>
                    <div className="col-xxl-5 col-xl-4 col-lg-9 user-title-info user-details">
                      <div className="form-group mb-3">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-md-6">
                            <span className="text-capitalize fw-bold request-title">
                              NAME
                            </span>
                          </div>
                          <div className="col-xl-8 col-lg-6 col-md-6">
                            <span className="request-data">
                              {formData?.name}
                            </span>
                            <span className="error-msg text-danger"></span>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-3">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-md-6">
                            <span className="text-capitalize fw-bold request-title">
                              EMAIL ID
                            </span>
                          </div>
                          <div className="col-xl-8 col-lg-6 col-md-6">
                            <span className="request-data">
                              {formData?.emailId}
                            </span>
                            <span className="error-msg text-danger"></span>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-3">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-md-6">
                            <span className="text-capitalize fw-bold request-title">
                              STATUS
                            </span>
                          </div>
                          <div className="col-xl-8 col-lg-6 col-md-6">
                            {/*  {/*   {/*  className={
                                formData?.status
                                  ? "badge text-white bg-secondary"
                                  : "badge text-white bg-secondary inactive-btn"
                              } */}
                            <span
                              className={
                                status
                                  ? 'request-data badge text-white bg-secondary'
                                  : 'request-data badge text-white bg-secondary inactive-btn'
                              }
                            >
                              {status ? 'Active' : 'Inactive'}
                            </span>
                            <span className="text-danger error-msg"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-5 col-xl-6 offset-xl-0 offset-lg-3 col-lg-9 mt--15 user-title-info user-details">
                      <div className="form-group mb-3">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-md-6">
                            <span
                              className="text-capitalize fw-bold request-title"
                              htmlFor="Name"
                            >
                              PHONE NUMBER
                            </span>
                          </div>
                          <div className="col-xl-8 col-lg-6 col-md-6">
                            <span className="request-data">
                              {formData?.mobile}
                            </span>
                            <span
                              className="text-danger error-msg"
                              style={{ color: 'red' }}
                            ></span>
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-3">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-md-6">
                            <span className="text-capitalize fw-bold request-title">
                              ROLE
                            </span>
                          </div>
                          <div className="col-xl-8 col-lg-6 col-md-6">
                            <span className="request-data">
                              {formData?.role?.role}
                            </span>
                            <span className="text-danger error-msg"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  // UserAccess: state.admin.adminData.staticRolePermission.userAccess,
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi })(ViewAdminUser);
