import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Button, Modal, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import $ from "jquery";
import debounce from "lodash.debounce";
import swal from "sweetalert";
import Pagination from "rc-pagination";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { Layout } from "../../Components/Layout";
import { callApi } from "../../Api";
import Spinner from "../../Components/Loader/Spinner";
import {
  showMessageNotification,
  showModalNotification,
  validateCategory,
  validateSubCategory,
} from "../../Utils/Functions";
import API from "../../Api/Routes";

import "react-phone-input-2/lib/style.css";

$.DataTable = require("datatables.net");
require("datatables.net-responsive");

/******************* 
@Purpose : Used for master managements view
@Parameter : {}
@Author : INIC
******************/
function MasterManagement() {
  const history = useHistory();

  const [show, setShow] = useState(false);

  const [spin, setSpin] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [editCategoryData, setEditCategoryData] = useState(null);
  const [errors, setErrors] = useState({});
  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);

  const [photo] = useState(true);

  const [subCategoryRow, setSubCategoryRow] = useState([]);

  const total = 1;
  const [eventKeys] = useState("country");

  const [searchText, setSearchtext] = useState("");
  const [loading, setLoading] = useState(false);
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const [listSpin, setListSpin] = useState(false);
  const [category, setCategory] = useState("");
  const [show1, setShow1] = useState(false);

  const [downloadSelect, setDownloadSelect] = useState("");
  const [editSubCategoryId, setEditSubCategoryId] = useState(null);

  const [categoryImg, setCategoryImg] = useState("");
  const [catSpin, setCatSpin] = useState(false);
  const [subCatAddSpin, setSubCatAddSpin] = useState(false);
  /******************* 
  @Purpose : Used for download view
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleDownload = async (e) => {
    setDownloadSelect(e);
    let requiredDownload = e.value;
    if (requiredDownload === "Excel") {
      try {
        const response = await callApi(
          API.DOWNLOAD_EXCEL,
          "",
          "get",
          "DOWNLOAD_EXCEL",
          true,
          true,
          false
        );

        if (response.status === 1) {
          window.open(response.data);
          showMessageNotification("Downloaded file successfully", "success");
        } else if (response.status === 0) {
          showMessageNotification("Something went wrong", "error");
        }
      } catch (error) {
        console.error();
      }
    }
    if (requiredDownload === "CSV") {
      try {
        const response = await callApi(
          API.DOWNLOAD_CSV,
          "",
          "get",
          "DOWNLOAD_CSV",
          true,
          true,
          false
        );

        if (response.status === 1) {
          window.open(response.data);
          showMessageNotification("Downloaded file successfully", "success");
        } else if (response.status === 0) {
          showMessageNotification("Something went wrong", "error");
        }
      } catch (error) {
        console.error();
      }
    }
  };

  /******************* 
  @Purpose : Used for download options
  @Parameter : props
  @Author : INIC
  ******************/
  const options = [
    { value: "Excel", label: "Excel" },
    { value: "CSV", label: "CSV" },
  ];

  /******************* 
  @Purpose : Used for add sub category
  @Parameter : {event}
  @Author : INIC
  ******************/
  const addSubCategories = (event) => {
    event.preventDefault();

    const item = {
      subCategorytitle: "",
      image: "",
      new: true,
      _id: "new",
    };
    setSubCategoryRow([...subCategoryRow, item]);
    setEditSubCategoryId("new");
  };

  /******************* 
  @Purpose : Used for onChange for the row view
  @Parameter : {e, idx, type}
  @Author : INIC
  ******************/
  const handleRowChange = (e, idx, type) => {
    const rows = [...subCategoryRow];
    if (type === "file") {
      rows[idx] = {
        ...rows[idx],
        ["previewImage"]: URL.createObjectURL(e.target.files[0]),
        ["image"]: e.target.files[0],
      };
    } else {
      const { name, value } = e.target;
      rows[idx] = {
        ...rows[idx],
        [name]: value,
      };
    }
    setSubCategoryRow(rows);
  };

  /******************* 
  @Purpose : Used for get categories
  @Parameter : props
  @Author : INIC
  ******************/
  const getCategoryList = async () => {
    setListSpin(true);
    try {
      const response = await callApi(
        API.GET_CATEGORY,
        "",
        "get",
        "GET_CATEGORY",
        true,
        true,
        false
      );

      if (response.status === 1) {
        setListSpin(false);
        setCategoryList(response.data);
        setPage(1);
        setPagesize(10);
      } else if (response?.status === 0) {
        setListSpin(false);
        showModalNotification(response?.message, "error");
      }
    } catch (error) {
      console.error();
    }
  };
  useEffect(() => {
    getCategoryList();
  }, []);

  /******************* 
@Purpose : Used for upload image
@Parameter : {e}
@Author : INIC
******************/
  const handleUploadClick = (e) => {
    imageUploader.current.click();
  };
  const handleImageUpload = (e) => {
    try {
      const [file] = e.target.files;

      if (file) {
        let editObj = {
          ...editCategoryData,
          image: URL.createObjectURL(e.target.files[0]),
        };

        setEditCategoryData(editObj);
        setCategoryImg(e.target.files[0]);
      }
    } catch (error) {}
  };

  /******************* 
  @Purpose : Used for manage country
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleCloseCountry = () => {
    setShow(false);

    setErrors({});
  };

  /******************* 
  @Purpose : Used for close modal
  @Parameter : {}
  @Author : INIC
  ******************/
  const modelCloseFun = () => {
    handleCloseCountry();
    setEditCategoryData(null);
    setCategory("");
  };
  /******************* 
  @Purpose : Used for close modal for sub category
  @Parameter : {}
  @Author : INIC
  ******************/
  const modelCloseFun1 = () => {
    setShow1(false);
    setEditCategoryData(null);
    setSubCategoryRow([]);
    setEditSubCategoryId(null);
  };

  /******************* 
  @Purpose : Used for add the category
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleAddCountry = async (e) => {
    setShow(true);
  };

  /******************* 
  @Purpose : Used for sub category add
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleAddSubCategory = async (e) => {
    setShow1(true);
  };

  /******************* 
  @Purpose : Used for EditCategoryData
  @Parameter : {}
  @Author : INIC
  ******************/
  const modelOnAddBtn = (data) => {
    if (data) {
      setEditCategoryData(data);
      setCategory(data?.title);
      setCategoryImg(data?.image);
    } else {
      setCategory("");
    }
    if (eventKeys) {
      handleAddCountry();
    }
  };

  /******************* 
  @Purpose : Used for modal for sub category
  @Parameter : {}
  @Author : INIC
  ******************/
  const modelOnSubCategory = (data) => {
    if (data) {
      if (
        Array.isArray(data?.subCategoryDetails) &&
        data?.subCategoryDetails?.length > 0
      ) {
        setSubCategoryRow([data?.subCategoryDetails[0]]);
      }
      setEditCategoryData(data);
      setCategory(data?.title);
    }
    handleAddSubCategory();
    getSubCategoryDataList(data);
  };

  /******************* 
  @Purpose : Used for getsub category data
  @Parameter : {}
  @Author : INIC
  ******************/
  const getSubCategoryDataList = async (data) => {
    try {
      let body = {
        _id: data._id,
      };

      let response = await callApi(
        API.GET_SUB_CATEGORY,
        body,
        "post",
        "GET_SUB_CATEGORY_LIST",

        true,
        false,
        "",
        history
      );

      if (response.status === 1) {
        setSubCategoryRow(response?.data);
      }
    } catch (error) {
      console.error();
    }
  };

  /******************* 
  @Purpose : Used for edit category
  @Parameter : val
  @Author : INIC
  ******************/
  const editCategory = async (event) => {
    setSpin(true);
    const uploadCatResponse = await uploadFile(categoryImg);

    event.preventDefault();
    try {
      if (handleEditCatValidate()) return false;
      let url = categoryImg;
      if (typeof categoryImg === "object") {
        url = await uploadFile(categoryImg);
      } else {
        url = categoryImg;
      }
      const adminId = localStorage.getItem("id");
      let body = {
        title: category,
        _id: editCategoryData._id,
        image: uploadCatResponse?.imagePath,
        compressedImage: uploadCatResponse?.compressedImage,
        updatedBy: adminId,
      };
      let response = await callApi(
        API.EDIT_CATEGORY,
        body,
        "post",
        "EDIT_CATEGORY",
        true,
        false,
        "",
        history
      );
      if (response.status === 1) {
        setSpin(false);
        modelCloseFun();
        showMessageNotification(response.message, "success");

        getCategoryList();
        setEditCategoryData(null);
      } else {
        setSpin(false);
        showMessageNotification(response.message, "error");
      }
    } catch (error) {}
  };

  /******************* 
  @Purpose : Used for handle edit category validations
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleEditCatValidate = () => {
    let errorStatus = false;
    if (validateCategory(category) === false) {
      errorStatus = true;
      showModalNotification("Please enter the valid title ", "error");
    }
    if (!categoryImg) {
      errorStatus = true;
      showModalNotification("Please upload the Image ", "error");
    }
    return errorStatus;
  };
  /******************* 
  @Purpose : Used for sub category validate
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleEditSubCatValidate = (data) => {
    let errorStatus = false;
    if (!data?.title || validateSubCategory(data.title) === false) {
      errorStatus = true;
      showModalNotification("Enter the valid sub-category title ", "error");
    }
    if (!data.image) {
      errorStatus = true;
      showModalNotification("Please upload the Image ", "error");
    }
    return errorStatus;
  };

  /******************* 
  @Purpose : Used for pagination
  @Parameter : {page, pagesize}
  @Author : INIC
  ******************/
  function paginationChange(page, pagesize) {
    setPage(page);
    setPagesize(pagesize);
  }

  /******************* 
  @Purpose : Used for delete categories
  @Parameter : {id}
  @Author : INIC
  ******************/
  const deleteCategory = async (id) => {
    if (id) {
      swal({
        title: "Are you sure,You Want To Delete ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let body = {
            _id: id,
          };
          const response = await callApi(
            API.DELETE_CATEGORY,
            body,
            "delete",
            null,
            true,
            false
          );
          if (response.status === 1) {
            showMessageNotification("Deleted successfully", "success");
            getCategoryList();
          } else {
            showModalNotification(response.message, "error");
          }
        }
      });
    }
  };

  /******************* 
  @Purpose : Used for upload images
  @Parameter : {file}
  @Author : INIC
  ******************/
  const uploadFile = async (file) => {
    let formData2 = new FormData();
    formData2.append("image", file);
    let response;
    response = await callApi(
      API.IMAGES,
      formData2,
      "post",
      "IMAGES",
      null,
      true,
      "",
      history
    );

    if (response) {
      return response;
    }
  };

  /******************* 
  @Purpose : Used for image upload sub category
  @Parameter : {file}
  @Author : INIC
  ******************/
  const uploadSubFile = async (file) => {
    let formData2 = new FormData();
    formData2.append("image", file);
    let response;
    response = await callApi(
      API.SUB_CATEGORY_IMAGES,
      formData2,
      "post",
      "SUB_CATEGORY_IMAGES",
      null,
      true,
      "",
      history
    );

    if (response) {
      return response;
    }
  };

  /******************* 
  @Purpose : Used for add the sub category data
  @Parameter : {data}
  @Author : INIC
  ******************/
  const addSubCategoryData = async (data) => {
    setSubCatAddSpin(true);
    try {
      if (handleEditSubCatValidate(data)) return false;
      let url = data.image;
      let uploadResponse = {};
      if (typeof url === "object") {
        uploadResponse = await uploadSubFile(data.image);
        url = uploadResponse.imagePath;
      } else {
        url = data.image;
      }
      let body = {
        categoryId: editCategoryData._id,
        title: data.title,
        image: url,
        compressedImage: uploadResponse?.compressedImage,
        createdBy: localStorage.getItem("id"),
      };

      let response;

      response = await callApi(
        API.ADD_SUB_CATEGORY,
        body,
        "post",
        "ADD_SUB_CATEGORY",
        true,
        false,
        false,
        history
      );

      if (response.status === 1) {
        setSubCatAddSpin(false);
        modelCloseFun1();

        showModalNotification(response.message, "success");
        getCategoryList();
      } else {
        setSubCatAddSpin(false);
        showModalNotification(response.message, "error");
      }
    } catch (error) {}
  };

  /******************* 
  @Purpose : Used for image path for the category
  @Parameter : {}
  @Author : INIC
  ******************/
  const addUpdateFunction = async (e) => {
    setCatSpin(true);
    try {
      if (handleValidate()) return false;
      const uploadCatResponse = await uploadFile(categoryImg);

      let body = {
        title: category,
        image: uploadCatResponse?.imagePath,
        compressedImage: uploadCatResponse?.compressedImage,
      };
      let response;
      response = await callApi(
        API.ADD_CATEGORY,
        body,
        "post",
        "ADD_CATEGORY",
        true,
        false,
        true,
        history
      );

      if (response.status === 1) {
        setCatSpin(false);
        modelCloseFun();

        showModalNotification(response.message, "success");
        getCategoryList();
      } else {
        setCatSpin(false);
        showModalNotification(response.message, "error");
      }
    } catch (error) {
      console.error();
    }
  };

  /******************* 
  @Purpose : Used for search fields
  @Parameter : {evtKey}
  @Author : INIC
  ******************/
  const searchField = async (evtKey) => {
    try {
      if (evtKey) {
        let body = {
          searchText: evtKey,
        };

        const response = await callApi(
          API.SEARCH_CATEGORY,
          body,
          "post",
          "CATEGORY_SEARCH",
          true,
          false,
          true,
          history
        );
        setLoading(false);
        if (response.status === 1) {
          setCategoryList(response.data);
          setPage(1);
          setPagesize(10);
        } else if (response.status === 0) {
          showModalNotification(response.message, "error");
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  /******************* 
  @Purpose : Used for debounce for the search
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleDebouncedChange = React.useMemo(
    () => debounce((evt) => searchField(evt), 1000),
    []
  );

  /******************* 
  @Purpose : Used for search on change
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleSearchChange = (e) => {
    e.persist();
    if (e.target.value.length === 0) {
      clearInput();
    }
    setLoading(true);
    setSearchtext(e.target.value);
    handleDebouncedChange(e.target.value);
  };

  /******************* 
  @Purpose : Used for handle validate category
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleValidate = () => {
    let errorStatus = false;
    if (validateCategory(category) === false) {
      errorStatus = true;
      showModalNotification("Enter the valid title ", "error");
    }
    if (!categoryImg) {
      errorStatus = true;
      showModalNotification("Please upload  the valid Image ", "error");
    }
    return errorStatus;
  };

  /******************* 
  @Purpose : Used for delete sub category data
  @Parameter : {element}
  @Author : INIC
  ******************/
  const deleteSubCategoryData = async (element) => {
    if (element._id) {
      let body = {
        _id: element._id,
      };
      const response = await callApi(
        API.DELETE_SUB_CATEGORY_ROW,
        body,
        "delete",
        null,
        true,
        false
      );
      if (response.status === 1) {
        showMessageNotification(response.message, "success");
        modelCloseFun1();
        getCategoryList();
        getSubCategoryDataList();
      } else {
        showModalNotification(response.message, "error");
      }
    }
  };

  /******************* 
  @Purpose : Used for edit sub categories
  @Parameter : {id, data}
  @Author : INIC
  ******************/
  const handleEditSubCategoryId = (id, data) => {
    if (id === editSubCategoryId) {
      editSubCategoryData(data);
    } else {
      setEditSubCategoryId(id);
    }
  };

  /******************* 
  @Purpose : Used for EditCategoryData
  @Parameter : {element}
  @Author : INIC
  ******************/

  const editSubCategoryData = async (element) => {
    if (handleEditSubCatValidate(element)) return false;
    const uploadCatResponse1 = await uploadSubFile(element.image);
    let url = element.image;
    if (typeof element.image === "object") {
      url = await uploadSubFile(element.image);
    } else {
      url = element.image;
    }
    let body = {
      _id: element._id,
      image: uploadCatResponse1?.imagePath,
      compressedImage: uploadCatResponse1?.compressedImage,
      title: element.title,
      updatedBy: element.updatedBy,
    };

    const response = await callApi(
      API.EDIT_SUB_CATEGORY,
      body,
      "post",
      "EDIT_SUB_CATEGORY",
      true,
      false,
      true,
      "",
      history
    );
    if (response?.status === 1) {
      showModalNotification(response.message, "success");

      modelCloseFun1();
      getSubCategoryDataList();
      getCategoryList();
    } else {
      showModalNotification(response.message, "error");
    }
  };

  /******************* 
  @Purpose : Used for category status change with admin Id
  @Parameter : {_id}
  @Author : INIC
  ******************/
  const changeCategoryStatus = async (_id) => {
    let body = {
      _id: _id,
    };
    const response = await callApi(
      API.CATEGORY_CHANGE_STATUS,
      body,
      "post",
      "CATEGORY_CHANGE_STATUS",
      true,
      false,
      true,
      "",
      history
    );
    if (response?.status === 1) {
      showMessageNotification(response.message, "success");
      getCategoryList();
    } else {
      showMessageNotification(response.message, "error");
    }
  };

  /******************* 
  @Purpose : Used for clear all form field value
  @Parameter : {}
  @Author : INIC
  ******************/
  const clearInput = () => {
    setSearchtext("");
    setPage(1);
    getCategoryList();
  };

  /******************* 
  @Purpose : Used for breadcrum
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      key: "3",
      title: "Masters",
      url: "/Master",
    },
    {
      key: "31",
      title: "Category",
    },
  ];
  const showAddSubCategoryBtn = subCategoryRow?.filter((x) => x?.new)[0];

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Masters">
      <div className="main-content-area">
        <div className="main-content-block">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <div className="left-buttons d-flex ">
                <div className="d-flex align-items-center">
                  <div className="search position-relative has-icon-left">
                    <input
                      type="search"
                      value={searchText}
                      className="form-control text-capitalize"
                      placeholder="search"
                      onChange={(evt) => {
                        handleSearchChange(evt);
                      }}
                    />

                    {searchText && searchText.length > 0 ? (
                      <div className="clear">
                        <span onClick={clearInput}>
                          <em className="icon-close"></em>
                        </span>
                      </div>
                    ) : null}

                    <div className="form-control-position">
                      <em className="icon-magnifier"></em>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-buttons d-flex justify-content-end">
                <div className="form-group">
                  <Select
                    id="download"
                    name="download"
                    options={options}
                    placeholder="Download"
                    onChange={(e) => {
                      handleDownload(e);
                    }}
                    className="download-csv me-4"
                    value={downloadSelect}
                  />
                  <span className="text-danger error-msg"></span>
                </div>

                <div className="d-flex align-items-center">
                  <Button
                    type="button"
                    onClick={() => modelOnAddBtn()}
                    className="btn btn-primary glow-primary"
                  >
                    {"Add New Category"}
                  </Button>
                </div>
              </div>
            </div>

            <div className="card-body">
              {/* category modal starts  */}
              <div className="d-flex align-items-center justify-content-between mb-2 flex-wrap">
                <Modal
                  show={show}
                  onHide={modelCloseFun}
                  centered
                  className="border  md={{ span: 3, offset: 3 }}"
                >
                  <Modal.Header closeButton>
                    <div className="d-flex align-items-center">
                      <div className="icon mr-2"></div>
                      <h5 className="modal-title" id="exampleModalLongTitle">
                        {editCategoryData?._id
                          ? "Edit Category"
                          : "Add New Category"}
                      </h5>
                    </div>
                  </Modal.Header>
                  <Modal.Body closeButton>
                    <div className="notification-form">
                      <div className="form-group mb-4">
                        <label className="form-label" for="image">
                          {"Image (1340X640)"}
                          <sup className="text-danger">*</sup>
                        </label>

                        <div
                          className="edit-image w-100 text-center"
                          style={{ height: "155px" }}
                        >
                          {!editCategoryData?.image ? (
                            <div className=" col-md-12">
                              <div className="modal-img-wrapper">
                                <div className="icon-wrapper">
                                  <i
                                    className="icon-cloud-upload bx-lg"
                                    onClick={() => handleUploadClick()}
                                  ></i>
                                  <input
                                    type="file"
                                    accept="image/png, image/jpeg ,image/jpg,image/webp,image/svg"
                                    onChange={handleImageUpload}
                                    multiple={false}
                                    ref={imageUploader}
                                    style={{
                                      display: "none",
                                    }}
                                  />
                                </div>
                                <div className="img-content-wrapper">
                                  <span className="img-text-color">
                                    {" "}
                                    Drop files here or{" "}
                                  </span>
                                  <button
                                    className="img-change-color"
                                    onClick={() => handleUploadClick()}
                                    style={{
                                      border: "none",
                                      background: "transparent",
                                    }}
                                  >
                                    &nbsp; Click here
                                  </button>{" "}
                                </div>
                                <div className="img-file-text">
                                  Please note that JPEG,PNG,WEBP,SVG image file
                                  is allowed.
                                  <sup>*</sup>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <button
                                onClick={() => handleUploadClick()}
                                style={{
                                  border: "none",
                                  background: "transparent",
                                  display: "flex",
                                  textAlign: "left",
                                }}
                              >
                                <input
                                  type="file"
                                  accept="image/png, image/jpeg ,image/jpg,image/webp,image/svg"
                                  onChange={handleImageUpload}
                                  multiple={false}
                                  ref={imageUploader}
                                  style={{
                                    display: "none",
                                  }}
                                />

                                <div className="add-image-title">
                                  <img
                                    ref={uploadedImage}
                                    src={
                                      editCategoryData?.image ||
                                      "/assets/images/categoryImage.jpeg"
                                    }
                                    style={{
                                      display: "block",
                                      width: "324px",
                                      maxHeight: "155px",
                                    }}
                                    className="col-md-4"
                                  />
                                </div>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group mb-4">
                        <label className="mb-0" for="Category">
                          {"Category"}
                          <sup className="text-danger">*</sup>
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Category name here."
                          autoComplete="off"
                          onChange={(e) => {
                            setCategory(e.target.value);

                            setErrors({ ...errors, category: "" });
                          }}
                          name="category"
                          id="category"
                          value={category}
                        ></input>

                        <span className="text-danger error-msg">
                          {errors.category}
                        </span>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn btn-secondary me-2"
                      onClick={() => modelCloseFun()}
                    >
                      {"Close"}
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={(event) =>
                        editCategoryData?._id
                          ? editCategory(event)
                          : addUpdateFunction(event)
                      }
                    >
                      {editCategoryData?._id ? "Update" : "Add"}
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
              {/* category ends */}
              {/* sub category modal starts */}
              <div className="d-flex align-items-center justify-content-between mb-2 flex-wrap">
                <Modal
                  show={show1}
                  size="lg"
                  centered
                  onHide={modelCloseFun1}
                  className="border  md={{ span: 3, offset: 3 }}"
                >
                  <Modal.Header closeButton>
                    <div className="d-flex align-items-center">
                      <div className="icon mr-2"></div>
                      <h5 className="modal-title" id="exampleModalLongTitle">
                        Add/Edit Sub-Category sub data
                      </h5>
                    </div>
                  </Modal.Header>
                  <Modal.Body closeButton className="pb-0">
                    <div className="notification-form">
                      <div className="form-group mb-4">
                        <label className="mb-0" for="sub-category-img">
                          {"Image"}
                          <sup className="text-danger">*</sup>
                        </label>

                        <div
                          className="edit-image w-100 text-center"
                          style={{ height: "155px" }}
                        >
                          {!editCategoryData?.image ? (
                            <div className=" col text-center"></div>
                          ) : (
                            <>
                              {" "}
                              <div>
                                <div>
                                  <img
                                    src={editCategoryData?.image}
                                    style={{
                                      display: "block",
                                      width: "324px",
                                      maxHeight: "155px",
                                    }}
                                    disabled
                                    className="col-md-4"
                                  />
                                </div>
                              </div>
                              <div></div>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="form-group mb-4">
                        <label className="mb-0" for="CountryName">
                          {"Category"}
                          <sup className="text-danger">*</sup>
                        </label>

                        <div className="col-md-12 pt-2">
                          <input
                            type="text"
                            className="col-md-12 form-control"
                            placeholder="Enter sub-Category name here."
                            style={{ height: "30px" }}
                            name="sub-category"
                            id="category"
                            autoComplete="off"
                            defaultValue={category}
                            disabled
                          ></input>
                        </div>
                        <span className="error-msg" style={{ color: "red" }}>
                          {errors.category}
                        </span>
                      </div>

                      {/* sub category */}

                      {/* get the sub category list */}
                      <div className="subcategory-list">
                        {Array.isArray(subCategoryRow) &&
                          subCategoryRow?.map((element, idx) => {
                            const isIdExists =
                              editSubCategoryId === element._id;
                            return (
                              <div
                                className="row subcategory-selection-modal my-4"
                                key={element?._id}
                              >
                                <label className="form-label" for="image">
                                  {"Subcategory title"}
                                  <sup className="text-danger">*</sup>
                                </label>
                                <div className="col-lg-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder="Enter sub-Category name here."
                                    onChange={(e) =>
                                      handleRowChange(e, idx, "text")
                                    }
                                    name="title"
                                    id={idx + "subCategory"}
                                    value={element?.title || ""}
                                    disabled={editSubCategoryId !== element._id}
                                  ></input>
                                </div>
                                {/* subcategory image */}

                                <div
                                  className="col-lg-4 upload-img-wrap"
                                  style={{ marginTop: "-25px" }}
                                >
                                  <div className="">
                                    <label
                                      htmlFor={"file-input" + idx}
                                      className="form-label"
                                    >
                                      {"Upload Image"}
                                      <sup className="text-danger">*</sup>
                                    </label>
                                    <label
                                      htmlFor={"file-input" + idx}
                                      className="file-input-sub-category"
                                    >
                                      <img
                                        src={
                                          element.image !== "" &&
                                          typeof element.image === "string"
                                            ? element?.image
                                            : element.previewImage
                                        }
                                        className="img-fluid img-rounded"
                                        style={{
                                          display: "block",
                                          width: "50px",
                                          height: "50px",
                                          color: "var(--input-color)",
                                        }}
                                        // alt="upload"
                                      />
                                    </label>

                                    {isIdExists && (
                                      <input
                                        id={"file-input" + idx}
                                        // name={'image'}
                                        type="file"
                                        accept="image/png, image/jpeg ,image/jpg,image/webp,image/svg"
                                        style={{ display: "none" }}
                                        onChange={(e) =>
                                          handleRowChange(e, idx, "file")
                                        }
                                      />
                                    )}
                                  </div>
                                </div>

                                {/* icons */}
                                <div
                                  className="col-lg-2 d-flex justify-content-end align-self-center"
                                  style={{ marginTop: "-22px" }}
                                  onClick={() =>
                                    handleEditSubCategoryId(
                                      element._id,
                                      element
                                    )
                                  }
                                >
                                  {editSubCategoryId === element._id ? (
                                    element.new ? (
                                      <div
                                        className="edit-wrapper"
                                        style={{ visibility: "hidden" }}
                                      >
                                        no
                                      </div>
                                    ) : (
                                      <i className="bx bx-save me-3"></i>
                                    )
                                  ) : (
                                    <i className="icon-note me-3"></i>
                                  )}
                                  {element.new ? (
                                    <div
                                      className="delete-wrapper"
                                      style={{ visibility: "hidden" }}
                                    >
                                      none
                                    </div>
                                  ) : (
                                    <div
                                      className="delete-wrapper"
                                      onClick={() =>
                                        deleteSubCategoryData(element)
                                      }
                                    >
                                      <i className="icon-trash me-3"></i>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div className="row">
                        <div className="col-md-5  d-flex align-items-center mb-3">
                          <div className="icon-wrapper"></div>

                          {!showAddSubCategoryBtn ? (
                            <button
                              className="btn btn-primary"
                              onClick={addSubCategories}
                            >
                              {"Add Sub-Category"}
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                addSubCategoryData(showAddSubCategoryBtn)
                              }
                            >
                              {subCatAddSpin ? (
                                <i className="bx bx-loader-alt">Save...</i>
                              ) : (
                                "Save"
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              {/* sub category modal ends */}
              <div className=" notification-card tabs-block p-0">
                <div className="table-responsive">
                  <table
                    className="table row-border nowrap common-datatable"
                    id="master-country-listing"
                  >
                    <thead>
                      <tr>
                        {photo ? (
                          <th>
                            <b>Category</b>
                          </th>
                        ) : null}

                        <th>
                          <b>Sub-Category</b>
                        </th>

                        <th>
                          <b>Last Modified Date & Time</b>
                        </th>

                        <th>
                          <b>Status</b>
                        </th>

                        <th>
                          <b>Action</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoryList && categoryList?.length === 0 ? (
                        <tr className="text-center text-danger not-found-txt">
                          <td colSpan="6">
                            {listSpin ? (
                              <div className="d-flex align-center justify-content-center">
                                <Spinner />
                              </div>
                            ) : null}{" "}
                            {!loading ? (
                              <h6
                                className="text-center text-danger not-found-txt"
                                colSpan="6"
                              ></h6>
                            ) : null}
                          </td>
                        </tr>
                      ) : (
                        categoryList &&
                        Array.isArray(categoryList) &&
                        categoryList?.map((list, key) => {
                          const subCategoryTitle =
                            list?.subCategoryDetails &&
                            list.subCategoryDetails?.length > 0 &&
                            list?.subCategoryDetails
                              ?.map((item) => item.title)
                              ?.join(", ");

                          return (
                            <tr key={list?._id}>
                              <td>
                                <div className="eamil-col d-flex align-items-center">
                                  <span>{list.title}</span>
                                </div>
                              </td>

                              <td>
                                <div
                                  className="email-col d-flex align-items-center"
                                  style={{ wordBreak: "break-all" }}
                                >
                                  <div
                                    className="text-break"
                                    style={{
                                      maxWidth: "200px",

                                      whiteSpace: "normal",
                                    }}
                                  >
                                    {subCategoryTitle}
                                  </div>
                                </div>
                              </td>

                              {list.updatedAt ? (
                                <td>
                                  <div className="eamil-col d-flex align-items-center">
                                    <span>
                                      {moment(list?.updatedAt).format(
                                        "l, h:mm:ss a"
                                      )}
                                    </span>
                                  </div>
                                </td>
                              ) : (
                                <td>
                                  <div className="email-col d-flex align-items-center">
                                    <span></span>
                                  </div>
                                </td>
                              )}

                              <td>
                                <div className="custom-control custom-switch light">
                                  <Form.Check
                                    type="switch"
                                    onChange={() => {
                                      list.status = !list.status;

                                      changeCategoryStatus(list._id);
                                    }}
                                    checked={list.status}
                                    id={list._id}
                                  />

                                  <label
                                    className="custom-control-label"
                                    for={list._id}
                                  ></label>
                                </div>
                              </td>

                              <td>
                                <div className="d-flex">
                                  <OverlayTrigger
                                    placement="left"
                                    overlay={
                                      <Tooltip id="tooltip1">
                                        Add SubCategory
                                      </Tooltip>
                                    }
                                  >
                                    <a
                                      className="cursor-pointer m-1"
                                      onClick={() => modelOnSubCategory(list)}
                                    >
                                      <i className="icon-plus"></i>
                                    </a>
                                  </OverlayTrigger>

                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="edit">Edit</Tooltip>}
                                  >
                                    <a
                                      className="cursor-pointer m-1"
                                      onClick={() => modelOnAddBtn(list)}
                                    >
                                      <i className="icon-note"></i>
                                    </a>
                                  </OverlayTrigger>

                                  <OverlayTrigger
                                    placement="right"
                                    overlay={
                                      <Tooltip id="delete">Delete</Tooltip>
                                    }
                                  >
                                    <a
                                      className="cursor-pointer m-1"
                                      onClick={() => deleteCategory(list._id)}
                                    >
                                      <i className="icon-trash"></i>
                                    </a>
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>

                {categoryList && categoryList.length < 10 ? (
                  <div>
                    <div className="table-footer mt-2 mt-sm-3 d-flex align-items-center flex-wrap justify-content-end">
                      <div className="m-2">
                        <label className="mr-2">Showing</label>{" "}
                        <b className="me-1">{categoryList.length}</b>
                        <label className="ml-2">
                          Out of <b>{categoryList.length}</b> Categories
                        </label>
                      </div>
                      <div className="pagination-list m-2">
                        <Pagination
                          className="ant-pagination"
                          pageSize={pagesize}
                          current={page}
                          total={total}
                          onChange={paginationChange}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="table-footer mt-2 mt-sm-3 d-flex align-items-center flex-wrap justify-content-end">
                    <div className="m-2">
                      <label className="mr-2">Showing</label>{" "}
                      <b className="me-1">10</b>
                      <label className="ml-2">
                        Out of <b>{total}</b> Categories
                      </label>
                    </div>
                    <div className="pagination-list m-2">
                      <Pagination
                        className="ant-pagination"
                        pageSize={pagesize}
                        current={page}
                        total={total}
                        onChange={paginationChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="footer-text d-flex align-items-centerf justify-content-between">
            <span className="">2023 © Vista Admin Panel</span>
            <span>All Rights Reserved</span>
          </div>
        </footer>
      </div>
    </Layout>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  language: state.admin.language,
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi })(MasterManagement);
