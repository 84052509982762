import { callApi } from "../../Api";
import { showMessageNotification, removeItem } from "../../Utils/Functions";
import Routes from "../../Api/Routes"; // API endpoint routes
const {
  DOWNLOAD_REPORTS_VIDEO_VIEWS,
  REPORTS_VIDEO_VIEWS,
  PAYMENT_LIST,
  DOWNLOAD_PAYMENT_LIST,
  PAYOUT_VIDEO_VIEWS,
  LOG_OUT,
  LOGIN,
  DOWNLOAD_PAYOUT,
  PAYOUT_LIST,
  DOWNLOAD_ADMIN,
  ADMIN_LIST,
  UPDATE_EMAIL_TEMPLATE,
  EMAIL_LIST,
  GET_ALL_PERMISSSIONS,
  SEARCH_CUSTOMERS,
  LIST_ADMIN_ROLE,
  REJECTED_LIST,
  DOWNLOAD_REJECTED,
  SELECT_PLAN,
  DOWNLOAD_CUSTOMERS,
  UPDATE_CMS,
  GET_VIDEOS,
  HIDE_VIDEO,
  CELEBRITY_REQUESTS,
  VIDEO_INFO,
  REPORT_VIDEOS,
  REPORTED_COMMENTS_CARD_DATA,
  VIDEO_REPORTERS,
  SEARCH_REPORTERS,
  COMMENTS_VIDEOS,
  SEARCH_COMMENTS_VIDEOS,
  ALL_REPORTED_COMMENTS,
  HIDE_COMMENTS,
  DOWNLOAD_MANAGE_VIDS,
  DOWNLOAD_WITH_VIDEO_REPORTS,
  DOWNLOAD_VIDEO_REPORTER_LIST,
  APPROVE_CELEBRITY,
  GET_REQUEST_HISTORY,
  REJECT_CELEBRITY,
  REPORTED_VIDS_CARD_DATA,
  ALL_CELEBRITIES,
  DOWNLOAD_CELEBREQUESTS,
  DOWNLOAD_CELEB,
  CMS_LISTING,
  REJECT_PAYMENT,
  CELEB_DROPDOWN,
  CARDS_AND_HIGH_VIEW_VIDEOS,
  MONTHWISE_VID_COUNT,
  SUBSCRIPTION_DATA,
  PRODUCT_VIEWS,
  DOWNLOAD_REPORTS_PRODUCTCLICKS,
  REPORTS_PRODUCT_VIEWS,
  DOWNLOAD_PURCHASE_VIDS,
  REPORTS_VIDEO_PURCHASE,
  PAYOUT_VID_DATA,
} = Routes;
// GET_VIDEOS + `?pageNumber=${data.page}&pageSize=${data.pagesize}`
export const getVideos = (data) => async (dispatch) => {
  const res = await callApi(GET_VIDEOS, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setVideoData(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};

export const getSearchVideos = (data) => async (dispatch) => {
  const res = await callApi(GET_VIDEOS, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setSearchVideoData(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
// get reported search vids
export const getReportedSearchVideos = (data) => async (dispatch) => {
  const res = await callApi(REPORT_VIDEOS, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setReportedSearchVideoData(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
// video INFO change
export const videoStatusChange = (data) => async (dispatch) => {
  const res = await callApi(
    VIDEO_INFO + `?videoId=${data.videoId}`,
    "",
    "get",
    "",
    true
  );
  if (res) {
    if (res.status) {
      dispatch(setvideoStatus(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
// hide video
export const videoHide = (data) => async (dispatch) => {
  const res = await callApi(`${HIDE_VIDEO}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setVideoHide(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
// Celebrity requests
export const celebrityRequests = (data) => async (dispatch) => {
  const res = await callApi(`${CELEBRITY_REQUESTS}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setCelebrityRequests(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
//reported vids
export const reportedVideos = (data) => async (dispatch) => {
  const res = await callApi(`${REPORT_VIDEOS}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setReportVideo(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
// comments vids
export const commentedVideos = (data) => async (dispatch) => {
  const res = await callApi(`${COMMENTS_VIDEOS}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setCommentVideos(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
// VIDEO REPORTERS
export const videoReporters = (data) => async (dispatch) => {
  const res = await callApi(`${VIDEO_REPORTERS}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setVideoReporters(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
// SEARCH COMMENTS VIDS SEARCH_REPORTERS
export const videoSearchReporters = (data) => async (dispatch) => {
  const res = await callApi(`${SEARCH_REPORTERS}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setReportedSearchVideoData(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};

// reported comments vids search
export const videoSearchReportersVideos = (data) => async (dispatch) => {
  const res = await callApi(
    `${SEARCH_COMMENTS_VIDEOS}`,
    data,
    "post",
    "",
    true
  );
  if (res) {
    if (res.status) {
      dispatch(setSearchCommentVideos(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};

// reported comments list
export const commentedLists = (data) => async (dispatch) => {
  const res = await callApi(`${ALL_REPORTED_COMMENTS}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setCommentsList(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
// REPORTED VIDS SEARCH
export const reportersSearch = (data) => async (dispatch) => {
  const res = await callApi(`${SEARCH_REPORTERS}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setReportersSearch(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};

export const commentsHide = (data) => async (dispatch) => {
  const res = await callApi(`${HIDE_COMMENTS}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setHideComments(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const downloadManageVids = (data) => async (dispatch) => {
  const res = await callApi(`${DOWNLOAD_MANAGE_VIDS}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setDownloadManageVids(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const downloadReportVideosFormat = (data) => async (dispatch) => {
  const res = await callApi(
    `${DOWNLOAD_WITH_VIDEO_REPORTS}`,
    data,
    "post",
    "",
    true
  );
  if (res) {
    if (res.status) {
      dispatch(setreportDownloadVideosList(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const downloadReporters = (data) => async (dispatch) => {
  const res = await callApi(
    `${DOWNLOAD_VIDEO_REPORTER_LIST}`,
    data,
    "post",
    "",
    true
  );
  if (res) {
    if (res.status) {
      //
      dispatch(setVideoReporterList(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const approveCelebrity = (data) => async (dispatch) => {
  const res = await callApi(`${APPROVE_CELEBRITY}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setApproveCelebrity(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const requestHistory = (data) => async (dispatch) => {
  const res = await callApi(`${GET_REQUEST_HISTORY}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setRequestHistory(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
// reject the celebrity
export const rejectRequest = (data) => async (dispatch) => {
  const res = await callApi(`${REJECT_CELEBRITY}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setRejectRequest(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
// reported video card data
export const reportedCardData = (data) => async (dispatch) => {
  const res = await callApi(REPORTED_VIDS_CARD_DATA, "", "get", "", true);
  if (res) {
    if (res.status) {
      dispatch(setReportedVidsCard(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const reportedCommentsCardData = (data) => async (dispatch) => {
  const res = await callApi(REPORTED_COMMENTS_CARD_DATA, "", "get", "", true);
  if (res) {
    if (res.status) {
      dispatch(setReportedCommentsCard(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const becameCelebrity = (data) => async (dispatch) => {
  const res = await callApi(`${ALL_CELEBRITIES}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setapprovedCelebrities(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const downloadRequests = (data) => async (dispatch) => {
  const res = await callApi(
    `${DOWNLOAD_CELEBREQUESTS}`,
    data,
    "post",
    "",
    true
  );
  if (res) {
    if (res.status) {
      dispatch(downloadCelebReq(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const downloadCelebrities = (data) => async (dispatch) => {
  const res = await callApi(`${DOWNLOAD_CELEB}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(downloadCeleb(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const celebList = (data) => async (dispatch) => {
  const res = await callApi(`${ALL_CELEBRITIES}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setAllCeleb(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};

export const cmsListing = (data) => async (dispatch) => {
  const res = await callApi(CMS_LISTING, "", "get", "", true);
  if (res) {
    if (res.status) {
      dispatch(setCmsList(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const updateCms = (data) => async (dispatch) => {
  const res = await callApi(`${UPDATE_CMS}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setCmsUpdate(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};

export const downloadCustomersList = (data) => async (dispatch) => {
  const res = await callApi(`${DOWNLOAD_CUSTOMERS}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setCustomerDownload(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};

export const searchCustomersList = (data) => async (dispatch) => {
  const res = await callApi(`${SEARCH_CUSTOMERS}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setSearchCustomers(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const setRejectHistory = (data) => async (dispatch) => {
  const res = await callApi(`${REJECTED_LIST}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setRejectedList(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};

export const downloadRejected = (data) => async (dispatch) => {
  const res = await callApi(`${DOWNLOAD_REJECTED}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setRejectDownload(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const vistaRoles = (data) => async (dispatch) => {
  const res = await callApi(`${LIST_ADMIN_ROLE}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setVistaRolesList(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
// get permissions
export const vistaPermissions = (data) => async (dispatch) => {
  const res = await callApi(GET_ALL_PERMISSSIONS, "", "get", "", true);
  if (res) {
    if (res.status) {
      dispatch(getPermissions(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const getEmailTemplatesList = (data) => async (dispatch) => {
  const res = await callApi(EMAIL_LIST, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(getEmailTemplates(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
// email templates updates
export const updateTheEmailTemplates = (data) => async (dispatch) => {
  const res = await callApi(UPDATE_EMAIL_TEMPLATE, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(updateEmailTemplates(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
// customers list
export const getTheUsersList = (data) => async (dispatch) => {
  const res = await callApi(SEARCH_CUSTOMERS, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(searchUsersList(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
// admin List
export const adminListing = (data) => async (dispatch) => {
  const res = await callApi(ADMIN_LIST, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(adminUsersList(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const downloadUsersList = (data) => async (dispatch) => {
  const res = await callApi(DOWNLOAD_ADMIN, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(downloadAdminUsers(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const payoutRequestsView = (data) => async (dispatch) => {
  const res = await callApi(PAYOUT_LIST, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(payoutRequests(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const paymentFilterVideos = (data) => async (dispatch) => {
  const res = await callApi(PAYOUT_VIDEO_VIEWS, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setPaymentFilter(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const downloadViewPayout = (data) => async (dispatch) => {
  const res = await callApi(DOWNLOAD_PAYOUT, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setDownload(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const rejectPayoutRequests = (data) => async (dispatch) => {
  const res = await callApi(REJECT_PAYMENT, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setPaymentReject(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const downloadReportsPayment = (data) => async (dispatch) => {
  const res = await callApi(DOWNLOAD_PAYMENT_LIST, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setDownloadPayouts(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const searchPaymentsList = (data) => async (dispatch) => {
  const res = await callApi(PAYMENT_LIST, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setReportsPaymentList(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const reportsVideoViews = (data) => async (dispatch) => {
  const res = await callApi(REPORTS_VIDEO_VIEWS, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setReportsVideoViews(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};

export const setDownloadReportedViews = (data) => async (dispatch) => {
  const res = await callApi(
    DOWNLOAD_REPORTS_VIDEO_VIEWS,
    data,
    "post",
    "",
    true
  );
  if (res) {
    if (res.status) {
      dispatch(downloadVideoReports(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const getCelebDropdown = (data) => async (dispatch) => {
  const res = await callApi(`${CELEB_DROPDOWN}`, data, "post", "", true);
  if (res) {
    await dispatch(setCelebrities(res?.data));
    return Promise.resolve(res);
  } else return Promise.reject(res);
};
// FILTER REPORTS
export const reportsFilterProdClicks = (data) => async (dispatch) => {
  const res = await callApi(`${REPORTS_PRODUCT_VIEWS}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setProductClicksReports(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
/*******************
@purpose :  used fo user login
@Parameter : {data}
@Author : INIC
******************/
export const login = (data) => async (dispatch) => {
  const res = await callApi(LOGIN, data, "post", "BRAND_LOGIN");
  if (res) {
    if (res?.status) {
      dispatch(setLoginData(res.data));
      showMessageNotification(res.message, "success");
    }
    return res;
  }
  return Promise.reject(res);
};
/*******************
@purpose :  used for user logout
@Parameter : {}
@Author : INIC
******************/
export const logout = () => async (dispatch) => {
  removeItem("accessToken");
  removeItem("emailid");
  localStorage.clear();
  dispatch(removeLoginData());
  callApi(LOG_OUT, "", "", "LOGOUT", true);

  showMessageNotification("Logged Out Successfully", "error");
};

/*******************
@purpose :  set user profile
@Parameter : {data}
@Author : INIC
******************/
export const removeLoginData = () => ({
  type: "REMOVE_LOGIN_DATA",
});
// dashboard apis
export const setDashboardData = (data) => async (dispatch) => {
  const res = await callApi(CARDS_AND_HIGH_VIEW_VIDEOS, "", "get", "", true);
  if (res) {
    if (res.status) {
      dispatch(topHighestViewedVideos(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
export const getSubscriptionData = (data) => async (dispatch) => {
  const res = await callApi(SUBSCRIPTION_DATA, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setSubscriptionData(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
//
export const monthwiseVideosData = (data) => async (dispatch) => {
  const res = await callApi(MONTHWISE_VID_COUNT, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setMonthwiseVidsViewData(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
// PAYMENT PRODUCT CLICKS
export const productClickForPayment = (data) => async (dispatch) => {
  const res = await callApi(PRODUCT_VIEWS, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setPaymentProductClicks(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
//
export const productReportsClicks = (data) => async (dispatch) => {
  const res = await callApi(
    DOWNLOAD_REPORTS_PRODUCTCLICKS,
    data,
    "post",
    "",
    true
  );
  if (res) {
    if (res.status) {
      dispatch(setReportsProductClicks(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
// vidsReportsDownload
export const vidsReportsDownload = (data) => async (dispatch) => {
  const res = await callApi(DOWNLOAD_PURCHASE_VIDS, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setVideoReportsDownload(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
// setSearchVids
export const getSearchResultsVids = (data) => async (dispatch) => {
  const res = await callApi(REPORTS_VIDEO_PURCHASE, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setSearchVids(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
// video purchase data
export const getPayoutVidPurchaseData = (data) => async (dispatch) => {
  const res = await callApi(PAYOUT_VID_DATA, data, "post", "", true);
  if (res) {
    if (res.status) {
      dispatch(setVidPurchaseData(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};
/*******************
@purpose :  set user profile
@Parameter : {data}
@Author : INIC
******************/
export const setLoginData = (data) => ({
  type: "SET_LOGIN_DATA",
  payload: data,
});

export const setVideoData = (data) => ({
  type: "GET_VIDEOS",
  payload: data,
});
export const setSearchVideoData = (data) => ({
  type: "GET_SEARCH_VIDEOS",
  payload: data,
});
export const setvideoStatus = (data) => ({
  type: "CHANGE_VIDEO_STATUS",
  payload: data,
});
export const setCelebrityRequests = (data) => ({
  type: "CELEBRITY_REQUESTS",
  payload: data,
});
export const setVideoHide = (data) => ({
  type: "HIDE_VIDEO",
  payload: data,
});
export const setReportVideo = (data) => ({
  type: "REPORTED_VIDEO",
  payload: data,
});
export const setReportedSearchVideoData = (data) => ({
  type: "REPORTED_SEARCH",
  payload: data,
});
export const setVideoReporters = (data) => ({
  type: "VIDEO_REPORTERS",
  payload: data,
});
export const setReportersSearch = (data) => ({
  type: "VIDEO_REPORTERS_SEARCH",
  payload: data,
});
export const setCommentVideos = (data) => ({
  type: "COMMENT_VIDS_LIST",
  payload: data,
});
export const setSearchCommentVideos = (data) => ({
  type: "COMMENT_SEARCH_VIDS_LIST",
  payload: data,
});
export const setCommentsList = (data) => ({
  type: "COMMENT_LIST",
  payload: data,
});
export const setHideComments = (data) => ({
  type: "HIDE_COMMENT",
  payload: data,
});
export const setDownloadManageVids = (data) => ({
  type: "DOWNLOAD_VIDS",
  payload: data,
});
export const setreportDownloadVideosList = (data) => ({
  type: "DOWNLOAD_REPORT_VIDS",
  payload: data,
});
export const setVideoReporterList = (data) => ({
  type: "DOWNLOAD_REPORT_VIDEO_LIST",
  payload: data,
});
export const setApproveCelebrity = (data) => ({
  type: "CELEBRITY_APPROVE",
  payload: data,
});
export const setRequestHistory = (data) => ({
  type: "CELEBRITY_REQ_HISTORY",
  payload: data,
});
export const setRejectRequest = (data) => ({
  type: "CELEBRITY_REJECT",
  payload: data,
});
export const setUserProfileData = (data) => ({
  type: "PROFILE_DATA",
  payload: data,
});

export const setReportedVidsCard = (data) => ({
  type: "REPORTED_VID_CARDS",
  payload: data,
});
export const setReportedCommentsCard = (data) => ({
  type: "REPORTED_COMMENTS_CARDS",
  payload: data,
});
export const setapprovedCelebrities = (data) => ({
  type: "CELEBRITIES",
  payload: data,
});
export const downloadCelebReq = (data) => ({
  type: "CELEBRITIES_DOWNLOAD",
  payload: data,
});
export const downloadCeleb = (data) => ({
  type: "CELEB_DOWNLOAD",
  payload: data,
});
export const viewCeleb = (data) => ({
  type: "CELEB_VIEW",
  payload: data,
});
export const setAllCeleb = (data) => ({
  type: "ALL_CELEB",
  payload: data,
});
export const setCmsList = (data) => ({
  type: "ALL_CMS",
  payload: data,
});
export const setCmsUpdate = (data) => ({
  type: "UPDATE_CMS",
  payload: data,
});
export const setCustomerDownload = (data) => ({
  type: "DOWNLOAD_CUSTOMERS",
  payload: data,
});
export const setSearchCustomers = (data) => ({
  type: "SEARCH_CUSTOMERS",
  payload: data,
});
export const setRejectedList = (data) => ({
  type: "REJECTED_LISTS",
  payload: data,
});
export const setRejectDownload = (data) => ({
  type: "DOWNLOAD_REJECTED_LISTS",
  payload: data,
});
export const setVistaRolesList = (data) => ({
  type: "ROLES_LISTS",
  payload: data,
});
export const getPermissions = (data) => ({
  type: "PERMS_LISTS",
  payload: data,
});
export const getEmailTemplates = (data) => ({
  type: "TEMP_LISTS",
  payload: data,
});
export const updateEmailTemplates = (data) => ({
  type: "TEMP_UPDATE",
  payload: data,
});
export const searchUsersList = (data) => ({
  type: "USERS",
  payload: data,
});
export const adminUsersList = (data) => ({
  type: "ADMIN_USERS",
  payload: data,
});
export const downloadAdminUsers = (data) => ({
  type: "ADMIN_DOWNLOAD",
  payload: data,
});
export const payoutRequests = (data) => ({
  type: "ADMIN_PAYOUT",
  payload: data,
});
export const setDownload = (data) => ({
  type: "DOWNLOAD_PAYOUT",
  payload: data,
});
export const setPaymentReject = (data) => ({
  type: "REJECT_PAYOUT",
  payload: data,
});
export const setPaymentFilter = (data) => ({
  type: "FILTER_PAYOUT",
  payload: data,
});
export const setDownloadPayouts = (data) => ({
  type: "DOWNLOAD_PAYOUT",
  payload: data,
});
export const setReportsPaymentList = (data) => ({
  type: "PAYMENT_LIST",
  payload: data,
});
export const setReportsVideoViews = (data) => ({
  type: "REPORTS_VIDEO_VIEWS",
  payload: data,
});
export const downloadVideoReports = (data) => ({
  type: "DOWNLOAD_VIDEO_REPORTS",
  payload: data,
});
export const topHighestViewedVideos = (data) => ({
  type: "DASHBOARD_VIDS",
  payload: data,
});
export const setMonthwiseVidsViewData = (data) => ({
  type: "MONTH_VID_VIEW",
  payload: data,
});
export const setSubscriptionData = (data) => ({
  type: "SUBSCRIPTION_DATA",
  payload: data,
});
export const setCelebrities = (data) => ({
  type: "CELEB_LIST",
  payload: data,
});
export const setPaymentProductClicks = (data) => ({
  type: "PRODUCT_CLICKS",
  payload: data,
});
export const setReportsProductClicks = (data) => ({
  type: "PRODUCT_CLICKS_REPORTS",
  payload: data,
});
export const setProductClicksReports = (data) => ({
  type: "PRODUCT_CLICKS_REPORTS_FILTER",
  payload: data,
});
// setVideoReportsDownload
export const setVideoReportsDownload = (data) => ({
  type: "PRODUCT_VIDS_DOWNLOAD",
  payload: data,
});
export const setSearchVids = (data) => ({
  type: "SEARCH_VIDS",
  payload: data,
});
export const setVidPurchaseData = (data) => ({
  type: "VID_PURCHASE_DATA",
  payload: data,
});