import React, { useEffect, useState, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { changeTheme } from "../../Store/Actions";

/******************* 
@Purpose : Used for dynamic theme intigration
@Parameter : {props}
@Author : INIC
******************/
function Theme(props) {
  const [toggleSettings, setTogglesettings] = useState(false);
  const [checked, setChecked] = useState(props.theme && props.theme.data);

  /******************* 
  @Purpose : React hook used for add theme classes
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (props.theme && props.theme.data && props.theme.data === true) {
      document.body.classList.add("dark");
      document.body.classList.remove("light");
    } else {
      document.body.classList.add("light");
      document.body.classList.remove("dark");
    }
    props.changeTheme(props.theme && props.theme.data);
  }, []);

  /******************* 
  @Purpose : Used for dynamic outer click handler
  @Parameter : {callback}
  @Author : INIC
  ******************/
  function useOuterClick(callback) {
    const settingsRef = useRef();
    const callbackRef = useRef();
    useEffect(() => {
      callbackRef.current = callback;
    });

    /******************* 
    @Purpose : React hook used for handle click event
    @Parameter : {}
    @Author : INIC
    ******************/
    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);

      function handleClick(e) {
        if (
          settingsRef.current &&
          callbackRef.current &&
          !settingsRef.current.contains(e.target)
        ) {
          callbackRef.current(e);
        }
      }
    }, []);

    return settingsRef;
  }

  /******************* 
  @Purpose : Used for theme change handle
  @Parameter : {callback}
  @Author : INIC
  ******************/
  const handleCheckClick = () => {
    setChecked(!checked);
    if (!checked) {
      document.body.classList.add("dark");
      document.body.classList.remove("light");
    } else {
      document.body.classList.add("light");
      document.body.classList.remove("dark");
    }
    props.changeTheme(!checked);
  };

  const settingsRef = useOuterClick((e) => {
    setTogglesettings(false);
  });

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <div
      ref={settingsRef}
      id="container1"
      className={
        toggleSettings ? "theme-setting-block open" : "theme-setting-block "
      }
    >
      <Link
        onClick={() => setTogglesettings(!toggleSettings)}
        className="theme-setting-link on click"
        style={{ display: "none" }}
      >
        <i className="bx bx-cog bx-flip-horizontal bx-spin"></i>
      </Link>
      <div className="d-flex align-items-center">
        <span className="light-icon icon mr-1 d-block">
          <em className="bx bx-sun"></em>
        </span>
        <div className="custom-control custom-switch theme-switch">
          <input
            type="checkbox"
            checked={checked}
            onChange={() => handleCheckClick()}
            className="custom-control-input"
            id="customSwitchTheme"
          />
          <label
            className="custom-control-label"
            for="customSwitchTheme"
          ></label>
        </div>
        <span className="dark-icon icon">
          <em className="bx bxs-sun"></em>
        </span>
      </div>
    </div>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  theme: state.admin.theme,
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { changeTheme })(withRouter(Theme));
