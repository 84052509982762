const INTIAL_STATE = {
  tabs: "Activated Brand",
};
/******************* 
@Purpose : Used for store data in redux
@Parameter : state, action
@Author : INIC
******************/
export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case "SET_TABS":
      return Object.assign({}, state, {
        tabs: action.payload,
      });

    default:
      return state;
  }
};
