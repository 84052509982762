import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as _ from "lodash";

import { sidebaropen } from "../../Store/Actions";
import { callApi } from "../../Api";

/******************* 
@Purpose : Used for sidebar view
@Parameter : {props}
@Author : INIC
******************/
function Sidebar(props) {
  const myObjectString = sessionStorage.getItem("dataPermissions");

  // Convert the string to an object and it is used to get the results and view accordingly
  const resultPermissions = JSON.parse(myObjectString);

  const [, setUserName] = useState("");
  const [, setPhoto] = useState("");
  const [, setAdminUserAccess] = useState({});
  const [, setCmsPagesAccess] = useState({});

  const [, setEmailTemplateAccess] = useState({});
  const [, setRolesAccess] = useState({});
  const [, setUserAccess] = useState({});
  const [lang] = useTranslation("language");
  const [usermenu, openUsermenu] = useState(false);

  const [contentmenu, openContentmenu] = useState(false);
  const [blogsmenu, openBlogsmenu] = useState(false);

  const [settingsmenu, openSettingsmenu] = useState(false);
  const [, setShowUserMenu] = useState(false);
  const [, setShowContentMenu] = useState(false);
  const [, setShowEmailTempMenu] = useState(false);

  const path = props.location.pathname;
  const innerRef = useOuterClick();

  /******************* 
  @Purpose : React hook used for role permission
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (props.rolePermissions && !_.isEmpty(props.rolePermissions)) {
      let {
        adminUserAccess,
        cmsPagesAccess,

        emailTemplateAccess,
        rolesAccess,
        userAccess,
      } = props.rolePermissions;
      setShowUserMenu(userAccess.read);
      setShowContentMenu(cmsPagesAccess.read);
      setShowEmailTempMenu(emailTemplateAccess.read);
      setAdminUserAccess(adminUserAccess);
      setCmsPagesAccess(cmsPagesAccess);

      setEmailTemplateAccess(emailTemplateAccess);
      setRolesAccess(rolesAccess);
      setUserAccess(userAccess);
    }
    if (props.admindata && !_.isEmpty(props.admindata)) {
      let { firstname, lastname, photo } = props.admindata;
      setUserName(firstname + " " + lastname);
      setPhoto(photo);
    }
  }, [props]);

  /******************* 
  @Purpose :Used for outer click
  @Parameter : {}
  @Author : INIC
  ******************/
  function useOuterClick(callback) {
    const outerRef = useRef();
    const callbackRef = useRef();

    /******************* 
    @Purpose : React hook used callback ref
    @Parameter : {}
    @Author : INIC
    ******************/
    useEffect(() => {
      callbackRef.current = callback;
    });

    /******************* 
    @Purpose : React hook used handle click
    @Parameter : {}
    @Author : INIC
    ******************/
    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);
      function handleClick(e) {
        if (
          outerRef.current &&
          callbackRef.current &&
          !outerRef.current.contains(e.target)
        ) {
          callbackRef.current(e);
        }
      }
    }, []);
    return outerRef;
  }

  /******************* 
  @Purpose : Used for sidebar open
  @Parameter : {}
  @Author : INIC
  ******************/
  const SidebarOpen = () => {
    document.body.classList.remove("sidebar-open");
  };

  /******************* 
  @Purpose : Used for change routes
  @Parameter : e, page
  @Author : INIC
  ******************/
  const changeRoute = (e, page) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (
      page === "/dashboard" ||
      page === "/master" ||
      page === "/emailTemplates" ||
      page === "/reportedvideos" ||
      page === "/reportedcomments" ||
      page === "/payout" ||
      page === "/managevideos" ||
      page === "/Reports" ||
      page === "/CMS" ||
      page === "/planConfiguration" ||
      page === "/profile"
    ) {
      openUsermenu(false);
      openContentmenu(false);
      openSettingsmenu(false);
    } else if (
      page === "/usermanagement" ||
      page === "/celebrityList" ||
      page === "/brandList" ||
      page === "/rolesList" ||
      page === "/adminusers"
    ) {
      openUsermenu(true);
    }
    document.body.classList.remove("sidebar-open");
    return props.history.push(page);
  };

  /******************* 
  @Purpose : Used for user menu handle
  @Parameter : {}
  @Author : INIC
  ******************/
  const user = (e) => {
    openUsermenu(!usermenu);
  };

  /******************* 
  @Purpose : Used for settings handle
  @Parameter : {}
  @Author : INIC
  ******************/
  const getActiveLinkClass = (activePath, type, subpath = []) => {
    let pathSplit = path.split("/");
    let isActive = false;

    switch (type) {
      case "base-path":
        isActive = path === activePath || subpath.includes(`/${pathSplit[1]}`);
        break;
      case "user-parent":
      case "user-child":
        isActive = activePath === "showUserMenu" && usermenu;
        break;
      case "content-parent":
      case "content-child":
        isActive = activePath === "contentmenu" && contentmenu;
        break;
      case "blogs-parent":
        isActive = activePath === "blogsmenu" && blogsmenu;
        break;
      case "settings-parent":
        isActive = activePath === "settingsmenu" && settingsmenu;
        break;
      default:
        break;
    }

    const getClass = (prefix, active) => {
      return `${prefix} ${active ? "submenu-open" : ""}`;
    };

    switch (type) {
      case "base-path":
        return `menu-link ${isActive ? "active-link" : ""}`;
      case "user-parent":
        return getClass("menu-list-item has-child-submenu level1", isActive);
      case "user-child":
        return getClass("sidebar-menu-list sub-menu-list", isActive);
      case "content-parent":
        return getClass("menu-list-item has-child-submenu level1", isActive);
      case "content-child":
        return getClass("sidebar-menu-list sub-menu-list", isActive);
      case "blogs-parent":
        return getClass("menu-list-item has-child-submenu level1", isActive);
      case "settings-parent":
        return getClass("menu-list-item has-child-submenu level1", isActive);
      default:
        return "";
    }
  };

  const getActiveMenuNav = () => {
    let pathSplit = path.split("/");
    const newPath = pathSplit?.length > 1 ? pathSplit[1] : path;
    if (
      newPath == "usermanagement" ||
      newPath == "celebrityList" ||
      newPath == "brandList" ||
      newPath == "rolesList" ||
      newPath == "adminusers" ||
      newPath == "userdetails"
    )
      return "menu-link active-link";
    return "menu-link";
  };
  let isLogined = localStorage.getItem("accessToken");

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <div>
      {isLogined && (
        <div ref={innerRef} id="container" className="App">
          <header className="App-header"></header>
          <aside className="sidebar">
            <div className="sidebar-inner-wrapper">
              <div className="sidebar-heading d-flex align-items-end justify-content-between">
                <Link to="/" className="navbar-brand">
                  <div className="brand-logo">
                    <img
                      className="img-fluid"
                      src="/assets/images/logo.svg"
                      alt=""
                      width="64px"
                      height="64px"
                    />
                  </div>
                </Link>
                <span onClick={SidebarOpen} className="sidebar-close">
                  <em className="icon-close"></em>
                </span>
              </div>
              <PerfectScrollbar>
                <div className="sidebar-menu">
                  <ul className="sidebar-menu-list">
                    <li className="menu-list-item">
                      <a
                        className={getActiveLinkClass(
                          "/dashboard",
                          "base-path"
                        )}
                        onClick={(e) => {
                          changeRoute(e, "/dashboard");
                        }}
                      >
                        <i className="bx bx-home-alt" />
                        <span className="menu-title">
                          {lang("CustomLabels.dashboard")}
                        </span>
                      </a>
                    </li>

                    <li
                      onClick={user}
                      className={getActiveLinkClass(
                        "showUserMenu",
                        "user-parent"
                      )}
                    >
                      <a className={getActiveMenuNav()}>
                        <i className="bx bx-user"></i>
                        <span className="menu-title">
                          {lang("CustomLabels.users")}
                        </span>
                        <i className="icon-arrow-right" />
                      </a>
                      {/* p */}
                      <ul
                        className={getActiveLinkClass(
                          "showUserMenu",
                          "user-child"
                        )}
                        style={
                          usermenu ? { display: "block" } : { display: "none" }
                        }
                      >
                        {/* manage users userAccess */}
                        {resultPermissions?.userAccess?.viewList ? (
                          <li className="menu-list-item">
                            <a
                              onClick={(evt) => {
                                changeRoute(evt, "/usermanagement");
                              }}
                              className={getActiveLinkClass(
                                "/usermanagement",
                                "base-path",
                                ["/userdetails"]
                              )}
                            >
                              <i className="bx bxs-right-arrow-alt" />
                              <span className="menu-title">
                                {lang("Manage Customer")}
                              </span>
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {/* maange celebriyty  celebrityAccess*/}
                        {resultPermissions?.celebrityAccess?.viewList ? (
                          <li className="menu-list-item">
                            <a
                              onClick={(evt) => {
                                changeRoute(evt, "/celebrityList");
                              }}
                              className={getActiveLinkClass(
                                "/celebrityList",
                                "base-path",
                                [
                                  "/celebritydetails",
                                  "/getRejectedRequestHistoryOfUser",
                                  "/celebrityrequest",
                                ]
                              )}
                            >
                              <i className="bx bxs-right-arrow-alt" />
                              <span className="menu-title">
                                {lang("Manage Celebrity")}
                              </span>
                            </a>
                          </li>
                        ) : (
                          ""
                        )}

                        {/*manage brand brandsAccess  */}
                        {resultPermissions?.brandsAccess?.viewList ? (
                          <li className="menu-list-item">
                            <a
                              onClick={(evt) => {
                                changeRoute(evt, "/brandList");
                              }}
                              className={getActiveLinkClass(
                                "/brandList",
                                "base-path",
                                ["/brandprofile", "/BrandAccount"]
                              )}
                            >
                              <i className="bx bxs-right-arrow-alt" />
                              <span className="menu-title">
                                {lang("Manage Brand")}
                              </span>
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {/* vista roles rolesAccess */}
                        {resultPermissions?.rolesAccess?.viewList ? (
                          <li className="menu-list-item">
                            <a
                              onClick={(evt) => {
                                changeRoute(evt, "/rolesList");
                              }}
                              className={getActiveLinkClass(
                                "/rolesList",
                                "base-path",
                                ["/editRole"]
                              )}
                            >
                              <i className="bx bxs-right-arrow-alt" />
                              <span className="menu-title">
                                {lang("Vista Roles")}
                              </span>
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {/* admin users adminUserAccess */}
                        {resultPermissions?.adminUserAccess?.viewList ? (
                          <li className="menu-list-item">
                            <a
                              onClick={(evt) => {
                                changeRoute(evt, "/adminusers");
                              }}
                              className={getActiveLinkClass(
                                "/adminusers",
                                "base-path",
                                ["/userProfile", "/adduser", "/updateUser"]
                              )}
                            >
                              <i className="bx bxs-right-arrow-alt" />
                              <span className="menu-title">
                                {lang("Vista Users")}
                              </span>
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </li>
                    {/* manage videos videosAccess */}
                    {resultPermissions?.videosAccess?.viewList ? (
                      <li className="menu-list-item">
                        <a
                          onClick={(evt) => {
                            changeRoute(evt, "/managevideos");
                          }}
                          className={getActiveLinkClass(
                            "/managevideos",
                            "base-path",
                            "/videodetails"
                          )}
                        >
                          <i className="bx bx-video"></i>
                          <span className="menu-title">
                            {lang("Manage Videos")}
                          </span>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* reported videos link */}
                    {resultPermissions?.reportedVideosAccess?.viewList ? (
                      <li className="menu-list-item">
                        <a
                          onClick={(evt) => {
                            changeRoute(evt, "/reportedvideos");
                          }}
                          className={getActiveLinkClass(
                            "/reportedvideos",
                            "base-path",
                            "/repotedvideodetails"
                          )}
                        >
                          <i className="bx bx-video-off"></i>
                          <span className="menu-title">
                            {lang("Reported Videos")}
                          </span>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* reported comments */}

                    {resultPermissions?.reportedCommentsAccess?.viewList ? (
                      <li className="menu-list-item">
                        <a
                          onClick={(evt) => {
                            changeRoute(evt, "/reportedcomments");
                          }}
                          className={getActiveLinkClass(
                            "/reportedcomments",
                            "base-path",
                            "/reportedcommentdetail"
                          )}
                        >
                          <i className="bx bx-message-add"></i>
                          <span className="menu-title">
                            {lang("Reported Comments")}
                          </span>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* master */}
                    {resultPermissions?.masterAccess?.viewList ? (
                      <li className="menu-list-item">
                        <a
                          onClick={(evt) => {
                            changeRoute(evt, "/master");
                          }}
                          className={getActiveLinkClass("/master", "base-path")}
                        >
                          <i className="bx bxs-spreadsheet" />
                          <span className="menu-title">
                            {lang("CustomLabels.master")}
                          </span>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* payout  payoutAccess*/}
                    {resultPermissions?.payoutAccess?.viewList ? (
                      <li className="menu-list-item">
                        <a
                          onClick={(evt) => {
                            changeRoute(evt, "/payout");
                          }}
                          className={getActiveLinkClass(
                            "/payout",
                            "base-path",
                            "/paymentRequest"
                          )}
                        >
                          <i className="bx bx-rupee"></i>
                          <span className="menu-title">Payout</span>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* Report */}
                    {resultPermissions?.reportsAccess?.viewList ? (
                      <li className="menu-list-item">
                        <a
                          onClick={(evt) => {
                            changeRoute(evt, "/Reports");
                          }}
                          className={getActiveLinkClass(
                            "/Reports",
                            "base-path",
                            "/reportPaymentDetail"
                          )}
                        >
                          <i className="bx bxs-report"></i>
                          <span className="menu-title">{lang("Reports")}</span>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* CMS */}
                    {resultPermissions?.cmsPagesAccess?.viewList ? (
                      <li className="menu-list-item">
                        <a
                          onClick={(evt) => {
                            changeRoute(evt, "/CMS");
                          }}
                          className={getActiveLinkClass("/CMS", "base-path")}
                        >
                          <i className="bx bx-file"></i>
                          <span className="menu-title">{lang("CMS")}</span>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* email templates  emailSettingsAccess*/}
                    {resultPermissions?.emailTemplateAccess?.viewList ? (
                      <li className="menu-list-item">
                        <a
                          onClick={(evt) => {
                            changeRoute(evt, "/emailTemplates");
                          }}
                          className={getActiveLinkClass(
                            "/emailTemplates",
                            "base-path"
                          )}
                        >
                          <i className="bx bx-mail-send" />
                          <span className="menu-title">Email Template</span>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>

                  <ul className="sidebar-menu-list">
                    <li className="menu-list-item">
                      <a
                        onClick={(evt) => {
                          changeRoute(evt, "/planConfiguration");
                        }}
                        className={getActiveLinkClass(
                          "/planConfiguration",
                          "base-path",
                          "/addPlan"
                        )}
                      >
                        <i className="bx bx-cog"></i>
                        <span className="menu-title">Settings</span>
                      </a>
                    </li>
                  </ul>
                  <ul className="sidebar-menu-list">
                    {/* my profile */}

                    <li className="menu-list-item">
                      <a
                        onClick={(evt) => {
                          changeRoute(evt, "/profile");
                        }}
                        className={getActiveLinkClass("/profile", "base-path")}
                      >
                        <i className="bx bx-user-circle"></i>
                        <span className="menu-title">My Profile</span>
                      </a>
                    </li>

                    {/* my profie ends */}
                  </ul>
                </div>
              </PerfectScrollbar>
            </div>
          </aside>
        </div>
      )}
    </div>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  language: state.admin.language,
  admindata: state.admin.adminData,
  sidebar: state.admin.sidebar,
  editadminprofile: state.admin.editAdminProfileData,
  rolePermissions: state.admin.adminData.staticRolePermission,
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi, sidebaropen })(
  withRouter(Sidebar)
);
