//internal
import { callApi } from "../../Api";
import Routes from "../../Api/Routes"; // API endpoint routes
import { showMessageNotification } from "../../Utils/Functions";

const {
  CHANGE_ACTIVE_STATUS,
  DELETE_BRAND,
  GET_BRANDS,
  DOWNLOAD_BRAND_DATA,
  BRAND_PROFILE,
  ADD_BRAND,
  CHANGEBRANDEMAIL,
} = Routes;

/*******************
@purpose : used for get all Brands list
@Parameter : {}
@Author : INIC
******************/
export const brandLsting =
  (number = 1, size = 10) =>
  async (dispatch) => {
    const res = await callApi(
      `${GET_BRANDS}?pageNumber=${number}&pageSize=${size}`,
      {
        searchText: "",
      },
      "post",
      "",
      true
    );
    if (res) {
      if (res.status) {
        dispatch(setBrandData(res.data));
      }
      return res;
    }
    return Promise.reject(res);
  };

/*******************
@purpose : used for search all Brands
@Parameter : {data,number,size}
@Author : INIC
******************/
export const searchData =
  (data, number = 1, size = 10) =>
  async (dispatch) => {
    const res = await callApi(
      `${GET_BRANDS}?pageNumber=${number}&pageSize=${size}`,
      data,
      "post",
      "",
      true
    );

    if (res) {
      if (res.status) {
        dispatch(setBrandData(res));
      } else dispatch(setBrandData(""));
      return res;
    }
    return Promise.reject(res);
  };

/*******************
@purpose : used for search inactive Brands
@Parameter : {data,number,size}
@Author : INIC
******************/
export const searchInActiveBrand =
  (data, number = 1, size = 10) =>
  async (dispatch) => {
    const res = await callApi(
      `${GET_BRANDS}?pageNumber=${number}&pageSize=${size}`,
      data,
      "post",
      "",
      true
    );

    if (res) {
      if (res.status) {
        dispatch(setinactiveBrandData(res));
      } else dispatch(setinactiveBrandData(""));
      return res;
    }
    return Promise.reject(res);
  };
/*******************
@purpose : used for set all Brands
@Parameter : {data}
@Author : INIC
******************/
export const setinactiveBrandData = (data) => ({
  type: "SET_IN_BRAND_DATA",
  payload: data,
});
/*******************
@purpose : used for set all Brands
@Parameter : {data}
@Author : INIC
******************/
export const setBrandData = (data) => ({
  type: "SET_BRAND_DATA",
  payload: data,
});
/*******************
@purpose : used for Reset Password
@Parameter : {data}
@Author : INIC
******************/
export const ChangeBrandStatus = (data) => async () => {
  const res = await callApi(`${CHANGE_ACTIVE_STATUS}`, data, "post", "", true);
  if (res) {
    if (res.status) {
      showMessageNotification(res.message, "success");
    } else showMessageNotification(res.message, "error");
    return res;
  }
  return Promise.reject(res);
};

/*******************
@purpose : used for Delete Brand
@Parameter : {data}
@Author : INIC
******************/
export const deleteBrand = (data) => async () => {
  const res = await callApi(`${DELETE_BRAND}`, data, "delete", "", true);
  if (res) {
    if (res.status) {
      showMessageNotification(res.message, "success");
    } else showMessageNotification(res.message, "error");
    return res;
  }
  return Promise.reject(res);
};

/*******************
@purpose : used for download Brand data
@Parameter : {data}
@Author : INIC
******************/
export const downloadBrandData = (data) => async () => {
  const res = await callApi(
    `${DOWNLOAD_BRAND_DATA}?format=${data}`,
    "",
    "get",
    "",
    true
  );
  if (res) {
    if (res.status) {
      window.open(res.data);
      showMessageNotification(res.message, "success");
    } else showMessageNotification(res.message, "error");
    return res;
  }
  return Promise.reject(res);
};

/*******************
@purpose : used for get Brand profile
@Parameter : {}
@Author : INIC
******************/
export const brandProfile = (data) => async (dispatch) => {
  const res = await callApi(
    `${BRAND_PROFILE}?brandId=${data}`,
    "",
    "get",
    "",
    true
  );
  if (res) {
    if (res.status) {
      dispatch(setBrandProfile(res.data));
    }
    return res;
  }
  return Promise.reject(res);
};

/*******************
@purpose : used for set Brands profile
@Parameter : {data}
@Author : INIC
******************/
export const setBrandProfile = (data) => ({
  type: "SET_BRAND_PROFILE",
  payload: data,
});

/*******************
@purpose : used for create brand
@Parameter : {data}
@Author : INIC
******************/
export const addBrand = (data) => async () => {
  const res = await callApi(ADD_BRAND, data, "post", "", true);
  if (res) {
    if (res.status) {
      showMessageNotification(res.message, "success");
    } else showMessageNotification(res.message, "error");
    return res;
  }
  return Promise.reject(res);
};

/*******************
@purpose : used for create brand
@Parameter : {data}
@Author : INIC
******************/
export const editBrandEmail = (data) => async () => {
  const res = await callApi(CHANGEBRANDEMAIL, data, "post", "", true);
  if (res) {
    if (res.status) {
      showMessageNotification(res.message, "success");
    } else showMessageNotification(res.message, "error");
    return res;
  }
  return Promise.reject(res);
};
