import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";

import { addBrandSchema } from "../../Utils/ValidationSchema";
import { addBrand } from "../../Store/Actions/Brand";
import Layout from "../../Components/Layout/Layout";

import "react-phone-input-2/lib/style.css";

/******************* 
@Purpose : Used for brand Account 
@Parameter : {}
@Author : INIC
******************/
const BrandAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  /******************* 
  @Purpose : Used for Bread crumb
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      key: "6",
      title: "Manage Brand",
      url: "/brandlist",
    },
    {
      key: "6",
      title: "Create Brand",
      url: "/BrandAccount",
    },
  ];

  /******************* 
   @purpose : used for validation schema and api calling
   @Parameter : {}
   @Author :INIC
   ******************/
  const formik = useFormik({
    initialValues: { organization: "", emailId: "" },
    enableReinitialize: true,
    validationSchema: addBrandSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const res = await dispatch(addBrand(values));
      if (res) {
        setLoading(false);
        history.push("/brandList");
      }
    },
  });

  /******************* 
  @Purpose : Used for post image 
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleBack = () => {
    history.push("/brandList");
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Manage Brand">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="card">
              <div className="card-header pb-0">
                <h5 className="card-title">Create Brand</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="form-group col-lg-6 col-md-6 mb-4">
                    <label className="form-label" htmlFor="organization">
                      Organization
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="organization"
                      className="form-control"
                      placeholder="Enter Organization Name"
                      autoComplete="off"
                      {...formik.getFieldProps("organization")}
                    />
                    {formik.touched.organization &&
                    formik.errors.organization ? (
                      <span className="text-danger error-msg">
                        {formik.errors.organization}
                      </span>
                    ) : null}
                  </div>

                  <div className="form-group col-lg-6 col-md-6 mb-4">
                    <label className="form-label" htmlFor="Email">
                      Email Id
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      id="emailId"
                      placeholder="Enter email"
                      type="email"
                      autoComplete="off"
                      {...formik.getFieldProps("emailId")}
                    />
                    {formik.touched.emailId && formik.errors.emailId ? (
                      <span className="text-danger error-msg">
                        {formik.errors.emailId}
                      </span>
                    ) : null}
                  </div>

                  <div className="col-md-12 text-end me-2">
                    <Button
                      variant="light"
                      className="me-2"
                      onClick={() => handleBack()}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => formik.handleSubmit()}
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          {" "}
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Creating...
                        </>
                      ) : (
                        "Create Brand"
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  );
};

export default BrandAccount;
