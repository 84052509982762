import React, { useState, useEffect, useRef } from "react";

import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Select from "react-select";
import { callApi } from "../../Api";
import API from "../../Api/Routes";
import { IMG_URL } from "../../Config";
import {
  validateEmail,
  showMessageNotification,
  validateMobileNumber,
  validateProfileName,
  showModalNotification,
} from "../../Utils/Functions";

import errorMessages from "../../Utils/ErrorMessages";
import Layout from "../../Components/Layout/Layout";

/******************* 
@Purpose : Used for profile page
@Parameter : {props}
@Author : INIC
******************/
function AddUser(props) {
  const [show1, setShow1] = useState();
  const [page] = useState(1);
  const [pageSize] = useState(10);
  const [error, setError] = useState({});

  const [rolesList, setRolesList] = useState("");
  const [role, setRole] = useState("");
  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);
  const [photo] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    emailId: "",
    photo: "",
    status: "",
    errors: {},
  });
  const [profileImage, setProfileImage] = useState("");

  let { name, emailId, mobile, errors } = formData;
  /******************* 
  @Purpose : Used for modal in the rejection
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const modelCloseFun1 = () => {
    setShow1(false);
    setProfileImage("/assets/images/avatar-s-16.jpg");
  };
  //
  // updateProfile
  const acceptProfileImage = () => {
    setShow1(false);
  };
  /******************* 
  @Purpose : React hook Used for getting the profile data
  @Parameter : {}
  @Author : INIC
  ******************/
  /******************* 
  @Purpose : Used for get all roles
  @Parameter : {}
  @Author : INIC
  ******************/
  const getAllRoles = async () => {
    let body = { page, pagesize: pageSize };

    const response = await callApi(API.LIST_ROLES, body, "post", null, true);
    if (response.status === 1) {
      setRolesList(
        response.data.listing.map((el) => {
          return {
            value: el._id,
            label: el.role,
          };
        })
      );
    }
  };

  /******************* 
  @Purpose : React hook used for get roles page
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    getAllRoles();
  }, []);
  /******************* 
  @Purpose : Used for validate profile
  @Parameter : {}
  @Author : INIC
  *******************/
  // const validateForm = () => {
  //   if (formData.hasOwnProperty("name")) {
  //     if (isEmpty(name)) {
  //       errors.name = errorMessages.PROVIDE_NAME;
  //     } else if (validateProfileName(name) === false) {
  //       errors.name = errorMessages.PROVIDE_VALID_NAME;
  //     } else delete errors.name;
  //   }
  //   if (formData.hasOwnProperty("emailId")) {
  //     if (isEmpty(emailId)) errors.emailId = errorMessages.PROVIDE_EMAIL;
  //     else if (!validateEmail(emailId))
  //       errors.emailId = errorMessages.PROVIDE_VALID_EMAIL;
  //     else delete errors.emailId;
  //   }
  //   if (formData.hasOwnProperty("mobile")) {
  //     if (isEmpty(mobile)) errors.mobile = errorMessages.PROVIDE_MOBILE_NUMBER;
  //     else if (!validateMobileNumber(mobile))
  //       errors.mobile = errorMessages.PROVIDE_VALID_MOBILE_NUMBER;
  //     else delete errors.mobile;
  //   }

  //   if (isEmpty(role)) errors.role = errorMessages.PROVIDE_ROLE;
  //   else delete errors.role;

  //   const isFormvalid = Object.keys(errors).length !== 0 ? false : true;

  //   setFormData({
  //     ...formData,
  //     errors: errors,
  //   });
  //   return isFormvalid;
  // };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = errorMessages.PROVIDE_NAME;
    }

    if (!formData.emailId.trim()) {
      newErrors.emailId = errorMessages.PROVIDE_EMAIL;
    }

    if (!formData.mobile.trim()) {
      newErrors.mobile = errorMessages.PROVIDE_MOBILE_NUMBER;
    }

    if (!formData.role) {
      newErrors.role = errorMessages.PROVIDE_ROLE;
    }

    setFormData((prevData) => ({
      ...prevData,
      errors: newErrors,
    }));

    return Object.keys(newErrors).length === 0;
  };
  /******************* 
  @Purpose : Used for set form data
  @Parameter : {e}
  @Author : INIC
  *******************/
  // const handleChange = (e) => {
  //   if (e?.target.value) {
  //     setFormData({
  //       ...formData,
  //       [e.target.name]: e.target.value,
  //       errors: Object.assign(formData.errors, { [e.target.name]: "" }),
  //     });
  //   } else {
  //     setFormData({
  //       ...formData,
  //       [e.target.name]: "",
  //     });
  //   }
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      errors: {
        ...prevData.errors,
        [name]: "", // Clear error when the user starts typing
      },
    }));
  };
  /******************* 
  @Purpose : Used for update profile
  @Parameter : {e}
  @Author : INIC
  *******************/
  const updateProfile = async () => {
    if (validateForm()) {
      let url = profileImage;

      if (typeof profileImage === "object") {
        url = await uploadProfileImage(profileImage);
      } else {
        url = profileImage;
      }
      let response;
      let body = {
        emailId: emailId,
        name: name,
        mobile: mobile,
        photo: url,
        role: role?.value,
      };

      response = await callApi(API.ADD_ADMIN, body, "post", "ADD_ADMIN", true);
      if (response.status === 1) {
        showModalNotification(
          "Account Activation Link has been sent to the respective email Id",
          "success"
        );
        props.history.push("/adminusers");
        setFormData({
          name: "",
          mobile: "",
          emailId: "",
          photo: "",
          role: "",
          errors: {},
        });
      } else if (response.status === 0) {
        showModalNotification(response?.message, "error");
      }
    }
  };

  let imagePreview;
  if (photo) {
    imagePreview = <img src={photo ? IMG_URL + photo : ""} alt="" />;
  } else {
    imagePreview = <img src={"/assets/images/avatar-s-16.jpg"} alt="" />;
  }
  /******************* 
  @Purpose : Used for uploadImage
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      setProfileImage(current.file);
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  /******************* 
  @Purpose : Used for upload image
  @Parameter : {file}
  @Author : INIC
  *******************/
  const uploadProfileImage = async (file) => {
    let formData2 = new FormData();
    formData2.append("image", file);
    let response;
    response = await callApi(
      API.PROFILE_IMAGE,
      formData2,
      "post",
      "PROFILE_IMAGE",
      null,
      true
    );

    if (response.imagePath) {
      return response.imagePath;
    } else {
      showMessageNotification("Something went wrong");
    }
  };

  /******************* 
  @Purpose : Used for bread crumb
  @Parameter : {}
  @Author : INIC
  *******************/
  const breadcrumb = [
    {
      title: "Vista Users",
      url: "/adminusers",
    },
    {
      title: "Add User",
    },
  ];
  /******************* 
  @Purpose : Used for clear passwords
  @Parameter : {}
  @Author : INIC
  ******************/
  const previewImageChanges = async (id) => {
    setShow1(true);
  };
  /******************* 
  @Purpose : Used for clear profile data
  @Parameter : {}
  @Author : INIC
  ******************/
  const clearProfileData = () => {
    setRole("");
    setFormData({
      name: "",
      mobile: "",
      emailId: "",
      photo: "",
      role: "",
      errors: {},
    });
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Vista Users">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="card">
              <form>
                <div className="card-header">
                  <h5 className="card-title">User Information</h5>
                </div>
                <div className="card-body">
                  <div className="row mb-5">
                    <div className="col-lg-3 col-xxl-2 col-md-4 mb-md-5">
                      <div className="text-center">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageUpload}
                          ref={imageUploader}
                          style={{
                            display: "none",
                          }}
                        />
                        <div className="user-image mx-auto d-block mb-3">
                          <img
                            ref={uploadedImage}
                            src={
                              profileImage !== "" &&
                              typeof profileImage === "object"
                                ? URL.createObjectURL(profileImage)
                                : profileImage ||
                                  "/assets/images/avatar-s-16.jpg"
                            }
                            alt="Icon"
                          />
                        </div>

                        <Button
                          className="btn btn-primary"
                          onClick={previewImageChanges}
                        >
                          {" "}
                          Preview Image
                        </Button>
                      </div>
                    </div>
                    <div className="col-lg-9 col-xxl-10 col-md-8">
                      <div className="row">
                        <div className="col-lg-12 col-xl-6 col-md-12">
                          <div className="form-group mb-3">
                            <label className="form-label" htmlFor="Name">
                              Name <span className="text-danger">*</span>
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              autoComplete="off"
                              id="name"
                              name="name"
                              placeholder="Enter name"
                              value={name}
                              onChange={(e) => handleChange(e)}
                            />
                            <span className="text-danger d-block">
                              {errors.name}
                            </span>
                          </div>
                          <div className="form-group mb-3">
                            <label className="form-label" htmlFor="Email">
                              Email Address{" "}
                              <span className="text-danger">*</span>
                            </label>

                            <input
                              className="form-control"
                              id="emailId"
                              name="emailId"
                              placeholder="Enter email"
                              type="email"
                              value={emailId}
                              autoComplete="off"
                              onChange={(e) => handleChange(e)}
                            />
                            <span className="text-danger d-block">
                              {errors.emailId}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-6 col-md-12">
                          <div className="form-group mb-3">
                            <label className="form-label" htmlFor="PhoneNumber">
                              Phone No.
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              name="mobile"
                              id="mobile"
                              value={mobile}
                              autoComplete="off"
                              onChange={(e) => handleChange(e)}
                            />

                            <span className="text-danger d-block w-100 mt-1">
                              {errors.mobile}
                            </span>
                          </div>
                          <div className="form-group mb-3">
                            <label className="form-label" htmlFor="mobile">
                              Role <span className="text-danger">*</span>
                            </label>
                            <Select
                              className="selectpicker w-100"
                              id="role"
                              name="role"
                              value={role}
                              options={rolesList}
                              onChange={(e) => {
                                setRole(e);
                                setFormData((prevData) => ({
                                  ...prevData,
                                  role: e,
                                  errors: {
                                    ...prevData.errors,
                                    role: "",
                                  },
                                }));
                              }}
                            ></Select>
                            <span className="text-danger d-block w-100 mt-1">
                              {formData.errors.role}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-12 text-end">
                      <Button
                        variant="light"
                        onClick={clearProfileData}
                        className="me-2"
                      >
                        Clear
                      </Button>
                      <Button variant="primary" onClick={updateProfile}>
                        Create User
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
      <div
        className="d-flex align-items-center justify-content-between mb-2 flex-wrap"
        style={{ gap: "12px", fontSize: "15px" }}
      >
        <Modal
          show={show1}
          onHide={modelCloseFun1}
          className="border  md={{ span: 3, offset: 3 }}"
        >
          <Modal.Header closeButton>
            <h5 className="modal-title" id="exampleModalLongTitle">
              Preview Image
            </h5>
          </Modal.Header>
          <Modal.Body closeButton>
            <div className="notification-form">
              <div className="row">
                <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <img
                      ref={uploadedImage}
                      src={
                        profileImage ===
                        "https://d2lfc46helbwci.cloudfront.net/"
                          ? "/assets/images/no-user.png"
                          : profileImage !== "" &&
                            typeof profileImage === "object"
                          ? URL.createObjectURL(profileImage)
                          : profileImage || "/assets/images/avatar-s-16.jpg"
                      }
                      style={{
                        display: "block",
                        width: "225px",
                        maxHeight: "155px",
                      }}
                      alt="Icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="me-2"
              variant="primary"
              onClick={() => imageUploader.current.click()}
            >
              Change Image
            </Button>
            <Button onClick={acceptProfileImage}>Accept Image</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Layout>
  );
}
/*******************
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
*******************/
const mapStateToProps = (state) => ({});

/****************** 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
*****************/
export default connect(mapStateToProps, { callApi })(AddUser);
