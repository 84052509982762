import { Modal, Button, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";

import CKEditor from "../Editor/Ckeditor";

import { updateCms, cmsListing } from "../../Store/Actions/User";
import {
  showMessageNotification,
  showModalNotification,
} from "../../Utils/Functions";
function EditContent(props) {
  const dispatch = useDispatch();
  const [spin, setSpin] = useState(false);
  const { show, closeModal, data, callBack } = props;
  const [desc, setDesc] = useState(data.description);

  const [, setCmsList] = useState([]);

  useEffect(() => {
    setDesc(data.description);
  }, [data.description]);

  function handleChange(e) {
    setDesc(e);
  }
  useEffect(() => {
    getListing();
  }, []);

  const getListing = async (e) => {
    try {
      dispatch(cmsListing()).then((response) => {
        if (response.status === 1) {
          setCmsList(response?.data);
        }
      });
    } catch (error) {
      console.error();
    }
  };
  // update the cms
  async function UpdateDescription() {
    setSpin(true);

    let body = {
      cmsId: data?._id,
      pageTitle: data?.pageTitle,
      description: desc,
    };
    try {
      dispatch(updateCms(body)).then((response) => {
        if (response.status === 1) {
          setSpin(false);

          closeModal();
          callBack && callBack();
          showMessageNotification("Edited successfully", "success");
        } else if (response.status === 0) {
          setSpin(false);
          showModalNotification(response?.message, "error");
        }
      });
    } catch (error) {
      console.error();
    }
  }
  return (
    <Modal show={show} onHide={closeModal} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6 className="mb-4"> {data?.pageTitle}</h6>
        <CKEditor html={data?.description} handleChange={handleChange} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="primary" className="me-0" onClick={UpdateDescription}>
          {/* Save Changes */}
          {spin ? (
            <>
              {" "}
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>{" "}
              Save Changes...
            </>
          ) : (
            "Save Changes"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditContent;
