import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { Tab, Tabs, Button, Modal } from "react-bootstrap";

import { isEmpty } from "lodash";

import { callApi } from "../../Api";
import API from "../../Api/Routes";
import { IMG_URL } from "../../Config";
import {
  validateEmail,
  showMessageNotification,
  validatePassword,
  validateMobileNumber,
  validateProfileName,
  showModalNotification,
} from "../../Utils/Functions";
import { setUserProfileData } from "../../Store/Actions/User";
import errorMessages from "../../Utils/ErrorMessages";
import Layout from "../../Components/Layout/Layout";

/******************* 
@Purpose : Used for profile page
@Parameter : {props}
@Author : INIC
******************/
function Profile(props) {
  const [spinner, setSpinner] = useState(false);
  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);
  const [photo] = useState("");

  const dispatch = useDispatch();
  const profileRedux = useSelector((state) => state.user.profileData);
  const [eventKeys, setEventKeys] = useState("Manage Profile Data");
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    emailId: "",
    photo: "",
    errors: {},
  });
  const [show1, setShow1] = useState();
  const [profileImage, setProfileImage] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [, setisFormValidPassword] = useState(true);
  let [changeErrors, setChangeErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  let { name, emailId, mobile, errors } = formData;

  /******************* 
  @Purpose : React hook Used for getting the profile data
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (profileRedux) {
      const { name, emailId, mobile, photo } = profileRedux;
      setFormData({
        ...formData,
        name,
        emailId,
        mobile,
        photo,
      });
      if (photo && photo?.match(IMG_URL)) {
        setProfileImage(photo);
      } else {
        setProfileImage(IMG_URL + photo);
      }
    } else {
      getAdminProfile();
    }
  }, [profileRedux]);
  /******************* 
  @Purpose : Used for modal in the rejection
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const modelCloseFun1 = () => {
    setShow1(false);
    getAdminProfile();
  };
  // updateProfile
  function acceptProfileImage() {
    setShow1(false);
    updateProfile();
  }
  /******************* 
  @Purpose : Used for validate profile
  @Parameter : {}
  @Author : INIC
  *******************/
  const validateForm = () => {
    if (formData.hasOwnProperty("name")) {
      if (isEmpty(name)) {
        errors.name = errorMessages.PROVIDE_NAME;
      } else if (validateProfileName(name) === false) {
        errors.name = errorMessages.PROVIDE_VALID_NAME;
      } else delete errors.name;
    }
    if (formData.hasOwnProperty("emailId")) {
      if (isEmpty(emailId)) errors.emailId = errorMessages.PROVIDE_EMAIL;
      else if (!validateEmail(emailId))
        errors.emailId = errorMessages.PROVIDE_VALID_EMAIL;
      else delete errors.emailId;
    }
    if (formData.hasOwnProperty("mobile")) {
      if (isEmpty(mobile)) errors.mobile = errorMessages.PROVIDE_MOBILE_NUMBER;
      else if (!validateMobileNumber(mobile))
        errors.mobile = errorMessages.PROVIDE_VALID_MOBILE_NUMBER;
      else delete errors.mobile;
    }

    const isFormvalid = Object.keys(errors).length !== 0 ? false : true;
    setFormData({
      ...formData,
      errors: errors,
    });
    return isFormvalid;
  };

  /******************* 
  @Purpose : Used for password validation
  @Parameter : {}
  @Author : INIC
  *******************/
  const validPasswords = () => {
    let changeError = { newPassword: "", confirmPassword: "" };
    let isFormValidPassword = true;
    if (!newPassword.trim())
      changeError.newPassword = errorMessages.PROVIDE_PASSWORD;
    else if (!validatePassword(newPassword))
      changeError.newPassword = errorMessages.PROVIDE_VALID_PASSWORD;
    else changeError.newPassword = "";
    if (!confirmPassword.trim())
      changeError.confirmPassword = errorMessages.PROVIDE_PASSWORD;
    else if (!validatePassword(confirmPassword))
      changeError.confirmPassword = errorMessages.PROVIDE_VALID_PASSWORD;
    else changeError.confirmPassword = "";
    if (newPassword !== confirmPassword)
      changeError.confirmPassword = errorMessages.PASSWORD_NOT_MATCHED;
    if (changeError.newPassword !== "" || changeError.confirmPassword !== "")
      isFormValidPassword = false;
    setChangeErrors(changeError);
    setisFormValidPassword(isFormValidPassword);
    return isFormValidPassword;
  };

  /******************* 
  @Purpose : Used for set form data
  @Parameter : {e}
  @Author : INIC
  *******************/
  const handleChange = (e) => {
    if (e.target.value) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        errors: Object.assign(formData.errors, { [e.target.name]: "" }),
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: "",
      });
    }
  };

  /******************* 
  @Purpose : Used for get admin profile
  @Parameter : {}
  @Author : INIC
  *******************/
  const getAdminProfile = async () => {
    const response = await callApi(
      API.GET_PROFILE,
      "",
      "get",
      "GET_PROFILE",
      true
    );
    if (response.status === 1) {
      dispatch(setUserProfileData(response.data));
      let { data } = response,
        { name, emailId, mobile, photo } = data;
      setFormData({
        ...formData,
        name,
        emailId,
        mobile,
        photo,
      });
      if (photo && photo?.match(IMG_URL)) {
        setProfileImage(photo);
      } else {
        setProfileImage(IMG_URL + photo);
      }
    }
  };

  /******************* 
  @Purpose : Used for update profile
  @Parameter : {e}
  @Author : INIC
  *******************/
  const updateProfile = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      let url = profileImage;

      if (typeof profileImage === "object") {
        url = await uploadProfileImage(profileImage);
      } else {
        url = profileImage;
      }
      let response;
      let body = {
        emailId: emailId,
        name: name,
        mobile: mobile,
        photo: url,
      };

      response = await callApi(
        API.UPDATE_PROFILE,
        body,
        "post",
        "EDITADMIN_PROFILE",
        true
      );

      if (response.status === 1) {
        showMessageNotification("Details updated successfully", "success");
        getAdminProfile();
      } else if (response.status === 0) {
        showModalNotification(response?.message, "error");
      }
    }
  };

  let imagePreview;
  if (photo) {
    imagePreview = <img src={photo ? IMG_URL + photo : ""} alt="" />;
  } else {
    imagePreview = <img src={"/assets/images/avatar-s-16.jpg"} alt="" />;
  }
  /******************* 
  @Purpose : Used for uploadImage
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      setProfileImage(current.file);
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  /******************* 
  @Purpose : Used for upload image
  @Parameter : {file}
  @Author : INIC
  *******************/
  const uploadProfileImage = async (file) => {
    let formData2 = new FormData();
    formData2.append("image", file);
    let response;
    response = await callApi(
      API.PROFILE_IMAGE,
      formData2,
      "post",
      "PROFILE_IMAGE",
      null,
      true
    );

    if (response.imagePath) {
      return response.imagePath;
    } else {
      showMessageNotification("Something went wrong");
    }
  };

  /******************* 
  @Purpose : Used for update password
  @Parameter : {evt}
  @Author : INIC
  *******************/
  const updatePassword = async (evt) => {
    evt.preventDefault();
    const body = {
      oldPassword: oldPassword,
      newPassword: confirmPassword,
    };
    try {
      if (validPasswords()) {
        setSpinner(true);
        const response = await callApi(
          API.CHANGE_PASSWORD,
          body,
          "post",
          "",
          true
        );

        if (response.status === 1) {
          setSpinner(false);
          showMessageNotification(response.message, "success");
          props.history.push("/");
        } else if (response.status === 0) {
          setSpinner(false);
          showModalNotification(response?.message, "error");
        }
      }
    } catch (error) {
      console.error();
    }
  };

  /******************* 
  @Purpose : Used for bread crumb
  @Parameter : {}
  @Author : INIC
  *******************/
  const breadcrumb = [
    {
      title: eventKeys,
      url: "/profile",
    },
  ];

  /******************* 
  @Purpose : Used for clear profile data
  @Parameter : {}
  @Author : INIC
  ******************/
  const clearProfileData = () => {
    setFormData({
      name: "",
      mobile: "",
      emailId: "",
      photo: "",
      errors: {},
    });
  };
  /******************* 
  @Purpose : Used for clear passwords
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleAddSubCategory = async (id) => {
    setShow1(true);
  };

  /******************* 
  @Purpose : Used for clear passwords
  @Parameter : {}
  @Author : INIC
  ******************/
  const clearForm = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setChangeErrors("");
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="My Profile">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="card">
              <form>
                <div className="card-header">
                  <Tabs
                    defaultActiveKey="Manage Profile Data"
                    id="uncontrolled-tab-example"
                    onSelect={(eventKey) => setEventKeys(eventKey)}
                    className="mb-5"
                  >
                    <Tab
                      eventKey="Manage Profile Data"
                      title={"Manage Profile Data"}
                    >
                      <div className="row mb-5">
                        <div className="col-lg-3 col-xxl-2 col-md-4 mb-md-5">
                          <div className="text-center">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageUpload}
                              ref={imageUploader}
                              style={{
                                display: "none",
                              }}
                            />
                            <div className="user-image mx-auto d-block mb-3">
                              <img
                                ref={uploadedImage}
                                src={
                                  profileImage ===
                                  "https://d2lfc46helbwci.cloudfront.net/"
                                    ? "/assets/images/no-user.png"
                                    : profileImage !== "" &&
                                      typeof profileImage === "object"
                                    ? URL.createObjectURL(profileImage)
                                    : profileImage ||
                                      "/assets/images/avatar-s-16.jpg"
                                }
                                alt="Icon"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "/assets/images/no-user.png";
                                }}
                              />
                            </div>

                            <Button
                              className="btn btn-primary"
                              onClick={() => handleAddSubCategory()}
                            >
                              {" "}
                              Preview Image
                            </Button>
                          </div>
                        </div>
                        <div className="col-lg-9 col-xxl-10 col-md-8">
                          <div className="row">
                            <div className="col-lg-12 col-xl-6 col-md-12">
                              <div className="form-group mb-4">
                                <label className="form-label" htmlFor="Name">
                                  Name <span className="text-danger">*</span>
                                </label>

                                <input
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  name="name"
                                  placeholder="Enter name"
                                  value={name}
                                  autoComplete="off"
                                  onChange={(e) => handleChange(e)}
                                />
                                <span className="text-danger d-block fs-13">
                                  {errors.name}
                                </span>
                              </div>
                              <div className="form-group mb-4">
                                <label className="form-label" htmlFor="Email">
                                  Email Address{" "}
                                  <span className="text-danger">*</span>
                                </label>

                                <input
                                  className="form-control"
                                  id="emailId"
                                  name="emailId"
                                  placeholder="Enter email"
                                  type="email"
                                  value={emailId}
                                />
                                <span className="text-danger d-block fs-13"></span>
                              </div>
                            </div>
                            <div className="col-lg-12 col-xl-6 col-md-12">
                              <div className="form-group mb-4">
                                <label
                                  className="form-label"
                                  htmlFor="PhoneNumber"
                                >
                                  Phone No.
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  name="mobile"
                                  id="mobile"
                                  value={mobile}
                                  autoComplete="off"
                                  onChange={(e) => handleChange(e)}
                                />

                                <span className="text-danger d-block fs-13 w-100 mt-1">
                                  {errors.mobile}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-md-12 text-end">
                          <Button
                            variant="light"
                            onClick={() => clearProfileData()}
                            className="me-2"
                          >
                            Clear
                          </Button>
                          <Button
                            variant="primary"
                            onClick={(evt) => updateProfile(evt)}
                          >
                            <>
                              <span
                                className=""
                                role="status"
                                aria-hidden="true"
                              ></span>{" "}
                              Save Changes
                            </>
                          </Button>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="Manage Password" title={"Manage Password"}>
                      <div className="row">
                        <div className="col-md-12">
                          <h5 className="card-title mb-4">Change Password</h5>
                          <div className="row mb-5">
                            <div className="col-lg-6 col-xl-4">
                              <div className="user-title-info user-details">
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    htmlFor="Old Password"
                                  >
                                    Old Password{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="w-100">
                                    <input
                                      type="password"
                                      className="form-control"
                                      value={oldPassword}
                                      name=" Old Password"
                                      autoComplete="off"
                                      placeholder="Enter old password"
                                      onChange={(e) => {
                                        setOldPassword(e.target.value);
                                        let changeError = Object.assign(
                                          changeErrors,
                                          { OldPassword: "" }
                                        );
                                        setChangeErrors(changeError);
                                      }}
                                    />
                                    <span className="text-danger d-block fs-13">
                                      {changeErrors.oldPassword}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6 col-xl-4">
                              <div className="user-title-info user-details">
                                <div className="form-group">
                                  <label className="form-label" htmlFor="Name">
                                    New Password{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="w-100">
                                    <input
                                      type="password"
                                      className="form-control"
                                      value={newPassword}
                                      autoComplete="off"
                                      placeholder="Enter new password"
                                      onChange={(e) => {
                                        setNewPassword(e.target.value);
                                        let changeError = Object.assign(
                                          changeErrors,
                                          { newPassword: "" }
                                        );
                                        setChangeErrors(changeError);
                                      }}
                                    />

                                    <span className="text-danger d-block fs-13">
                                      {changeErrors.newPassword}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6 col-xl-4">
                              <div className="user-title-info user-details">
                                <div className="form-group align-items-center">
                                  <label
                                    className="form-label"
                                    htmlFor="Confirm Password"
                                  >
                                    Confirm Password{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="w-100">
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="confirmpassword"
                                      autoComplete="off"
                                      name="confirmPassword"
                                      value={confirmPassword}
                                      placeholder="Enter confirm password"
                                      onChange={(e) => {
                                        setConfirmPassword(e.target.value);
                                        let changeError = Object.assign(
                                          changeErrors,
                                          { confirmPassword: "" }
                                        );
                                        setChangeErrors(changeError);
                                      }}
                                    />
                                    <span className="text-danger d-block fs-13">
                                      {changeErrors.confirmPassword}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-md-12 text-end">
                              <Button
                                variant="light"
                                className="me-2"
                                onClick={() => clearForm()}
                              >
                                Clear
                              </Button>
                              <Button
                                variant="primary"
                                onClick={(evt) => updatePassword(evt)}
                              >
                                {spinner ? (
                                  <>
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>{" "}
                                    Updating...
                                  </>
                                ) : (
                                  "Save Changes"
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </form>
            </div>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
      {/* modal for the image preview in profile */}
      <div
        className="d-flex align-items-center justify-content-between mb-2 flex-wrap"
        style={{ gap: "12px", fontSize: "15px" }}
      >
        <Modal
          show={show1}
          onHide={modelCloseFun1}
          className="border  md={{ span: 3, offset: 3 }}"
        >
          <Modal.Header closeButton>
            <h5 className="modal-title" id="exampleModalLongTitle">
              Preview Image
            </h5>
          </Modal.Header>
          <Modal.Body closeButton>
            <div className="notification-form">
              <div className="row">
                <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <img
                      ref={uploadedImage}
                      src={
                        profileImage ===
                        "https://d2lfc46helbwci.cloudfront.net/"
                          ? "/assets/images/no-user.png"
                          : profileImage !== "" &&
                            typeof profileImage === "object"
                          ? URL.createObjectURL(profileImage)
                          : profileImage || "/assets/images/avatar-s-16.jpg"
                      }
                      style={{
                        display: "block",
                        width: "175px",
                        maxHeight: "155px",
                      }}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="me-2"
              variant="primary"
              onClick={() => imageUploader.current.click()}
            >
              Change Image
            </Button>
            <Button onClick={acceptProfileImage}>Accept Image</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Layout>
  );
}
/*******************
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
*******************/
const mapStateToProps = (state) => ({});

/****************** 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
*****************/
export default connect(mapStateToProps, { callApi })(Profile);
