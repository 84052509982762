import React, { useState, useEffect } from "react";
import debounce from "lodash.debounce";
import { useDispatch, connect } from "react-redux";
import { Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";

import $ from "jquery";
import Pagination from "rc-pagination";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import _ from "lodash";
import { adminListing, downloadUsersList } from "../../Store/Actions/User";
import { callApi } from "../../Api";

import { editUser } from "../../Store/Actions";
import {
  showMessageNotification,
  showModalNotification,
} from "../../Utils/Functions";
import API from "../../Api/Routes";
import Layout from "../../Components/Layout/Layout";

import "rc-pagination/assets/index.css";
import "react-datepicker/dist/react-datepicker.css";
import "antd/dist/antd.css";

$.DataTable = require("datatables.net");
require("datatables.net-responsive");

/******************* 
@Purpose : Used for admin users page
@Parameter : {props}
@Author : INIC
******************/
function AdminUsers(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [downloadSelect, setDownloadSelect] = useState("");

  const [pagesize, setPagesize] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [, setShowEditOption] = useState(false);
  const [, setShowDeleteOption] = useState(false);
  const [, setShowAddOption] = useState(false);
  const [adminUsers, setAdminUsers] = useState([]);

  /******************* 
  @Purpose : React hook used for get user access
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    if (props.UserAccess && !_.isEmpty(props.UserAccess)) {
      let { UserAccess } = props;
      setShowAddOption(UserAccess.create);
      setShowEditOption(UserAccess.edit);
      setShowDeleteOption(UserAccess.delete);
    }
  }, []);
  /******************* 
  @Purpose : Used forDOWNLOAD  the requests
  @Parameter : e
  @Author : INIC
  ******************/
  const handleDownload = async (e) => {
    setDownloadSelect(e);
    let requiredDownload = e.value;

    if (requiredDownload === "Excel") {
      let body = {
        format: "xls",
      };

      try {
        dispatch(downloadUsersList(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showMessageNotification("Downloaded file successfully", "success");
          }
        });
      } catch (error) {
        setLoading(false);
        console.error();
      }
    }
    if (requiredDownload === "CSV") {
      let body = {
        format: "csv",
      };
      try {
        dispatch(downloadUsersList(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showMessageNotification("Downloaded file successfully", "success");
          }
        });
      } catch (error) {
        setLoading(false);
        console.error();
      }
    }
  };
  /******************* 
  @Purpose : Used for search fields
  @Parameter : {evtKey}
  @Author : INIC
  ******************/
  const searchField = async (evtKey) => {
    try {
      if (evtKey) {
        let body = {
          page: page,
          pagesize: pagesize,
          searchText: evtKey,
        };

        const response = await callApi(
          API.ADMIN_LIST,
          body,
          "post",
          "ADMIN_SEARCH",
          true,
          false,
          true,
          history
        );
        setLoading(false);
        if (response.status === 1) {
          setAdminUsers(response?.data);
        } else if (response.status === 0) {
          showModalNotification("no records were found", "error");
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  /******************* 
  @Purpose : Used for get all the vids
  @Parameter : status, timezoneId
  @Author : INIC
  ******************/
  const getAdminUsersList = async (e) => {
    setLoading(true);

    let body = {
      page: page,
      pagesize: pagesize,
      searchText: "",
    };

    try {
      dispatch(adminListing(body)).then((response) => {
        if (response.status === 1) {
          setAdminUsers(response?.data);

          setTotal(response.totalCount);
        }
      });
    } catch (error) {
      setLoading(false);
      console.error();
    }
  };

  /******************* 
  @Purpose : React hook used for select all check
  @Parameter : {}
  @Author : INIC
  ******************/

  useEffect(() => {
    getAdminUsersList();
  }, []);

  /******************* 
  @Purpose : Used for pagination
  @Parameter : {page, pagesize}
  @Author : INIC
  ******************/
  function paginationChange(page, pagesize) {
    setPage(page);
    setPagesize(pagesize);
  }

  /******************* 
  @Purpose : Used for user state change
  @Parameter : {status, userId}
  @Author : INIC
  ******************/
  const userStatusChange = async (status, userId) => {
    if (!status && userId) {
      swal({
        title: "Are you sure,You Want change status?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let body = { userIds: [userId], status };
          const response = await callApi(
            API.ADMIN_STATUS,
            body,
            "post",
            null,
            true,
            "",
            "",
            history
          );

          if (response) {
            getAdminUsersList();
            showMessageNotification(response.message, "success");
          } else {
            showMessageNotification(response.message, "error");
          }
        }
      });
    }
  };

  /******************* 
  @Purpose : Used for user state change
  @Parameter : {status, userId}
  @Author : INIC
  ******************/
  const userStatusChange1 = async (status, userId) => {
    if (userId) {
      let body = { userIds: [userId], status: true };
      const response = await callApi(
        API.ADMIN_STATUS,
        body,
        "post",
        null,
        true,
        "",
        "",
        history
      );
      if (response) {
        getAdminUsersList();
        showMessageNotification(response.message, "success");
      } else {
        showMessageNotification(response.message, "error");
      }
    }
  };
  /******************* 
  @Purpose : Used for user delete
  @Parameter : {uid}
  @Author : INIC
  ******************/
  const deleteUser = (uid) => {
    swal({
      title: "Are you sure,You Want To Delete ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        let body = { userIds: [uid] };
        const response = await callApi(
          API.DELETE_ADMIN,
          body,
          "post",
          null,
          true,
          "",
          "",
          history
        );
        if (response.status === 1) {
          showMessageNotification("Deleted successfully", "success");
          getAdminUsersList();
        }
      }
    });
  };

  /******************* 
  @Purpose : Used for clear form field data
  @Parameter : {}
  @Author : INIC
  ******************/
  const clearInput = () => {
    setSearchText("");
    getAdminUsersList();
  };

  /******************* 
  @Purpose : used for Bread crumb
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      key: "7",
      title: "Vista Users",

      url: "/adminusers",
    },
  ];
  // download options
  const options = [
    { value: "Excel", label: "Excel" },
    { value: "CSV", label: "CSV" },
  ];
  /******************* 
  @Purpose : Used for search on change
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleSearchChange = (e) => {
    e.persist();
    if (e.target.value.length === 0) {
      clearInput();
    }
    setLoading(true);
    setSearchText(e.target.value);
    handleDebouncedChange(e.target.value);
  };
  /******************* 
  @Purpose : Used for debounce for the search
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleDebouncedChange = React.useMemo(
    () => debounce((evt) => searchField(evt), 1000),
    []
  );

  const imageValidator = (str) => {
    let isImageValid = false;
    let checkPng = str.indexOf(".png");
    let checkJpeg = str.indexOf(".jpeg");
    let checkJpg = str.indexOf(".jpg");
    let checkWebP = str.indexOf(".webp");
    if (
      checkPng !== -1 ||
      checkJpeg !== -1 ||
      checkJpg !== -1 ||
      checkWebP !== -1
    ) {
      return (isImageValid = true);
    } else {
      return (isImageValid = false);
    }
  };
  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Vista Users">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <form>
              <div className="card">
                <div className="card-header">
                  <div className="notification-card tabs-block">
                    <div className="content-area position-relative">
                      <div className="user-listing-filterOptions mb-2 d-block">
                        <div className="row mb-2">
                          <div className="col-sm-8 position-static">
                            <div className="left-buttons d-flex ">
                              <label>
                                <div className="search-input-wrapper position-relative">
                                  <i className="icon-magnifier"></i>{" "}
                                  <input
                                    type="search"
                                    value={searchText}
                                    className="form-control text-capitalize"
                                    placeholder="search"
                                    onChange={(evt) => {
                                      handleSearchChange(evt);
                                    }}
                                  />
                                  {searchText && searchText.length > 0 ? (
                                    <div className="clear">
                                      <span
                                        onClick={clearInput}
                                        style={{ marginRight: "20px" }}
                                      >
                                        <em className="icon-close"></em>
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              </label>
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div
                              className="right-buttons d-flex justify-content-end"
                            >
                              <div className="form-group me-2">
                                <div className="w-100">
                                  <Select
                                    id="download"
                                    name="download"
                                    options={options}
                                    placeholder="Download"
                                    onChange={(e) => {
                                      handleDownload(e);
                                    }}
                                    value={downloadSelect}
                                  />
                                </div>
                                <span
                                  className="error-msg"
                                  style={{ color: "red" }}
                                ></span>
                              </div>
                                <Button
                                  onClick={() =>
                                    props.history.push(`/adduser`)
                                  }
                                >
                                  Add User
                                </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive mt-4">
                      <table
                        className="table row-border nowrap common-datatable"
                        id="user-listing"
                      >
                        <thead>
                          <tr>
                            <th>
                              <b>Name</b>
                            </th>

                            <th>
                              <b>Email</b>
                            </th>
                            <th>
                              <b>Phone Number</b>
                            </th>
                            <th>
                              <b>Role</b>
                            </th>
                            <th>
                              <b>Status</b>
                            </th>
                            <th className="all">
                              <b>Action</b>
                              <b></b>
                              <b> </b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {adminUsers && adminUsers.length === 0 ? (
                            <tr className="text-center text-danger not-found-txt">
                              <td colSpan="6">
                                {!loading ? (
                                  <h6
                                    className="text-center text-danger not-found-txt"
                                    colSpan="6"
                                  >
                                    No Records Found!
                                  </h6>
                                ) : null}
                              </td>
                            </tr>
                          ) : (
                            adminUsers &&
                            Array.isArray(adminUsers) &&
                            adminUsers.map((user) => {
                              return (
                                <tr key={user?._id}>
                                  {/* check box */}

                                  <td>
                                    <div className="email-col d-flex align-items-center d-flex align-center">
                                      <span className="avtar-md">
                                        <img
                                          src={
                                            user?.photo &&
                                              imageValidator(user?.photo)
                                              ? user?.photo
                                              : "/assets/images/no-user.png"
                                          }
                                        />
                                      </span>
                                      <span className="ps-3">
                                        {user?.name}
                                      </span>
                                    </div>
                                  </td>

                                  <td>
                                    <div className="eamil-col d-flex align-items-center">
                                      <span>{user?.emailId}</span>
                                    </div>
                                  </td>

                                  <td>
                                    <div className="eamil-col d-flex align-items-center">
                                      <span>{user?.mobile}</span>
                                    </div>
                                  </td>

                                  <td>
                                    <div className="eamil-col d-flex align-items-center">
                                      <span>{user?.role?.role}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="custom-control custom-switch light">
                                      <Form.Check
                                        type="switch"
                                        onChange={() => {
                                          if (user.status) {
                                            userStatusChange(
                                              !user.status,
                                              user._id
                                            );
                                          } else if (!user.status) {
                                            userStatusChange1(
                                              user.status,
                                              user._id
                                            );
                                          }
                                        }}
                                        checked={user.status}
                                        id={user?._id}
                                      />

                                      <label
                                        className="custom-control-label"
                                        for={user._id}
                                      ></label>
                                    </div>
                                  </td>

                                  <td>
                                    <div className="d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id="tooltip1">View</Tooltip>
                                        }
                                      >
                                        <a
                                          onClick={() =>
                                            props.history.push(
                                              `/userProfile/${user?._id}`
                                            )
                                          }
                                          className="cursor-pointer me-3"
                                        >
                                          <i className="icon-eye"></i>
                                        </a>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id="edit">Edit</Tooltip>
                                        }
                                      >
                                        <a className="cursor-pointer me-3">
                                          <i
                                            className="icon-note"
                                            onClick={() =>
                                              props.history.push(
                                                `/updateUser/${user?._id}`
                                              )
                                            }
                                          ></i>
                                        </a>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id="delete">Delete</Tooltip>
                                        }
                                      >
                                        <a
                                          className="cursor-pointer me-3"
                                          onClick={() => deleteUser(user?._id)}
                                        >
                                          <i className="icon-trash"></i>
                                        </a>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                    {adminUsers && adminUsers.length < 10 ? (
                      <div>
                        <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                          <p className="mb-0 align-self-center text-primary-light fs-14">
                            Showing <b> {adminUsers.length} </b> Out of{" "}
                            <b> {total} </b> Brands
                          </p>

                          <div className="pagination-list ms-2">
                            <Pagination
                              className="ant-pagination"
                              pageSize={pagesize}
                              current={page}
                              total={total}
                              onChange={paginationChange}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                        <p className="mb-0 align-self-center text-primary-light fs-14">
                          Showing <b> 10 </b> Out of{" "}
                          <b> {total} </b> Users
                        </p>
                        <div className="pagination-list ms-2">
                          <Pagination
                            className="ant-pagination"
                            pageSize={pagesize}
                            current={page}
                            total={total}
                            onChange={paginationChange}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  );
}
/******************* 
@Purpose : Used for get data from redux
@Parameter : state
@Author : INIC
******************/
const mapStateToProps = (state) => ({
  UserAccess:
    state.admin.adminData &&
    state.admin.adminData.staticRolePermission &&
    state.admin.adminData.staticRolePermission.userAccess,
});
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(mapStateToProps, { callApi, editUser })(AdminUsers);
