import React, { useState } from "react";
import { Button, Tooltip, OverlayTrigger, Form } from "react-bootstrap";
import { Link, withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { callApi } from "../../Api";
import { changeResize, changeTheme } from "../../Store/Actions";
import errorMessages from "../../Utils/ErrorMessages";
import {
  validatePassword,
  setItem,
  showMessageNotification,
  showModalNotification,
} from "../../Utils/Functions";
import API from "../../Api/Routes";
import { emailRegex } from "../../Utils/Regex";
/******************* 
@Purpose : Used for admin login view
@Parameter : props
@Author : INIC
******************/
function Login(props) {
  const history = useHistory();
  const [emailId, setEmailId] = useState(localStorage.getItem("emailId"));
  const [, setIsFormValid] = useState(true);
  let [errors, setErrors] = useState({ emailId: "", password: "" });
  const [password, setPassword] = useState("");
  let [loading, setLoading] = useState(false);
  let [hidden, setHidden] = useState(true);
  let storeDataPer;
  /******************* 
  @Purpose : Used for validate form data
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateForm = () => {
    let mailFormat = emailRegex;

    let tempErrors = { emailId: "", password: "" };
    let isFormValid = true;

    if (!emailId)
      tempErrors.emailId = "*Please enter your email  or phone number";
    else if (!mailFormat.test(emailId))
      tempErrors.emailId = "Please enter vaild email or phone number";
    else tempErrors.emailId = "";

    if (!password.trim()) tempErrors.password = errorMessages.PROVIDE_PASSWORD;
    else if (!validatePassword(password))
      tempErrors.password = errorMessages.PROVIDE_VALID_PASSWORD;
    else tempErrors.password = "";

    if (tempErrors.emailId !== "" || tempErrors.password !== "")
      isFormValid = false;

    setErrors(tempErrors);
    setIsFormValid(isFormValid);
    return isFormValid;
  };

  /******************* 
  @Purpose : Used for rander tooltip data
  @Parameter : {passwordProps}
  @Author : INIC
  ******************/
  function renderTooltip(passwordProps) {
    if (!hidden) {
      return (
        <Tooltip id="button-tooltip" {...passwordProps}>
          Hide Password
        </Tooltip>
      );
    } else {
      return (
        <Tooltip id="button-tooltip" {...passwordProps}>
          Show Password
        </Tooltip>
      );
    }
  }

  /******************* 
  @Purpose : Used for login action
  @Parameter : {passwordProps}
  @Author : INIC
  ******************/
  async function adminLogin(event) {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        let body = {
          emailId: emailId,
          password: password,
        };

        const data = await callApi(API.LOGIN, body, "post", "ADMIN_LOGIN");
        setLoading(false);

        if (data.status === 1) {
          history.push("/dashboard");
          localStorage.setItem("id", data.data._id);
          const dataPermissions = JSON.stringify(data.data.rolePermission);
          sessionStorage.setItem("dataPermissions", dataPermissions);
          if (data.data.theme == "Dark") {
            document.body.classList.add("dark");
            document.body.classList.remove("light");
            props.changeTheme(true);
          } else {
            showModalNotification();
            document.body.classList.add("light");
            document.body.classList.remove("dark");
            props.changeTheme(false);
          }

          if (data.data.menuAlignment == "Horizontal") {
            document.body.classList.add("Horizontal");
            document.body.classList.remove("vertical");
            props.changeResize(true);
          } else {
            document.body.classList.add("vertical");
            document.body.classList.remove("Horizontal");
            props.changeResize(false);
          }

          showMessageNotification(data.message, "success");
          setItem("accessToken", data.access_token);

          sessionStorage.setItem("accessToken", data.access_token);

          props.history.push("/dashboard");
        } else if (!data.status) {
          showMessageNotification(data?.message, "error");
        } else {
          showModalNotification("Something went wrong ", "error");
        }
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
  }

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <div className="App">
      <header className="App-header"></header>

      <div
        className="login-wrapper"
        style={{
          background:
            "url(../assets/images/login-bg.jpg) no-repeat center center",
        }}
      >
        <div className="login-body">
          <div className="login-container row m-0">
            <div className="col-xl-8 col-11">
              <div className="card bg-login-card">
                <div className="row g-0">
                  <div className="col-md-6 col-12 pl-0 pr-0">
                    <div className="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
                      <div className="card-title text-center">
                        <img
                          className="img-fluid"
                          src="/assets/images/logo.svg"
                          alt="Vista App"
                        ></img>
                      </div>
                      <div className="card-header mb-4">
                        <h5 className="mb-0 card-title text-center">Welcome</h5>
                      </div>
                      <div className="card-content">
                        <div className="card-body pb-0">
                          <form onSubmit={(evt) => adminLogin(evt)}>
                            <div className="form-group mb-4">
                              <label className="form-label" for="EmailAddress">
                                Email Address{" "}
                              </label>
                              <input
                                type="email"
                                aria-describedby="emailHelp"
                                placeholder="Enter email"
                                className={
                                  errors.emailId
                                    ? "form-control input-error"
                                    : "form-control"
                                }
                                name="emailId"
                                value={emailId}
                                id="email"
                                autoComplete="off"
                                onChange={(e) => {
                                  setEmailId(e.target.value);
                                  localStorage.setItem(
                                    "emailId",
                                    e.target.value
                                  );
                                  let errors1 = Object.assign(errors, {
                                    emailId: "",
                                  });
                                  setErrors(errors1);
                                }}
                              />
                              <span className="error-msg">
                                {errors.emailId}
                              </span>
                            </div>
                            <div className="form-group mb-4">
                              <label className="form-label" for="Password">
                                Password
                              </label>
                              <input
                                className={
                                  errors.password
                                    ? "form-control input-error"
                                    : "form-control"
                                }
                                name="password"
                                value={password}
                                id="password"
                                type={hidden ? "password" : "text"}
                                placeholder="Password"
                                onChange={(e) => {
                                  setPassword(e.target.value);
                                  let errors1 = Object.assign(errors, {
                                    password: "",
                                  });
                                  setErrors(errors1);
                                }}
                              />
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                              >
                                <span
                                  onClick={() => setHidden(!hidden)}
                                  toggle="#password-field"
                                  className={
                                    hidden
                                      ? "bx bx-hide field-icon toggle-password"
                                      : "bx bx-show field-icon toggle-password"
                                  }
                                ></span>
                              </OverlayTrigger>
                              <em className="fa fa-lock" aria-hidden="true" />
                              <span className="error-msg">
                                {errors.password}
                              </span>
                            </div>
                            <div className="form-group mb-4 d-flex flex-md-row flex-column justify-content-between align-items-center mb-3">
                              <Form.Check
                                inline
                                label="Keep me logged in"
                                name="group1"
                                type="checkbox"
                                id="logged-in"
                              />
                              <Link to="/forgotpassword" className="card-link">
                                Forgot password?
                              </Link>
                            </div>
                            <Button
                              className="btn w-100 btn-primary glow position-relative btn-block"
                              type="submit"
                              onClick={(evt) => adminLogin(evt)}
                            >
                              {loading ? "Loading..." : "Login"}
                              <i className="ms-2 text-white align-middle icon-login"></i>
                            </Button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-md-block d-none text-center align-self-center p-7">
                    <div className="card-content">
                      <img
                        className="img-fluid"
                        src="../assets/images/login.png"
                        alt="login"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
/******************* 
@Purpose : Used for customize theme and connect redux
@Parameter : {}
@Author : INIC
******************/
export default connect(null, { callApi, changeTheme, changeResize })(
  withRouter(Login)
);
