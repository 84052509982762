/******************* 
@Purpose : We can use following api calls and can change api name easily
@Author : INIC
******************/
export default {
  //Authentication
  LOG_OUT: "/admin/logout",
  LOGIN: "/admin/login",
  FORGOT: "/admin/forgotPassword",
  RESET_PASSWORD: "/admin/resetPassword",
  CHANGE_PASSWORD: "/admin/changePassword",
  // Profile
  GET_PROFILE: "/admin/profile",
  UPDATE_PROFILE: "/admin/editProfile",
  PROFILE_IMAGE: "/images/profile",
  //cms
  CMS_DETAILS: "/cmsDetail/",
  CMS_LIST: "/cmsList",
  add_Update_CMS: "/addUpdateCMS",
  CMS_DELETE: "/cmsDelete",

  //Admin Emails
  ADD_EMAIL_SETTINGS: "/addEmailSettings",
  GET_EMAIL_SETTINGS: "/getEmailSettings",
  GET_EMAIL_TITLE: "/getEmailTitle",
  DELETE_EMAIL_SETTINGS: "/deleteEmailSetting/",

  //Email Listing
  EMAIL_LIST: "/listEmail",
  DELETE_EMAIL: "/deleteTemplate",
  CHNAGE_EMAIL_STATUS: "/changeTemplateStatus",
  UPDATE_EMAIL_TEMPLATE: "/addUpdateEmail",

  //Edit Email
  EMAIL_DETAILS: "/detailEmail/",
  ADD_UPDATE_EMAILS: "/addUpdateEmail",

  //Master Management
  COUNTRY_CSV: "/downloadSampleCsvForCountries",
  UPLOAD_CSV: "/bulkUpload",

  //Country
  COUNTRY_LIST: "/countriesListing",
  COUNTRY_STATUS: "/changeCountriesStatus",
  GET_COUNTERY_DETAILS: "/getCountryDetails/",
  DELETE_COUNTRIES: "/deleteCountries",
  ADD_UPDATE_COUNTRY: "/addUpdateCountry",
  ADD_CATEGORY: "/categories/addcategories",
  GET_CATEGORY: "/categories/getcategories",
  EDIT_CATEGORY: "/categories/editCategory",
  CATEGORY_CHANGE_STATUS: "/categories/changeActiveStatus",
  DELETE_CATEGORY: "/categories/deleteCategory",
  SEARCH_CATEGORY: "/categories/search",
  DOWNLOAD_EXCEL: "/categories/downloadExcel",
  DOWNLOAD_CSV: "/categories/downloadcsv",

  // sub category
  ADD_SUB_CATEGORY: "/categories/addsubCategory",
  GET_SUB_CATEGORY: "/categories/getsubCategory",
  SUB_CATEGORY_IMAGES: "/images/subcategory",
  SUB_CATEGORY_IMAGES_SUB_FOLDER: "/images/subCategory/",
  DELETE_SUB_CATEGORY_ROW: "/categories/deletesubCategory",
  EDIT_SUB_CATEGORY: "/categories/editsubCategory",
  //Timezone
  TIMEZONE_LISTING: "/timezoneListing",
  GET_TIMEZONE_DETAILS: "/getTimezoneDetails/",
  DELETE_TIMEZONE: "/deleteTimezones",
  ADD_UPDATE_TIMEZONE: "/addUpdateTimezone",
  TIMEZONE_STATUS: "/changeTimezoneStatus",

  //Currency
  CURRENCY_LIST: "/currenciesListing",
  GET_CURRENCY_DETAILS: "/getCurrencyDetails/",
  DETETE_CURRENCY: "/deleteCurrencies",
  ADD_UPDATE_CURRENCY: "/addUpdateCurrency",
  CURRENCY_STATUS: "/changeCurrenciesStatus",
  // Images
  IMAGES: "/images/category",
  //Users
  SEARCH_BRAND: "/brands/searchByAdmin",
  USERS_STATUS: "/user/changeStatus",
  DELETE_USERS: "/user/deleteUsers",

  //Edit Users
  ADD_NEW_USERS: "/user/addUserByAdmin",
  GET_USERS_PROFILE: "/user/userProfile/",

  //Admin
  ADMIN_LIST: "/admin/userListing",
  ADMIN_STATUS: "/admin/changeStatus",
  DELETE_ADMIN: "/admin/deleteUsers",
  ADD_ADMIN: "/admin/addAdminUser",
  GET_ADMIN_USER: "/admin/userProfile/",
  LIST_ADMIN_ROLE: "/listRole",
  UPDATE_ADMIN: "/admin/updateUser",
  ADMIN_RESET_PASSWORD: "/admin/resetPassword",
  DOWNLOAD_ADMIN: "/admin/downloadSubAdminList",
  ADMIN_PROFILE_IMAGE: "/images/admin/profile",
  UPDATE_EMAIL: "/admin/sendActivationLink",
  //Global Settings
  GET_GLOBAL_SETTINGS: "/getglobalSettings",
  ADD_Global_settings: "/addGlobalSettings",
  GET_TIMEZONE: "/getTimezoneList",
  GET_CURRENCY_list: "/getCurrenciesList",
  GET_DATE_SETTINGS: "/getDateSettings",

  //Payment settings
  GET_PAYMENT_DETAILS: "/getPaymentDetails",
  ADD_PAYEMENT_DETAILS: "/addPaymentDetails",

  //Email Notificatiion setting
  ADD_EMAIL_NOTIFICATIONS: "/addEmailNotificationSettings",
  GET_EMAIL_NOTIFICATIONS: "/getEmailNotificationSettings",

  //Social Media
  GET_SOCIAL_SDK: "/getSocialMediaSDK",
  GET_SOCIAL_LINKS: "/getSocialMediaLinks",
  ADD_SOCIAL_SDK: "/addSocialMediaSDK",
  ADD_SOCIAL_LINKS: "/addSocialMediaLinks",

  //SMTP Settings
  GET_SMTP: "/getSMTPAndSMSSettings",
  ADD_SMTP: "/addSMTPAndSMSSettings",

  //Blog Settings
  ADD_UPDATE_BLOG: "/addUpdateBlog",
  LIST_BLOG: "/listBlog",
  DELETE_BLOG: "/deleteBlog",
  GET_BLOG: "/getBlogDetails/",
  ADD_UPDATE_BLOG_CATEGORY: "/addUpdateblogCategory",
  LIST_BLOG_CATEGORY: "/listBlogCategory",
  DELETE_BLOG_CATEGORY: "/deleteBlogCategory",
  GET_BLOG_CATEGORY_DETAILS: "/getBlogCategoryDetails/",
  CHANGE_BLOG_STATUS: "/changeBlogStatus",
  CHANGE_BLOG_CATEGORY_STATUS: "/changeBlogCategoryStatus",
  ADD_SLUG: "/createBlogSlug",

  //Blog & FAQS Settings
  ADD_UPDATE_FAQ: "/addUpdateFAQ",
  FAQ_LISTNG: "/faqsListing",
  DELETE_FAQ: "/deleteFAQs",
  GET_FAQ_DETAILS: "/getFAQDetails/",
  GET_FAQs_LIST: "/getFAQsList",
  ADD_UDPATE_FAQ_CATEGORY: "/addUpdateFAQCategory",
  FAQ_CATEGORY_LISTING: "/faqCategoriesListing",
  DELETE_FAQ_CATEGORY: "/deleteFAQCategories",
  GET_FAQ_CATEGORY_DETAILS: "/getFAQCategoryDetails/",
  GET_FAQ_CATEGORY_LIST: "/getFAQCategoriesList",

  //Common
  DOWNLOAD_FILE: "/downloadUserFile",
  FILE_UPLOAD: "/admin/fileUpload",
  DELETE_TEMP: "/deleteTemplateSettings/",
  SAVED_TEMP_Settings: "/saveTemplateSettings",
  SAVED_FILTER: "/saveFilter",
  DELETE_FILTER: "/deleteFilter/",
  SAVE_COLUMN_SETTINGS: "/saveColumnSettings",
  GET_LIST: "/getCountriesList",

  //Role Management
  LIST_ROLES: "/listRole",
  GET_ROLE_PERMISSION: "/getRolePermission/",
  CHANGE_ROLE_STATUS: "/changeRoleStatus",
  ADD_ROLE: "/addRole",
  DELETE_ROLE: "/deleteRoles",
  // Brand
  BRANDS_LIST: "/brandList",
  // celebrity
  CELEBRITY_LIST: "/celebrityList",
  // dashboard
  DASHBOARD: "/dashboard",
  //brand
  // ADD_BRAND: "/brands/saveBrand",
  ADD_BRAND: "/brands/saveBrandEmail",
  BRAND_IMAGE: "/images/:Brand",
  BRAND_RESET_PASSWORD: "/brands/resetPassword",
  CHANGE_ACTIVE_STATUS: "/brands/changeActiveStatus",
  GET_BRANDS: "/brands/searchByAdmin",
  DELETE_BRAND: "/brands/deleteBrand",
  DOWNLOAD_BRAND_DATA: "/brands/downloadDataSheet",
  BRAND_PROFILE: "/brands/getBrandInfo",
  // settings
  GET_PLAN: "/getPlanConfig",
  GET_COMPANY_INFO: "/getCompanyInfo",
  UPDATE_COMPANY_INFO: "/updateCompanyInfo",
  GET_PAYMENT: "/getPaymentGetwayInfo",
  UPDATE_PAYMENT: "/updatePaymentGetwayInfo",
  GET_MISC_INFO: "/getSettingsMiscInfo",
  UPDATE_MISC_INFO: "/updateSettingsMiscInfo",
  GET_SMTP_INFO: "/getSMTPSettings",
  UPDATE_SMTP_INFO: "/addSMTPSettings",
  SAVE_PLAN: "/saveNewPlan",
  PLAN_CHANGE_STATUS: "/plan/changeActiveStatus",
  // vid's list
  GET_VIDEOS: "/admin/getAllVideos",
  HIDE_VIDEO: "/admin/hideVideo",
  VIDEO_INFO: "/admin/getVideoInfo",
  // celebrity re

  // reported vids
  REPORT_VIDEOS: "/admin/getAllReportedVideos",
  VIDEO_REPORTERS: "/admin/getVideoReporters",
  SEARCH_REPORTERS: "/admin/getVideoReporters",
  // reported comments
  COMMENTS_VIDEOS: "/admin/getVideosWithReportedComments",
  SEARCH_COMMENTS_VIDEOS: "/admin/getVideosWithReportedComments",
  ALL_REPORTED_COMMENTS: "/admin/getAllReportedComments",
  HIDE_COMMENTS: "/admin/hideComment",
  DOWNLOAD_MANAGE_VIDS: "/admin/downloadVideosList",
  DOWNLOAD_WITH_VIDEO_REPORTS: "/admin/downloadReportedVideosList",
  DOWNLOAD_VIDEO_REPORTER_LIST: "/admin/downloadVideoReportersList",
  // celeb req
  GET_REQUEST_HISTORY: "/admin/getRequestHistoryOfUser",
  APPROVE_CELEBRITY: "/admin/approveCelebrity",
  REJECT_CELEBRITY: "/admin/rejectCelebrity",
  CELEBRITY_REQUESTS: "/admin/getCelebrityRequests",
  ALL_CELEBRITIES: "/admin/getAllCelebrities",
  DOWNLOAD_CELEBREQUESTS: "/admin/downloadCelebrityRequests",
  DOWNLOAD_CELEB: "/admin/downloadCelebrityLists",
  //  users list
  USERS_LIST: "/user/userListing",
  REPORTED_VIDS_CARD_DATA: "/admin/getReportWiseVideoCount",
  REPORTED_COMMENTS_CARD_DATA: "/admin/getCommentReportWiseVideoCount",
  COMMENT_REPORTERS: "/admin/getCommentReporters",
  // cms list
  CMS_LISTING: "/cmsList",
  UPDATE_CMS: "/addUpdateCMS",
  // customers list
  DOWNLOAD_CUSTOMERS: "/admin/downloadCustomerLists",
  SEARCH_CUSTOMERS: "/admin/getCustomerList",
  // rejected history
  REJECTED_LIST: "/admin/getRejectedCelebrityRequests",
  DOWNLOAD_REJECTED: "/admin/downloadRejectedCelebrityRequests",
  GET_ALL_PERMISSSIONS: "/getAllPermission",
  // payout
  PAYOUT_LIST: "/payout/getPayoutRequests",
  DOWNLOAD_PAYOUT: "/payout/downloadPayoutRequests",
  REJECT_PAYMENT: "/payout/rejectPayoutRequst",
  APPROVE_PAYOUT: "/payout/approvePayoutRequst",

  PAYOUT_VIDEO_VIEWS: "/payout/getVideoViews",
  // reports
  PAYMENT_LIST: "/reports/getProcessedRequests",
  DOWNLOAD_PAYMENT_LIST: "/reports/downloadProcessedRequests",
  REPORTS_VIDEO_VIEWS: "/reports/getAllVideoViews",
  DOWNLOAD_REPORTS_VIDEO_VIEWS: "/reports/downloadAllVideoViews",
  // brands
  BRAND_LIST: "/api/brands/getAllBrands",

  CHANGEBRANDEMAIL: "/brands/sendAccountLink",
  ALL_BRANDS: "/brands/getAllBrands",
  CUSTOMERS_LIST_ALL: "/admin/getCustomerListWithoutPagination",
  CELEB_REQ_ALL: "/admin/getCelebrityRequestsWithoutPagination",
  CELEB_ALL: "/admin/getAllCelebritiesWithoutPagination",
  // dashboard
  CARDS_AND_HIGH_VIEW_VIDEOS: "/admin/dasboardData",
  MONTHWISE_VID_COUNT: "/admin/monthWiseVideoViewData",
  SUBSCRIPTION_DATA: "/admin/monthWiseEarningsData",
  CELEB_DROPDOWN: "/brandManagement/getCelebrityList",
  PRODUCT_VIEWS: "/payout/getProductViews",
  DOWNLOAD_PRODUCT_VIEWS: "/payout/downloadProductViews",
  DOWNLOAD_REPORTS_PRODUCTCLICKS: "/reports/downloadAllProductViews",
  REPORTS_PRODUCT_VIEWS: "/reports/getAllProductViews",
  REPORTS_VIDEO_PURCHASE: "/reports/getVidPurchaseReportsAdmin",
  DOWNLOAD_PURCHASE_VIDS: "/reports/downloadVidPurchaseReportsAdmin",
  PAYOUT_VID_PURCHASE: "/payout/downloadVidPurchasedData",
  PAYOUT_VID_DATA: "/payout/getVidPurchasedData",
  GET_CITIES: "/admin/getCitiesOfCustomers",
  // /reports/downloadVidPurchaseReportsAdmin
};
