import React, { useState, useEffect, useRef, useCallback } from "react";
import swal from "sweetalert";
import {
  OverlayTrigger,
  Tooltip,
  Tab,
  Tabs,
  Form,
  Modal,
  Button,
  Image,
} from "react-bootstrap";
import Select from "react-select";

import Pagination from "rc-pagination";
import moment from "moment";
import "rc-pagination/assets/index.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import debounce from "lodash.debounce";
import { useDispatch } from "react-redux";
import _, { isEmpty } from "lodash";
import { Layout, Theme } from "../../Components/Layout";
import { callApi } from "../../Api";
import { useLocation, useHistory } from "react-router-dom";
import {
  celebrityRequests,
  rejectRequest,
  downloadRequests,
  celebList,
  downloadCelebrities,
  approveCelebrity,
  downloadRejected,
} from "../../Store/Actions/User";

import "antd/dist/antd.css";
import { useOnClickOutside } from "../../Hooks";

import $ from "jquery";
import errorMessages from "../../Utils/ErrorMessages";
import API from "../../Api/Routes";
import {
  showMessageNotification,
  showModalNotification,
} from "../../Utils/Functions";

$.DataTable = require("datatables.net");
require("datatables.net-responsive");
/******************* 
@Purpose : Used for filter options
@Parameter : props
@Author : INIC
******************/

/******************* 
@Purpose : Used for users list view
@Parameter : props
@Author : INIC
******************/

function CelebrityList(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [eventKey, setEventKeys] = useState();
  const [downloadSelect, setDownloadSelect] = useState("");
  const [downloadSelect1] = useState("");

  const [toggleSelect, setToggleSelect] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [pagesize, setPagesize] = useState(10);
  const [page, setPage] = useState(1);
  const [request, setRequest] = useState([]);
  const [loading, setLoading] = useState(true);

  const [addedFilter] = useState([]);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [changeErrors, setChangeErrors] = useState({
    confirmPassword: "",
  });
  const [, setCelebSpin] = useState(false);
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  const [celebFilter, setCelebFilter] = useState([]);
  const [searchText1, setSearchtext1] = useState("");
  const [searchText, setSearchtext] = useState("");
  const [searchText2, setSearchtext2] = useState("");
  const [downloadSelect2] = useState("");
  const [totalReq, setTotalReq] = useState(0);
  const [filterObj, setFilterObj] = useState([
    {
      key: "",
      type: "",
      input: "",
      value: { startDate: "", endDate: "" },
      condition: "$and",
    },
  ]);
  const [show1, setShow1] = useState(false);
  const [rejectTotal, setRejectTotal] = useState(0);

  const [, setUserStatus] = useState("");

  const [allChecked, setAllChecked] = useState(false);
  const [loadingcheckbox, setLoadingcheckbox] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [filterpopup, openFilterpopup] = useState(false);
  const [, openDownloadpopup] = useState(false);

  const [, setisFormValidPassword] = useState(true);
  const [approveCeleb, setApproveCeleb] = useState([]);
  const [celebcompleateData, setCelebCompleateData] = useState([]);
  const [totalCeleb, setTotalCeleb] = useState(0);
  const [errorsLicence, setErrorsLicence] = useState([
    {
      key: "",
      type: "",
      input: "",
      value: { startDate: "", endDate: "" },
    },
  ]);
  const refFilter = useRef();
  const refDownload = useRef();
  const onCloseFilter = useCallback(() => openFilterpopup(false), []);
  useOnClickOutside(refFilter, onCloseFilter);
  const onCloseDownload = useCallback(() => openDownloadpopup(false), []);
  useOnClickOutside(refDownload, onCloseDownload);
  const [requestsCompleate, setRequestCompleate] = useState([]);
  const [reqPage, setReqPage] = useState(1);
  const [reqPageSize, setReqPageSize] = useState(10);
  const [rejPage, setRejPage] = useState(1);
  const [rejPageSize, setRejPageSize] = useState(10);
  const [celebPage, setCelebPage] = useState(1);
  const [celebPageSize, setCelebPageSize] = useState(10);

  const breadcrumb = [
    {
      key: "51",
      title: "Manage Celebrity",
    },
  ];
  /******************* 
  @Purpose : Used for get all celeb req
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const getCelebrityRequests = async (e) => {
    setLoading(true);
    let body = {
      pageNumber: reqPage,
      pageSize: reqPageSize,
      keyword: "",
      filter: celebFilter || [],
    };

    try {
      dispatch(celebrityRequests(body)).then((response) => {
        if (response.status === 1) {
          setRequest(response?.requests);
          setTotalReq(response?.totalRequests);
        } else if (response.status === 0) {
          setRequest([]);
          setPage(1);
          setTotalReq(0);
        }
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const getAllTheCelebReq = async (e) => {
    setLoading(true);
    let body = {
      searchText: "",
    };

    try {
      const response = await callApi(
        API.CELEB_REQ_ALL,
        body,
        "post",
        null,
        true,
        "",
        "",
        history
      );

      if (response.status === 1) {
        setRequestCompleate(response?.requests);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  /******************* 
  @Purpose : Used for reset filter
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const getCelebrityRequests1 = async (e) => {
    setLoading(true);
    let body = {
      pageNumber: reqPage,
      pageSize: reqPageSize,
      keyword: "",
      filter: [],
    };

    try {
      dispatch(celebrityRequests(body)).then((response) => {
        if (response.status === 1) {
          setRequest(response?.requests);
        }
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };
  // filterArray
  const handleFilterArray = () => {
    let selected = filterObj.map((item) => item.key.value);
    setSelectedFilterItems(selected);
  };

  /******************* 
  @Purpose : Used for download apis
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const options = [
    { value: "Excel", label: "Excel" },
    { value: "CSV", label: "CSV" },
  ];

  /******************* 
  @Purpose : Used for modal in the rejection
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  function modelCloseFun1() {
    setShow1(false);
  }
  /******************* 
  @Purpose : Used for pagination
  @Parameter : page, pagesize
  @Author : INIC
  ******************/

  // celeb pagination
  function paginationChange3(celebPage, celebPageSize) {
    setCelebPage(celebPage);
    setCelebPageSize(celebPageSize);
    celebrities(celebPage, celebPageSize);
  }
  // req pagination
  function paginationChange1(reqPage, reqPageSize) {
    setReqPage(reqPage);
    setReqPageSize(reqPageSize);
  }
  // rejPagination
  function paginationChange2(rejPage, rejPageSize) {
    setRejPage(rejPage);
    setRejPageSize(rejPageSize);
  }
  /******************* 
  @Purpose : Used for user status change
  @Parameter : status, userId
  @Author : INIC
  ******************/

  /******************* 
  @Purpose : Used for user state change
  @Parameter : {status, userId}
  @Author : INIC
  ******************/
  const userStatusChange = async (status, userId) => {
    if (!status && userId) {
      swal({
        title: "Are you sure,You Want change status?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let body = { userIds: [userId], status };
          const response = await callApi(
            API.USERS_STATUS,
            body,
            "post",
            null,
            true,
            "",
            "",
            history
          );

          if (response) {
            celebrities();
            showMessageNotification(response.message, "success");
          } else {
            showMessageNotification(response.message, "error");
          }
        }
      });
    }
  };
  /******************* 
  @Purpose : Used for user state change
  @Parameter : {status, userId}
  @Author : INIC
  ******************/
  const userStatusChange1 = async (status, userId) => {
    if (userId) {
      let body = { userIds: [userId], status: true };
      const response = await callApi(
        API.USERS_STATUS,
        body,
        "post",
        null,
        true,
        "",
        "",
        history
      );
      if (response) {
        celebrities();
        showMessageNotification(response.message, "success");
      } else {
        showMessageNotification(response.message, "error");
      }
    }
  };
  /******************* 
  @Purpose : Used for bulk actions options
  @Parameter : props
  @Author : INIC
  ******************/
  const options1 = [
    { value: true, label: "Active" },
    { value: false, label: "InActive" },
  ];
  /******************* 
  @Purpose : Used for celebrities list
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const celebrities = async (
    propscelebpage = celebPage,
    propscelebsize = celebPageSize
  ) => {
    setCelebSpin(true);
    let body = {
      pageNumber: propscelebpage,
      pageSize: propscelebsize,
      keyword: "",
      filter: celebFilter || [],
    };
    const response = await callApi(
      API.ALL_CELEBRITIES,
      body,
      "post",
      "CELEBRITIES",
      true,
      "",
      "",
      history
    );
    if (response.status === 1) {
      setCelebSpin(false);
      setAllChecked(false);
      setApproveCeleb(response?.celebrityList);
      setTotalCeleb(response?.totalData);
      setUsersList(response?.celebrityList);
    }
  };
  // celeb compleate list for the filter data
  const getCompleateCelebData = async () => {
    let body = {
      searchText: "",
    };
    const response = await callApi(
      API.CELEB_ALL,
      body,
      "post",
      "CELEBRITIES",
      true,
      "",
      "",
      history
    );
    if (response.status === 1) {
      setCelebCompleateData(response?.celebrityList);
    }
  };
  /******************* 
  @Purpose : Used for celebrities list
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const celebrities1 = async () => {
    let body = {
      pageNumber: 1,
      pageSize: 10,
      keyword: "",
      filter: [],
    };
    const response = await callApi(
      API.ALL_CELEBRITIES,
      body,
      "post",
      "CELEBRITIES",
      true,
      "",
      "",
      history
    );
    if (response.status === 1) {
      setApproveCeleb(response?.celebrityList);
      setTotalCeleb(response?.totalData);
    }
  };

  /******************* 
  @Purpose : Used for bulk actions for bulk status change
  @Parameter : {}
  @Author : INIC
  ******************/
  async function applyBulkActions(e) {
    setToggleSelect(e);
    let data = usersList.filter((rec) => rec.isChecked == true);

    let delArr = data.map(({ _id }) => _id);
    let body;
    if (delArr.length > 0) {
      delArr = _.compact(delArr);
      if (e.value === true) {
        body = { userIds: delArr, status: true };
        const response = await callApi(
          API.USERS_STATUS,
          body,
          "post",
          null,
          true,
          "",
          "",
          history
        );
        if (response.status === 1) {
          showModalNotification(response.message, "success");
          setToggleSelect("");

          celebrities1();
        } else {
          showMessageNotification(response.message, "error");
        }
      } else if (e.value === false) {
        body = { userIds: delArr, status: false };
        const response = await callApi(
          API.USERS_STATUS,
          body,
          "post",
          null,
          true,
          "",
          "",
          history
        );
        if (response.status === 1) {
          showModalNotification(response.message, "success");
          setToggleSelect("");

          celebrities1();
        } else {
          showMessageNotification(response.message, "error");
        }
      }
    }
  }

  /******************* 
  @Purpose : Used for add new filter records
  @Parameter : e
  @Author : INIC
  ******************/
  const addNewRow = (e) => {
    handleFilterArray();
    setErrorsLicence([
      ...errorsLicence,
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    setFilterObj([
      ...filterObj,
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
  };
  /******************* 
  @Purpose : Used for remove filter records
  @Parameter : index
  @Author : INIC
  ******************/
  const removeRow = (index) => {
    let array = [...filterObj];
    array.splice(index, 1);
    setFilterObj(array);
    array = [...errorsLicence];
    array.splice(index, 1);
    setErrorsLicence(array);
    setTimeout(function () {
      setLoadingFilter(true);
    }, 100);
  };

  /******************* 
  @Purpose : Used for handle checkbox
  @Parameter : e
  @Author : INIC
  ******************/
  const handleChangeCheckbox1 = (e) => {
    let list = approveCeleb;
    let checkedALL = allChecked;
    if (e.target.value === "checkAll") {
      list.forEach((item) => {
        item.isChecked = e.target.checked;
        checkedALL = e.target.checked;
      });
    } else {
      // assign the values of checked to e.target.checked
      list.find((item) => item._id === e.target.name).isChecked =
        e.target.checked;
      // this contains the values of the checked one
      let magenicVendors = list.filter(
        (vendor) => vendor["isChecked"] === true
      );

      if (magenicVendors.length === list?.length) {
        checkedALL = true;
      } else {
        checkedALL = false;
      }
    }
    setUsersList(list);
    setAllChecked(checkedALL);
    setTimeout(function () {
      setLoadingcheckbox(!loadingcheckbox);
    }, 100);
  };

  /******************* 
  @Purpose : Used forDOWNLOAD  the requests
  @Parameter : e
  @Author : INIC
  ******************/
  async function handleDownload(e) {
    setDownloadSelect(e);
    let requiredDownload = e.value;

    if (requiredDownload === "Excel") {
      let body = {
        format: "xls",
      };

      try {
        dispatch(downloadRequests(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showModalNotification(response.message, "success");
          } else {
            showModalNotification(response.message, "error");
          }
        });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
    if (requiredDownload === "CSV") {
      let body = {
        format: "csv",
      };
      try {
        dispatch(downloadRequests(body)).then((response) => {
          if (response.status === 1) {
            showModalNotification(response.message, "success");
            window.open(response.data);
          } else {
            showModalNotification(response.message, "error");
          }
        });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
  }
  // handle download
  async function handleDownload1(e) {
    setDownloadSelect(e);
    let requiredDownload = e.value;

    if (requiredDownload === "Excel") {
      let body = {
        format: "xls",
      };

      try {
        dispatch(downloadCelebrities(body)).then((response) => {
          if (response.status === 1) {
            showMessageNotification(response.message, "success");
            window.open(response.data);
          } else {
            showModalNotification(response.message, "error");
          }
        });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
    if (requiredDownload === "CSV") {
      let body = {
        format: "csv",
      };
      try {
        dispatch(downloadCelebrities(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);
            showMessageNotification(response.message, "success");
          } else {
            showModalNotification(response.message, "error");
          }
        });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
  }
  // handle download
  async function handleDownload2(e) {
    setDownloadSelect(e);
    let requiredDownload = e.value;

    if (requiredDownload === "Excel") {
      let body = {
        format: "xls",
      };

      try {
        dispatch(downloadRejected(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showModalNotification(response.message, "success");
          } else {
            showModalNotification(response.message, "error");
          }
        });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
    if (requiredDownload === "CSV") {
      let body = {
        format: "csv",
      };
      try {
        dispatch(downloadRejected(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showModalNotification(response.message, "success");
          } else {
            showModalNotification(response.message, "error");
          }
        });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
  }
  // selected options
  const selectFieldOptions = [
    { value: "dob", label: "Age" },
    { value: "city", label: "City" },
    { value: "state", label: "State" },
    { value: "gender", label: "Gender" },
  ];
  const selectFieldOptions1 = [
    { value: "city", label: "City" },
    { value: "state", label: "State" },
    { value: "gender", label: "Gender" },
    { value: "date", label: "Date" },
  ];
  const selectContentOptions = [
    { value: "name", label: "Name" },

    { value: "date", label: "Date" },
  ];
  // reset filter
  const resetFilter = () => {
    setFilterObj([
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    setSelectedFilterItems([]);
    openFilterpopup(!filterpopup);
    setCelebFilter([]);
    setPage(1);
    getCelebrityRequests1();
    celebrities1();
  };
  // filter api
  const ApplyFilter = async () => {
    try {
      if (validateFiltersearch()) {
        let payload = filterObj.map((item) => {
          if (item.key.label === "City" || item.key.label === "State") {
            return item.type.map((val) => {
              return {
                key:
                  item.key.label === "City"
                    ? "city"
                    : item.key.label === "State"
                    ? "state"
                    : item.key.label === "Gender"
                    ? "gender"
                    : "",
                type: "contains",
                value:
                  item.key.label === "City"
                    ? val?.city
                    : item.key.label === "State"
                    ? val?.state
                    : item.key.label === "Gender"
                    ? val?.value
                    : "",
              };
            });
          }
          return {
            // key:
            // item.key.label === "Category"
            //   ? "categoryId"
            //   : "Date"
            //   ? "date"
            //   : "",
            // : item.key.label === "State"
            key:
              item.key.label === "Gender" ? "gender" : "Dob" ? "age" : "date",
            type: item.key.label === "Date" ? "date" : "contains",

            value:
              item.key.label === "Date"
                ? {
                    startDate: moment(item.value.startDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                    endDate: moment(item.value.endDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                  }
                : item?.type?.value,
          };
        });

        setLoading(true);

        let body = {
          pageNumber: 1,
          pageSize: pagesize,
          keyword: "",
          filter: payload.flat(),
        };

        dispatch(celebList(body)).then((response) => {
          if (response.status === 1) {
            setCelebPage(1);
            setCelebFilter(payload.flat());
            setApproveCeleb(response?.celebrityList);
            setTotalCeleb(response?.totalData);
            openFilterpopup(!filterpopup);
          } else if (response.status === 0) {
            setApproveCeleb([]);
            setTotalCeleb(0);
            showModalNotification("No data was found", "error");
          }
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  // apply filter for the requests
  const ApplyFilter1 = async () => {
    try {
      if (validateFiltersearch()) {
        let payload = filterObj.map((item) => {
          if (
            item.key.label === "City" ||
            item.key.label === "State"
            // item.key.label === "Gender"
          ) {
            return item.type.map((val) => {
              return {
                key:
                  item.key.label === "City"
                    ? "city"
                    : item.key.label === "State"
                    ? "state"
                    : item.key.label === "Gender"
                    ? "gender"
                    : "",
                type: "contains",
                value:
                  item.key.label === "City"
                    ? val?.userInfo?.city
                    : item.key.label === "State"
                    ? val?.userInfo?.state
                    : item.key.label === "Gender"
                    ? val?.value
                    : // ?item.key.value
                      "",
              };
            });
          }
          return {
            // key:
            // item.key.label === "Category"
            //   ? "categoryId"
            //   : "Date"
            //   ? "date"
            //   : "",
            key: item.key.label === "Gender" ? "gender" : "date",
            type: item.key.label === "Date" ? "date" : "contains",

            value:
              item.key.label === "Date"
                ? {
                    startDate: moment(item.value.startDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                    endDate: moment(item.value.endDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                  }
                : item?.type?.value,
          };
        });

        setLoading(true);

        let body = {
          pageNumber: 1,
          pageSize: reqPageSize,
          keyword: "",
          filter: payload.flat(),
        };

        dispatch(celebrityRequests(body)).then((response) => {
          if (response.status === 1) {
            setCelebFilter(payload.flat());
            setRequest(response?.requests);
            setReqPage(1);
            openFilterpopup(!filterpopup);
          } else if (response.status === 0) {
            showModalNotification(response?.message, "error");
            setRequest([]);
            setTotalReq(0);
          }
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  // validate filter search
  const validateFiltersearch = () => {
    let isFormValid = true;

    let valid = filterObj.map((each) => {
      if (each.key.label === "State" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the fields";
      } else {
        if (each.key.label === "State") {
          isFormValid = true;
          each.error = "";
        }
      }
      if (each.key.label === "City" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the fields";
      } else {
        if (each.key.label === "City") {
          isFormValid = true;
          each.error = "";
        }
      }
      if (each.key.label === "Gender" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the fields";
      } else {
        if (each.key.label === "Gender") {
          isFormValid = true;
          each.error = "";
        }
      }

      if (
        each.key.label === "Date" &&
        (each.value.startDate === "" || each.value.endDate === "")
      ) {
        isFormValid = false;
        each.error = "Select the fields";
      } else {
        if (each.key.label === "Date") {
          isFormValid = true;
          each.error = "";
        }
      }
      return each;
    });

    setFilterObj(valid);
    return isFormValid;
  };
  // celeb data for filter
  const ageFilter = [
    {
      value: {
        minAge: 0,
        maxAge: 9,
      },
      label: "< 10",
    },
    {
      value: {
        minAge: 10,
        maxAge: 18,
      },
      label: "10 - 18",
    },
    {
      value: {
        minAge: 18,
        maxAge: 35,
      },
      label: "18 - 35",
    },
    {
      value: {
        minAge: 35,
        maxAge: 150,
      },
      label: "35 <",
    },
  ];
  const approveCelebList = Array.isArray(celebcompleateData)
    ? celebcompleateData
        .filter(
          (item, index) =>
            index ===
            celebcompleateData.findIndex((other) => item.city === other.city)
        )
        .map((item) => ({
          ...item,

          label: item.city,
          value: item.city,
        }))
    : [];

  const approveCelebListState = Array.isArray(celebcompleateData)
    ? celebcompleateData
        .filter(
          (item, index) =>
            index ===
            celebcompleateData.findIndex((other) => item.state === other.state)
        )
        .map((item) => ({
          ...item,

          label: item.state,
          value: item.state,
        }))
    : [];

  // remove duplicates

  // requests data for select format

  const approveCelebLists = Array.isArray(request)
    ? requestsCompleate
        .filter(
          (item, index) =>
            index === request.findIndex((other) => item.city === other.city)
        )
        .map((item) => ({
          ...item,

          label: item?.userInfo?.city,
          value: item?.userInfo?.city,
        }))
    : [];

  const approveCelebListsGender = [
    { value: "MALE", label: "MALE" },
    { value: "FEMALE", label: "FEMALE" },
  ];
  const approveCelebListsState = Array.isArray(request)
    ? requestsCompleate
        .filter(
          (item, index) =>
            index === request.findIndex((other) => item.state === other.state)
        )
        .map((item) => ({
          ...item,

          label: item?.userInfo?.state,
          value: item?.userInfo?.state,
        }))
    : [];

  /******************* 
  @Purpose : Used for display filtered data
  @Parameter : {}
  @Author : Vista app
  ******************/
  const renderFilter = () => {
    return (
      <div className="custom-dropdown-menu w-50 dropdown-icon">
        <div className="card-body" style={{ paddingRight: "70px" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-repeat">
                {filterObj &&
                  filterObj.length > 0 &&
                  filterObj.map((o, i) => {
                    let itemType = o.key.label;
                    return (
                      <form
                        key={o?.key?.label}
                        className="form repeater-default"
                        data-limit="5"
                      >
                        <div className="field-form">
                          <div className="target-form">
                            <div
                              className="target-details"
                              style={{ marginBottom: "10px" }}
                            >
                              <div className="row align-items-start">
                                <div className="form-group col-md-5 col-lg-6 mb-2">
                                  <label className="form-label">
                                    Select Field
                                  </label>
                                  <Select
                                    filterOption={(option) =>
                                      !selectedFilterItems.includes(
                                        option.value
                                      )
                                    }
                                    showSearch
                                    placeholder="Enter Field"
                                    optionFilterProp="children"
                                    className="w-100 custom-input filter-select"
                                    value={o.key}
                                    onChange={(value) => {
                                      o.key = value;
                                      o.type = null;
                                      setFilterObj([...filterObj]);

                                      if (errorsLicence[i])
                                        delete errorsLicence[i].key;
                                    }}
                                    options={selectFieldOptions}
                                  />

                                  <span className="text-danger error-msg"></span>
                                </div>
                                {itemType === "Date" ? (
                                  <div className="col-md-5 col-lg-5 mb-2 ">
                                    <div className="input-daterange custom-daterange input-control">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <label className="form-label">
                                            Select Start date
                                          </label>
                                          <DatePicker
                                            selected={
                                              o.value.startDate
                                                ? new Date(o.value.startDate)
                                                : ""
                                            }
                                            placeholderText="Select Start Date"
                                            dateFormat="MMM d yyyy"
                                            className="form-control"
                                            onChange={(evt) => {
                                              o.value.startDate = evt;
                                              setFilterObj([...filterObj]);
                                              if (errorsLicence[i])
                                                delete errorsLicence[i].value
                                                  .startDate;
                                            }}
                                          />
                                          <span className="text-danger error-msg">
                                            {o.error}
                                          </span>
                                        </div>
                                        <div className="col-md-6">
                                          <label className="form-label">
                                            Select end date
                                          </label>

                                          <DatePicker
                                            selected={
                                              o.value.endDate
                                                ? new Date(o.value.endDate)
                                                : ""
                                            }
                                            minDate={
                                              o.value.startDate
                                                ? new Date(o.value.startDate)
                                                : new Date()
                                            }
                                            disabled={
                                              o.value.startDate ? false : true
                                            }
                                            dateFormat="MMM d yyyy"
                                            placeholderText="Select End Date"
                                            className="form-control"
                                            onChange={(evt) => {
                                              o.value.endDate = evt;
                                              setFilterObj([...filterObj]);
                                              if (errorsLicence[i])
                                                delete errorsLicence[i].value
                                                  .endDate;
                                            }}
                                          />
                                          <span className="text-danger error-msg">
                                            {o.error}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="form-group col-md-5 col-lg-6 mb-2">
                                    <label className="form-label">
                                      {o.key.label === "Gender"
                                        ? "Select Content"
                                        : "Select Content (max 5)"}
                                    </label>
                                    <Select
                                      closeMenuOnSelect={
                                        o.key.label === "City" ? false : true
                                      }
                                      isMulti={
                                        o.key.label === "City"
                                          ? true
                                          : false || o.key.label === "State"
                                          ? true
                                          : false
                                      }
                                      showSearch={false}
                                      placeholder="Select Type"
                                      className="w-100 custom-input filter-select"
                                      optionFilterProp="children"
                                      value={o.type}
                                      isOptionDisabled={() =>
                                        o.type?.length >= 5
                                      }
                                      onChange={(value) => {
                                        o.type = value;
                                        if (o.type === "date") {
                                          delete o.value;
                                          o.value = {
                                            startDate: "",
                                            endDate: "",
                                          };
                                        }
                                        setFilterObj([...filterObj]);
                                        handleFilterArray();
                                        if (errorsLicence[i])
                                          delete errorsLicence[i].type;
                                      }}
                                      options={
                                        o.key.label === "Age"
                                          ? ageFilter
                                          : o.key.label === "City"
                                          ? approveCelebList
                                          : o.key.label === "State"
                                          ? approveCelebListState
                                          : o.key.label === "Gender"
                                          ? approveCelebListsGender
                                          : selectContentOptions
                                      }
                                    />

                                    <span className="text-danger error-msg">
                                      {o.error}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className="add-row col-lg-2 col-md-2"
                              style={{ display: "flex", gap: "10px" }}
                            >
                              {filterObj.length <= 3 && i === 0 ? (
                                <span
                                  className="add-ic icon-plus text-success"
                                  onClick={(e) => addNewRow(e)}
                                  type="button"
                                ></span>
                              ) : null}
                              {filterObj && filterObj.length >= 2 ? (
                                <span
                                  className="icon-minus mr-1 text-danger"
                                  onClick={() => removeRow(i)}
                                  type="button"
                                ></span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </form>
                    );
                  })}
              </div>

              {loadingcheckbox ? <div></div> : null}

              {loadingFilter ? <div></div> : null}
            </div>
            <div className="col-md-12 text-end pt-4">
              <button
                onClick={() => resetFilter()}
                type="button"
                className="btn btn-light me-2"
              >
                Clear
              </button>
              <button
                onClick={(evt) => ApplyFilter()}
                type="button"
                className="btn btn-primary"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /******************* 
  @Purpose : Used for display filtered data
  @Parameter : {}
  @Author : Vista app
  ******************/
  const renderFilter1 = () => {
    return (
      <div className="custom-dropdown-menu w-50 dropdown-icon">
        <div className="card-body" style={{ paddingRight: "70px" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-repeat">
                {filterObj &&
                  filterObj.length > 0 &&
                  filterObj.map((o, i) => {
                    let itemType = o.key.label;
                    return (
                      <form
                        key={o?.key?.label}
                        className="form repeater-default"
                        data-limit="5"
                      >
                        <div className="field-form">
                          <div className="target-form">
                            <div
                              className="target-details"
                              style={{ marginBottom: "10px" }}
                            >
                              <div className="row align-items-start">
                                <div className="form-group col-md-5 col-lg-6 mb-2">
                                  <label className="form-label">
                                    Select Field
                                  </label>
                                  <Select
                                    filterOption={(option) =>
                                      !selectedFilterItems.includes(
                                        option.value
                                      )
                                    }
                                    showSearch
                                    placeholder="Enter Field"
                                    optionFilterProp="children"
                                    className="w-100 custom-input filter-select"
                                    value={o.key}
                                    onChange={(value) => {
                                      o.key = value;
                                      o.type = null;
                                      setFilterObj([...filterObj]);

                                      if (errorsLicence[i])
                                        delete errorsLicence[i].key;
                                    }}
                                    options={selectFieldOptions1}
                                  />

                                  <span className="text-danger error-msg"></span>
                                </div>
                                {itemType === "Date" ? (
                                  <div className="col-md-5 col-lg-5 mb-2 ">
                                    <div className="input-daterange custom-daterange input-control">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <label className="form-label">
                                            Select Start date
                                          </label>
                                          <DatePicker
                                            selected={
                                              o.value.startDate
                                                ? new Date(o.value.startDate)
                                                : ""
                                            }
                                            placeholderText="Select Start Date"
                                            dateFormat="MMM d yyyy"
                                            className="form-control"
                                            onChange={(evt) => {
                                              o.value.startDate = evt;
                                              setFilterObj([...filterObj]);
                                              if (errorsLicence[i])
                                                delete errorsLicence[i].value
                                                  .startDate;
                                            }}
                                          />
                                          <span className="text-danger error-msg">
                                            {o.error}
                                          </span>
                                        </div>
                                        <div className="col-md-6">
                                          <label className="form-label">
                                            Select end date
                                          </label>

                                          <DatePicker
                                            selected={
                                              o.value.endDate
                                                ? new Date(o.value.endDate)
                                                : ""
                                            }
                                            minDate={
                                              o.value.startDate
                                                ? new Date(o.value.startDate)
                                                : new Date()
                                            }
                                            disabled={
                                              o.value.startDate ? false : true
                                            }
                                            dateFormat="MMM d yyyy"
                                            placeholderText="Select End Date"
                                            className="form-control"
                                            onChange={(evt) => {
                                              o.value.endDate = evt;
                                              setFilterObj([...filterObj]);
                                              if (errorsLicence[i])
                                                delete errorsLicence[i].value
                                                  .endDate;
                                            }}
                                          />
                                          <span className="text-danger error-msg">
                                            {o.error}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="form-group col-md-5 col-lg-6 mb-2">
                                    <label className="form-label">
                                      {o.key.label === "Gender"
                                        ? "Select Content "
                                        : "Select Content (max 5)"}
                                    </label>
                                    <Select
                                      closeMenuOnSelect={
                                        o.key.label === "City" ? false : true
                                      }
                                      isMulti={
                                        o.key.label === "Gender" ? false : true
                                      }
                                      showSearch={false}
                                      placeholder="Select Type"
                                      className="w-100 custom-input filter-select"
                                      optionFilterProp="children"
                                      value={o.type}
                                      isOptionDisabled={() =>
                                        o.type?.length >= 5
                                      }
                                      onChange={(value) => {
                                        o.type = value;
                                        if (o.type === "date") {
                                          delete o.value;
                                          o.value = {
                                            startDate: "",
                                            endDate: "",
                                          };
                                        }
                                        setFilterObj([...filterObj]);
                                        handleFilterArray();
                                        if (errorsLicence[i])
                                          delete errorsLicence[i].type;
                                      }}
                                      options={
                                        o.key.label === "City"
                                          ? approveCelebLists
                                          : o.key.label === "State"
                                          ? approveCelebListsState
                                          : o.key.label === "Gender"
                                          ? approveCelebListsGender
                                          : selectContentOptions
                                      }
                                    />

                                    <span className="text-danger error-msg">
                                      {o.error}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className="add-row col-lg-2 col-md-2"
                              style={{ display: "flex", gap: "10px" }}
                            >
                              {filterObj.length <= 3 && i === 0 ? (
                                <span
                                  className="add-ic icon-plus text-success"
                                  onClick={(e) => addNewRow(e)}
                                  type="button"
                                ></span>
                              ) : null}
                              {filterObj && filterObj.length >= 2 ? (
                                <span
                                  className="icon-minus mr-1 text-danger"
                                  onClick={() => removeRow(i)}
                                  type="button"
                                ></span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </form>
                    );
                  })}
              </div>

              {loadingcheckbox ? <div></div> : null}

              {loadingFilter ? <div></div> : null}
            </div>
            <div className="col-md-12 text-end pt-4">
              <button
                onClick={() => resetFilter()}
                type="button"
                className="btn btn-light me-2"
              >
                Clear
              </button>
              <button
                onClick={(evt) => ApplyFilter1()}
                type="button"
                className="btn btn-primary"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const [id, setId] = useState();
  /******************* 
  @Purpose :handle modal
  @Parameter : {}
  @Author : Vista app
 ******************/
  async function handleAddSubCategory(id) {
    setShow1(true);
    setId(id);
  }
  /******************* 
  @Purpose :clear celebrity search
  @Parameter : {}
  @Author : Vista app
 ******************/
  const clearInput = () => {
    setSearchtext("");
    getCelebrityRequests();
  };
  /******************* 
  @Purpose :clear celebrity search
  @Parameter : {}
  @Author : Vista app
 ******************/
  const clearInput1 = () => {
    setSearchtext1("");
    setSearchtext2("");
    celebrities1();
    getrejectHistory1();
  };
  /******************* 
  @Purpose : Download File
  @Parameter : {}
  @Author : Vista app
  ******************/

  let isSelected = usersList.filter((rec) => rec.isChecked === true);
  let isCheckboxSelected = isSelected.map(({ _id }) => _id);

  const [rejectHistory, setRejectHistory] = useState([]);
  //
  /******************* 
  @Purpose : Used for rejected history
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const getrejectHistory = async () => {
    let body = {
      pageNumber: rejPage,
      pageSize: rejPageSize,
      keyword: searchText2 || "",
    };
    const response = await callApi(
      API.REJECTED_LIST,
      body,
      "post",
      "REJECTED_LIST",
      true,
      "",
      "",
      history
    );

    if (response.status === 1) {
      setRejectHistory(response?.requests);
      setRejectTotal(response?.totalRequests);
    } else {
      setRejectHistory([]);
      setPage(1);
      setRejectTotal(0);
    }
  };
  const getrejectHistory1 = async () => {
    let body = {
      pageNumber: rejPage,
      pageSize: rejPageSize,
      keyword: "",
    };
    const response = await callApi(
      API.REJECTED_LIST,
      body,
      "post",
      "REJECTED_LIST",
      true,
      "",
      "",
      history
    );

    if (response.status === 1) {
      setRejectHistory(response?.requests);
      setRejPage(1);
      setRejectTotal(response?.totalRequests);
    } else {
      setRejectHistory([]);
      setPage(1);
      setRejectTotal(0);
    }
  };
  // code strats here
  useEffect(() => {
    getCelebrityRequests();
  }, [page, pagesize, reqPage, reqPageSize]);
  useEffect(() => {
    getrejectHistory();
  }, [rejPageSize, rejPage]);

  useEffect(() => {
    getAllTheCelebReq();
    getCompleateCelebData();
  }, []);
  useEffect(() => {
    celebrities();
  }, []);

  useEffect(() => {
    if (location.state?.tab) {
      setEventKeys(location.state?.tab);
    }
  }, [location.state?.tab]);

  /******************* 
  @Purpose : approve celebrity
  @Parameter : {}
  @Author : Vista app
  ******************/

  const celebrityApprove = async (id) => {
    let body = {
      requestId: id,
    };

    try {
      const response = await dispatch(approveCelebrity(body));

      if (response.status === 1) {
        showModalNotification("Approved as Celebrity", "success");
        getCelebrityRequests();
        celebrities();
      } else {
        showModalNotification(response.message, "error");
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  /******************* 
  @Purpose : validate modal fields
  @Parameter : {}
  @Author : Vista app
  ******************/
  const validPasswords = () => {
    let changeError = { confirmPassword: "" };
    let isFormValidPassword = true;
    if (!confirmPassword.trim())
      changeError.confirmPassword = errorMessages.PROVIDE_COMMENT;
    else changeError.confirmPassword = "";
    if (changeError.confirmPassword !== "") isFormValidPassword = false;
    setChangeErrors(changeError);
    setisFormValidPassword(isFormValidPassword);
    return isFormValidPassword;
  };
  /******************* 
  @Purpose : reject celeb
  @Parameter : {}
  @Author : Vista app
  ******************/
  const rejectCelebrity = async (id) => {
    let body = {
      requestId: id,
      rejectedComment: confirmPassword,
    };

    try {
      if (validPasswords()) {
        dispatch(rejectRequest(body)).then((response) => {
          if (response.status === 1) {
            showModalNotification("Rejected successfully", "success");
            setConfirmPassword("");
            modelCloseFun1();

            getCelebrityRequests();
            celebrities();
            getrejectHistory();
          }
        });
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };
  /******************* 
  @Purpose : Debounce for searcg celeb req
  @Parameter : {}
  @Author : Vista app
  ******************/
  const handleDebouncedChange = React.useMemo(
    () => debounce((evt) => searchFieldRequests(evt), 1000),
    []
  );
  /******************* 
  @Purpose : Debounce for celeb approval
  @Parameter : {}
  @Author : Vista app
  ******************/
  const handleDebouncedChange1 = React.useMemo(
    () => debounce((evt) => searchFieldRequests1(evt), 1000),
    []
  );
  /******************* 
  @Purpose : Debounce for celeb approval
  @Parameter : {}
  @Author : Vista app
  ******************/
  const handleDebouncedChange3 = React.useMemo(
    () => debounce((evt) => searchFieldRequests2(evt), 1000),
    []
  );
  /******************* 
  @Purpose : search req
  @Parameter : {}
  @Author : Vista app
  ******************/

  const searchFieldRequests = async (evtKey) => {
    setLoading(true);

    try {
      if (evtKey) {
        let body = {
          pageNumber: reqPage,
          pageSize: reqPageSize,
          keyword: evtKey,
        };
        dispatch(celebrityRequests(body)).then((response) => {
          if (response.status === 1) {
            setRequest(response?.requests);
            setPage(1);
            setPagesize(10);
          } else if (response.status === 0) {
            showModalNotification("No requests were found", "error");
          }
        });
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };
  /******************* 
  @Purpose : celebrities search
  @Parameter : {}
  @Author : Vista app
  ******************/
  const searchFieldRequests1 = async (evtKey) => {
    let body = {
      pageNumber: 1,
      pageSize: pagesize,
      keyword: evtKey,
    };

    const response = await callApi(
      API.ALL_CELEBRITIES,
      body,
      "post",
      "CELEBRITIES",
      true,
      "",
      "",
      history
    );
    if (response.status === 1) {
      setCelebPage(1);
      setApproveCeleb(response?.celebrityList);
      setTotalCeleb(response?.totalData);
    } else if (response.status === 0) {
      showModalNotification(response.message, "error");
    }
  };
  /******************* 
  @Purpose : Reject search
  @Parameter : {}
  @Author : Vista app
  ******************/
  const searchFieldRequests2 = async (evtKey) => {
    let body = {
      pageNumber: 1,
      pageSize: rejPageSize,
      keyword: evtKey,
    };

    const response = await callApi(
      API.REJECTED_LIST,
      body,
      "post",
      "REJECT_SEARCH",
      true,
      "",
      "",
      history
    );
    if (response.status === 1) {
      setRejectHistory(response?.requests);
      setRejPage(1);
      setRejectTotal(response?.totalRequests);
    } else if (response.status === 0) {
      showModalNotification(response.message, "error");
    }
  };
  /******************* 
  @Purpose : onchange 
  @Parameter : {}
  @Author : Vista app
  ******************/
  const handleSearchChange = (e) => {
    e.persist();
    if (e.target.value.length === 0) {
      getCelebrityRequests1();
    }
    setLoading(true);
    setSearchtext(e.target.value);
    handleDebouncedChange(e.target.value);
  };
  /******************* 
  @Purpose : on change for the celebrities
  @Parameter : {}
  @Author : Vista app
  ******************/
  const handleSearchChange1 = (e) => {
    e.persist();
    if (e.target.value.length === 0) {
      celebrities1();
    }
    setLoading(true);

    setSearchtext1(e.target.value);
    handleDebouncedChange1(e.target.value);
  };
  /******************* 
  @Purpose : on change for the Reject
  @Parameter : {}
  @Author : Vista app
  ******************/
  const handleSearchChange2 = (e) => {
    e.persist();
    if (e.target.value.length === 0) {
      getrejectHistory1();
    }
    setLoading(true);

    setSearchtext2(e.target.value);
    handleDebouncedChange3(e.target.value);
  };
  function handleTabs(e) {
    setEventKeys(e);
    setFilterObj([
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    openFilterpopup(false);
  }

  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Manage Celebrity">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <form>
              <div className="card">
                <div className="card-header">
                  <div className="notification-card tabs-block">
                    <Tabs
                      // defaultActiveKey={'Requests'}
                      activeKey={eventKey}
                      id="uncontrolled-tab-example"
                      onSelect={(eventKey) => handleTabs(eventKey)}
                      className="pb-1"
                    >
                      <Tab
                        eventKey="Requests"
                        title={`Requests (${request.length})`}
                        className="pt-4"
                      >
                        <div className="content-area position-relative">
                          <div className="user-listing-filterOptions mb-2 d-block">
                            <div className="row mb-2">
                              <div className="col-sm-8 position-static">
                                <div className="left-buttons d-flex ">
                                  <label>
                                    <div className="search-input-wrapper position-relative">
                                      <i className="icon-magnifier"></i>{" "}
                                      <input
                                        type="search"
                                        value={searchText}
                                        className="form-control text-capitalize"
                                        placeholder="search"
                                        onChange={(evt) => {
                                          handleSearchChange(evt);
                                        }}
                                      />
                                      {searchText && searchText.length > 0 ? (
                                        <div className="clear">
                                          <span
                                            onClick={clearInput}
                                            style={{ marginRight: "20px" }}
                                          >
                                            <em className="icon-close"></em>
                                          </span>
                                        </div>
                                      ) : null}
                                    </div>
                                  </label>

                                  <div
                                    id="container1"
                                    className={
                                      filterpopup
                                        ? "custom-dropdown filter-data-dropdown position-static ml-2 open"
                                        : "custom-dropdown filter-data-dropdown position-static ml-2"
                                    }
                                  >
                                    <button
                                      onClick={() => {
                                        openFilterpopup(!filterpopup);
                                        openDownloadpopup(false);
                                      }}
                                      className="btn btn-bordered dropdown-toggle custom-filter-dropdown btn-bg-white d-flex align-items-center"
                                      type="button"
                                    >
                                      <i className="bx bx-filter-alt align-text-top me-2"></i>
                                      Filter
                                    </button>
                                    {renderFilter1()}
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm-4">
                                <div
                                  className="right-buttons d-flex justify-content-end"
                                  style={{ gap: "10px" }}
                                >
                                  <div className="form-group  col-sm-4  d-flex">
                                    <div className="w-100">
                                      <Select
                                        id="download"
                                        name="download"
                                        options={options}
                                        placeholder="Download"
                                        onChange={(e) => {
                                          handleDownload(e);
                                        }}
                                        value={downloadSelect}
                                      />
                                    </div>
                                    <span
                                      className="error-msg"
                                      style={{ color: "red" }}
                                    ></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="filter-labels">
                              {addedFilter.description ? (
                                <div className="multiple-filter">
                                  <label className="mb-0">
                                    {addedFilter.description}
                                  </label>
                                  <label className="remove-label mb-0">
                                    <em className="icon-close"></em>
                                  </label>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div
                          className="d-flex align-items-center justify-content-between mb-2 flex-wrap"
                          style={{ gap: "12px", fontSize: "15px" }}
                        >
                          <Modal
                            show={show1}
                            onHide={modelCloseFun1}
                            className="border  md={{ span: 3, offset: 3 }}"
                          >
                            <Modal.Header closeButton>
                              <h5
                                className="modal-title"
                                id="exampleModalLongTitle"
                              >
                                Rejection Comment
                              </h5>
                            </Modal.Header>
                            <Modal.Body closeButton>
                              <div className="notification-form">
                                <div className="row">
                                  <div className="col-md-12 mb-4">
                                    <div className="form-group">
                                      <label
                                        className="form-label"
                                        htmlFor="CountryName"
                                      >
                                        {"Comment"}
                                        <sup className="text-danger">*</sup>
                                      </label>

                                      <textarea
                                        type="text"
                                        className="form-control"
                                        id="confirmpassword"
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        placeholder="Enter Rejected Comment"
                                        onChange={(e) => {
                                          setConfirmPassword(e.target.value);
                                          let changeErrors1 = Object.assign(
                                            changeErrors,
                                            { confirmPassword: "" }
                                          );
                                          setChangeErrors(changeErrors1);
                                        }}
                                      />
                                      <span className="text-danger d-block">
                                        {changeErrors.confirmPassword}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="light"
                                onClick={(evt) => modelCloseFun1()}
                              >
                                Close
                              </Button>
                              <Button
                                className="me-2"
                                variant="primary"
                                onClick={(evt) => rejectCelebrity(id)}
                              >
                                Reject
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div>

                        <div className="table-responsive">
                          <table
                            className="table row-border nowrap common-datatable"
                            id="user-listing"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Name</b>
                                </th>

                                <th>
                                  <b>Email</b>
                                </th>
                                <th>
                                  <b>Phone Number</b>
                                </th>
                                <th>
                                  <b>Gender</b>
                                </th>
                                <th>
                                  <b>Request Date & Time</b>
                                </th>
                                <th className="all">
                                  <b>Action</b>
                                  <b></b>
                                  <b>
                                    {" "}
                                    <Theme />
                                  </b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {request && request.length === 0 ? (
                                <tr className="text-center text-danger not-found-txt">
                                  <td colSpan="6">
                                    {!loading ? (
                                      <h6
                                        className="text-center text-danger not-found-txt"
                                        colSpan="6"
                                      >
                                        <Image
                                          src="https://vistaadmindev.devpress.net/assets/images/no_data_found.png"
                                          width={200}
                                          height={200}
                                        />
                                      </h6>
                                    ) : null}
                                  </td>
                                </tr>
                              ) : (
                                Array.isArray(request) &&
                                request.map((user, key) => {
                                  return (
                                    <tr key={user?._id}>
                                      <td>
                                        <div className="email-col d-flex align-items-center d-flex align-center ">
                                          <span className="avtar-md">
                                            <img
                                              src={
                                                user?.userInfo
                                                  ?.creatorProfilephoto
                                                  ? user?.userInfo
                                                      ?.creatorProfilephoto
                                                  : "/assets/images/no-user.png"
                                              }
                                            />
                                          </span>

                                          <span className="ps-3">
                                            {user?.userInfo?.firstname}&nbsp;
                                            {user?.userInfo?.lastname}
                                          </span>
                                        </div>
                                      </td>

                                      <td>
                                        <div className="eamil-col d-flex align-items-center">
                                          <span>
                                            {user?.userInfo?.emailId
                                              ? user?.userInfo?.emailId
                                              : "-"}
                                          </span>
                                        </div>
                                      </td>

                                      <td>
                                        <div className="eamil-col d-flex align-items-center">
                                          <span>
                                            {user?.userInfo?.mobileNumber
                                              ? user?.userInfo?.mobileNumber
                                              : "-"}
                                          </span>
                                        </div>
                                      </td>

                                      <td>
                                        <div className="eamil-col d-flex align-items-center">
                                          <span>
                                            {user?.userInfo?.gender
                                              ? user?.userInfo?.gender
                                              : "-"}
                                          </span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="custom-control custom-switch light">
                                          {moment(user?.requestDate).format(
                                            "l, h:mm:ss a"
                                          )}
                                          <label
                                            className="custom-control-label"
                                            htmlFor={user?._id}
                                          ></label>
                                        </div>
                                      </td>

                                      <td>
                                        <div className="d-flex">
                                          <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                              <Tooltip id="view">View</Tooltip>
                                            }
                                          >
                                            <a
                                              onClick={() =>
                                                history.push(
                                                  `/celebrityrequest/${user?.userInfo?._id}`
                                                )
                                              }
                                              className="cursor-pointer me-3"
                                            >
                                              <i className="icon-eye"></i>
                                            </a>
                                          </OverlayTrigger>

                                          <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                              <Tooltip id="approve">
                                                Approve
                                              </Tooltip>
                                            }
                                          >
                                            <a className="cursor-pointer me-3">
                                              <i
                                                className="icon-user-following"
                                                onClick={() =>
                                                  celebrityApprove(user?._id)
                                                }
                                              ></i>
                                            </a>
                                          </OverlayTrigger>

                                          <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                              <Tooltip id="reject">
                                                Reject
                                              </Tooltip>
                                            }
                                          >
                                            <a
                                              className="cursor-pointer me-3"
                                              onClick={() =>
                                                handleAddSubCategory(user?._id)
                                              }
                                            >
                                              <i className="icon-user"></i>
                                            </a>
                                          </OverlayTrigger>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                        {request && request.length < 10 ? (
                          <div>
                            <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                              <p className="mb-0 align-self-center text-primary-light fs-14">
                                Showing <b> {totalReq}</b> Out of{" "}
                                <b> {totalReq} </b>
                              </p>
                              <div className="pagination-list ms-2">
                                <Pagination
                                  className="ant-pagination"
                                  pageSize={reqPageSize}
                                  current={reqPage}
                                  total={totalReq}
                                  onChange={paginationChange1}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                            <p className="mb-0 align-self-center text-primary-light fs-14">
                              Showing
                              <b> 10</b>
                              Out of
                              <b> {request.length}</b>
                            </p>

                            <div className="pagination-list ms-2">
                              <Pagination
                                className="ant-pagination"
                                pageSize={reqPageSize}
                                current={reqPage}
                                total={totalReq}
                                onChange={paginationChange1}
                              />
                            </div>
                          </div>
                        )}
                      </Tab>

                      <Tab
                        eventKey={"Celebrity"}
                        title={"Celebrity"}
                        className="pt-4"
                      >
                        <div className="content-area position-relative">
                          <div className="user-listing-filterOptions mb-2 d-block">
                            <div className="row mb-2">
                              <div className="col-sm-8 position-static">
                                <div className="left-buttons d-flex ">
                                  <label>
                                    <div className="search-input-wrapper position-relative">
                                      <i className="icon-magnifier"></i>{" "}
                                      <input
                                        type="search"
                                        value={searchText1}
                                        className="form-control text-capitalize"
                                        placeholder="search"
                                        onChange={(evt) => {
                                          handleSearchChange1(evt);
                                        }}
                                      />
                                      {searchText1 && searchText1.length > 0 ? (
                                        <div className="clear">
                                          <span onClick={clearInput1}>
                                            <em className="icon-close"></em>
                                          </span>
                                        </div>
                                      ) : null}
                                    </div>
                                  </label>

                                  <div
                                    id="container1"
                                    className={
                                      filterpopup
                                        ? "custom-dropdown filter-data-dropdown position-static open"
                                        : "custom-dropdown filter-data-dropdown position-static"
                                    }
                                  >
                                    <button
                                      onClick={() => {
                                        openFilterpopup(!filterpopup);
                                        openDownloadpopup(false);
                                      }}
                                      className="btn btn-bordered dropdown-toggle custom-filter-dropdown btn-bg-white d-flex align-items-center"
                                      type="button"
                                    >
                                      <i className="bx bx-filter-alt align-text-top me-2"></i>
                                      Filter
                                    </button>
                                    {renderFilter()}
                                  </div>

                                  {isCheckboxSelected.length > 0 ? (
                                    <>
                                      <div className="form-group">
                                        <Select
                                          id="toggleSelect"
                                          name="toggleSelect"
                                          options={options1}
                                          placeholder="Bulk Actions"
                                          onChange={(e) => {
                                            applyBulkActions(e);
                                          }}
                                          className="download-csv me-4"
                                          value={toggleSelect}
                                        />
                                        <span className="text-danger error-msg"></span>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div
                                  className="right-buttons d-flex justify-content-end"
                                  style={{ gap: "10px" }}
                                >
                                  <div className="form-group  col-sm-4  d-flex">
                                    <div className="w-100">
                                      <Select
                                        id="download"
                                        name="download"
                                        options={options}
                                        placeholder="Download"
                                        onChange={(e) => {
                                          handleDownload1(e);
                                        }}
                                        value={downloadSelect1}
                                      />
                                    </div>
                                    <span
                                      className="error-msg"
                                      style={{ color: "red" }}
                                    ></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="table-responsive">
                          <table
                            className="table row-border nowrap common-datatable"
                            id="user-listing"
                          >
                            <thead>
                              <tr>
                                <th className="checkbox-table">
                                  <div className="custom-checkbox">
                                    <label>
                                      <input
                                        type="checkbox"
                                        name="checkAll"
                                        value="checkAll"
                                        checked={allChecked}
                                        onChange={handleChangeCheckbox1}
                                      />
                                      <span></span>
                                    </label>
                                  </div>
                                </th>

                                <th>
                                  <b>&nbsp;Name</b>
                                </th>

                                <th>
                                  <b>Email</b>
                                </th>
                                <th>
                                  <b>Phone Number</b>
                                </th>
                                <th>
                                  <b>Gender</b>
                                </th>
                                <th>
                                  <b>Status</b>
                                </th>
                                <th className="all">
                                  <b>Action</b>
                                  <b></b>
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {approveCeleb && approveCeleb.length === 0 ? (
                                <tr className="text-center text-danger not-found-txt">
                                  <td colSpan="6">
                                    {!loading ? (
                                      <h6
                                        className="text-center text-danger not-found-txt"
                                        colSpan="6"
                                      >
                                        <Image
                                          src="https://vistaadmindev.devpress.net/assets/images/no_data_found.png"
                                          width={200}
                                          height={200}
                                        />
                                      </h6>
                                    ) : null}
                                  </td>
                                </tr>
                              ) : (
                                Array.isArray(approveCeleb) &&
                                approveCeleb.map((user, i) => {
                                  return (
                                    <tr key={user?._id}>
                                      <td>
                                        <div className="custom-checkbox">
                                          <label>
                                            <input
                                              type="checkbox"
                                              checked={user.isChecked}
                                              onChange={handleChangeCheckbox1}
                                              name={user?._id}
                                            />
                                            <span />
                                          </label>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="email-col d-flex align-items-center d-flex align-center">
                                          <span className="avtar-md">
                                            <img
                                              src={
                                                user?.creatorProfilephoto
                                                  ? user?.creatorProfilephoto
                                                  : "/assets/images/no-user.png"
                                              }
                                            />
                                          </span>

                                          <span className="ps-3">
                                            {user?.firstname}&nbsp;
                                            {user?.lastname}
                                          </span>
                                        </div>
                                      </td>

                                      <td>
                                        <div className="eamil-col d-flex align-items-center">
                                          <span>
                                            {user?.emailId
                                              ? user?.emailId
                                              : "-"}
                                          </span>
                                        </div>
                                      </td>

                                      <td>
                                        <div className="eamil-col d-flex align-items-center">
                                          <span>
                                            {user?.mobileNumber
                                              ? user?.mobileNumber
                                              : "-"}
                                          </span>
                                        </div>
                                      </td>

                                      <td>
                                        <div className="eamil-col d-flex align-items-center">
                                          <span>
                                            {user?.gender ? user?.gender : "-"}
                                          </span>
                                        </div>
                                      </td>

                                      <td>
                                        <div className="custom-control custom-switch light">
                                          <Form.Check
                                            type="switch"
                                            onChange={() => {
                                              if (user.status) {
                                                userStatusChange(
                                                  !user.status,
                                                  user._id
                                                );
                                              } else if (!user.status) {
                                                userStatusChange1(
                                                  user.status,
                                                  user._id
                                                );
                                              }
                                            }}
                                            checked={user.status}
                                            id={user?._id}
                                          />

                                          <label
                                            className="custom-control-label"
                                            htmlFor={user._id}
                                          ></label>
                                        </div>
                                      </td>

                                      <td>
                                        <div className="d-flex ml-3">
                                          <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                              <Tooltip id="tooltip1">
                                                View
                                              </Tooltip>
                                            }
                                          >
                                            <a
                                              onClick={() =>
                                                history.push(
                                                  `/celebritydetails/${user?._id}`,
                                                  eventKey
                                                )
                                              }
                                              className="cursor-pointer me-3"
                                            >
                                              <i className="icon-eye"></i>
                                            </a>
                                          </OverlayTrigger>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                        {approveCeleb && approveCeleb.length < 10 ? (
                          <div>
                            <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                              <p className="mb-0 align-self-center text-primary-light fs-14">
                                Showing{" "}
                                <b className="me-1">{approveCeleb.length}</b>
                                Out of
                                <b> {totalCeleb} </b> Celebrity
                              </p>

                              <div className="pagination-list ms-2">
                                <Pagination
                                  className="ant-pagination"
                                  pageSize={celebPageSize}
                                  current={celebPage}
                                  total={totalCeleb}
                                  onChange={paginationChange3}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                            <p className="mb-0 align-self-center text-primary-light fs-14">
                              Showing <b className="me-1">10</b>
                              Out of
                              <b> {totalCeleb} </b> Celebrity
                            </p>

                            <div className="pagination-list ms-2">
                              <Pagination
                                className="ant-pagination"
                                pageSize={celebPageSize}
                                current={celebPage}
                                total={totalCeleb}
                                onChange={paginationChange3}
                              />
                            </div>
                          </div>
                        )}
                      </Tab>

                      <Tab
                        eventKey="Reject History"
                        title={"Reject History"}
                        className="pt-4"
                      >
                        <div className="content-area position-relative">
                          <div className="user-listing-filterOptions mb-2 d-block">
                            <div className="row mb-2">
                              <div className="col-sm-8 position-static">
                                <div className="left-buttons d-flex ">
                                  <label>
                                    <div className="search-input-wrapper position-relative">
                                      <i className="icon-magnifier"></i>{" "}
                                      <input
                                        type="search"
                                        value={searchText2}
                                        className="form-control text-capitalize"
                                        placeholder="search"
                                        onChange={(evt) => {
                                          handleSearchChange2(evt);
                                        }}
                                      />
                                      {searchText2 && searchText2.length > 0 ? (
                                        <div className="clear">
                                          <span onClick={clearInput1}>
                                            <em className="icon-close"></em>
                                          </span>
                                        </div>
                                      ) : null}
                                    </div>
                                  </label>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div
                                  className="right-buttons d-flex justify-content-end"
                                  style={{ gap: "10px" }}
                                >
                                  <div className="form-group  col-sm-4  d-flex">
                                    <div className="w-100">
                                      <Select
                                        id="download"
                                        name="download"
                                        options={options}
                                        placeholder="Download"
                                        onChange={(e) => {
                                          handleDownload2(e);
                                        }}
                                        value={downloadSelect2}
                                      />
                                    </div>
                                    <span
                                      className="error-msg"
                                      style={{ color: "red" }}
                                    ></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="table-responsive">
                          <table
                            className="table row-border nowrap common-datatable"
                            id="user-listing"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Name</b>
                                </th>

                                <th>
                                  <b>Email</b>
                                </th>
                                <th>
                                  <b>Phone Number</b>
                                </th>
                                <th>
                                  <b>Gender</b>
                                </th>
                                <th>
                                  <b>Date & Time</b>
                                </th>
                                <th className="all">
                                  <b>Action</b>
                                  <b></b>
                                  <b>
                                    {" "}
                                    <Theme />
                                  </b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {rejectHistory && rejectHistory.length === 0 ? (
                                <tr className="text-center text-danger not-found-txt">
                                  <td colSpan="6">
                                    {!loading ? (
                                      <h6
                                        className="text-center text-danger not-found-txt"
                                        colSpan="6"
                                      >
                                        <Image
                                          src="https://vistaadmindev.devpress.net/assets/images/no_data_found.png"
                                          width={200}
                                          height={200}
                                        />
                                      </h6>
                                    ) : null}
                                  </td>
                                </tr>
                              ) : (
                                Array.isArray(rejectHistory) &&
                                rejectHistory.map((user, i) => {
                                  return (
                                    <tr key={user._id}>
                                      <td>
                                        <div className="email-col d-flex align-items-center d-flex align-center">
                                          <span className="avtar-md">
                                            <img
                                              src={
                                                user?.userInfo
                                                  ?.creatorProfilephoto
                                                  ? user?.userInfo
                                                      ?.creatorProfilephoto
                                                  : "/assets/images/no-user.png"
                                              }
                                            />
                                          </span>

                                          <span className="ps-3">
                                            {user?.userInfo?.firstname}&nbsp;
                                            {user?.userInfo?.lastname}
                                          </span>
                                        </div>
                                      </td>

                                      <td>
                                        <div className="eamil-col d-flex align-items-center">
                                          <span>
                                            {user?.userInfo?.emailId
                                              ? user?.userInfo?.emailId
                                              : "-"}
                                          </span>
                                        </div>
                                      </td>

                                      <td>
                                        <div className="eamil-col d-flex align-items-center">
                                          <span>
                                            {user?.userInfo?.mobileNumber
                                              ? user?.userInfo?.mobileNumber
                                              : "-"}
                                          </span>
                                        </div>
                                      </td>

                                      <td>
                                        <div className="eamil-col d-flex align-items-center">
                                          <span>
                                            {user?.userInfo?.gender
                                              ? user?.userInfo?.gender
                                              : "-"}
                                          </span>
                                        </div>
                                      </td>

                                      <td>
                                        <div className="custom-control custom-switch light">
                                          {moment(user?.actionDate).format(
                                            "l, h:mm:ss a"
                                          )}
                                        </div>
                                      </td>

                                      <td>
                                        <div className="d-flex ml-3">
                                          <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                              <Tooltip id="tooltip1">
                                                View
                                              </Tooltip>
                                            }
                                          >
                                            <a
                                              onClick={() =>
                                                history.push(
                                                  `/getRejectedRequestHistoryOfUser/${user?.userId}`,
                                                  eventKey
                                                )
                                              }
                                              className="cursor-pointer me-3"
                                            >
                                              <i className="icon-eye"></i>
                                            </a>
                                          </OverlayTrigger>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                        {rejectHistory && rejectHistory.length < 10 ? (
                          <div>
                            <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                              <p className="mb-0 align-self-center text-primary-light fs-14">
                                Showing <b> {rejectHistory.length} </b>
                                Out of <b> {rejectTotal} </b> Celebrity
                              </p>

                              <div className="pagination-list ms-2">
                                <Pagination
                                  className="ant-pagination"
                                  pageSize={rejPageSize}
                                  current={rejPage}
                                  total={rejectTotal}
                                  onChange={paginationChange2}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                            <p className="mb-0 align-self-center text-primary-light fs-14">
                              Showing <b> 10 </b>
                              Out of <b> {rejectTotal}</b> Celebrity
                            </p>
                            <div className="pagination-list ms-2">
                              <Pagination
                                className="ant-pagination"
                                pageSize={rejPageSize}
                                current={rejPage}
                                // setRejectTotal
                                total={rejectTotal}
                                onChange={paginationChange2}
                              />
                            </div>
                          </div>
                        )}
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  );
}

export default CelebrityList;
