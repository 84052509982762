import React, { useState, useEffect } from "react";
import { Button, Tab, Nav } from "react-bootstrap";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import "antd/dist/antd.css";

import { searchData, searchInActiveBrand } from "../../Store/Actions/Brand";
import ChangeStatusModel from "../../Components/Modals/ChangeStatusModel";
import Layout from "../../Components/Layout/Layout";
import DownloadButton from "../../Components/Download/DownloadButton";
import FilterModel from "../../Components/Modals/FilterModal";
import EditEmailModel from "../../Components/Modals/EditEmailModel";

import "react-datepicker/dist/react-datepicker.css";
import "rc-pagination/assets/index.css";
import { setTabsValue } from "../../Store/Actions/Tabs";
import ActiveBrand from "../../Components/BrandProfile/ActiveBrand";
import InActiveBrand from "../../Components/BrandProfile/InActiveBrand";

$.DataTable = require("datatables.net");
require("datatables.net-responsive");

/******************* 
@Purpose : Used for brand list view
@Parameter : {}
@Author : INIC
******************/
function UsersList() {
  const { tabs } = useSelector((data) => data.tabs);
  const field = 2;
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [, setBrandList] = useState("");
  const [pagesize] = useState(10);
  const [modalShow, setModalShow] = useState(false);
  const [page, setPage] = useState(1);
  const [, setTotal] = useState(0);
  const [filterValue, setFilterValue] = useState([]);
  const [dataId] = useState("");
  const [showModel, setShowModel] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [status, setStatus] = useState(true);

  /******************* 
  @Purpose : Used for brand listing
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(async () => {
    if (tabs === "In-Active Brand") {
      setStatus(false);
      searchField(false, 1, searchText);
    } else if (tabs === "Activated Brand") {
      setStatus(true);
      searchField(true, 1, searchText);
    }
  }, [tabs, searchText]);

  /*******************
    @purpose :  used for Tabs handle 
    @Parameter : {}
    @Author : INIC
  ******************/
  const handleTabs = (eventKey) => {
    setSearchText("");
    dispatch(setTabsValue(eventKey));
  };

  /******************* 
  @Purpose : Used for search value
  @Parameter : keyword
  @Author : INIC
  ******************/
  const searchField = async (tempStatus, tempPage = 1, keyword = "") => {
    let body = {
      searchText: keyword,
      isActive: tempStatus,
      page: tempPage,
      pagesize: pagesize,
    };
    if (tempStatus ?? status) {
      const res = await dispatch(searchData(body, tempPage));
      if (res.status === 1) {
        setPage(Number(res.pageNumber));
        setTotal(res.total);
      } else setBrandList(res.message);
    } else {
      const res = await dispatch(searchInActiveBrand(body, tempPage));
      if (res.status === 1) {
        setPage(Number(res.pageNumber));
        setTotal(res.total);
      } else setBrandList(res.message);
    }
  };

  /******************* 
  @Purpose : used for filter search
  @Parameter : {}
  @Author : Vista app
  ******************/

  const onHide = () => {
    setModalShow(false);
    setEmailModal(false);
  };

  /******************* 
  @Purpose : used for breadcrumb
  @Parameter : {}
  @Author : Vista app
  ******************/
  const breadcrumb = [
    {
      key: "6",
      title: "Brand List",
      url: "/managebrand",
    },
  ];
  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Manage Brand">
      <ChangeStatusModel
        showModel={showModel}
        onHide={setShowModel}
        getAllBrand={searchField}
        dataId={dataId}
        page={page}
        pageSize={pagesize}
      />
      <EditEmailModel
        showModel={emailModal}
        onHide={setEmailModal}
        dataId={dataId}
        page={page}
        pageSize={pagesize}
      />
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block ">
            <div className="row mb-4">
              <div className="col-md-6">
                <div className="d-flex">
                  <div className="search-input-wrapper position-relative me-2">
                    <i className="icon-magnifier"></i>
                    <input
                      type="search"
                      className="form-control text-capitalize"
                      placeholder="search"
                      value={searchText}
                      onChange={(evt) => setSearchText(evt.target.value)}
                    />
                  </div>
                  <Button
                    variant="primary"
                    active
                    className="btn-bordered filter-btn d-flex align-items-center"
                    onClick={() => setModalShow(true)}
                  >
                    <i className="bx bx-filter-alt align-text-top me-2"></i>
                    Filter
                  </Button>
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-end">
                <DownloadButton />
                <Link to="/BrandAccount" className="ms-2">
                  <Button variant="primary" className="btn">
                    Add Brand
                  </Button>
                </Link>
              </div>
            </div>

            <div className="profile-tabs tabs-block p-0">
              <Tab.Container
                id="left-tabs-example"
                activeKey={tabs ? tabs : "Activated Brand"}
                onSelect={(eventKey) => handleTabs(eventKey)}
              >
                <Nav variant="pills" className="mb-5">
                  <Nav.Item>
                    <Nav.Link eventKey="Activated Brand" className="text-white">
                      Active Brand
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="In-Active Brand" className="text-white">
                      In-Active Brand
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content className="notification-card">
                  <Tab.Pane eventKey="Activated Brand">
                    <ActiveBrand filterValue={filterValue} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="In-Active Brand">
                    <InActiveBrand filterValue={filterValue} />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
          <footer>
            <div className="footer-text d-flex flex-wrap align-items-center justify-content-between">
              <span className="mr-5">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>

      <FilterModel
        show={modalShow}
        pageNumber={page}
        page={page}
        setPage={setPage}
        pageSize={10}
        searchText={searchText}
        onHide={onHide}
        field={field}
        setFilterValue={setFilterValue}
        filterValue={filterValue}
      />
    </Layout>
  );
}

export default UsersList;
