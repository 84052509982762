import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import "antd/dist/antd.css";
import moment from "moment";
import Pagination from "rc-pagination";

import {
  ChangeBrandStatus,
  searchInActiveBrand,
} from "../../Store/Actions/Brand";
import ChangeStatusModel from "../../Components/Modals/ChangeStatusModel";

import EditEmailModel from "../../Components/Modals/EditEmailModel";

import "react-datepicker/dist/react-datepicker.css";
import "rc-pagination/assets/index.css";

$.DataTable = require("datatables.net");
require("datatables.net-responsive");

/******************* 
@Purpose : Used for brand list view
@Parameter : {}
@Author : INIC
******************/
function InActiveBrand({ filterValue }) {
  const BrandDetails = useSelector((data) => data?.brand?.inactivebrand);

  const brands = useSelector((data) => data.brand.inactivebrand);
  const dispatch = useDispatch();
  const [searchText] = useState("");
  const [, setBrandList] = useState("");
  const [pagesize, setPagesize] = useState(10);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState({ key: 0, status: false });

  const [dataId, setDataId] = useState("");
  const [showModel, setShowModel] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [, setSpin] = useState(false);
  const [tempFilter, setTempFilter] = useState();

  useEffect(() => {
    if (BrandDetails) {
      setPage(parseInt(BrandDetails.pageNumber));
      setPagesize(parseInt(BrandDetails.pageSize));
      setTotal(BrandDetails.total);
    } else {
      setPage(1);
      setPagesize(10);
      setTotal(0);
    }
  }, [BrandDetails]);
  useEffect(() => {
    setTempFilter(filterValue);
  }, []);
  /******************* 
  @Purpose : Used for brand status change
  @Parameter : {id}
  @Author : INIC
  ******************/
  const ChangeStatus = async (id) => {
    let body = {
      brandId: id,
    };
    setLoading({ key: id, status: true });
    try {
      const res = await dispatch(ChangeBrandStatus(body));
      if (res) {
        searchField("", page);
        setShowModel(false);
        setLoading({ key: 0, status: false });
      }
    } catch (error) {
      console.error();
    }
  };

  /******************* 
  @Purpose : Used for pagination
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const paginationChange = (tempPage, pagesize) => {
    let tempData = filterValue;
    getListing(searchText, tempPage, tempData);
  };
  /******************* 
  @Purpose : Used for search value
  @Parameter : keyword
  @Author : INIC
  ******************/
  const searchField = async (keyword = "", tempPage = 1) => {
    setSpin(true);
    let body = {
      searchText: keyword,
      isActive: false,
      page: tempPage,
      pagesize: pagesize,
      filter: filterValue ? filterValue : [],
    };
    const res = await dispatch(searchInActiveBrand(body, tempPage));
    if (res.status === 1) {
      setSpin(false);
      setPage(Number(res.pageNumber));
      setTotal(res.total ?? 0);
    } else {
      setSpin(false);
      setBrandList(res.message);
    }
  };

  /******************* 
  @Purpose : Used for handle status of brand
  @Parameter : {e,id}
  @Author : inic
  ******************/
  function handleStatus(e, id) {
    setDataId(id);
    if (!e.target.checked) {
      setShowModel(true);
    } else if (e.target.checked) ChangeStatus(id);
  }

  /******************* 
  @Purpose : Used for brand listing
  @Parameter : {keyword,tempPage}
  @Author : INIC
  ******************/
  const getListing = (keyword = "", tempPage = "") => {
    searchField(keyword, tempPage);
  };

  /******************* 
  @Purpose : used for breadcrumb
  @Parameter : {}
  @Author : Vista app
  ******************/

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <>
      <ChangeStatusModel
        showModel={showModel}
        onHide={setShowModel}
        getAllBrand={searchField}
        dataId={dataId}
        page={page}
        pageSize={pagesize}
      />
      <EditEmailModel
        showModel={emailModal}
        onHide={setEmailModal}
        dataId={dataId}
        page={page}
        pageSize={pagesize}
      />
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <table
              className="table row-border nowrap common-datatable"
              id="user-listing"
            >
              <thead>
                <tr>
                  <th>
                    Brand
                    <i aria-hidden="true"></i>
                  </th>
                  <th>
                    Email Address
                    <i aria-hidden="true"></i>
                  </th>
                  <th>
                    Payment Received <i aria-hidden="true"></i>
                  </th>
                  <th>
                    Buisness<i aria-hidden="true"></i>
                  </th>
                  <th>
                    Creation Date & Time
                    <i aria-hidden="true"></i>
                  </th>
                  <th>Status</th>
                  <th className="all">View</th>
                </tr>
              </thead>
              <tbody>
                {BrandDetails && BrandDetails?.data?.length > 0 ? (
                  BrandDetails?.data?.map((data, key) => (
                    <tr key={data?.organization}>
                      <td>{data?.organization}</td>
                      <td>{data?.emailId}</td>
                      <td>{data?.isAccountActivated ? "Yes" : "No"}</td>
                      <td>
                        {data?.isBusinessOnline === undefined
                          ? "-"
                          : data?.isBusinessOnline === true
                            ? "Online"
                            : "Offline"}
                      </td>
                      <td>{moment(data?.createdAt).format("l, h:mm:ss a")}</td>
                      <td>
                        {loading.key === data?._id && loading.status ? (
                          <div
                            className="spinner-border spinner-border-md text-primary "
                            style={{ width: "1.5rem", height: "1.5rem" }}
                            role="status"
                          ></div>
                        ) : (
                          <Form.Check
                            type="switch"
                            onChange={(e) => handleStatus(e, data?._id)}
                            id="custom-switch"
                            checked={data?.status}
                          />
                        )}
                      </td>
                      <td>
                        <div className="d-flex ml-3">
                          <Link
                            to={`/brandprofile/${data?._id}`}
                            className="cursor-pointer"
                          >
                            <i className="icon-eye"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="error-msg  align-item-center text-center ">
                    <td className="text-danger">Brands Not Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
            <div className="row" style={{ display: "contents" }}></div>
            <p className="mb-0 align-self-center text-primary-light fs-14">
              Showing <b>{brands?.data?.length ?? 0}</b> Out of
              <b> {total ?? 0 + " "}</b> Brands
            </p>
            <div className="pagination-list ms-2">
              <Pagination
                className="ant-pagination"
                pageSize={pagesize}
                current={page}
                total={total}
                onChange={paginationChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InActiveBrand;
