import React from "react";

import { Breadcrumb } from "react-bootstrap";

function PrivacyPolicy() {
  /******************* 
  @Purpose : Used for Bread crumb 
  @Parameter : {}
  @Author : Vista app
  ******************/

  const divStyle = {
    fontSize: "18px",
    fontWeight: 500,
  };

  const header = {
    fontWeight: 500,
  };
  return (
    <div>
      <header className="cms-header border-0">
        <div className="container">
          <div className="navigation-bars">
            <nav className="navbar d-flex navbar-expand bd-navbar justify-content-between">
              <div className="left-nav mr-auto d-flex align-items-center">
                <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                  <ul className=" nav navbar-nav">
                    <li>
                      <img
                        className="img-fluid"
                        src="/assets/images/logo.svg"
                        alt=""
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>

      <div className="cms-content-area">
        <div className="container">
          <Breadcrumb>
            <Breadcrumb.Item> PRIVACY POLICY</Breadcrumb.Item>
            <Breadcrumb.Item>PRIVACY POLICY</Breadcrumb.Item>
          </Breadcrumb>

          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="card ">
                <div className="card-header">
                  This Privacy Policy governs the manner in which KS Solutions
                  collects, uses, maintains, and discloses information collected
                  from users such as a “Buyer” and "Celebrity" of the Vista App.
                  This Privacy Policy applies to the App and all products and
                  services offered by KS Solutions.
                </div>
                <div className="card-body">
                  <ol className="ps-0">
                    <li>
                      1.
                      <span style={header}>
                        Personal identification information:{" "}
                      </span>
                      We may collect personal identification information from
                      Users in various ways, including, but not limited to, when
                      Users visit our App, register on the App, place an order,
                      fill out a form, and in connection with other activities,
                      services, features, or resources we make available on our
                      App. Users may be asked for, as appropriate, name, email
                      address, mailing address, phone number. Users may,
                      however, visit our App anonymously. We will collect
                      personal identification information from Users only if
                      they voluntarily submit such information to us. Users can
                      always refuse to supply personal identification
                      information, except that it may prevent them from engaging
                      in certain App-related activities.
                    </li>
                    <li>
                      2.{" "}
                      <span style={header}>
                        Non-personal identification information:
                      </span>{" "}
                      We may collect non-personal identification information
                      about Users whenever they interact with our App.
                      Non-personal identification information may include the
                      browser name, the type of computer or device, and
                      technical information about Users' means of connection to
                      our App, such as the operating system and the Internet
                      service providers utilized, and other similar information.
                    </li>
                    <li>
                      3.{" "}
                      <span style={header}>
                        How we use collected information:{" "}
                      </span>{" "}
                      KS Solutions may collect and use Users' personal
                      information for the following purposes:
                      <ul className="ml-3 mt-3" style={{ marginLeft: "25px" }}>
                        <li style={{ listStyleType: "disc" }}>
                          <span style={divStyle}>
                            To improve customer service:
                          </span>
                          &nbsp;Information you provide helps us respond to your
                          customer service requests and support needs more
                          efficiently.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          <span style={divStyle}>
                            To personalize user experience:
                          </span>
                          We may use information in the aggregate to understand
                          how our Users as a group use the services and
                          resources provided on our App.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          <span style={divStyle}> To improve our App: </span>
                          We may use feedback you provide to improve our
                          products and services.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          <span style={divStyle}>To process payments:</span> We
                          may use the information Users provide about themselves
                          when placing an order only to provide service to that
                          order. We do not share this information with outside
                          parties except to the extent necessary to provide the
                          service.
                        </li>
                        <li style={{ listStyleType: "disc" }}>
                          <span style={divStyle}>
                            To send periodic emails:{" "}
                          </span>{" "}
                          We may use the email address to send User information
                          and updates pertaining to their order. It may also be
                          used to respond to their inquiries, questions, and/or
                          other requests. If the User decides to opt-in to our
                          mailing list, they will receive emails that may
                          include company news, updates, related product or
                          service information, etc. If at any time the User
                          would like to unsubscribe from receiving future
                          emails, we include detailed unsubscribe instructions
                          at the bottom of each email or the User may contact us
                          via our App.
                        </li>
                      </ul>
                    </li>
                    <li>
                      4. How we protect your information: We adopt appropriate
                      data collection, storage, and processing practices and
                      security measures to protect against unauthorized access,
                      alteration, disclosure, or destruction of your personal
                      information, username, password, transaction information,
                      and data stored on our App.
                    </li>
                    <li>
                      5. Sharing your personal information: We do not sell,
                      trade, or rent Users' personal identification information
                      to others. We may share generic aggregated demographic
                      information not linked to any personal identification
                      information regarding visitors and users with our business
                      partners, trusted affiliates, and advertisers for the
                      purposes outlined above.
                    </li>
                    <li>
                      6. Changes to this privacy policy: KS Solutions has the
                      discretion to update this privacy policy at any time. When
                      we do, we will revise the updated date at the bottom of
                      this page. We encourage Users to frequently check this
                      page for any changes to stay informed about how we are
                      helping to protect the personal information we collect.
                      You acknowledge and agree that it is your responsibility
                      to review this privacy policy periodically and become
                      aware of modifications.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
