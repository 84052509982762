import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Spinner from "../../Components/Loader/Spinner";
import { Form, Row, Col } from "react-bootstrap";
import { isEmpty } from "lodash";
import DatePicker from "react-datepicker";
import Pagination from "rc-pagination";
import Select from "react-select";
import debounce from "lodash.debounce";
import moment from "moment";

import {
  videoStatusChange,
  videoHide,
  videoReporters,
  reportersSearch,
  downloadReporters,
} from "../../Store/Actions/User";
import {
  showMessageNotification,
  showModalNotification,
} from "../../Utils/Functions";
import Theme from "../../Components/Layout/Theme";
import Layout from "../../Components/Layout/Layout";

import "rc-pagination/assets/index.css";
import "react-datepicker/dist/react-datepicker.css";

/******************* 
@Purpose : Used for view reported videos
@Parameter : {}
@Author : INIC
*******************/
function ViewReportedVideo() {
  const dispatch = useDispatch();
  const reporterListRedux = useSelector((state) => state.user.reporters);

  const { slug } = useParams();
  const [celebFilter, setCelebFilter] = useState([]);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [videoDetails, setVideoDetails] = useState([]);
  const [hideVideo, setHideVideo] = useState([]);
  const [reporterList, setReporterList] = useState([]);
  const [pagesize, setPagesize] = useState(10);
  const [page, setPage] = useState(1);
  const [filterpopup, openFilterpopup] = useState(false);
  const [searchText, setSearchtext] = useState("");
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  const [total, setTotal] = useState(1);
  const [spin, setSpin] = useState(false);
  const [errorsLicence, setErrorsLicence] = useState([
    {
      key: "",
      type: "",
      input: "",
      value: { startDate: "", endDate: "" },
    },
  ]);
  const [filterObj, setFilterObj] = useState([
    {
      key: "",
      type: "",
      input: "",
      value: { startDate: "", endDate: "" },
      condition: "$and",
    },
  ]);
  const selectFieldOptions = [
    { value: "reportedReason", label: "reportedReason" },
    { value: "date", label: "date" },
  ];
  const [downloadSelect, setDownloadSelect] = useState("");
  const [loading, setLoading] = useState(false);

  /******************* 
  @Purpose : React hook used for get all reported videos
  @Parameter : {}
  @Author : INIC
  *******************/
  useEffect(() => {
    changeStatus(slug);
    getAllReporters(slug);
  }, [slug, page]);

  /******************* 
  @Purpose : Used for Bread crumb
  @Parameter : {}
  @Author : INIC
  *******************/
  const breadcrumb = [
    {
      title: "Reported Videos",
      url: "/reportedvideos",
    },
    {
      title: "Video Detail",
    },
  ];

  /******************* 
  @Purpose : Used for download options
  @Parameter : {}
  @Author : INIC
  *******************/
  const options = [
    { value: "Excel", label: "Excel" },
    { value: "CSV", label: "CSV" },
  ];

  /******************* 
  @Purpose : Used for select content options
  @Parameter : {}
  @Author : INIC
  *******************/
  const selectContentOptions = [
    { value: "reportedReason", label: "reportedReason" },
    { value: "date", label: "date" },
  ];

  /******************* 
  @Purpose : Used for card data options
  @Parameter : {}
  @Author : INIC
  *******************/
  const cardData = [
    {
      id: 1,
      name: "Video Views",
      count: videoDetails?.viewCount,
      iconView: "bx bx-video",
    },
    {
      id: 2,
      name: "Product Clicks",
      count: videoDetails?.affiliateClickCount,
      iconView: "bx bx-show",
    },
    { id: 3, name: "Total Purchase", count: 0, iconView: "bx bx-rupee" },
  ];

  /******************* 
  @Purpose : Used for brand data
  @Parameter : {}
  @Author : INIC
  *******************/
  const reportData = Array.isArray(reporterListRedux)
    ? reporterListRedux.map((item) => ({
      ...item,

      label: item.reason,
      value: item.reason,
    }))
    : [];

  // download api
  const handleDownload = async (e) => {
    setDownloadSelect(e);
    let requiredDownload = e.value;

    if (requiredDownload === "Excel") {
      let body = {
        format: "xls",
        videoId: slug,
      };

      try {
        dispatch(downloadReporters(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showMessageNotification("Downloaded file successfully", "success");
          }
        });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
    if (requiredDownload === "CSV") {
      let body = {
        format: "csv",
        videoId: slug,
      };
      try {
        dispatch(downloadReporters(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showMessageNotification("Downloaded file successfully", "success");
          }
        });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
  };

  /******************* 
  @Purpose : Used for change status 
  @Parameter : {id}
  @Author : INIC
  *******************/
  const changeStatus = async (id) => {
    setSpin(true);
    let body = {
      videoId: id,
    };
    setLoading(true);
    try {
      dispatch(videoStatusChange(body)).then((response) => {
        if (response.status === 1) {
          setSpin(false);
          setVideoDetails(response?.data[0]);
          setHideVideo(response?.data[0]?.status);
        } else if (response.status === 0) {
          setSpin(false);
          showModalNotification(response.message, "error");
        }
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  /******************* 
  @Purpose : Used for clear inputs
  @Parameter : {}
  @Author : INIC
  *******************/
  const clearInput = () => {
    setSearchtext("");
    getAllReporters(slug);
  };

  /******************* 
  @Purpose : Used for handle search change
  @Parameter : {e}
  @Author : INIC
  *******************/
  const handleSearchChange = (e) => {
    e.persist();
    if (e.target.value.length === 0) {
      clearInput();
    }
    setLoading(true);
    setSearchtext(e.target.value);
    handleDebouncedChange(e.target.value);
  };

  /******************* 
  @Purpose : Used for search debounce
  @Parameter : {}
  @Author : INIC
  *******************/
  const handleDebouncedChange = React.useMemo(
    () => debounce((evt) => searchField(evt), 1000),
    []
  );

  /******************* 
  @Purpose : Used for hide videos
  @Parameter : {slug}
  @Author : INIC
  *******************/
  const hideManageVideo = async (slug) => {
    let body = {
      videoId: slug,
    };
    setLoading(true);
    try {
      dispatch(videoHide(body)).then((response) => {
        if (response.status === 1) {
          setHideVideo(!hideVideo);
          showMessageNotification(response?.message, "success");
        } else {
          showMessageNotification(response?.message, "error");
        }
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  /******************* 
  @Purpose : Used for get all reports
  @Parameter : {slug}
  @Author : INIC
  *******************/
  const getAllReporters = async (slug) => {
    let body = {
      videoId: slug,
      pageNumber: page,
      pageSize: pagesize,
      filter: celebFilter || [],
    };
    setLoading(true);
    try {
      dispatch(videoReporters(body)).then((response) => {
        if (response.status === 1) {
          setReporterList(response?.data);
        } else if (response.status === 0) {
          showModalNotification(response.message, "error");
        }
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };
  /******************* 
  @Purpose : Used for reset reports
  @Parameter : {slug}
  @Author : INIC
  *******************/
  const getAllReporters1 = async (slug) => {
    let body = {
      videoId: slug,
      pageNumber: page,
      pageSize: pagesize,
      filter: [],
    };
    setLoading(true);
    try {
      dispatch(videoReporters(body)).then((response) => {
        if (response.status === 1) {
          setReporterList(response?.data);
        }
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };
  /******************* 
  @Purpose : Used for paginations
  @Parameter : {page, pagesize}
  @Author : INIC
  *******************/
  function paginationChange(page, pagesize) {
    setPage(page);
    setPagesize(pagesize);
  }

  /******************* 
  @Purpose : Used for search field
  @Parameter : {evtKey}
  @Author : INIC
  *******************/
  const searchField = async (evtKey) => {
    setLoading(true);
    try {
      if (evtKey) {
        let body = {
          pageNumber: page,
          pageSize: pagesize,
          searchText: evtKey,
          videoId: slug,
        };
        dispatch(reportersSearch(body)).then((response) => {
          if (response.status === 1) {
            setReporterList(response?.data);

            setPage(1);
            setPagesize(10);
          } else if (response.status === 0) {
            showModalNotification("no reports were found", "error");
          }
        });
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  /******************* 
  @Purpose : Used for remove filter records
  @Parameter : {index}
  @Author : INIC
  ******************/
  const removeRow = (index) => {
    let array = [...filterObj];
    array.splice(index, 1);
    setFilterObj(array);
    array = [...errorsLicence];
    array.splice(index, 1);
    setErrorsLicence(array);
    setTimeout(function () {
      setLoadingFilter(true);
    }, 100);
  };

  /******************* 
  @Purpose : Used for add new filter records
  @Parameter : {e}
  @Author : INIC
  ******************/
  const addNewRow = (e) => {
    setErrorsLicence([
      ...errorsLicence,
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    setFilterObj([
      ...filterObj,
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
  };

  /******************* 
  @Purpose : Used for handle filter array
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleFilterArray = () => {
    let selected = filterObj.map((item) => item.key.value);
    setSelectedFilterItems(selected);
  };
  // apply fileter

  const ApplyFilter = async () => {
    try {
      if (validateFiltersearch()) {
        let payload = filterObj.map((item) => {
          if (
            item.key.label === "reportedReason" ||
            item.key.label === "emailId"
          ) {
            return item.type.map((val) => {
              return {
                key:
                  item.key.label === "reportedReason"
                    ? "reportedReason"
                    : item.key.label === "emailId"
                      ? "emailId"
                      : "",
                type: "contains",
                value:
                  item.key.label === "reportedReason"
                    ? val?.reason
                    : item.key.label === "emailId"
                      ? val?.emailId
                      : "",
              };
            });
          }
          return {
            key: item.key.label === "category" ? "categoryId" : "date",
            type: item.key.label === "date" ? "date" : "contains",

            value:
              item.key.label === "date"
                ? {
                  startDate: moment(item.value.startDate).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  ),
                  endDate: moment(item.value.endDate).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  ),
                }
                : item.type._id,
          };
        });
        setLoading(true);
        let body = {
          pageNumber: 1,
          pageSize: pagesize,
          searchText: "",
          videoId: slug,
          filter: payload.flat(),
        };

        dispatch(reportersSearch(body)).then((response) => {
          if (response.status === 1) {
            setCelebFilter(payload.flat());
            setReporterList(response?.data);
            openFilterpopup(!filterpopup);

            setPagesize(10);
            setTotal(response.totalVideos);
            setLoading(false);
          } else if (response.status === 0) {
            showModalNotification("No videos were found", "error");
          }
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  /******************* 
  @Purpose : Used for render filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const renderFilter = () => {
    return (
      <div className="reported-video-wrap">
        <div className="custom-dropdown-menu dropdown-icon card w-50">
          <div className="card-body" style={{ paddingRight: "70px" }}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-repeat">
                  {filterObj &&
                    filterObj.length > 0 &&
                    filterObj.map((o, i) => {
                      let itemType = o.key.label;
                      return (
                        <form
                          key={itemType}
                          className="form repeater-default"
                          data-limit="5"
                        >
                          <div className="field-form">
                            <div className="target-form">
                              <div className="target-details">
                                <div className="row align-items-start">
                                  <div className="form-group col-md-5 col-lg-6 mb-2">
                                    <label className="form-label">
                                      Select Field
                                    </label>

                                    <Select
                                      filterOption={(option) =>
                                        !selectedFilterItems.includes(
                                          option.value
                                        )
                                      }
                                      showSearch
                                      placeholder="Enter Field"
                                      optionFilterProp="children"
                                      className="w-100 custom-input filter-select"
                                      value={o.key}
                                      onChange={(value) => {
                                        o.key = value;

                                        setFilterObj([...filterObj]);

                                        if (errorsLicence[i])
                                          delete errorsLicence[i].key;
                                      }}
                                      options={selectFieldOptions}
                                    />

                                    <span className="text-danger error-msg"></span>
                                  </div>
                                  {itemType === "date" ? (
                                    <div className="col-md-6 col-lg-6 mb-2 ">
                                      <div className="input-daterange custom-daterange input-control">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label className="text-left">
                                              Select Start date
                                            </label>
                                            <DatePicker
                                              selected={
                                                o.value.startDate
                                                  ? new Date(o.value.startDate)
                                                  : ""
                                              }
                                              placeholderText="Select Start Date"
                                              dateFormat="MMM d yyyy"
                                              className="form-control w-100"
                                              onChange={(evt) => {
                                                o.value.startDate = evt;
                                                setFilterObj([...filterObj]);
                                                if (errorsLicence[i])
                                                  delete errorsLicence[i].value
                                                    .startDate;
                                              }}
                                            />
                                            <span className="text-danger error-msg">
                                              {o.error}
                                            </span>
                                          </div>
                                          <div className="col-md-6">
                                            <label className="text-left">
                                              Select end date
                                            </label>

                                            <DatePicker
                                              selected={
                                                o.value.endDate
                                                  ? new Date(o.value.endDate)
                                                  : ""
                                              }
                                              minDate={
                                                o.value.startDate
                                                  ? new Date(o.value.startDate)
                                                  : new Date()
                                              }
                                              disabled={
                                                o.value.startDate ? false : true
                                              }
                                              dateFormat="MMM d yyyy"
                                              placeholderText="Select End Date"
                                              className="form-control w-100"
                                              onChange={(evt) => {
                                                o.value.endDate = evt;
                                                setFilterObj([...filterObj]);
                                                if (errorsLicence[i])
                                                  delete errorsLicence[i].value
                                                    .endDate;
                                              }}
                                            />
                                            <span className="text-danger error-msg">
                                              {o.error}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="form-group col-md-5 col-lg-6 mb-2">
                                      <label className="text-left">
                                        Select Content(Max 5)
                                      </label>
                                      <Select
                                        closeMenuOnSelect={false}
                                        isMulti={
                                          o.key.label === "category"
                                            ? false
                                            : true
                                        }
                                        showSearch={false}
                                        placeholder="Select Type"
                                        className="w-100 custom-input filter-select"
                                        optionFilterProp="children"
                                        value={o.type}
                                        isOptionDisabled={() =>
                                          o.type?.length >= 5
                                        }
                                        onChange={(value) => {
                                          o.type = value;
                                          if (o.type === "date") {
                                            delete o.value;
                                            o.value = {
                                              startDate: "",
                                              endDate: "",
                                            };
                                          }
                                          setFilterObj([...filterObj]);
                                          handleFilterArray();
                                          if (errorsLicence[i])
                                            delete errorsLicence[i].type;
                                        }}
                                        options={
                                          o.key.label === "reportedReason"
                                            ? reportData
                                            : selectContentOptions
                                        }
                                      />

                                      <span className="text-danger error-msg">
                                        {o.error}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                className="add-row col-lg-2 col-md-2"
                                style={{ display: "flex", gap: "10px" }}
                              >
                                {filterObj.length <= 1 ? (
                                  <div className="add-row add-plus">
                                    <span
                                      className="add-ic icon-plus text-success"
                                      onClick={(e) => addNewRow(e)}
                                      type="button"
                                    ></span>
                                  </div>
                                ) : null}
                                {filterObj && filterObj.length >= 2 ? (
                                  <span
                                    className="icon-minus mr-1 text-danger"
                                    onClick={() => removeRow(i)}
                                    type="button"
                                  ></span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </form>
                      );
                    })}
                </div>

                {loadingFilter ? <div></div> : null}

                <div className="col-md-12 text-end pt-4">
                  <button
                    onClick={() => resetFilter()}
                    type="button"
                    className="btn btn-light me-2"
                  >
                    Clear
                  </button>
                  <button
                    onClick={(evt) => ApplyFilter()}
                    type="button"
                    className="btn btn-primary "
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /******************* 
  @Purpose : Used for validate filter search
  @Parameter : props
  @Author : INIC
  ******************/
  const validateFiltersearch = () => {
    let isFormValid = true;

    let valid = filterObj.map((each) => {
      if (each.key.label === "reportedReason" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the reason";
      } else if (
        each.key.label === "reportedReason" &&
        isEmpty(each.type) == false
      ) {
        each.error = "";
        isFormValid = true;
      }

      if (
        each.key.label === "Date" &&
        (each.value.startDate === "" || each.value.endDate === "")
      ) {
        isFormValid = false;
        each.error = "Select the subCategory fields";
      } else {
        if (each.key.label === "Date") {
          isFormValid = true;
          each.error = "";
        }
      }
      return each;
    });

    setFilterObj(valid);
    return isFormValid;
  };
  /******************* 
  @Purpose : Used for reset filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const resetFilter = () => {
    setFilterObj([
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    setCelebFilter([]);
    openFilterpopup(!filterpopup);
    setSelectedFilterItems([]);
    getAllReporters1(slug);
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Reported Videos ">
      <div>VideoDetailPage</div>
      <div className="main-content-area">
        <div className="main-content-block">
          <div className="user-listing-filterOptions mb-2 d-block">
            {/* video details */}

            {/* <div className="content-card mb-3"> */}
            <div className="card mb-4">
              <div className="card-header">
                <h5 className="card-title">Video Detail</h5>
              </div>
              <div className="card-body user-details">
                <div className="row">
                  {spin ? (
                    <div className="d-flex align-center justify-content-center">
                      <Spinner />
                    </div>
                  ) : null}
                  <div className="col-lg-4 col-xxl-3 col-md-12">
                    <div className="video-img position-relative">
                      {videoDetails?.videoLink ? (
                        <video
                          controls={true}
                          className="br-12"
                          style={{
                            height: "170px",
                            width: "100%",
                          }}
                        >
                          <source
                            src={videoDetails?.videoLink}
                            type="video/mp4"
                          />
                        </video>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-8 col-xxl-9">
                    <div className="row">
                      <div className="col-xl-8 col-xxl-6">
                        <div className="row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            TITLE:
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            <div>{videoDetails?.videoTitle}</div>
                          </div>
                        </div>
                        <div className="row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            subcategory:
                          </div>
                          <div className="col-md-8 p-2">
                            <div className="request-data">
                              {Array.isArray(videoDetails?.subcategories)
                                ? videoDetails?.subcategories[0]?.title
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            PRODUCT LINK:
                          </div>
                          <div className="col-md-8 p-2">
                            <div className="request-data">
                              <a
                                href={videoDetails?.affilietedProductUrl}
                                target="_blank"
                              >
                                {videoDetails?.affilietedProductUrl}
                              </a>
                            </div>
                            <span className="text-danger error-msg"></span>
                          </div>
                        </div>

                        <div className="row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            HIDE VIDEO ?
                          </div>
                          <div className="col-md-8 p-2 request-data">
                            <Form.Check
                              type="switch"
                              className="custom-control-input"
                              onChange={() => {
                                hideManageVideo(videoDetails?._id);
                              }}
                              checked={hideVideo}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-8 col-xxl-6">
                        <div className="row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            CATEGORY:
                          </div>
                          <div className="col-md-8 p-2">
                            <div className="request-data">
                              {Array.isArray(videoDetails?.category)
                                ? videoDetails?.category[0]?.title
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div className="row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            CELEBRITY:
                          </div>
                          <div className="col-md-8 p-2">
                            <div className="request-data">
                              {Array.isArray(videoDetails?.celebrityDetails)
                                ? (videoDetails?.celebrityDetails[0]
                                  ?.firstname || "") +
                                "  " +
                                (videoDetails?.celebrityDetails[0]
                                  ?.lastname || "")
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div className="row g-0">
                          <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                            PUBLISH DATE:
                          </div>
                          <div className="col-md-8 p-2">
                            <div className="request-data">
                              {videoDetails?.createdAt
                                ? moment(videoDetails.createdAt).format("L")
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-header">
                <h5 className="card-title">Summary</h5>
              </div>
              <div className="card-body">
                <Row className="g-5">
                  {cardData?.map((data) => {
                    return (
                      <Col sm={6} xl={4} xxl={3} key={data.name}>
                        <div className="user-info-card col d-flex flex-wrap align-items-center p-3">
                          <div className="d-flex align-items-center">
                            <div className="icon-box-wrap">
                              <i className={data?.iconView}></i>
                            </div>
                            <div className="w-100 font-32">
                              <h3 className="text-black m-0 fw-bold">
                                {data.count}
                              </h3>
                              <h6 className="text-orange mb-0 pt-1">
                                {data.name}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
            {/* table list display for the reported vids */}
            <div className="row">
              <div className="content-card mb-3">
                <div className="row">
                  <div className="col-sm-8 position-static">
                    <div className="left-buttons d-flex ">
                      <div className="d-flex align-items-center">
                        <div className="search position-relative has-icon-left">
                          <input
                            type="search"
                            value={searchText}
                            className="form-control text-capitalize"
                            placeholder="search"
                            onChange={(evt) => {
                              handleSearchChange(evt);
                            }}
                          />

                          {searchText && searchText.length > 0 ? (
                            <div className="clear">
                              <span onClick={clearInput}>
                                <em className="icon-close"></em>
                              </span>
                            </div>
                          ) : null}

                          <div className="form-control-position">
                            <em className="icon-magnifier"></em>
                          </div>
                        </div>
                      </div>
                      {/* filter starts  */}
                      <div
                        id="container1"
                        className={
                          filterpopup
                            ? "custom-dropdown filter-data-dropdown position-static ml-2 open"
                            : "custom-dropdown filter-data-dropdown position-static ml-2"
                        }
                      >
                        <button
                          onClick={() => {
                            openFilterpopup(!filterpopup);
                          }}
                          className="btn btn btn-bordered d-flex align-items-center"
                          type="button"
                        >
                          <i className="bx bx-filter d-lg-none"></i>
                          <i className="bx bx-filter-alt align-text-top me-2"></i>
                          Filter
                        </button>
                        {renderFilter()}
                      </div>
                      {/* filter ends */}
                    </div>
                  </div>
                  <div className="col-sm-4 ">
                    <div
                      className="right-buttons d-flex justify-content-end"
                      style={{ gap: "10px" }}
                    >
                      {/* download   mb-md-5 mb-3*/}
                      <div className="form-group  col-sm-4  d-flex">
                        <div className="w-100">
                          <Select
                            id="download"
                            name="download"
                            options={options}
                            placeholder="Download"
                            onChange={(e) => {
                              handleDownload(e);
                            }}
                            value={downloadSelect}
                          />
                        </div>
                        <span
                          className="error-msg"
                          style={{ color: "red" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive mt-4">
                  <table
                    className="table row-border nowrap common-datatable"
                    id="user-listing"
                  >
                    <thead>
                      <tr>
                        <th>
                          <b className="d-none">Image</b>
                        </th>
                        <th>
                          <b>Name</b>
                        </th>

                        <th>
                          <b>Email</b>
                        </th>
                        <th>
                          <b>Phone Number</b>
                        </th>
                        <th>
                          <b>Gender</b>
                        </th>
                        <th>
                          <b>Report Reason</b>
                        </th>
                        <th className="all">
                          <b>Report Date & Time</b>
                          <b></b>
                          <b>
                            {" "}
                            <Theme />
                          </b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {reporterList && reporterList.length === 0 ? (
                        <tr className="text-center text-danger not-found-txt">
                          <td colSpan="6">
                            {!loading ? (
                              <h6
                                className="text-center text-danger not-found-txt"
                                colSpan="6"
                              >
                                No Records Found!
                              </h6>
                            ) : null}
                          </td>
                        </tr>
                      ) : (
                        reporterList &&
                        Array.isArray(reporterList) &&
                        reporterList.map((user) => {
                          return (
                            <tr key={user?._id}>
                              <td>
                                <div className="thumb-img ">
                                  {user?.reporterDetails[0]
                                    ?.creatorProfilephoto &&
                                    user?.reporterDetails[0]?.creatorProfilephoto.match(
                                      '"https://d3juhcxa2or09v.cloudfront.net/" '
                                    ) ? (
                                    <div className="flex align-items-center">
                                      <img
                                        src={
                                          user?.reporterDetails[0]
                                            ?.creatorProfilephoto
                                            ? user?.reporterDetails[0]
                                              ?.creatorProfilephoto
                                            : "/assets/images/no-user.png"
                                        }
                                        alt=""
                                      />

                                      <span>{user?.firstname}</span>
                                    </div>
                                  ) : (
                                    <img
                                      src={
                                        user?.reporterDetails[0]
                                          ?.creatorProfilephoto
                                          ? user?.reporterDetails[0]
                                            ?.creatorProfilephoto
                                          : "/assets/images/no-user.png"
                                      }
                                      alt=""
                                    />
                                  )}
                                </div>
                              </td>

                              <td>
                                <div className="eamil-col d-flex align-items-center">
                                  <span>
                                    {Array.isArray(user?.reporterDetails)
                                      ? (user?.reporterDetails[0]?.firstname ||
                                        "") +
                                      "  " +
                                      (user?.reporterDetails[0]?.lastname ||
                                        "-")
                                      : ""}
                                  </span>
                                </div>
                              </td>

                              <td>
                                <div className="eamil-col d-flex align-items-center">
                                  <span>
                                    {Array.isArray(user?.reporterDetails)
                                      ? user?.reporterDetails[0]?.emailId || "-"
                                      : ""}
                                  </span>
                                </div>
                              </td>

                              <td>
                                <div className="eamil-col d-flex align-items-center">
                                  <span>
                                    {" "}
                                    {Array.isArray(user?.reporterDetails)
                                      ? user?.reporterDetails[0]
                                        ?.mobileNumber || "-"
                                      : ""}
                                  </span>
                                </div>
                              </td>

                              <td>
                                <div className="eamil-col d-flex align-items-center">
                                  <span>
                                    {" "}
                                    {Array.isArray(user?.reporterDetails)
                                      ? user?.reporterDetails[0]?.gender || "-"
                                      : ""}
                                  </span>
                                </div>
                              </td>

                              <td>
                                <div className="eamil-col d-flex align-items-center">
                                  <span> {user?.reason}</span>
                                </div>
                              </td>
                              <td>
                                <div className="eamil-col d-flex align-items-center">
                                  <span>
                                    {" "}
                                    {user?.createdAt
                                      ? moment(user.createdAt).format(
                                        "l, h:mm:ss a"
                                      )
                                      : ""}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
                {reporterList && reporterList.length < 10 ? (
                  <div>
                    <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                      <p className="mb-0 align-self-center text-primary-light fs-14">
                        Showing <b> {reporterList.length} </b> Out of{" "}
                        <b> {reporterList.length} </b> Reports
                      </p>
                      <div className="pagination-list ms-2">
                        <Pagination
                          className="ant-pagination"
                          pageSize={pagesize}
                          current={page}
                          total={total}
                          onChange={paginationChange}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                    <p className="mb-0 align-self-center text-primary-light fs-14">
                      Showing <b> 10 </b> Out of{" "}
                      <b> {reporterList.length} </b> Reports
                    </p>
                    <div className="pagination-list ms-2">
                      <Pagination
                        className="ant-pagination"
                        pageSize={pagesize}
                        current={page}
                        total={total}
                        onChange={paginationChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  );
}

export default ViewReportedVideo;
