import React, { useState, useEffect } from "react";
import { Tab, Tabs, OverlayTrigger, Tooltip } from "react-bootstrap";

import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Pagination from "rc-pagination";
import DatePicker from "react-datepicker";
import debounce from "lodash.debounce";
import moment from "moment";
import Select from "react-select";
import { Layout } from "../../Components/Layout";
import Spinner from "../../Components/Loader/Spinner";
import { callApi } from "../../Api";
import API from "../../Api/Routes";
import {
  showMessageNotification,
  showModalNotification,
} from "../../Utils/Functions";
import {
  downloadReportsPayment,
  searchPaymentsList,
  reportsVideoViews,
  setDownloadReportedViews,
  productReportsClicks,
  reportsFilterProdClicks,
  vidsReportsDownload,
  getSearchResultsVids,
} from "../../Store/Actions/User";
/******************* 
@Purpose : Used for report page
@Parameter : {}
@Author : INIC
*******************/
const Reports = () => {
  let history = useHistory();

  const dispatch = useDispatch();

  const [categories, setCategories] = useState([]);
  const [brand, setBrand] = useState([]);
  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [searchText, setSearchtext] = useState("");
  const [searchText1, setSearchtext1] = useState("");
  const [searchText4, setSearchtext4] = useState("");
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  const [videoViewsListing, setVideoViewsListing] = useState([]);
  const [eventKeys, setEventKeys] = useState("List of Payment");
  const [downloadSelect, setDownloadSelect] = useState("");
  const [downloadSelect1] = useState("");
  const [downloadSelect2, setDownloadSelect2] = useState("");
  const [downloadSelect4, setDownloadSelect4] = useState("");
  let [loading, setLoading] = useState(false);
  const [celebFilter1, setCelebFilter1] = useState([]);
  const [payoutList, setPayoutList] = useState([]);
  const [totalPayout, setTotalPayout] = useState(0);
  const [filterpopup, openFilterpopup] = useState(false);
  const [celebFilter, setCelebFilter] = useState([]);
  const [celebFilter4, setCelebFilter4] = useState([]);
  const [viewPage, setViewPage] = useState(1);
  const [viewPageSize, setViewPageSize] = useState(10);
  const [totalView, setTotalView] = useState(0);
  const [spin, setSpin] = useState(false);
  const [productViewList, setProductViewList] = useState([]);
  const [prodPage, setProdPage] = useState(1);
  const [prodPageSize, setProdPageSize] = useState(10);

  const [totalProductClicks, setTotalProductClicks] = useState(0);
  const [celebFilter2, setCelebFilter2] = useState([]);
  const [searchText3, setSearchText3] = useState("");
  const [errorsLicence, setErrorsLicence] = useState([
    {
      key: "",
      type: "",
      input: "",
      value: { startDate: "", endDate: "" },
    },
  ]);
  const [purchaseList, setPurchaseList] = useState([]);
  const [vidPage, setVidPage] = useState(1);
  const [vidPageSize, setVidPageSize] = useState(10);
  const [totalVids, setTotalVids] = useState(0);
  const [filterObj, setFilterObj] = useState([
    {
      key: "",
      type: "",
      input: "",
      value: { startDate: "", endDate: "" },
      condition: "$and",
    },
  ]);
  /******************* 
  @Purpose : React hook used for get category list
  @Parameter : {}
  @Author : INIC
  ******************/
  useEffect(() => {
    getCategoryList();
    getBrandList();
    getProductClicksList();
  }, []);
  /******************* 
  @Purpose : Used for brand list
  @Parameter : {}
  @Author : INIC
  ******************/
  const getBrandList = async () => {
    try {
      let body = {
        searchText: "",
      };

      const response = await callApi(
        API.ALL_BRANDS,
        body,
        "post",
        "ALL_BRANDS",
        true,
        true,
        false
      );

      if (response) {
        const allBrandsList = Array.isArray(response.data)
          ? response.data.map((item) => ({
              ...item,
              label: item.organization,
              value: item._id,
            }))
          : [];
        setBrand(allBrandsList);
      }
    } catch (error) {
      console.error();
    }
  };
  /******************* 
  @Purpose : Used for category and sub category
  @Parameter : {}
  @Author : INIC
  ******************/
  const getCategoryList = async () => {
    try {
      const response = await callApi(
        `/categories/getcategories`,
        "",
        "get",
        "GET_CATEGORY",
        true,
        true,
        false
      );

      if (response) {
        const categoryData = Array.isArray(response.data)
          ? response.data.map((item) => ({
              ...item,
              label: item.title,
              value: item.title,
            }))
          : [];
        setCategories(categoryData);
      }
    } catch (error) {
      console.error();
    }
  };
  /******************* 
  @Purpose : Used for sub category data
  @Parameter : {}
  @Author : INIC
  ******************/
  const getSubCategoryData = () => {
    let categoryItem = filterObj?.filter(
      (item) => item.key.label === "Category"
    );

    let id =
      categoryItem.length > 1
        ? categoryItem[categoryItem.length - 1]?.type?._id
        : categoryItem[0]?.type?._id;

    let categoryFilter = categories?.filter((item) => item._id === id)[0]
      ?.subCategoryDetails;
    let subcategoryData = Array.isArray(categoryFilter)
      ? categoryFilter.map((item) => ({
          ...item,
          label: item.title,
          value: item.title,
        }))
      : [];

    return subcategoryData;
  };

  /******************* 
  @Purpose : Used for Bread crumb
  @Parameter : {}
  @Author : INIC
  *******************/
  const breadcrumb = [
    {
      title: eventKeys,
      url: "/reports",
    },
  ];
  const options = [
    { value: "Excel", label: "Excel" },
    { value: "CSV", label: "CSV" },
  ];
  // selected field options
  const selectFieldOptions = [
    { value: "date", label: "Date" },
    { value: "amountRange", label: "Amount" },
    { value: "gender", label: "Gender" },
  ];
  // options display
  const approveCelebList = Array.isArray(payoutList)
    ? payoutList
        .filter((item) => item.dob)
        .map((item) => ({
          ...item,

          label: item.dob,
          value: item.dob,
        }))
    : [];

  const approveCelebListCity = Array.isArray(payoutList)
    ? payoutList
        .filter((item) => item.city)
        .map((item) => ({
          ...item,

          label: item?.city,
          value: item?.city,
        }))
    : [];

  const approveCelebListsGender = [
    { value: "MALE", label: "MALE" },
    { value: "FEMALE", label: "FEMALE" },
  ];
  const handleFilterArray = () => {
    let selected = filterObj.map((item) => item.key.value);
    setSelectedFilterItems(selected);
  };
  const selectContentOptions = [
    { value: "name", label: "name" },
    { value: "amountRange", label: "Amount" },
    { value: "date", label: "date" },
  ];
  /******************* 
  @Purpose : Used for serch filter
  @Parameter : tab, i
  @Author : INIC
  ******************/

  const resetFilter = () => {
    setFilterObj([
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    setSelectedFilterItems([]);
    setCelebFilter([]);
    setCelebFilter1([]);
    getReportedVideos1();
    setCelebFilter2([]);
    setCelebFilter4([]);
    openFilterpopup(!filterpopup);
    setPage(1);
    getPayoutList1();

    getProductClicksList1();
    getVideoPurchaseList1();
  };

  /******************* 
  @Purpose : Used for add new filter records
  @Parameter : e
  @Author : INIC
  ******************/
  const addNewRow = (e) => {
    setErrorsLicence([
      ...errorsLicence,
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    setFilterObj([
      ...filterObj,
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
  };
  /******************* 
  @Purpose : Used for remove filter records
  @Parameter : index
  @Author : INIC
  ******************/
  const removeRow = (index) => {
    let array = [...filterObj];
    array.splice(index, 1);
    setFilterObj(array);
    array = [...errorsLicence];
    array.splice(index, 1);
    setErrorsLicence(array);
    setTimeout(function () {
      setLoadingFilter(true);
    }, 100);
  };
  useEffect(() => {
    if (eventKeys === "List of Payment") {
      getPayoutList();
    }
    getReportedVideos();
  }, [page, pagesize]);

  useEffect(() => {
    getVideoPurchaseList();
  }, []);
  /******************* 
  @Purpose : Used for apply filter
  @Parameter : tab
  @Author : INIC
  ******************/

  const ApplyFilter = async () => {
    try {
      if (validateFiltersearch()) {
        let payload = filterObj.map((item) => {
          if (
            item.key.label === "dob" ||
            item.key.label === "City"
            // item.key.label === "Gender"
          ) {
            return item.type.map((val) => {
              return {
                key:
                  item.key.label === "dob"
                    ? "dob"
                    : item.key.label === "City"
                    ? "city"
                    : item.key.label === "Gender"
                    ? "gender"
                    : "",
                type: "contains",
                value:
                  item.key.label === "dob"
                    ? val?.dob
                    : item.key.label === "City"
                    ? val?.city
                    : item.key.label === "Gender"
                    ? val?.value
                    : "",
              };
            });
          }
          return {
            key:
              item.key.label === "Gender"
                ? "gender"
                : item.key.label === "Amount"
                ? "amountRange"
                : "date",

            value:
              item.key.label === "Date"
                ? {
                    startDate: moment(item.value.startDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                    endDate: moment(item.value.endDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                  }
                : item.key.label === "Amount"
                ? {
                    minAmount: Number(item?.value?.minAmount),
                    maxAmount: Number(item?.value?.maxAmount),
                  }
                : item?.type?.value,
            type:
              item.key.label === "Date"
                ? "date"
                : item.key.label === "Amount"
                ? "number"
                : "contains",
          };
        });

        setLoading(true);

        let body = {
          searchText: "",
          pageNumber: 1,
          pageSize: pagesize,

          filter: payload.flat(),
        };

        dispatch(searchPaymentsList(body)).then((response) => {
          if (response.status === 1) {
            setCelebFilter(payload.flat());
            setPage(1);
            openFilterpopup(!filterpopup);
            setPayoutList(response?.data);
            setTotalPayout(response?.totalRequests);
          } else if (response.status === 0) {
            setPayoutList([]);
            setTotalPayout(0);
            showModalNotification(response?.message, "error");
          }
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  // validate filter search
  const validateFiltersearch = () => {
    let isFormValid = true;
    //
    let valid = filterObj.map((each) => {
      if (each.key.label === "State" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the fields";
      } else {
        if (each.key.label === "State") {
          isFormValid = true;
          each.error = "";
        }
      }
      if (each.key.label === "City" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the fields";
      } else {
        if (each.key.label === "City") {
          isFormValid = true;
          each.error = "";
        }
      }
      if (each.key.label === "Gender" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the fields";
      } else {
        if (each.key.label === "Gender") {
          isFormValid = true;
          each.error = "";
        }
      }

      if (
        each.key.label === "Date" &&
        (each.value.startDate === "" || each.value.endDate === "")
      ) {
        isFormValid = false;
        each.error = "Select the fields";
      } else {
        if (each.key.label === "Date") {
          isFormValid = true;
          each.error = "";
        }
      }

      if (
        each.key.label === "Amount" &&
        (!each.value.minAmount || !each.value.maxAmount)
      ) {
        isFormValid = false;
        each.error = "Enter the amount";
      } else {
        if (each.key.label === "Amount") {
          isFormValid = true;
          each.error = "";
        }
      }
      if (each.key.label === "Category" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the  category fields";
      } else if (each.key.label === "Category" && isEmpty(each.type) == false) {
        each.error = "";
        isFormValid = true;
      }
      if (each.key.label === "Brand" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the brand fields";
      } else {
        if (each.key.label === "Brand") {
          isFormValid = true;
          each.error = "";
        }
      }

      return each;
    });

    setFilterObj(valid);
    return isFormValid;
  };
  /******************* 
  @Purpose : Used for celebrities list
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const getPayoutList = async () => {
    setSpin(true);
    let body = {
      pageNumber: page,
      pageSize: pagesize,
      searchText: searchText || "",
      filter: celebFilter || [],
    };
    const response = await callApi(
      `/reports/getProcessedRequests`,
      body,
      "post",
      null,
      true,
      "",
      "",
      history
    );
    if (response.status === 1) {
      setSpin(false);

      setPayoutList(response?.data);
      setTotalPayout(response?.totalRequests);
    } else if (response.status === 0) {
      setSpin(false);
      showModalNotification(response?.message, "error");
    }
  };
  // getProductview list
  // pageNumber: prodPage,
  const getProductClicksList = async (
    propsProdpage = prodPage,
    propsProdpagesize = prodPageSize
  ) => {
    setSpin(true);
    let body = {
      pageNumber: propsProdpage,
      pageSize: propsProdpagesize,
      searchText: searchText3 || "",
      filter: celebFilter2 || [],
    };
    const response = await callApi(
      `/reports/getAllProductViews`,
      body,
      "post",
      null,
      true,
      "",
      "",
      history
    );
    if (response.status === 1) {
      setSpin(false);

      setProductViewList(response?.data);
      setTotalProductClicks(response?.totalViews);
    } else if (response.status === 0) {
      setSpin(false);
      showModalNotification(response?.message, "error");
    }
  };
  //
  const getVideoPurchaseList = async (
    propsvidpage = vidPage,
    propsvidpagesize = vidPageSize
  ) => {
    setSpin(true);
    let body = {
      pageNumber: propsvidpage,
      pageSize: propsvidpagesize,
      searchText: searchText4 || "",
      filter: celebFilter4 || [],
    };
    const response = await callApi(
      `/reports/getVidPurchaseReportsAdmin`,
      body,
      "post",
      null,
      true,
      "",
      "",
      history
    );
    if (response.status === 1) {
      setSpin(false);

      setPurchaseList(response?.data);
      setTotalVids(response?.totalNoOfOrders);
    } else if (response.status === 0) {
      setSpin(false);
      showModalNotification(response?.message, "error");
    }
  };
  const getVideoPurchaseList1 = async () => {
    setSpin(true);
    let body = {
      pageNumber: vidPage,
      pageSize: vidPageSize,
      searchText: "",
      filter: [],
    };
    const response = await callApi(
      `/reports/getVidPurchaseReportsAdmin`,
      body,
      "post",
      null,
      true,
      "",
      "",
      history
    );
    if (response.status === 1) {
      setSpin(false);

      setPurchaseList(response?.data);
      setTotalVids(response?.totalNoOfOrders);
    } else if (response.status === 0) {
      setSpin(false);
      showModalNotification(response?.message, "error");
    }
  };
  //
  const getProductClicksList1 = async () => {
    setSpin(true);
    let body = {
      pageNumber: prodPage,
      pageSize: prodPageSize,
      searchText: "",
      filter: [],
    };
    const response = await callApi(
      `/reports/getAllProductViews`,
      body,
      "post",
      null,
      true,
      "",
      "",
      history
    );
    if (response.status === 1) {
      setSpin(false);

      setProductViewList(response?.data);
      setTotalProductClicks(response?.totalViews);
    } else if (response.status === 0) {
      setSpin(false);
      showModalNotification(response?.message, "error");
    }
  };
  /******************* 
  @Purpose : Used for celebrities list
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const getPayoutList1 = async () => {
    let body = {
      pageNumber: page,
      pageSize: pagesize,
      searchText: "",
      filter: [],
    };
    const response = await callApi(
      `/reports/getProcessedRequests`,
      body,
      "post",
      null,
      true,
      "",
      "",
      history
    );
    if (response.status === 1) {
      setPayoutList(response?.data);
      setTotalPayout(response?.totalRequests);
    }
  };
  /******************* 
  @Purpose : Used for celebrities list
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const getReportedVideos = async (
    propsViewPage = viewPage,
    propsViewPageSize = viewPageSize
  ) => {
    let body = {
      pageNumber: propsViewPage,
      pageSize: propsViewPageSize,
      searchText: searchText1 || "",
      // filter: [],
      filter: celebFilter1 || [],
    };
    const response = await callApi(
      `/reports/getAllVideoViews`,
      body,
      "post",
      null,
      true,
      "",
      "",
      history
    );
    if (response.status === 1) {
      setVideoViewsListing(response?.data);
      setTotalView(response?.totalViews);
    } else if (response.status === 0) {
      setVideoViewsListing([]);
      setTotalView(0);
    }
  };
  /******************* 
  @Purpose : Used for celebrities list
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const getReportedVideos1 = async () => {
    let body = {
      pageNumber: viewPage,
      pageSize: viewPageSize,
      searchText: "",

      filter: [],
    };
    const response = await callApi(
      `/reports/getAllVideoViews`,
      body,
      "post",
      null,
      true,
      "",
      "",
      history
    );
    if (response.status === 1) {
      setVideoViewsListing(response?.data);
      setTotalView(response?.totalViews);
    } else if (response.status === 0) {
      setVideoViewsListing([]);
      setTotalView(0);
    }
  };
  // pagination change
  function paginationChange(page, pagesize) {
    setPage(page);
    setPagesize(pagesize);
  }
  // pagination for video views
  function paginationChange1(viewPage, viewPageSize) {
    setViewPage(viewPage);
    setViewPageSize(viewPageSize);
    getReportedVideos(viewPage, viewPageSize);
  }
  function paginationChange2(prodPage, prodPageSize) {
    setProdPage(prodPage);
    setProdPageSize(prodPageSize);
    getProductClicksList(prodPage, prodPageSize);
  }
  function paginationChange3(vidPage, vidPageSize) {
    setVidPageSize(vidPageSize);
    setVidPage(vidPage);
    getVideoPurchaseList(vidPage, vidPageSize);
  }
  /******************
  @Purpose : Used forDOWNLOAD  the requests
  @Parameter : e
  @Author : INIC
  ******************/
  const handleDownload = async (e) => {
    setDownloadSelect(e);
    let requiredDownload = e.value;

    if (requiredDownload === "Excel") {
      let body = {
        format: "xls",
      };

      try {
        dispatch(downloadReportsPayment(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showMessageNotification("Downloaded file successfully", "success");
          }
        });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
    if (requiredDownload === "CSV") {
      let body = {
        format: "csv",
      };
      try {
        dispatch(downloadReportsPayment(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showMessageNotification("Downloaded file successfully", "success");
          }
        });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
  };
  /******************
  @Purpose : Used forDOWNLOAD  the requests
  @Parameter : e
  @Author : INIC
  ******************/
  const handleDownload1 = async (e) => {
    setDownloadSelect(e);
    let requiredDownload = e.value;

    if (requiredDownload === "Excel") {
      let body = {
        format: "xls",
      };

      try {
        dispatch(setDownloadReportedViews(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showMessageNotification("Downloaded file successfully", "success");
          }
        });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
    if (requiredDownload === "CSV") {
      let body = {
        format: "csv",
      };
      try {
        dispatch(setDownloadReportedViews(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showMessageNotification("Downloaded file successfully", "success");
          }
        });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
  };
  // download
  const handleDownload2 = async (e) => {
    setDownloadSelect2(e);
    let requiredDownload = e.value;

    if (requiredDownload === "Excel") {
      let body = {
        format: "xls",
      };

      try {
        dispatch(productReportsClicks(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showMessageNotification("Downloaded file successfully", "success");
          }
        });
      } catch (error) {
        setLoading(false);
        console.error();
      }
    }
    if (requiredDownload === "CSV") {
      let body = {
        format: "csv",
      };
      try {
        dispatch(productReportsClicks(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showMessageNotification("Downloaded file successfully", "success");
          }
        });
      } catch (error) {
        console.error();
      }
    }
  };
  // download
  const handleDownload4 = async (e) => {
    setDownloadSelect4(e);
    let requiredDownload = e.value;

    if (requiredDownload === "Excel") {
      let body = {
        format: "xls",
      };

      try {
        dispatch(vidsReportsDownload(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showMessageNotification("Downloaded file successfully", "success");
          }
        });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
    if (requiredDownload === "CSV") {
      let body = {
        format: "csv",
      };
      try {
        dispatch(vidsReportsDownload(body)).then((response) => {
          if (response.status === 1) {
            window.open(response.data);

            showMessageNotification("Downloaded file successfully", "success");
          }
        });
      } catch (error) {
        console.error();
      }
    }
  };
  /******************* 
  @Purpose :clear celebrity search
  @Parameter : {}
  @Author : Vista app
 ******************/
  const clearInput = () => {
    setSearchtext("");
    setPage(1);
    getPayoutList1();
  };

  const clearInput1 = () => {
    setSearchtext1("");
    setSearchText3("");
    setSearchtext4("");
    getVideoPurchaseList();
    setViewPage(1);
    getReportedVideos1();
    getProductClicksList1();
    getVideoPurchaseList1();
  };
  /******************* 
  @Purpose : onchange 
  @Parameter : {}
  @Author : Vista app
  ******************/
  const handleSearchChange = (e) => {
    e.persist();
    if (e.target.value.length === 0) {
      clearInput();
    }
    setLoading(true);
    setSearchtext(e.target.value);
    handleDebouncedChange(e.target.value);
  };
  /******************* 
  @Purpose : onchange 
  @Parameter : {}
  @Author : Vista app
  ******************/
  const handleSearchChange1 = (e) => {
    e.persist();
    if (e.target.value.length === 0) {
      clearInput1();
    }
    setLoading(true);
    setSearchtext1(e.target.value);
    handleDebouncedChange1(e.target.value);
  };
  // search prod clicks
  const handleSearchChange2 = (e) => {
    e.persist();
    if (e.target.value.length === 0) {
      getProductClicksList1();
    }
    setLoading(true);
    setSearchText3(e.target.value);
    handleDebouncedChange2(e.target.value);
  };
  //
  const handleSearchChange3 = (e) => {
    e.persist();
    if (e.target.value.length === 0) {
      getVideoPurchaseList();
    }
    setLoading(true);
    setSearchtext4(e.target.value);
    handleDebouncedChange3(e.target.value);
  };
  /******************* 
  @Purpose : Debounce for searcg celeb req
  @Parameter : {}
  @Author : Vista app
  ******************/
  const handleDebouncedChange = React.useMemo(
    () => debounce((evt) => searchField(evt), 1000),
    []
  );
  /******************* 
  @Purpose : Debounce for searcg celeb req
  @Parameter : {}
  @Author : Vista app
  ******************/
  const handleDebouncedChange1 = React.useMemo(
    () => debounce((evt) => searchField1(evt), 1000),
    []
  );
  //
  const handleDebouncedChange2 = React.useMemo(
    () => debounce((evt) => searchField2(evt), 1000),
    []
  );
  const handleDebouncedChange3 = React.useMemo(
    () => debounce((evt) => searchField4(evt), 1000),
    []
  );
  /******************* 
  @Purpose : Used for search value
  @Parameter : keyword
  @Author : INIC
  ******************/
  const searchField = async (evtKey) => {
    setLoading(true);
    try {
      if (evtKey) {
        let body = {
          pageNumber: 1,
          pageSize: pagesize,
          searchText: evtKey,
          filter: [],
        };
        dispatch(searchPaymentsList(body)).then((response) => {
          if (response.status === 1) {
            setPage(1);
            setPayoutList(response?.data);
            setTotalPayout(response?.totalRequests);

            setLoading(false);
          } else if (response.status === 0) {
            showModalNotification("No records found", "error");
          }
        });
      }
    } catch {
      console.error();
    }
  };
  /******************* 
  @Purpose : Used for search value
  @Parameter : keyword
  @Author : INIC
  ******************/
  const searchField1 = async (evtKey) => {
    setLoading(true);
    try {
      if (evtKey) {
        let body = {
          pageNumber: 1,
          pageSize: viewPageSize,
          searchText: evtKey,
          filter: [],
        };
        dispatch(reportsVideoViews(body)).then((response) => {
          if (response.status === 1) {
            setViewPage(1);
            setVideoViewsListing(response?.data);
            setTotalView(response?.totalViews);

            setLoading(false);
          } else if (response.status === 0) {
            showModalNotification("No records found", "error");
          }
        });
      }
    } catch {
      console.error();
    }
  };
  //
  const searchField2 = async (evtKey) => {
    setLoading(true);
    try {
      if (evtKey) {
        let body = {
          pageNumber: 1,
          pageSize: prodPageSize,
          searchText: evtKey,
          filter: [],
        };

        dispatch(reportsFilterProdClicks(body)).then((response) => {
          if (response.status === 1) {
            setProdPage(1);
            setProductViewList(response?.data);

            setTotalProductClicks(response?.totalViews);
          } else if (response.status === 0) {
            showModalNotification(response?.message, "error");
            setProductViewList([]);
            setTotalProductClicks(0);
          }
        });
      }
    } catch {
      console.error();
    }
  };
  //
  const searchField4 = async (evtKey) => {
    setLoading(true);
    try {
      if (evtKey) {
        let body = {
          pageNumber: 1,
          pageSize: vidPageSize,
          searchText: evtKey,
          filter: [],
        };

        dispatch(getSearchResultsVids(body)).then((response) => {
          if (response?.status === 1) {
            setPurchaseList(response?.data);
            setVidPage(1);
            setTotalVids(response?.totalNoOfOrders);
          } else if (response?.status === 0) {
            showModalNotification(response?.message, "error");
          }
        });
      }
    } catch {
      console.error();
    }
  };
  /******************* 
  @Purpose : Used for display filtered data
  @Parameter : {}
  @Author : Vista app
  ******************/
  const renderFilter = () => {
    return (
      <div className="reports-dropdown">
        <div className="custom-dropdown-menu w-50 dropdown-icon">
          <div className="card-body" style={{ paddingRight: "70px" }}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-repeat">
                  {filterObj &&
                    filterObj.length > 0 &&
                    filterObj.map((o, i) => {
                      let itemType = o.key.label;
                      return (
                        <form
                          key={itemType}
                          className="form repeater-default"
                          data-limit="5"
                        >
                          <div className="field-form">
                            <div className="target-form">
                              <div
                                className="target-details"
                                style={{ marginBottom: "10px" }}
                              >
                                <div className="row align-items-start">
                                  <div className="form-group col-md-5 col-lg-6 mb-2">
                                    <label className="form-label">
                                      Select Field
                                    </label>
                                    <Select
                                      filterOption={(option) =>
                                        !selectedFilterItems.includes(
                                          option.value
                                        )
                                      }
                                      showSearch
                                      placeholder="Enter Field"
                                      optionFilterProp="children"
                                      className="w-100 custom-input filter-select"
                                      value={o.key}
                                      onChange={(value) => {
                                        o.key = value;
                                        o.type = null;
                                        setFilterObj([...filterObj]);

                                        if (errorsLicence[i])
                                          delete errorsLicence[i].key;
                                      }}
                                      options={selectFieldOptions}
                                    />

                                    <span className="text-danger error-msg"></span>
                                  </div>
                                  {itemType === "Date" ? (
                                    <div className="col-md-5 col-lg-5 mb-2 ">
                                      <div className="input-daterange custom-daterange input-control">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label className="form-label">
                                              Select Start date
                                            </label>
                                            <DatePicker
                                              selected={
                                                o.value.startDate
                                                  ? new Date(o.value.startDate)
                                                  : ""
                                              }
                                              placeholderText="Select Start Date"
                                              dateFormat="MMM d yyyy"
                                              className="form-control"
                                              onChange={(evt) => {
                                                o.value.startDate = evt;
                                                setFilterObj([...filterObj]);
                                                if (errorsLicence[i])
                                                  delete errorsLicence[i].value
                                                    .startDate;
                                              }}
                                            />
                                            <span className="text-danger error-msg">
                                              {o.error}
                                            </span>
                                          </div>
                                          <div className="col-md-6">
                                            <label className="form-label">
                                              Select end date
                                            </label>

                                            <DatePicker
                                              selected={
                                                o.value.endDate
                                                  ? new Date(o.value.endDate)
                                                  : ""
                                              }
                                              minDate={
                                                o.value.startDate
                                                  ? new Date(o.value.startDate)
                                                  : new Date()
                                              }
                                              disabled={
                                                o.value.startDate ? false : true
                                              }
                                              dateFormat="MMM d yyyy"
                                              placeholderText="Select End Date"
                                              className="form-control"
                                              onChange={(evt) => {
                                                o.value.endDate = evt;
                                                setFilterObj([...filterObj]);
                                                if (errorsLicence[i])
                                                  delete errorsLicence[i].value
                                                    .endDate;
                                              }}
                                            />
                                            <span className="text-danger error-msg">
                                              {o.error}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : itemType === "Amount" ? (
                                    <div className="col-md-5 col-lg-5 mb-2 ">
                                      <div className="input-daterange custom-daterange input-control">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label className="form-label">
                                              Select Min Amount
                                            </label>
                                            <input
                                              type="number"
                                              name="minAmount"
                                              value={o?.value?.minAmount || ""}
                                              placeholder="Min Amount"
                                              onChange={(evt) => {
                                                o.value.minAmount =
                                                  evt.target.value;
                                                setFilterObj([...filterObj]);
                                              }}
                                              className="form-control"
                                            />
                                            <span className="text-danger error-msg">
                                              {o.error}
                                            </span>
                                          </div>
                                          <div className="col-md-6">
                                            <label className="form-label">
                                              Select Max Amount
                                            </label>

                                            <input
                                              type="number"
                                              name="maxAmount"
                                              value={o?.value?.maxAmount || ""}
                                              placeholder="Max Amount"
                                              onChange={(evt) => {
                                                o.value.maxAmount =
                                                  evt.target.value;
                                                setFilterObj([...filterObj]);
                                              }}
                                              className="form-control"
                                            />
                                            <span className="text-danger error-msg">
                                              {o.error}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="form-group col-md-5 col-lg-6 mb-2">
                                      <label className="form-label">
                                        Select Content
                                      </label>
                                      <Select
                                        closeMenuOnSelect={
                                          o.key.label === "Gender"
                                            ? true
                                            : false
                                        }
                                        isMulti={
                                          o.key.label === "Gender"
                                            ? false
                                            : true
                                        }
                                        showSearch={false}
                                        placeholder="Select Type"
                                        className="w-100 custom-input filter-select"
                                        optionFilterProp="children"
                                        value={o.type}
                                        onChange={(value) => {
                                          o.type = value;
                                          if (o.type === "date") {
                                            delete o.value;
                                            o.value = {
                                              startDate: "",
                                              endDate: "",
                                            };
                                          }
                                          setFilterObj([...filterObj]);
                                          handleFilterArray();
                                          if (errorsLicence[i])
                                            delete errorsLicence[i].type;
                                        }}
                                        options={
                                          o.key.label === "dob"
                                            ? approveCelebList
                                            : o.key.label === "City"
                                            ? approveCelebListCity
                                            : o.key.label === "Gender"
                                            ? approveCelebListsGender
                                            : selectContentOptions
                                        }
                                      />

                                      <span className="text-danger error-msg">
                                        {o.error}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                className="add-row col-lg-2 col-md-2"
                                style={{ display: "flex", gap: "10px" }}
                              >
                                {filterObj.length <= 3 && i === 0 ? (
                                  <span
                                    className="add-ic icon-plus text-success"
                                    onClick={(e) => addNewRow(e)}
                                    type="button"
                                  ></span>
                                ) : null}
                                {filterObj && filterObj.length >= 2 ? (
                                  <span
                                    className="icon-minus mr-1 text-danger"
                                    onClick={() => removeRow(i)}
                                    type="button"
                                  ></span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </form>
                      );
                    })}
                </div>
              </div>
              <div className="col-md-12 text-end pt-4">
                <button
                  onClick={() => resetFilter()}
                  type="button"
                  className="btn btn-light me-2"
                >
                  Clear
                </button>
                <button
                  onClick={(evt) => ApplyFilter()}
                  type="button"
                  className="btn btn-primary"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const selectFieldOptions1 = [
    { value: "category", label: "Category" },

    { value: "brand", label: "Brand" },
    { value: "date", label: "Date" },
  ];
  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  const renderFilter1 = () => {
    return (
      <div className="reports-dropdown">
        <div className="custom-dropdown-menu dropdown-icon card w-50">
          <div className="card-body" style={{ paddingRight: "70px" }}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-repeat">
                  {filterObj &&
                    filterObj.length > 0 &&
                    filterObj.map((o, i) => {
                      let itemType = o.key.label;

                      return (
                        <form
                          key={itemType}
                          className="form repeater-default"
                          data-limit="5"
                        >
                          <div className="field-form">
                            <div className="target-form">
                              <div
                                className="target-details"
                                style={{ marginBottom: "10px" }}
                              >
                                <div className="row align-items-start">
                                  <div className="form-group col-md-5 col-lg-6 mb-2">
                                    <label className="form-label">
                                      Select Field
                                    </label>
                                    <Select
                                      filterOption={(option) =>
                                        !selectedFilterItems.includes(
                                          option.value
                                        )
                                      }
                                      showSearch
                                      placeholder="Enter Field"
                                      optionFilterProp="children"
                                      className="w-100 custom-input filter-select"
                                      value={o.key}
                                      onChange={(value) => {
                                        o.key = value;
                                        o.type = null;
                                        setFilterObj([...filterObj]);

                                        if (errorsLicence[i])
                                          delete errorsLicence[i].key;
                                      }}
                                      options={selectFieldOptions1}
                                    />

                                    <span className="text-danger error-msg"></span>
                                  </div>
                                  {itemType === "Date" ? (
                                    <div className="col-md-5 col-lg-5 mb-2 ">
                                      <div className="input-daterange custom-daterange input-control">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label className="form-label">
                                              Select Start date
                                            </label>
                                            <DatePicker
                                              selected={
                                                o.value.startDate
                                                  ? new Date(o.value.startDate)
                                                  : ""
                                              }
                                              placeholderText="Select Start Date"
                                              dateFormat="MMM d yyyy"
                                              className="form-control"
                                              onChange={(evt) => {
                                                o.value.startDate = evt;
                                                setFilterObj([...filterObj]);
                                                if (errorsLicence[i])
                                                  delete errorsLicence[i].value
                                                    .startDate;
                                              }}
                                            />
                                            <span className="text-danger error-msg">
                                              {o.error}
                                            </span>
                                          </div>
                                          <div className="col-md-6">
                                            <label className="form-label">
                                              Select end date
                                            </label>

                                            <DatePicker
                                              selected={
                                                o.value.endDate
                                                  ? new Date(o.value.endDate)
                                                  : ""
                                              }
                                              minDate={
                                                o.value.startDate
                                                  ? new Date(o.value.startDate)
                                                  : new Date()
                                              }
                                              disabled={
                                                o.value.startDate ? false : true
                                              }
                                              dateFormat="MMM d yyyy"
                                              placeholderText="Select End Date"
                                              className="form-control"
                                              onChange={(evt) => {
                                                o.value.endDate = evt;
                                                setFilterObj([...filterObj]);
                                                if (errorsLicence[i])
                                                  delete errorsLicence[i].value
                                                    .endDate;
                                              }}
                                            />
                                            <span className="text-danger error-msg">
                                              {o.error}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="form-group col-md-5 col-lg-6 mb-2">
                                      <label className="form-label">
                                        {o.key.label === "Category"
                                          ? "Select Content "
                                          : "Select Content (max 5)"}
                                      </label>
                                      <Select
                                        closeMenuOnSelect={
                                          o.key.label === "Category"
                                            ? true
                                            : false
                                        }
                                        isMulti={
                                          o.key.label === "Category"
                                            ? false
                                            : true
                                        }
                                        showSearch={false}
                                        placeholder="Select Type"
                                        className="w-100 custom-input filter-select"
                                        optionFilterProp="children"
                                        value={o.type}
                                        isOptionDisabled={() =>
                                          o.type?.length >= 5
                                        }
                                        onChange={(value) => {
                                          o.type = value;
                                          if (o.type === "date") {
                                            delete o.value;
                                            o.value = {
                                              startDate: "",
                                              endDate: "",
                                            };
                                          }
                                          setFilterObj([...filterObj]);
                                          handleFilterArray();
                                          if (errorsLicence[i])
                                            delete errorsLicence[i].type;
                                        }}
                                        options={
                                          o.key.label === "Category"
                                            ? categories
                                            : o.key.label === "SubCategory"
                                            ? getSubCategoryData()
                                            : o.key.label === "Brand"
                                            ? brand
                                            : selectContentOptions
                                        }
                                      />

                                      <span className="text-danger error-msg">
                                        {o.error}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                className="add-row col-lg-2 col-md-2"
                                style={{ display: "flex", gap: "10px" }}
                              >
                                {filterObj.length <= 3 && i === 0 ? (
                                  <span
                                    className="add-ic icon-plus text-success"
                                    onClick={(e) => addNewRow(e)}
                                    type="button"
                                  ></span>
                                ) : null}
                                {filterObj && filterObj.length >= 2 ? (
                                  <span
                                    className="icon-minus mr-1 text-danger"
                                    onClick={() => removeRow(i)}
                                    type="button"
                                  ></span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </form>
                      );
                    })}
                </div>
                {loadingFilter ? <div></div> : null}
                <div className="col-md-12 text-end pt-4">
                  <button
                    onClick={() => resetFilter()}
                    type="button"
                    className="btn btn-light me-2"
                  >
                    Clear
                  </button>
                  <button
                    onClick={(evt) => ApplyFilter1()}
                    type="button"
                    className="btn btn-primary me-2"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /******************* 
  @Purpose : Used for applay filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const ApplyFilter1 = async () => {
    try {
      if (validateFiltersearch()) {
        let payload = filterObj.map((item) => {
          if (item.key.label === "SubCategory" || item.key.label === "Brand") {
            return item.type.map((val) => {
              return {
                key:
                  item.key.label === "SubCategory"
                    ? "subCategoryId"
                    : item.key.label === "Brand"
                    ? "brandId"
                    : "",
                type: "contains",
                value: val._id,
              };
            });
          }
          return {
            key: item.key.label === "Category" ? "categoryId" : "date",
            // ? "date"
            // : ""
            type: item.key.label === "Date" ? "date" : "contains",

            value:
              item.key.label === "Date"
                ? {
                    startDate: moment(item.value.startDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                    endDate: moment(item.value.endDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                  }
                : item.type._id,
          };
        });

        setLoading(true);

        let body = {
          pageNumber: 1,
          pageSize: viewPageSize,
          searchText: "",
          filter: payload.flat(),
        };

        dispatch(reportsVideoViews(body)).then((response) => {
          if (response.status === 1) {
            setCelebFilter1(payload.flat());
            openFilterpopup(!filterpopup);
            setVideoViewsListing(response?.data);
            setViewPage(1);
            setTotalView(response?.totalViews);
          } else if (response.status === 0) {
            setVideoViewsListing([]);
            setTotalView(0);
            showModalNotification(response?.message, "error");
          }
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  // renderFilter
  const renderFilter2 = () => {
    return (
      <div className="reports-dropdown">
        <div className="custom-dropdown-menu dropdown-icon card w-50">
          <div className="card-body" style={{ paddingRight: "70px" }}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-repeat">
                  {filterObj &&
                    filterObj.length > 0 &&
                    filterObj.map((o, i) => {
                      let itemType = o.key.label;

                      return (
                        <form
                          key={itemType}
                          className="form repeater-default"
                          data-limit="5"
                        >
                          <div className="field-form">
                            <div className="target-form">
                              <div
                                className="target-details"
                                style={{ marginBottom: "10px" }}
                              >
                                <div className="row align-items-start">
                                  <div className="form-group col-md-5 col-lg-6 mb-2">
                                    <label className="form-label">
                                      Select Field
                                    </label>
                                    <Select
                                      filterOption={(option) =>
                                        !selectedFilterItems.includes(
                                          option.value
                                        )
                                      }
                                      showSearch
                                      placeholder="Enter Field"
                                      optionFilterProp="children"
                                      className="w-100 custom-input filter-select"
                                      value={o.key}
                                      onChange={(value) => {
                                        o.key = value;
                                        o.type = null;
                                        setFilterObj([...filterObj]);

                                        if (errorsLicence[i])
                                          delete errorsLicence[i].key;
                                      }}
                                      options={selectFieldOptions1}
                                    />

                                    <span className="text-danger error-msg"></span>
                                  </div>
                                  {itemType === "Date" ? (
                                    <div className="col-md-5 col-lg-5 mb-2 ">
                                      <div className="input-daterange custom-daterange input-control">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label className="form-label">
                                              Select Start date
                                            </label>
                                            <DatePicker
                                              selected={
                                                o.value.startDate
                                                  ? new Date(o.value.startDate)
                                                  : ""
                                              }
                                              placeholderText="Select Start Date"
                                              dateFormat="MMM d yyyy"
                                              className="form-control"
                                              onChange={(evt) => {
                                                o.value.startDate = evt;
                                                setFilterObj([...filterObj]);
                                                if (errorsLicence[i])
                                                  delete errorsLicence[i].value
                                                    .startDate;
                                              }}
                                            />
                                            <span className="text-danger error-msg">
                                              {o.error}
                                            </span>
                                          </div>
                                          <div className="col-md-6">
                                            <label className="form-label">
                                              Select end date
                                            </label>

                                            <DatePicker
                                              selected={
                                                o.value.endDate
                                                  ? new Date(o.value.endDate)
                                                  : ""
                                              }
                                              minDate={
                                                o.value.startDate
                                                  ? new Date(o.value.startDate)
                                                  : new Date()
                                              }
                                              disabled={
                                                o.value.startDate ? false : true
                                              }
                                              dateFormat="MMM d yyyy"
                                              placeholderText="Select End Date"
                                              className="form-control"
                                              onChange={(evt) => {
                                                o.value.endDate = evt;
                                                setFilterObj([...filterObj]);
                                                if (errorsLicence[i])
                                                  delete errorsLicence[i].value
                                                    .endDate;
                                              }}
                                            />
                                            <span className="text-danger error-msg">
                                              {o.error}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="form-group col-md-5 col-lg-6 mb-2">
                                      <label className="form-label">
                                        {o.key.label === "Category"
                                          ? "Select Content "
                                          : "Select Content (max 5)"}
                                      </label>
                                      <Select
                                        closeMenuOnSelect={
                                          o.key.label === "Category"
                                            ? true
                                            : false
                                        }
                                        isMulti={
                                          o.key.label === "Category"
                                            ? false
                                            : true
                                        }
                                        showSearch={false}
                                        placeholder="Select Type"
                                        className="w-100 custom-input filter-select"
                                        optionFilterProp="children"
                                        value={o.type}
                                        isOptionDisabled={() =>
                                          o.type?.length >= 5
                                        }
                                        onChange={(value) => {
                                          o.type = value;
                                          if (o.type === "date") {
                                            delete o.value;
                                            o.value = {
                                              startDate: "",
                                              endDate: "",
                                            };
                                          }
                                          setFilterObj([...filterObj]);
                                          handleFilterArray();
                                          if (errorsLicence[i])
                                            delete errorsLicence[i].type;
                                        }}
                                        options={
                                          o.key.label === "Category"
                                            ? categories
                                            : o.key.label === "SubCategory"
                                            ? getSubCategoryData()
                                            : o.key.label === "Brand"
                                            ? brand
                                            : selectContentOptions
                                        }
                                      />

                                      <span className="text-danger error-msg">
                                        {o.error}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                className="add-row col-lg-2 col-md-2"
                                style={{ display: "flex", gap: "10px" }}
                              >
                                {filterObj.length <= 3 && i === 0 ? (
                                  <span
                                    className="add-ic icon-plus text-success"
                                    onClick={(e) => addNewRow(e)}
                                    type="button"
                                  ></span>
                                ) : null}
                                {filterObj && filterObj.length >= 2 ? (
                                  <span
                                    className="icon-minus mr-1 text-danger"
                                    onClick={() => removeRow(i)}
                                    type="button"
                                  ></span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </form>
                      );
                    })}
                </div>
                {loadingFilter ? <div></div> : null}
                <div className="col-md-12 text-end pt-4">
                  <button
                    onClick={() => resetFilter()}
                    type="button"
                    className="btn btn-light me-2"
                  >
                    Clear
                  </button>
                  <button
                    onClick={(evt) => ApplyFilter2()}
                    type="button"
                    className="btn btn-primary"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  // render filter 4 for the vid purchase
  const renderFilter4 = () => {
    return (
      <div className="reports-dropdown">
        <div className="custom-dropdown-menu dropdown-icon card w-50">
          <div className="card-body" style={{ paddingRight: "70px" }}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-repeat">
                  {filterObj &&
                    filterObj.length > 0 &&
                    filterObj.map((o, i) => {
                      let itemType = o.key.label;

                      return (
                        <form
                          key={itemType}
                          className="form repeater-default"
                          data-limit="5"
                        >
                          <div className="field-form">
                            <div className="target-form">
                              <div
                                className="target-details"
                                style={{ marginBottom: "10px" }}
                              >
                                <div className="row align-items-start">
                                  <div className="form-group col-md-5 col-lg-6 mb-2">
                                    <label className="form-label">
                                      Select Field
                                    </label>
                                    <Select
                                      filterOption={(option) =>
                                        !selectedFilterItems.includes(
                                          option.value
                                        )
                                      }
                                      showSearch
                                      placeholder="Enter Field"
                                      optionFilterProp="children"
                                      className="w-100 custom-input filter-select"
                                      value={o.key}
                                      onChange={(value) => {
                                        o.key = value;
                                        o.type = null;
                                        setFilterObj([...filterObj]);

                                        if (errorsLicence[i])
                                          delete errorsLicence[i].key;
                                      }}
                                      options={selectFieldOptions1}
                                    />

                                    <span className="text-danger error-msg"></span>
                                  </div>
                                  {itemType === "Date" ? (
                                    <div className="col-md-5 col-lg-5 mb-2 ">
                                      <div className="input-daterange custom-daterange input-control">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label className="form-label">
                                              Select Start date
                                            </label>
                                            <DatePicker
                                              selected={
                                                o.value.startDate
                                                  ? new Date(o.value.startDate)
                                                  : ""
                                              }
                                              placeholderText="Select Start Date"
                                              dateFormat="MMM d yyyy"
                                              className="form-control"
                                              onChange={(evt) => {
                                                o.value.startDate = evt;
                                                setFilterObj([...filterObj]);
                                                if (errorsLicence[i])
                                                  delete errorsLicence[i].value
                                                    .startDate;
                                              }}
                                            />
                                            <span className="text-danger error-msg">
                                              {o.error}
                                            </span>
                                          </div>
                                          <div className="col-md-6">
                                            <label className="form-label">
                                              Select end date
                                            </label>

                                            <DatePicker
                                              selected={
                                                o.value.endDate
                                                  ? new Date(o.value.endDate)
                                                  : ""
                                              }
                                              minDate={
                                                o.value.startDate
                                                  ? new Date(o.value.startDate)
                                                  : new Date()
                                              }
                                              disabled={
                                                o.value.startDate ? false : true
                                              }
                                              dateFormat="MMM d yyyy"
                                              placeholderText="Select End Date"
                                              className="form-control"
                                              onChange={(evt) => {
                                                o.value.endDate = evt;
                                                setFilterObj([...filterObj]);
                                                if (errorsLicence[i])
                                                  delete errorsLicence[i].value
                                                    .endDate;
                                              }}
                                            />
                                            <span className="text-danger error-msg">
                                              {o.error}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="form-group col-md-5 col-lg-6 mb-2">
                                      <label className="form-label">
                                        {o.key.label === "Category"
                                          ? "Select Content "
                                          : "Select Content (max 5)"}
                                      </label>
                                      <Select
                                        closeMenuOnSelect={
                                          o.key.label === "Category"
                                            ? true
                                            : false
                                        }
                                        isMulti={
                                          o.key.label === "Category"
                                            ? false
                                            : true
                                        }
                                        showSearch={false}
                                        placeholder="Select Type"
                                        className="w-100 custom-input filter-select"
                                        optionFilterProp="children"
                                        value={o.type}
                                        isOptionDisabled={() =>
                                          o.type?.length >= 5
                                        }
                                        onChange={(value) => {
                                          o.type = value;
                                          if (o.type === "date") {
                                            delete o.value;
                                            o.value = {
                                              startDate: "",
                                              endDate: "",
                                            };
                                          }
                                          setFilterObj([...filterObj]);
                                          handleFilterArray();
                                          if (errorsLicence[i])
                                            delete errorsLicence[i].type;
                                        }}
                                        options={
                                          o.key.label === "Category"
                                            ? categories
                                            : o.key.label === "SubCategory"
                                            ? getSubCategoryData()
                                            : o.key.label === "Brand"
                                            ? brand
                                            : selectContentOptions
                                        }
                                      />

                                      <span className="text-danger error-msg">
                                        {o.error}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                className="add-row col-lg-2 col-md-2"
                                style={{ display: "flex", gap: "10px" }}
                              >
                                {filterObj.length <= 3 && i === 0 ? (
                                  <span
                                    className="add-ic icon-plus text-success"
                                    onClick={(e) => addNewRow(e)}
                                    type="button"
                                  ></span>
                                ) : null}
                                {filterObj && filterObj.length >= 2 ? (
                                  <span
                                    className="icon-minus mr-1 text-danger"
                                    onClick={() => removeRow(i)}
                                    type="button"
                                  ></span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </form>
                      );
                    })}
                </div>
                {loadingFilter ? <div></div> : null}
                <div className="col-md-12 text-end pt-4">
                  <button
                    onClick={() => resetFilter()}
                    type="button"
                    className="btn btn-light me-2"
                  >
                    Clear
                  </button>
                  <button
                    onClick={(evt) => ApplyFilter4()}
                    type="button"
                    className="btn btn-primary"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  /******************* 
  @Purpose : Used for applay filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const ApplyFilter2 = async () => {
    try {
      if (validateFiltersearch()) {
        let payload = filterObj.map((item) => {
          if (item.key.label === "SubCategory" || item.key.label === "Brand") {
            return item.type.map((val) => {
              return {
                key:
                  item.key.label === "SubCategory"
                    ? "subCategoryId"
                    : item.key.label === "Brand"
                    ? "brandId"
                    : "",
                type: "contains",
                value: val._id,
              };
            });
          }
          return {
            key: item.key.label === "Category" ? "categoryId" : "date",
            // ? "date"
            // : ""
            type: item.key.label === "Date" ? "date" : "contains",

            value:
              item.key.label === "Date"
                ? {
                    startDate: moment(item.value.startDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                    endDate: moment(item.value.endDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                  }
                : item.type._id,
          };
        });

        setLoading(true);

        let body = {
          pageNumber: 1,
          pageSize: viewPageSize,
          searchText: "",
          filter: payload.flat(),
        };

        dispatch(reportsFilterProdClicks(body)).then((response) => {
          if (response.status === 1) {
            setCelebFilter2(payload.flat());
            openFilterpopup(!filterpopup);
            setProdPage(1);
            setProductViewList(response?.data);
            setTotalProductClicks(response?.totalViews);

            setProdPage(1);
          } else if (response.status === 0) {
            showModalNotification(response?.message, "error");
            setProductViewList([]);
            setTotalProductClicks(0);
          }
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  // apply filter for the clicks
  const ApplyFilter4 = async () => {
    try {
      if (validateFiltersearch()) {
        let payload = filterObj.map((item) => {
          if (item.key.label === "SubCategory" || item.key.label === "Brand") {
            return item.type.map((val) => {
              return {
                key:
                  item.key.label === "SubCategory"
                    ? "subCategoryId"
                    : item.key.label === "Brand"
                    ? "brandId"
                    : "",
                type: "contains",
                value: val._id,
              };
            });
          }
          return {
            key: item.key.label === "Category" ? "categoryId" : "date",
            // ? "date"
            // : ""
            type: item.key.label === "Date" ? "date" : "contains",

            value:
              item.key.label === "Date"
                ? {
                    startDate: moment(item.value.startDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                    endDate: moment(item.value.endDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                  }
                : item.type._id,
          };
        });

        setLoading(true);

        let body = {
          pageNumber: 1,
          pageSize: viewPageSize,
          searchText: "",
          filter: payload.flat(),
        };

        dispatch(getSearchResultsVids(body)).then((response) => {
          if (response?.status === 1) {
            setCelebFilter4(payload.flat());
            openFilterpopup(!filterpopup);
            setVidPage(1);
            setPurchaseList(response?.data);
            setTotalVids(response?.totalNoOfOrders);
          } else if (response?.status === 0) {
            showModalNotification(response?.message, "error");
            setPurchaseList([]);
            setTotalVids(0);
          }
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  function handleTabs(e) {
    setEventKeys(e);
    setFilterObj([
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    openFilterpopup(false);
  }
  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Reports">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="card mb-5">
              <div className="card-header"></div>

              <Tabs
                defaultActiveKey="List of Payment"
                id="uncontrolled-tab-example"
                onSelect={(eventKey) => handleTabs(eventKey)}
                className="pb-0 "
                style={{ marginLeft: "20px" }}
              >
                {/* payouts list */}
                <Tab
                  eventKey="List of Payment"
                  title={`List of Payment `}
                  className="card-body"
                >
                  <div className="content-area position-relative">
                    <div className="user-listing-filterOptions mb-2 d-block">
                      <div className="row mb-2">
                        <div className="col-sm-8 position-static">
                          <div className="left-buttons d-flex ">
                            <label>
                              <div className="search-input-wrapper position-relative">
                                <i className="icon-magnifier"></i>{" "}
                                <input
                                  type="search"
                                  value={searchText}
                                  className="form-control text-capitalize"
                                  placeholder="search"
                                  onChange={(evt) => {
                                    handleSearchChange(evt);
                                  }}
                                />
                                {searchText && searchText.length > 0 ? (
                                  <div className="clear">
                                    <span
                                      onClick={clearInput}
                                      style={{ marginRight: "20px" }}
                                    >
                                      <em className="icon-close"></em>
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            </label>

                            <div
                              id="container1"
                              className={
                                filterpopup
                                  ? "custom-dropdown filter-data-dropdown position-static ml-2 open"
                                  : "custom-dropdown filter-data-dropdown position-static ml-2"
                              }
                            >
                              <button
                                onClick={() => {
                                  openFilterpopup(!filterpopup);
                                }}
                                className="btn btn-bordered dropdown-toggle custom-filter-dropdown btn-bg-white d-flex align-items-center"
                                type="button"
                              >
                                <i className="bx bx-filter-alt align-text-top me-2"></i>
                                Filter
                              </button>
                              {renderFilter()}
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-4">
                          <div
                            className="right-buttons d-flex justify-content-end"
                            style={{ gap: "10px" }}
                          >
                            <div className="form-group  col-sm-4  d-flex">
                              <div className="w-100">
                                <Select
                                  id="download"
                                  name="download"
                                  options={options}
                                  placeholder="Download"
                                  onChange={function (e) {
                                    handleDownload(e);
                                  }}
                                  value={downloadSelect}
                                />
                              </div>
                              <span
                                className="error-msg"
                                style={{ color: "red" }}
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {spin ? (
                      <div className="d-flex align-center justify-content-center">
                        <Spinner />
                      </div>
                    ) : null}
                    <table
                      className="table row-border nowrap common-datatable"
                      id="user-listing"
                    >
                      <thead>
                        <tr>
                          <th className="all">
                            <b>Tranaction Date & Time</b>
                          </th>

                          <th>
                            <b>Name</b>
                          </th>
                          <th>
                            <b>Email</b>
                          </th>
                          <th>
                            <b>Phone Number</b>
                          </th>
                          <th>
                            <b>Gender</b>
                          </th>
                          <th className="all">
                            <b>Amount</b>
                          </th>

                          <th className="all">
                            <b>Status</b>
                          </th>
                          <th className="all">
                            <b>Action</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {payoutList && payoutList.length === 0 ? (
                          <tr className="text-center text-danger not-found-txt">
                            <td colSpan="6">
                              {!loading ? (
                                <h6
                                  className="text-center text-danger not-found-txt"
                                  colSpan="6"
                                >
                                  No Records Found!
                                </h6>
                              ) : null}
                            </td>
                          </tr>
                        ) : (
                          payoutList &&
                          Array.isArray(payoutList) &&
                          payoutList.map((user, key) => {
                            return (
                              <tr key={user?._id}>
                                {/* check box */}

                                <td>
                                  <div className="d-flex">
                                    {moment(user?.executionTime).format(
                                      "l, h:mm:ss a"
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="email-col d-flex align-items-center d-flex align-center ">
                                    <span className="avtar-md">
                                      <img
                                        src={
                                          user?.celebrity[0]
                                            ?.creatorProfilephoto
                                            ? user?.celebrity[0]
                                                ?.creatorProfilephoto
                                            : "/assets/images/no-user.png"
                                        }
                                      />
                                    </span>

                                    {user?.celebrity?.length > 0 && (
                                      <span className="ps-3">
                                        {user?.celebrity[0]?.firstname +
                                          " " +
                                          user?.celebrity[0]?.lastname}
                                      </span>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="eamil-col d-flex align-items-center">
                                    <span> {user?.celebrity[0]?.emailId}</span>
                                  </div>
                                </td>

                                <td>
                                  <div className="eamil-col d-flex align-items-center">
                                    <span>
                                      {user?.celebrity[0]?.mobileNumber}
                                    </span>
                                  </div>
                                </td>

                                <td>
                                  <div className="eamil-col d-flex align-items-center">
                                    <span> {user?.celebrity[0]?.gender}</span>
                                  </div>
                                </td>
                                <td>
                                  <div className="custom-control custom-switch light">
                                    {user?.payment}
                                    <label
                                      className="custom-control-label"
                                      for={user?._id}
                                    ></label>
                                  </div>
                                </td>

                                <td>
                                  <div className="d-flex">{user?.status}</div>
                                </td>
                                <td>
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip1">View</Tooltip>
                                    }
                                  >
                                    <a
                                      onClick={() =>
                                        history.push(
                                          `/reportPaymentDetail/${user._id}`
                                        )
                                      }
                                      className="cursor-pointer"
                                    >
                                      <i className="icon-eye"></i>
                                    </a>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                  {payoutList && payoutList.length < 10 ? (
                    <div>
                      <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                        <p className="mb-0 align-self-center text-primary-light fs-14">
                          Showing <b> {payoutList.length} </b> Out of{" "}
                          <b> {totalPayout} </b>Entries
                        </p>

                        <div className="pagination-list ms-2">
                          <Pagination
                            className="ant-pagination"
                            pageSize={pagesize}
                            current={page}
                            total={totalPayout}
                            onChange={paginationChange}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                      <p className="mb-0 align-self-center text-primary-light fs-14">
                        Showing <b> 10</b> Out of <b> {totalPayout} </b>Entries
                      </p>
                      <div className="pagination-list ms-2">
                        <Pagination
                          className="ant-pagination"
                          pageSize={pagesize}
                          current={page}
                          total={totalPayout}
                          onChange={paginationChange}
                        />
                      </div>
                    </div>
                  )}
                </Tab>
                {/* video views */}
                <Tab
                  eventKey="Video Views"
                  title={`Video Views `}
                  className="card-body"
                >
                  <div className="content-area position-relative">
                    <div className="user-listing-filterOptions mb-2 d-block">
                      <div className="row mb-2">
                        <div className="col-sm-8 position-static">
                          <div className="left-buttons d-flex ">
                            <label>
                              <div className="search-input-wrapper position-relative">
                                <i className="icon-magnifier"></i>{" "}
                                <input
                                  type="search"
                                  value={searchText1}
                                  className="form-control text-capitalize"
                                  placeholder="search"
                                  onChange={(evt) => {
                                    handleSearchChange1(evt);
                                  }}
                                />
                                {searchText1 && searchText1.length > 0 ? (
                                  <div className="clear">
                                    <span
                                      onClick={clearInput1}
                                      style={{ marginRight: "20px" }}
                                    >
                                      <em className="icon-close"></em>
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            </label>

                            <div
                              id="container1"
                              className={
                                filterpopup
                                  ? "custom-dropdown filter-data-dropdown position-static ml-2 open"
                                  : "custom-dropdown filter-data-dropdown position-static ml-2"
                              }
                            >
                              <button
                                onClick={() => {
                                  openFilterpopup(!filterpopup);
                                }}
                                className="btn btn-bordered dropdown-toggle custom-filter-dropdown btn-bg-white d-flex align-items-center"
                                type="button"
                              >
                                <i className="bx bx-filter-alt align-text-top me-2"></i>
                                Filter
                              </button>
                              {renderFilter1()}
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-4">
                          <div
                            className="right-buttons d-flex justify-content-end"
                            style={{ gap: "10px" }}
                          >
                            <div className="form-group  col-sm-4  d-flex">
                              <div className="w-100">
                                <Select
                                  id="download"
                                  name="download"
                                  options={options}
                                  placeholder="Download"
                                  onChange={function (e) {
                                    handleDownload1(e);
                                  }}
                                  value={downloadSelect1}
                                />
                              </div>
                              <span
                                className="error-msg"
                                style={{ color: "red" }}
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table
                      className="table row-border nowrap common-datatable"
                      id="user-listing"
                    >
                      <thead>
                        <tr>
                          <th className="all">
                            <b>View Date & Time</b>
                          </th>
                          <th>
                            <b>Video Title</b>
                          </th>
                          <th>
                            <b>Posted By</b>
                          </th>
                          <th>
                            <b>User</b>
                          </th>
                          <th>
                            <b>Brand</b>
                          </th>
                          <th>
                            <b>Category</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {videoViewsListing && videoViewsListing.length === 0 ? (
                          <tr className="text-center text-danger not-found-txt">
                            <td colSpan="6">
                              {!loading ? (
                                <h6
                                  className="text-center text-danger not-found-txt"
                                  colSpan="6"
                                >
                                  No Records Found!
                                </h6>
                              ) : null}
                            </td>
                          </tr>
                        ) : (
                          videoViewsListing &&
                          Array.isArray(videoViewsListing) &&
                          videoViewsListing.map((user, key) => {
                            return (
                              <tr key={user?._id}>
                                {/* check box */}

                                <td>
                                  <div className="d-flex">
                                    {moment(user?.createdAt).format(
                                      "l, h:mm:ss a"
                                    )}
                                  </div>
                                </td>

                                <td>
                                  <div className="email-col d-flex align-items-center d-flex align-center">
                                    <span className="avtar-md">
                                      <img
                                        src={
                                          user?.videoDetails[0]?.videoThumb
                                            ? user?.videoDetails[0]?.videoThumb
                                            : "/assets/images/no-user.png"
                                        }
                                      />
                                    </span>
                                    <span className="text-break ps-3">
                                      {" "}
                                      {user?.videoDetails[0]?.videoTitle}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="email-col d-flex align-items-center d-flex align-center">
                                    <span className="avtar-md">
                                      <img
                                        src={
                                          user?.videoDetails[0]
                                            ?.creatorDetails[0]
                                            ?.creatorProfilephoto
                                            ? user?.videoDetails[0]
                                                ?.creatorDetails[0]
                                                ?.creatorProfilephoto
                                            : "/assets/images/no-user.png"
                                        }
                                      />
                                    </span>

                                    {user?.videoDetails[0]?.creatorDetails
                                      .length > 0 &&
                                    !user?.videoDetails[0]?.postedByBrand ? (
                                      <span className="ps-3">
                                        {user?.videoDetails[0]
                                          ?.creatorDetails[0]?.firstname +
                                          " " +
                                          user?.videoDetails[0]
                                            ?.creatorDetails[0]?.lastname}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {user?.videoDetails[0]?.postedByBrand ? (
                                      <span className="ps-3">Brand</span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>

                                <td>
                                  <div className="eamil-col d-flex align-items-center">
                                    <span className="avtar-md">
                                      <img
                                        src={
                                          user?.viewerDetails[0]
                                            ?.creatorProfilephoto
                                            ? user?.viewerDetails[0]
                                                ?.creatorProfilephoto
                                            : "/assets/images/no-user.png"
                                        }
                                      />
                                    </span>
                                    {user?.viewerDetails?.length > 0 && (
                                      <span className="ps-3">
                                        {user?.viewerDetails[0]?.firstname +
                                          " " +
                                          user?.viewerDetails[0]?.lastname}
                                      </span>
                                    )}
                                  </div>
                                </td>

                                <td>
                                  <div className="eamil-col d-flex align-items-center">
                                    <span>
                                      {" "}
                                      {
                                        user?.videoDetails[0]?.brands[0]
                                          ?.organization
                                      }
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="custom-control custom-switch light">
                                    {
                                      user?.videoDetails[0]?.categoryName[0]
                                        ?.title
                                    }
                                    <label
                                      className="custom-control-label"
                                      for={user?._id}
                                    ></label>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                  {videoViewsListing && videoViewsListing.length < 10 ? (
                    <div>
                      <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                        <p className="mb-0 align-self-center text-primary-light fs-14">
                          Showing <b> {videoViewsListing.length} </b> Out of{" "}
                          <b> {totalView} </b>Entries
                        </p>
                        <div className="pagination-list ms-2">
                          <Pagination
                            className="ant-pagination"
                            pageSize={viewPageSize}
                            current={viewPage}
                            total={totalView}
                            onChange={paginationChange1}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                      <p className="mb-0 align-self-center text-primary-light fs-14">
                        Showing <b>10 </b> Out of <b> {totalView} </b>Entries
                      </p>
                      <div className="pagination-list ms-2">
                        <Pagination
                          className="ant-pagination"
                          pageSize={viewPageSize}
                          current={viewPage}
                          total={totalView}
                          onChange={paginationChange1}
                        />
                      </div>
                    </div>
                  )}
                </Tab>
                {/* product clicks */}
                <Tab
                  eventKey="Product Views"
                  title={`Product Views`}
                  className="card-body"
                >
                  <div className="content-area position-relative">
                    <div className="user-listing-filterOptions mb-2 d-block">
                      <div className="row mb-2">
                        <div className="col-sm-8 position-static">
                          <div className="left-buttons d-flex ">
                            <label>
                              <div className="search-input-wrapper position-relative">
                                <i className="icon-magnifier"></i>{" "}
                                <input
                                  type="search"
                                  value={searchText3}
                                  className="form-control text-capitalize"
                                  placeholder="search"
                                  onChange={(evt) => {
                                    handleSearchChange2(evt);
                                  }}
                                />
                                {searchText3 && searchText3.length > 0 ? (
                                  <div className="clear">
                                    <span
                                      onClick={clearInput1}
                                      style={{ marginRight: "20px" }}
                                    >
                                      <em className="icon-close"></em>
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            </label>

                            <div
                              id="container1"
                              className={
                                filterpopup
                                  ? "custom-dropdown filter-data-dropdown position-static ml-2 open"
                                  : "custom-dropdown filter-data-dropdown position-static ml-2"
                              }
                            >
                              <button
                                onClick={() => {
                                  openFilterpopup(!filterpopup);
                                }}
                                className="btn btn-bordered dropdown-toggle custom-filter-dropdown btn-bg-white d-flex align-items-center"
                                type="button"
                              >
                                <i className="bx bx-filter-alt align-text-top me-2"></i>
                                Filter
                              </button>
                              {renderFilter2()}
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-4">
                          <div
                            className="right-buttons d-flex justify-content-end"
                            style={{ gap: "10px" }}
                          >
                            <div className="form-group  col-sm-4  d-flex">
                              <div className="w-100">
                                <Select
                                  id="download"
                                  name="download"
                                  options={options}
                                  placeholder="Download"
                                  onChange={function (e) {
                                    handleDownload2(e);
                                  }}
                                  value={downloadSelect2}
                                />
                              </div>
                              <span
                                className="error-msg"
                                style={{ color: "red" }}
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table
                      className="table row-border nowrap common-datatable"
                      id="user-listing"
                    >
                      <thead>
                        <tr>
                          <th className="all">
                            <b>View Date & Time</b>
                          </th>
                          <th>
                            <b>Video Title</b>
                          </th>

                          <th>
                            <b>User</b>
                          </th>
                          <th>
                            <b>Posted By</b>
                          </th>
                          <th>
                            <b>Brand</b>
                          </th>
                          <th>
                            <b>Category</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {productViewList && productViewList.length === 0 ? (
                          <tr className="text-center text-danger not-found-txt">
                            <td colSpan="6">
                              {!loading ? (
                                <h6
                                  className="text-center text-danger not-found-txt"
                                  colSpan="6"
                                >
                                  No Records Found!
                                </h6>
                              ) : null}
                            </td>
                          </tr>
                        ) : (
                          productViewList &&
                          Array.isArray(productViewList) &&
                          productViewList.map((user) => {
                            return (
                              <tr key={user?._id}>
                                {/* check box */}

                                <td>
                                  <div className="d-flex">
                                    {moment(user?.createdAt).format(
                                      "l, h:mm:ss a"
                                    )}
                                  </div>
                                </td>

                                <td>
                                  <div className="email-col d-flex align-items-center d-flex align-center">
                                    <span className="avtar-md">
                                      <img
                                        src={
                                          user?.videoDetails[0]?.videoThumb
                                            ? user?.videoDetails[0]?.videoThumb
                                            : "/assets/images/no-user.png"
                                        }
                                      />
                                    </span>
                                    <span className="text-break ps-3">
                                      {user?.videoDetails[0]?.videoTitle}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="eamil-col d-flex align-items-center">
                                    <span className="avtar-md">
                                      <img
                                        src={
                                          user?.viewerDetails[0]
                                            ?.creatorProfilephoto
                                            ? user?.viewerDetails[0]
                                                ?.creatorProfilephoto
                                            : "/assets/images/no-user.png"
                                        }
                                      />
                                    </span>
                                    {user?.viewerDetails?.length > 0 ? (
                                      <span className="ps-3">
                                        {user?.viewerDetails[0]?.firstname +
                                          " " +
                                          user?.viewerDetails[0]?.lastname}
                                      </span>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="email-col d-flex align-items-center d-flex align-center ">
                                    <span className="avtar-md">
                                      <img
                                        src={
                                          user?.videoDetails[0]
                                            ?.creatorDetails[0]
                                            ?.creatorProfilephoto
                                            ? user?.videoDetails[0]
                                                ?.creatorDetails[0]
                                                ?.creatorProfilephoto
                                            : "/assets/images/no-user.png"
                                        }
                                      />
                                    </span>

                                    {user?.videoDetails[0]?.creatorDetails
                                      ?.length > 0 &&
                                    !user?.videoDetails[0]?.postedByBrand ? (
                                      <span className="ps-3">
                                        {user?.videoDetails[0]
                                          ?.creatorDetails[0]?.firstname +
                                          " " +
                                          user?.videoDetails[0]
                                            ?.creatorDetails[0]?.lastname}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {user?.videoDetails[0]?.postedByBrand ? (
                                      <span className="ps-3">Brand</span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>

                                <td>
                                  <div className="eamil-col d-flex align-items-center">
                                    <span>
                                      {" "}
                                      {
                                        user?.videoDetails[0]?.brands[0]
                                          ?.organization
                                      }
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="custom-control custom-switch light">
                                    {
                                      user?.videoDetails[0]?.categoryName[0]
                                        ?.title
                                    }
                                    <label
                                      className="custom-control-label"
                                      for={user?._id}
                                    ></label>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                  {productViewList && productViewList.length < 10 ? (
                    <div>
                      <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                        <p className="mb-0 align-self-center text-primary-light fs-14">
                          Showing <b> {productViewList.length} </b> Out of{" "}
                          <b> {totalProductClicks} </b>Entries
                        </p>
                        <div className="pagination-list ms-2">
                          <Pagination
                            className="ant-pagination"
                            pageSize={prodPageSize}
                            current={prodPage}
                            total={totalProductClicks}
                            onChange={paginationChange2}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                      <p className="mb-0 align-self-center text-primary-light fs-14">
                        Showing <b> 10 </b> Out of <b> {totalProductClicks} </b>
                        Entries
                      </p>
                      <div className="pagination-list ms-2">
                        <Pagination
                          className="ant-pagination"
                          pageSize={prodPageSize}
                          current={prodPage}
                          total={totalProductClicks}
                          onChange={paginationChange2}
                        />
                      </div>
                    </div>
                  )}
                </Tab>
                {/* <Tab
                  eventKey="Product Purchased"
                  title={`Product Purchased`}
                  className="card-body"
                ></Tab> */}
                {/* video purchased */}
                <Tab
                  eventKey="Video Purchased"
                  title={`Video Purchased`}
                  className="card-body"
                >
                  <div className="content-area position-relative">
                    <div className="user-listing-filterOptions mb-2 d-block">
                      <div className="row mb-2">
                        <div className="col-sm-8 position-static">
                          <div className="left-buttons d-flex ">
                            <label>
                              <div className="search-input-wrapper position-relative">
                                <i className="icon-magnifier"></i>{" "}
                                <input
                                  type="search"
                                  value={searchText4}
                                  className="form-control text-capitalize"
                                  placeholder="search"
                                  onChange={(evt) => {
                                    handleSearchChange3(evt);
                                  }}
                                />
                                {searchText4 && searchText4.length > 0 ? (
                                  <div className="clear">
                                    <span
                                      onClick={clearInput1}
                                      style={{ marginRight: "20px" }}
                                    >
                                      <em className="icon-close"></em>
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            </label>

                            <div
                              id="container1"
                              className={
                                filterpopup
                                  ? "custom-dropdown filter-data-dropdown position-static ml-2 open"
                                  : "custom-dropdown filter-data-dropdown position-static ml-2"
                              }
                            >
                              <button
                                onClick={() => {
                                  openFilterpopup(!filterpopup);
                                }}
                                className="btn btn-bordered dropdown-toggle custom-filter-dropdown btn-bg-white d-flex align-items-center"
                                type="button"
                              >
                                <i className="bx bx-filter-alt align-text-top me-2"></i>
                                Filter
                              </button>
                              {renderFilter4()}
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-4">
                          <div
                            className="right-buttons d-flex justify-content-end"
                            style={{ gap: "10px" }}
                          >
                            <div className="form-group  col-sm-4  d-flex">
                              <div className="w-100">
                                <Select
                                  id="download"
                                  name="download"
                                  options={options}
                                  placeholder="Download"
                                  onChange={function (e) {
                                    handleDownload4(e);
                                  }}
                                  value={downloadSelect4}
                                />
                              </div>
                              <span
                                className="error-msg"
                                style={{ color: "red" }}
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table
                      className="table row-border nowrap common-datatable"
                      id="user-listing"
                    >
                      <thead>
                        <tr>
                          <th className="all">
                            <b>Purchase Date & Time</b>
                          </th>
                          <th>
                            <b>Celebrity</b>
                          </th>

                          <th>
                            <b>Category</b>
                          </th>

                          <th>
                            <b>Brand</b>
                          </th>
                          <th>
                            <b>No. Of Vid</b>
                          </th>
                          <th>
                            <b>Published Vid</b>
                          </th>
                          <th>
                            <b>Remaining Vid</b>
                          </th>
                          <th>
                            <b> ₹ Price/Video</b>
                          </th>
                          <th>
                            <b>Total Price (Including Vista Charges)</b>
                          </th>
                          <th>
                            <b>Payment Released</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {purchaseList && purchaseList.length === 0 ? (
                          <tr className="text-center text-danger not-found-txt">
                            <td colSpan="6">
                              <h6
                                className="text-center text-danger not-found-txt"
                                colSpan="6"
                              >
                                No Records Found!
                              </h6>
                            </td>
                          </tr>
                        ) : (
                          purchaseList &&
                          Array.isArray(purchaseList) &&
                          purchaseList.map((user) => {
                            return (
                              <tr key={user?._id}>
                                <td>
                                  <div className="d-flex">
                                    {moment(user?.createdAt).format(
                                      "l, h:mm:ss a"
                                    )}
                                  </div>
                                </td>

                                <td>
                                  <div className="email-col d-flex align-items-center d-flex align-center">
                                    <span className="avtar-md">
                                      <img
                                        src={
                                          user?.celebrityName[0]
                                            ?.creatorProfilephoto
                                            ? user?.celebrityName[0]
                                                ?.creatorProfilephoto
                                            : "/assets/images/no-user.png"
                                        }
                                      />
                                    </span>
                                    {user?.celebrityName?.length > 0 && (
                                      <span className="ps-3">
                                        {user?.celebrityName[0]?.firstname +
                                          " " +
                                          user?.celebrityName[0]?.lastname}
                                      </span>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="email-col d-flex align-items-center d-flex align-center ">
                                    <span>{user?.category[0]?.title} </span>
                                  </div>
                                </td>

                                <td>
                                  <div className="eamil-col d-flex align-items-center">
                                    <span>{user?.brands[0]?.organization}</span>
                                  </div>
                                </td>

                                <td>
                                  <div className="eamil-col d-flex align-items-center">
                                    <span> {user?.noOfVideosOrdered}</span>
                                  </div>
                                </td>

                                <td>
                                  <div className="eamil-col d-flex align-items-center">
                                    <span> {user?.noOfVideoPublished}</span>
                                  </div>
                                </td>
                                <td>
                                  <div className="eamil-col d-flex align-items-center">
                                    <span>
                                      {" "}
                                      {user?.noOfVideosOrdered -
                                        user?.noOfVideoPublished}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="custom-control custom-switch light">
                                    <span>
                                      {" "}
                                      {user?.pricePerVideo
                                        ? user?.pricePerVideo
                                        : "-"}{" "}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="custom-control custom-switch light">
                                    <span> {user?.price}</span>
                                  </div>
                                </td>

                                <td>
                                  <div className="custom-control custom-switch light">
                                    <span>
                                      {" "}
                                      {user?.paymentReleasedFromAdmin
                                        ? "Yes"
                                        : "No"}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                  {purchaseList && purchaseList.length < 10 ? (
                    <div>
                      <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                        <p className="mb-0 align-self-center text-primary-light fs-14">
                          Showing <b> {purchaseList?.length} </b> Out of{" "}
                          <b> {totalVids} </b> Entries
                        </p>
                        <div className="pagination-list ms-2">
                          <Pagination
                            className="ant-pagination"
                            pageSize={vidPageSize}
                            current={vidPage}
                            total={totalVids}
                            onChange={paginationChange3}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                      <p className="mb-0 align-self-center text-primary-light fs-14">
                        Showing <b> 10 </b> Out of <b> {totalVids} </b> Entries
                      </p>
                      <div className="pagination-list ms-2">
                        <Pagination
                          className="ant-pagination"
                          pageSize={vidPageSize}
                          current={vidPage}
                          total={totalVids}
                          onChange={paginationChange3}
                        />
                      </div>
                    </div>
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  );
};

export default Reports;
