import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Spinner from "../../Components/Loader/Spinner";
import { isEmpty } from "lodash";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { callApi } from "../../Api";
import API from "../../Api/Routes";
import Layout from "../../Components/Layout/Layout";
import CardData from "../../Components/Cards/CardData";
import Chart from "../../Components/Charts/Chart";
import {
  setDashboardData,
  monthwiseVideosData,
  getSubscriptionData,
} from "../../Store/Actions/User";
import { showModalNotification } from "../../Utils/Functions";
import EarningChart from "../../Components/Charts/EarningChart";
/******************* 
@Purpose : Used for dashboard view
@Parameter : {}
@Author : Vista app
******************/
function Dashboard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [spin, setSpin] = useState(false);
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  const [trendDate, setTrendDate] = useState(new Date());
  const [filterpopup, openFilterpopup] = useState(false);
  const [filterpopup1, openFilterpopup1] = useState(false);

  const [, setLoadingFilter] = useState(false);
  const [topVids, setTopVids] = useState([]);
  const [brand, setBrand] = useState([]);
  const [date, setDate] = useState(new Date());
  const [celebrity, setCelebrity] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [errorsLicence, setErrorsLicence] = useState([
    {
      key: "",
      type: "",
      input: "",
      value: { startDate: "", endDate: "" },
    },
  ]);
  const [filterObj, setFilterObj] = useState([
    {
      key: "",
      type: "",
      input: "",
      value: { startDate: "", endDate: "" },
      condition: "$and",
    },
  ]);
  const [filterObj1, setFilterObj1] = useState([
    {
      key: "",
      type: "",
      input: "",
      value: { startDate: "", endDate: "" },
      condition: "$and",
    },
  ]);
  const [subscriptionData, setsubscriptionData] = useState([]);
  const [clearTrendData, setClearTrendData] = useState(false);
  const [trendFilter, setTrendFilter] = useState();
  const [earningFilter, setEarningFilter] = useState();
  const [earningClear, setEarningClear] = useState(false);
  const handleCalendarClose = () => console.log("Calendar closed");
  const handleCalendarOpen = () => console.log("Calendar opened");
  /******************* 
  @Purpose : Used for add new filter records
  @Parameter : {e}
  @Author : INIC
  ******************/
  const addNewRow = (e) => {
    handleFilterArray();
    setErrorsLicence([
      ...errorsLicence,
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    setFilterObj([
      ...filterObj,
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
  };
  /******************* 
  @Purpose : Used for remove filter records
  @Parameter : {index}
  @Author : INIC
  ******************/
  const removeRow = (index) => {
    let array = [...filterObj];
    array.splice(index, 1);
    setFilterObj(array);
    array = [...errorsLicence];
    array.splice(index, 1);
    setErrorsLicence(array);
    setTimeout(function () {
      setLoadingFilter(true);
    }, 100);
  };
  //
  /******************* 
  @Purpose : Used for filter 
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleFilterArray = () => {
    let selected = filterObj.map((item) => item.key.value);
    setSelectedFilterItems(selected);
  };
  //
  useEffect(() => {
    getHighestViewdVids();
    getBrandList();
    getCompleateCelebData();
  }, []);
  /******************* 
  @Purpose : Used for brand list
  @Parameter : {}
  @Author : INIC
  ******************/
  const getBrandList = async () => {
    try {
      let body = {
        searchText: "",
      };

      const response = await callApi(
        API.ALL_BRANDS,
        body,
        "post",
        "ALL_BRANDS",
        true,
        true,
        false
      );

      if (response) {
        const allBrandsList = Array.isArray(response.data)
          ? response.data.map((item) => ({
            ...item,
            label: item.organization,
            value: item._id,
          }))
          : [];
        setBrand(allBrandsList);
      }
    } catch (error) {
      console.error();
    }
  };
  /******************* 
  @Purpose : Used for get all the vids
  @Parameter : status, timezoneId
  @Author : INIC
  ******************/
  const getHighestViewdVids = async (e) => {
    setSpin(true);
    try {
      dispatch(setDashboardData()).then((response) => {
        if (response.status === 1) {
          setSpin(false);
          setTopVids(response?.videos);
        } else if (response?.message === 0) {
          showModalNotification(response?.message, "error");
        }
      });
    } catch (error) {
      console.error();
    }
  };
  /******************* 
  @Purpose : Used for get all roles
  @Parameter : {}
  @Author : INIC
  ******************/
  const getCompleateCelebData = async () => {
    let body = {
      searchText: "",
    };
    const response = await callApi(
      API.CELEB_ALL,
      body,
      "post",
      "CELEBRITIES",
      true,
      "",
      "",
      history
    );

    if (response.status === 1) {
      const allcelebData = Array.isArray(response.celebrityList)
        ? response.celebrityList.map((item) => ({
          ...item,
          label: item?.firstname + " " + item.lastname,

          value: item._id,
        }))
        : [];
      setCelebrity(allcelebData);
    }
  };
  /******************* 
  @Purpose : Used for reset filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const resetFilter = () => {
    setFilterObj([
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    setFilterObj1([
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    setSelectedFilterItems([]);
    openFilterpopup(!filterpopup);

    setClearTrendData(true);
  };
  const resetFilter1 = () => {
    setFilterObj1([
      {
        key: "",
        type: "",
        input: "",
        value: { startDate: "", endDate: "" },
        condition: "$and",
      },
    ]);
    setSelectedFilterItems([]);

    openFilterpopup1(!filterpopup1);
    setClearTrendData(true);
    setEarningClear(true);
  };

  /******************* 
  @Purpose : Used for Bread crumb
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      key: "1",
      title: "Dashboard",
      subtitle: "Dashboard",
      url: "/",
    },
  ];
  const selectFieldOptions = [
    { value: "celebrity", label: "Celebrity" },
    { value: "brand", label: "Brand" },
  ];
  // field options
  const selectFieldOptions1 = [{ value: "brand", label: "Brand" }];
  const selectContentOptions = [
    { value: "Select Field", label: "Select Field" },
  ];
  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  const renderFilter = () => {
    return (
      <div className="custom-dropdown-menu filter-menu">
        <div className="card-body" style={{ paddingRight: "70px" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-repeat">
                {filterObj &&
                  filterObj.length > 0 &&
                  filterObj.map((o, i) => {
                    return (
                      <form
                        key={o?.key?.label}
                        className="form repeater-default"
                        data-limit="5"
                      >
                        <div className="field-form">
                          <div className="target-form">
                            <div
                              className="target-details"
                              style={{ marginBottom: "10px" }}
                            >
                              <div className="row align-items-start">
                                <div className="form-group col-md-5 col-lg-6 mb-2">
                                  <label className="form-label">
                                    Select Field
                                  </label>
                                  <Select
                                    filterOption={(option) =>
                                      !selectedFilterItems.includes(
                                        option.value
                                      )
                                    }
                                    showSearch
                                    placeholder="Enter Field"
                                    optionFilterProp="children"
                                    className="w-100 custom-input filter-select css-2b097c-container"
                                    value={o.key}
                                    onChange={(value) => {
                                      o.key = value;
                                      o.type = null;
                                      setFilterObj([...filterObj]);

                                      if (errorsLicence[i])
                                        delete errorsLicence[i].key;
                                    }}
                                    options={selectFieldOptions}
                                  />

                                  <span className="text-danger error-msg"></span>
                                </div>
                                <div className="form-group col-md-5 col-lg-6 mb-2">
                                  <label className="form-label">
                                    Select Content (max 5)
                                  </label>
                                  <Select
                                    closeMenuOnSelect={false}
                                    isMulti={
                                      o.key.label === "Category" ? false : true
                                    }
                                    showSearch={false}
                                    placeholder="Select Type"
                                    className="w-100 custom-input filter-select"
                                    optionFilterProp="children"
                                    value={o.type}
                                    isOptionDisabled={() => o.type?.length >= 5}
                                    onChange={(value) => {
                                      o.type = value;
                                      if (o.type === "date") {
                                        delete o.value;
                                        o.value = {
                                          startDate: "",
                                          endDate: "",
                                        };
                                      }
                                      setFilterObj([...filterObj]);
                                      handleFilterArray();
                                      if (errorsLicence[i])
                                        delete errorsLicence[i].type;
                                    }}
                                    options={
                                      o.key.label === "Celebrity"
                                        ? celebrity
                                        : o.key.label === "Brand"
                                          ? brand
                                          : selectContentOptions
                                    }
                                  />

                                  <span className="text-danger error-msg">
                                    {o.error}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              className="add-row col-lg-2 col-md-2"
                              style={{ display: "flex", gap: "10px" }}
                            >
                              {filterObj.length <= 1 && i === 0 ? (
                                <span
                                  className="add-ic icon-plus text-success"
                                  onClick={(e) => addNewRow(e)}
                                  type="button"
                                ></span>
                              ) : null}
                              {filterObj && filterObj.length >= 2 ? (
                                <span
                                  className="icon-minus mr-1 text-danger"
                                  onClick={() => removeRow(i)}
                                  type="button"
                                ></span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </form>
                    );
                  })}
              </div>

              <div className="col-md-12 text-end pt-4">
                <button
                  onClick={() => resetFilter()}
                  type="button"
                  className="btn btn-light me-2"
                >
                  Clear
                </button>
                <button
                  onClick={(evt) => ApplyFilter()}
                  type="button"
                  className="btn btn-primary"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /*******************
  @purpose : Render HTML/ React Components earning  chart
  @Author : INIC
  ******************/
  const renderFilter1 = () => {
    return (
      <div className="custom-dropdown-menu filter-menu">
        <div className="card-body" style={{ paddingRight: "70px" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-repeat">
                {filterObj1 &&
                  filterObj1.length > 0 &&
                  filterObj1.map((o, i) => {
                    return (
                      <form
                        key={o?.key?.label}
                        className="form repeater-default"
                        data-limit="5"
                      >
                        <div className="field-form">
                          <div className="target-form">
                            <div
                              className="target-details"
                              style={{ marginBottom: "10px" }}
                            >
                              <div className="row align-items-start">
                                <div className="form-group col-md-5 col-lg-6 mb-2">
                                  <label className="form-label">
                                    Select Field
                                  </label>
                                  <Select
                                    filterOption={(option) =>
                                      !selectedFilterItems.includes(
                                        option.value
                                      )
                                    }
                                    showSearch
                                    placeholder="Enter Field"
                                    optionFilterProp="children"
                                    className="w-100 custom-input filter-select"
                                    value={o.key}
                                    onChange={(value) => {
                                      o.key = value;
                                      o.type = null;
                                      setFilterObj1([...filterObj1]);

                                      if (errorsLicence[i])
                                        delete errorsLicence[i].key;
                                    }}
                                    options={selectFieldOptions1}
                                  />

                                  <span className="text-danger error-msg"></span>
                                </div>
                                <div className="form-group col-md-5 col-lg-6 mb-2">
                                  <label className="form-label">
                                    Select Content (max 5)
                                  </label>
                                  <Select
                                    closeMenuOnSelect={false}
                                    isMulti={
                                      o.key.label === "Category" ? false : true
                                    }
                                    showSearch={false}
                                    placeholder="Select Type"
                                    className="w-100 custom-input filter-select"
                                    optionFilterProp="children"
                                    value={o.type}
                                    isOptionDisabled={() => o.type?.length >= 5}
                                    onChange={(value) => {
                                      o.type = value;
                                      if (o.type === "date") {
                                        delete o.value;
                                        o.value = {
                                          startDate: "",
                                          endDate: "",
                                        };
                                      }
                                      setFilterObj1([...filterObj1]);
                                      handleFilterArray();
                                      if (errorsLicence[i])
                                        delete errorsLicence[i].type;
                                    }}
                                    options={
                                      o.key.label === "Brand"
                                        ? brand
                                        : selectContentOptions
                                    }
                                  />

                                  <span className="text-danger error-msg">
                                    {o.error}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              className="add-row col-lg-2 col-md-2"
                              style={{ display: "flex", gap: "10px" }}
                            >
                              {filterObj.length <= 0 && i === 0 ? (
                                <span
                                  className="add-ic icon-plus text-success"
                                  onClick={(e) => addNewRow(e)}
                                  type="button"
                                ></span>
                              ) : null}
                              {filterObj1 && filterObj1.length >= 2 ? (
                                <span
                                  className="icon-minus mr-1 text-danger"
                                  onClick={() => removeRow(i)}
                                  type="button"
                                ></span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </form>
                    );
                  })}
              </div>

              <div className="col-md-12 text-end pt-4">
                <button
                  onClick={() => resetFilter1()}
                  type="button"
                  className="btn btn-light me-2"
                >
                  Clear
                </button>
                <button
                  onClick={(evt) => ApplyFilter1()}
                  type="button"
                  className="btn btn-primary"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  /******************* 
  @Purpose : Used for applay filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const ApplyFilter = async () => {
    try {
      if (validateFiltersearch()) {
        setFilterObj1([
          {
            key: "",
            type: "",
            input: "",
            value: { startDate: "", endDate: "" },
            condition: "$and",
          },
        ]);
        let payload = filterObj.map((item) => {
          if (item.key.label === "Celebrity" || item.key.label === "Brand") {
            return item.type.map((val) => {
              return {
                key:
                  item.key.label === "Celebrity"
                    ? "creatorId"
                    : item.key.label === "Brand"
                      ? "brandId"
                      : "",
                type: "contains",
                value: val._id,
              };
            });
          }
        });

        let body = {
          year: Number(moment(trendDate).format("YYYY")),
          filter: payload.flat(),
        };

        dispatch(monthwiseVideosData(body)).then((response) => {
          if (response.status === 1) {
            setTrendFilter(payload.flat());
            setViewData(response?.viewCountByMonth);
            openFilterpopup(!filterpopup);
          } else {
            showModalNotification(response?.message, "error");
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  /******************* 
  @Purpose : Used for applay filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const ApplyFilter1 = async () => {
    try {
      if (validateFiltersearch1()) {
        setFilterObj([
          {
            key: "",
            type: "",
            input: "",
            value: { startDate: "", endDate: "" },
            condition: "$and",
          },
        ]);
        let payload = filterObj1.map((item) => {
          if (item.key.label === "Brand") {
            return item.type.map((val) => {
              return {
                key: item.key.label === "Brand" ? "brandId" : "",
                type: "contains",
                value: val._id,
              };
            });
          }
        });

        let body = {
          year: Number(moment(date).format("YYYY")),
          filter: payload.flat(),
        };

        await dispatch(getSubscriptionData(body)).then((response) => {
          if (response.status === 1) {
            setEarningFilter(payload.flat());
            setsubscriptionData(response?.paymentData);
            openFilterpopup1(!filterpopup1);
          } else if (response?.message === 0) {
            showModalNotification(response?.message, "error");
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  /******************* 
  @Purpose : Used for validate filter
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateFiltersearch = () => {
    let isFormValid = true;

    let valid = filterObj.map((each) => {
      if (each.key.label === "Brand" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the brand fields";
      } else {
        if (each.key.label === "Brand") {
          isFormValid = true;
          each.error = "";
        }
      }
      if (each.key.label === "Celebrity" && isEmpty(each.type)) {
        isFormValid = false;
        each.error = "Select the fields";
      } else {
        if (each.key.label === "Celebrity") {
          isFormValid = true;
          each.error = "";
        }
      }

      return each;
    });

    setFilterObj(valid);
    return isFormValid;
  };
  // validate
  const validateFiltersearch1 = () => {
    let isFormValid1 = true;

    let valid = filterObj1.map((each) => {
      if (each.key.label === "Brand" && isEmpty(each.type)) {
        isFormValid1 = false;
        each.error = "Select the brand fields";
      } else {
        if (each.key.label === "Brand") {
          isFormValid1 = true;
          each.error = "";
        }
      }

      return each;
    });

    setFilterObj1(valid);
    return isFormValid1;
  };
  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Dashboard">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <CardData />
            {/* trend ui charts */}
            <Row>
              <Col md={6} className="mb-5">
                <div className="card graph-wrap">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="card-title align-self-center">
                      Trending Videos
                    </h5>
                    <div className="action-wrap d-flex flex-wrap align-item-center">
                      <div
                        id="container1"
                        className={
                          filterpopup
                            ? "custom-dropdown filter-data-dropdown position-static ml-2 open"
                            : "custom-dropdown filter-data-dropdown position-static ml-2"
                        }
                      >
                        <button
                          onClick={() => {
                            openFilterpopup(!filterpopup);
                          }}
                          className="btn btn-bordered dropdown-toggle custom-filter-dropdown btn-bg-white d-flex align-items-center"
                          type="button"
                        >
                          <i className="bx bx-filter d-lg-none"></i>
                          <i className="bx bx-filter-alt align-text-top me-2"></i>
                          Filter
                        </button>
                        {renderFilter()}
                      </div>
                      <div
                        className="custom-datepicker"
                        onClick={() => {
                          document.querySelectorAll("input")[1].click();
                        }}
                      >
                        <span className="cal-icon">
                          <img
                            src="assets/images/bxs-calendar.svg"
                            alt="calendar icon"
                            width="16"
                            height="16"
                          ></img>
                        </span>
                        <DatePicker
                          selected={trendDate}
                          onChange={(date) => setTrendDate(date)}
                          showYearPicker
                          dateFormat="yyyy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-body chart-wrapper">
                    <Chart
                      viewData={viewData}
                      setViewData={setViewData}
                      date={trendDate}
                      filterValues={trendFilter}
                      clearData={clearTrendData}
                      setClearTrendData={setClearTrendData}
                    />
                  </div>
                </div>
              </Col>
              <Col md={6} className="mb-5">
                <div className="card graph-wrap">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="card-title align-self-center">
                      Earnings (₹)
                    </h5>
                    <div className="action-wrap d-flex flex-wrap align-item-center">
                      <div
                        id="container1"
                        className={
                          filterpopup1
                            ? "custom-dropdown filter-data-dropdown position-static ml-2 open dashboardFilter"
                            : "custom-dropdown filter-data-dropdown position-static ml-2"
                        }
                        style={{ marginLeft: "600" }}
                      >
                        <button
                          onClick={() => {
                            openFilterpopup1(!filterpopup1);
                          }}
                          className="btn btn-bordered dropdown-toggle custom-filter-dropdown btn-bg-white d-flex align-items-center"
                          type="button"
                        >
                          <i className="bx bx-filter d-lg-none"></i>
                          <i className="bx bx-filter-alt align-text-top me-2"></i>
                          Filter
                        </button>
                        {renderFilter1()}
                      </div>
                      <div
                        className="custom-datepicker"
                        onClick={() => {
                          document.querySelectorAll("input")[4].click();
                        }}
                      >
                        <span className="cal-icon">
                          <img
                            src="assets/images/bxs-calendar.svg"
                            alt="calendar icon"
                            width="16"
                            height="16"
                          ></img>
                        </span>
                        <DatePicker
                          selected={date}
                          onChange={(date) => setDate(date)}
                          onCalendarClose={handleCalendarClose}
                          onCalendarOpen={handleCalendarOpen}
                          showYearPicker
                          dateFormat="yyyy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-body chart-wrapper">
                    <EarningChart
                      subscriptionData={subscriptionData}
                      setsubscriptionData={setsubscriptionData}
                      date={date}
                      filterValues={earningFilter}
                      clearData={earningClear}
                      setEarningClear={setEarningClear}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="mb-5">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Top 10 Highest Viewed Video </h5>
                  </div>
                  <div className="card-body">
                    {spin ? (
                      <div className="d-flex align-center justify-content-center">
                        <Spinner />
                      </div>
                    ) : null}
                    <div className="row">
                      {topVids && topVids.length === 0 ? (
                        <div className="text-center text-danger not-found-txt">
                          {" "}
                        </div>
                      ) : (
                        ""
                      )}

                      {topVids?.map((video) => {
                        return (
                          <div
                            className="col-lg-4 col-xl-3 col-md-4 col-6"
                            key={video?.createdAt}
                          >
                            <div className="video-card">
                              <div className="video-img position-relative">
                                <video
                                  controls={true}
                                  width="100%;"
                                  // className="border border-secondary border-1 video-box"
                                  height="250"
                                >
                                  <source
                                    src={video?.videoLink}
                                    type="video/mp4"
                                  />
                                </video>
                              </div>
                              <div className="video-info">

                                <h6 className="video-title fw-bold text-secondary">
                                  {video?.brand &&
                                    video?.brand[0] &&
                                    video?.brand[0]?.organization}
                                </h6>
                                <p className="video-date mb-1">
                                  {" "}
                                  Published on:{" "}
                                  {moment(video?.createdAt).format("L")}
                                </p>

                                <h6 className="video-title fw-semibold  text-secondary mb-1">
                                  {video?.videoTitle}
                                </h6>
                                <p className="video-title-name text-secondary mb-1">
                                  {video?.postedByBrand ? "By Brand" : ""}{" "}
                                  {video?.celebrityDetails[0]?.firstname
                                    ? `By ${video?.celebrityDetails[0]?.firstname}`
                                    : ""}
                                  &nbsp;
                                  {video?.celebrityDetails[0]?.lastname
                                    ? video?.celebrityDetails[0]?.lastname
                                    : ""}
                                </p>

                                <div class="d-flex flex-wrap">
                                  <div class="text-orange fs-14 me-3">
                                    <span class="fw-semibold">Views : </span>
                                    <span>{video?.viewCount}</span>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  );
}

export default Dashboard;
