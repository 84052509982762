import * as yup from "yup";

// const alphabatic = /^[a-z A-Z!@#\$%\^\&*\)\(+=._-]+$/g;
const alphabatic = /^[a-zA-Z][\W\s]*[^\d]*$/;

export const addBrandSchema = yup.object().shape({
  organization: yup
    .string()
    .max(50, "The organization name may not be greater than 50 characters")
    .matches(alphabatic, "Enter only Alphabate")
    .required("Please enter the first organization name"),
  emailId: yup
    .string()
    .email("Please enter a valid email address")
    .required("Please enter email address"),
});

export const editEmailSchema = yup.object().shape({
  emailId: yup
    .string()
    .email("Please enter a valid email address")
    .required("Please enter email address"),
});
