import React, { useState, useEffect } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Spinner from '../../Components/Loader/Spinner';
import { useDispatch } from 'react-redux';
import Pagination from 'rc-pagination';
import moment from 'moment';
import { callApi } from '../../Api';
import {
  videoStatusChange,
  commentedLists,
  commentsHide,
} from '../../Store/Actions/User';
import {
  showMessageNotification,
  showModalNotification,
} from '../../Utils/Functions';
import Layout from '../../Components/Layout/Layout';
import Loader from '../../Components/Loader/Loader';
import API from '../../Api/Routes';

import 'rc-pagination/assets/index.css';
import '../../Scss/Component/_reportedComments.scss';

import $ from 'jquery';

$.DataTable = require('datatables.net');
require('datatables.net-responsive');

/******************* 
@Purpose : Used for reported comment detail page
@Parameter : {}
@Author : INIC
*******************/
function ReportedCommentDetail(props) {
  let { slug } = useParams();
  const dispatch = useDispatch();
  const [commentList, setCommentList] = useState([]);
  const [pagesize, setPagesize] = useState(10);
  const [page, setPage] = useState(1);
  const [total] = useState(1);
  const [videoDetails, setVideoDetails] = useState([]);
  const [show1, setShow1] = useState(false);
  const [modalList, setModalList] = useState([]);
  const [currentListId, setCurrentListId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, setShowIcon] = useState(false);
  const IMG_URL = 'https://d3juhcxa2or09v.cloudfront.net/';
  const [spin, setSpin] = useState(false);
  /******************* 
  @Purpose : React hook used for get comment and change status
  @Parameter : {}
  @Author : INIC
  *******************/
  useEffect(() => {
    changeStatus(slug);
    getAllComments(slug);
  }, [slug, page]);

  /******************* 
  @Purpose : Used for bread crumb
  @Parameter : {}
  @Author : INIC
  *******************/
  const breadcrumb = [
    {
      title: 'Reported Comments',
      url: '/reportedcomments',
    },
    {
      title: 'Comments Details',
    },
  ];

  /******************* 
  @Purpose : Used for modal close
  @Parameter : {}
  @Author : INIC
  *******************/
  function modelCloseFun1() {
    setShow1(false);
  }

  /******************* 
  @Purpose : Used for pagination change
  @Parameter : {page, pagesize}
  @Author : INIC
  *******************/
  function paginationChange(page, pagesize) {
    setPage(page);
    setPagesize(pagesize);
  }

  /******************* 
  @Purpose : Used for get all comments
  @Parameter : {slug}
  @Author : INIC
  *******************/
  const getAllComments = async (slug) => {
    let body = {
      videoId: slug,
      pageNumber: page,
      pageSize: pagesize,
    };

    setLoading(true);
    try {
      dispatch(commentedLists(body)).then((response) => {
        if (response.status === 1) {
          setCommentList(response?.data);
        }

        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.error();
    }
  };

  /******************* 
  @Purpose : Used for hide comment
  @Parameter : {id}
  @Author : INIC
  *******************/
  const hideComment = async (id) => {
    let body = {
      commentId: id,
    };
    setLoading(true);
    try {
      dispatch(commentsHide(body)).then((response) => {
        if (response.status === 1) {
          showMessageNotification(response?.message);
          getAllComments(slug);
        }

        setShowIcon(response?.data?.isHiddenByAdmin);

        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  /******************* 
  @Purpose : Used for change status
  @Parameter : {id}
  @Author : INIC
  *******************/
  const changeStatus = async (id) => {
    setSpin(true);
    let body = {
      videoId: id,
    };
    setLoading(true);
    try {
      dispatch(videoStatusChange(body)).then((response) => {
        if (response.status === 1) {
          setSpin(false);
          setVideoDetails(response?.data[0]);
        } else if (response.status === 0) {
          setSpin(false);
          showModalNotification(response.message, 'error');
        }
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  /******************* 
  @Purpose : Used for handle on more click
  @Parameter : {user}
  @Author : INIC
  *******************/
  const handleViewMoreClick = (user) => {
    const index = currentListId.indexOf(user?._id);
    if (index > -1) {
      currentListId.splice(index, 1);
      setCurrentListId([currentListId]);
    } else {
      setCurrentListId([...currentListId, user?._id]);
    }
  };

  /******************* 
  @Purpose : Used for handle sub category modal
  @Parameter : {data}
  @Author : INIC
  *******************/
  const modelOnSubCategory = (data) => {
    getCommentReporters(data?._id);
    setShow1(true);
  };

  /******************* 
  @Purpose : Used for get commented reports
  @Parameter : {id}
  @Author : INIC
  *******************/
  const getCommentReporters = async (id) => {
    try {
      let body = {
        pageNumber: page,
        pageSize: pagesize,
        videoId: slug,
        commentId: id,
      };
      let response = await callApi(
        API.COMMENT_REPORTERS,
        body,
        'post',
        'COMMENT_REPORTERS',
        true,
        false
      );
      if (response.status === 1) {
        setModalList(response?.data);
      }
    } catch (error) {
      console.error();
    }
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Reported Comments ">
      <div>Reported Comments</div>
      <div className="main-content-area">
        <div className="main-content-block">
          <div className="card mb-4">
            <div className="card-header">
              <h5 className="card-title">Video Detail</h5>
            </div>
            <div className="card-body user-details">
              <div className="row">
                {spin ? (
                  <div className="d-flex align-center justify-content-center">
                    <Spinner />
                  </div>
                ) : null}
                <div className="col-lg-4 col-xxl-3 col-md-12">
                  <div className="video-img position-relative">
                    {videoDetails?.videoLink ? (
                      <video
                        className="br-12"
                        controls={true}
                        style={{
                          height: '170px',
                          width: '100%',
                        }}
                      >
                        <source
                          src={videoDetails?.videoLink}
                          type="video/mp4"
                        />
                      </video>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-8 col-xxl-5">
                  <div className="input-group row g-0">
                    <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                      Title
                    </div>
                    <div className="col-md-8 p-2 request-data">
                      {videoDetails?.videoTitle}
                    </div>
                  </div>

                  <div className="input-group row g-0">
                    <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                      SUB-CATEGORY
                    </div>
                    <div className="col-md-8 p-2 request-data">
                      {Array.isArray(videoDetails?.subcategories)
                        ? videoDetails?.subcategories[0]?.title
                        : ''}
                    </div>
                  </div>

                  <div className="input-group row g-0">
                    <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                      PRODUCT LINK
                    </div>
                    <div className="col-md-8 p-2 request-data">
                      <a
                        href={videoDetails?.affilietedProductUrl}
                        target="_blank"
                      >
                        {videoDetails?.affilietedProductUrl}
                      </a>
                    </div>
                  </div>
                </div>

                <div className="offset-lg-4 offset-xxl-0 col-lg-8 col-xxl-4">
                  <div className="input-group row g-0">
                    <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                      CATEGORY
                    </div>
                    <div className="col-md-8 p-2 request-data">
                      {Array.isArray(videoDetails?.category)
                        ? videoDetails?.category[0]?.title
                        : ''}
                    </div>
                  </div>

                  <div className="input-group row g-0">
                    <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                      CELEBRITY
                    </div>
                    <div className="col-md-8 p-2 request-data">
                      {Array.isArray(videoDetails?.celebrityDetails)
                        ? (videoDetails?.celebrityDetails[0]?.firstname || '') +
                        '  ' +
                        (videoDetails?.celebrityDetails[0]?.lastname || '')
                        : ''}
                    </div>
                  </div>
                  <div className="input-group row g-0">
                    <div className="col-md-4 p-2 text-uppercase fw-bold request-title">
                      {' '}
                      PUBLISH DATE
                    </div>
                    <div className="col-md-8 p-2 request-data">
                      {videoDetails?.createdAt
                        ? moment(videoDetails.createdAt).format('L')
                        : ''}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="repoters-list-table " style={{ minWidth: '800px' }}>
            <div className="d-flex align-items-center justify-content-between mb-2 flex-wrap">
              <div className="modal-wrapper">
                <Modal
                  size="xl"
                  show={show1}
                  onHide={modelCloseFun1}
                  centered
                  className="border  md={{ span: 3, offset: 3 }} table-responsive modal-view"
                >
                  <Modal.Header closeButton>
                    <h5 className="modal-title" id="exampleModalLongTitle">
                      Reported List
                    </h5>
                  </Modal.Header>
                  <Modal.Body closeButton className="pt-2">
                    <div className="notification-form">
                      {/* get the reported list */}
                      <div className="wrapper-row">
                        <div className="row">
                          <div className="table-responsive">
                            <table
                              className="table row-border nowrap common-datatable"
                              id="user-listing"
                            >
                              <thead>
                                <tr>
                                  <th>
                                    <b>Name</b>
                                  </th>

                                  <th>
                                    <b>Email</b>
                                    <i aria-hidden="true"></i>
                                  </th>
                                  <th>
                                    <b>Phone Number</b>
                                    <i aria-hidden="true"></i>
                                  </th>
                                  <th>
                                    <b>Gender</b>
                                    <i aria-hidden="true"></i>
                                  </th>
                                  <th>
                                    <b>Reported Reason</b>
                                    <i aria-hidden="true"></i>
                                  </th>
                                  <th>
                                    <b>Reported Date & Time</b>
                                    <i aria-hidden="true"></i>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {modalList && modalList?.length === 0 ? (
                                  <tr className="text-center text-danger not-found-txt">
                                    <td colSpan="6">
                                      {loading ? <Loader /> : null}{' '}
                                      {!loading ? (
                                        <h6
                                          className="text-center text-danger not-found-txt"
                                          colSpan="6"
                                        >
                                          {'No records are found '}
                                        </h6>
                                      ) : null}
                                    </td>
                                  </tr>
                                ) : (
                                  modalList &&
                                  Array.isArray(modalList) &&
                                  modalList?.map((user, idx) => {
                                    return (
                                      <tr key={user?._id}>
                                        {/* name,image */}
                                        <td>
                                          <div className="d-flex align-center justify-space-between">
                                            <span className="avtar-md">
                                              <img
                                                src={
                                                  user?.reporterDetails[0]
                                                    ?.creatorProfilephoto
                                                    ? user?.reporterDetails[0]
                                                      ?.creatorProfilephoto
                                                    : '/assets/images/no-user.png'
                                                }
                                                alt=""
                                                className="img-circle m-0"
                                              />
                                            </span>
                                            <span className="fs-14 ms-2 align-self-center">
                                              {user?.reporterDetails[0]
                                                ? `${user?.reporterDetails[0]?.firstname}
                                                      ${user?.reporterDetails[0]?.lastname}`
                                                : '-'}
                                            </span>
                                          </div>
                                        </td>
                                        <td className="fs-14 align-middle">
                                          <div className="eamil-col d-flex align-items-center">
                                            <span>
                                              {user?.reporterDetails[0]
                                                ? `${user?.reporterDetails[0]?.emailId}
                                                    `
                                                : '-'}
                                            </span>
                                          </div>
                                        </td>
                                        <td className="fs-14 align-middle">
                                          <div className="eamil-col d-flex align-items-center">
                                            <span>
                                              {user?.reporterDetails[0]
                                                ? `${user?.reporterDetails[0]?.mobileNumber}
                                                    `
                                                : '-'}
                                            </span>
                                          </div>
                                        </td>

                                        <td className="fs-14 align-middle">
                                          <div className="eamil-col d-flex align-items-center">
                                            <span>
                                              {user?.reporterDetails[0]
                                                ? `${user?.reporterDetails[0]?.gender}
                                                    `
                                                : '-'}
                                            </span>
                                          </div>
                                        </td>
                                        <td className="fs-14 align-middle">
                                          {user?.reason
                                            ? ` ${user?.reason}`
                                            : '-'}
                                        </td>
                                        <td className="fs-14 align-middle">
                                          <div className="eamil-col d-flex align-items-center">
                                            <span>
                                              {user?.createdAt
                                                ? moment(user.createdAt).format(
                                                  'l, h:mm:ss a'
                                                )
                                                : ''}
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })
                                )}
                              </tbody>
                            </table>
                            {modalList && modalList.length < 10 ? (
                              <div>
                                {/* less than 10 logic */}
                                <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                                  <p className="mb-0 align-self-center text-primary-light fs-14">
                                    Showing <b> {modalList.length} </b> Out of{" "}
                                    <b> {modalList.length} </b> Reports
                                  </p>
                                  <div className="pagination-list ms-2">
                                    <Pagination
                                      className="ant-pagination"
                                      pageSize={pagesize}
                                      current={page}
                                      total={total}
                                      onChange={paginationChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                                <p className="mb-0 align-self-center text-primary-light fs-14">
                                  Showing <b> 10 </b> Out of{" "}
                                  <b> {modalList.length} </b>
                                </p>
                                <div className="pagination-list ms-2">
                                  <Pagination
                                    className="ant-pagination"
                                    pageSize={pagesize}
                                    current={page}
                                    total={total}
                                    onChange={paginationChange}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>

          <div className="card mb-4">
            <div className="card-header">
              <h5 className="card-title">{commentList.length} Comments</h5>
            </div>
            <div className="card-body">
              <ul className="media-list-wrapper-comments comments-wrapper">
                {Array.isArray(commentList) &&
                  commentList?.map((user, idx) => {
                    let subLists =
                      Array.isArray(user?.subCommnets) &&
                      user?.subCommnets.length > 1;

                    return (
                      <li className="media header-part mb-2" key={user?._id}>
                        <div className="comment-detailed row">
                          <div className="image-wrapper-class col-xxl-1 col-md-2">
                            {user?.commenterDetails[0]?.creatorProfilephoto.includes(
                              '.jpg||.png'
                            ) ? (
                              <img
                                className="thumb-img  img-circle"
                                src={'/assets/images/no-user.png'}
                              />
                            ) : (
                              <img
                                className="thumb-img  img-circle"
                                src={
                                  user?.commenterDetails[0]?.creatorProfilephoto
                                    ? user?.commenterDetails[0]
                                      ?.creatorProfilephoto
                                    : '/assets/images/no-user.png'
                                }
                              ></img>
                            )}
                            <p className="text-muted comment-name text-center mb-0">
                              {Array.isArray(user?.commenterDetails)
                                ? (user?.commenterDetails[0]?.firstname || '') +
                                '  ' +
                                (user?.commenterDetails[0]?.lastname || '')
                                : ''}
                            </p>
                          </div>
                          <div className="col-md-7 col-xxl-6 align-self-center">
                            <p className="mb-0 comment-description">
                              {user?.commentText}
                            </p>
                          </div>
                          {user?.reportedCount === 0 ? (
                            <div></div>
                          ) : (
                            <div className="col-md-3 col-xxl-5 text-end ">
                              <strong
                                className="text-muted cursor-pointer me-2"
                                onClick={() => modelOnSubCategory(user)}
                              >
                                <span className="text-danger fw-regular fs-14">
                                  {user.reportedCount} Reported
                                </span>
                              </strong>
                              <strong
                                className="text-muted"
                                onClick={() => hideComment(user?._id)}
                              >
                                <OverlayTrigger
                                  placement="left"
                                  overlay={
                                    <Tooltip id="tooltip1">
                                      Comment show/hide
                                    </Tooltip>
                                  }
                                >
                                  {user?.isHiddenByAdmin ? (
                                    <i className="bx bx-hide"></i>
                                  ) : (
                                    <i className="icon-eye"></i>
                                  )}
                                  {/* <i className="icon-eye"></i> */}
                                </OverlayTrigger>
                              </strong>
                            </div>
                          )}
                        </div>

                        <div className="media body-part">
                          <div className="sub-comments">
                            <ul className="media-list-wrapper">
                              {Array.isArray(user?.subCommnets) &&
                                user?.subCommnets.length > 0
                                ? user?.subCommnets
                                  .slice(
                                    0,
                                    !currentListId.includes(user._id)
                                      ? 1
                                      : user?.subCommnets?.length
                                  )
                                  .map((reports, idx) => {
                                    return (
                                      <li
                                        className="row media header-part mb-2"
                                        key={reports?._id}
                                      >
                                        <div className="image-wrapper-class col-xxl-1 col-md-2">
                                          <img
                                            className="thumb-img img-circle mw-50 mh-50"
                                            src={
                                              reports?.commenterDetails[0]?.creatorProfilephoto.includes(
                                                '.jpg||.png'
                                              )
                                                ? IMG_URL +
                                                reports?.commenterDetails[0]
                                                  ?.creatorProfilephoto
                                                : '/assets/images/no-user.png'
                                            }
                                          />
                                          <p className="text-muted comment-name text-center mb-0">
                                            {Array.isArray(
                                              reports?.commenterDetails
                                            )
                                              ? (reports?.commenterDetails[0]
                                                ?.firstname || '') +
                                              '  ' +
                                              (reports?.commenterDetails[0]
                                                ?.lastname || '')
                                              : ''}
                                          </p>
                                        </div>
                                        <div className="media body-part col-md-7 col-xxl-6 align-self-center">
                                          <p className="mb-0 comment-description">
                                            {reports?.commentText}
                                          </p>
                                        </div>
                                        {reports?.reportedCount === 0 ? (
                                          <div></div>
                                        ) : (
                                          <div className="col-md-3 col-xxl-5 text-end ">
                                            <strong
                                              className="text-muted cursor-pointer me-2"
                                              onClick={() =>
                                                modelOnSubCategory(reports)
                                              }
                                            >
                                              <span className="text-danger fw-regular fs-14">
                                                {reports.reportedCount}{' '}
                                                Reported
                                              </span>
                                            </strong>{' '}
                                            <strong
                                              className="text-muted"
                                              onClick={() =>
                                                hideComment(reports?._id)
                                              }
                                            >
                                              <OverlayTrigger
                                                placement="left"
                                                overlay={
                                                  <Tooltip id="tooltip1">
                                                    Comment show/hide
                                                  </Tooltip>
                                                }
                                              >
                                                {reports?.isHiddenByAdmin ? (
                                                  <i className="bx bx-hide"></i>
                                                ) : (
                                                  <i className="icon-eye"></i>
                                                )}
                                                {/* {reports?.isHiddenByAdmin ? ''} */}
                                                {/* <i className="icon-eye"></i> */}
                                              </OverlayTrigger>
                                            </strong>
                                          </div>
                                        )}
                                      </li>
                                    );
                                  })
                                : ''}
                            </ul>
                            {subLists ? (
                              <div
                                className="View-all fs-14"
                                onClick={() => handleViewMoreClick(user)}
                                style={{
                                  cursor: 'pointer',
                                  color: 'red',
                                  display: 'flex',
                                  textAlign: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                {!currentListId.includes(user._id)
                                  ? ` View all ${user?.subCommnets?.length}
                                      replies`
                                  : 'view less'}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <footer>
            <div className="footer-text d-flex align-items-centerf justify-content-between">
              <span className="">2023 © Vista Admin Panel</span>
              <span>All Rights Reserved</span>
            </div>
          </footer>
        </div>
      </div>
    </Layout>
  );
}

export default ReportedCommentDetail;
