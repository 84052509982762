import React, { useState, useEffect } from "react";

import { Link, useHistory } from "react-router-dom";

import Spinner from "../../Components/Loader/Spinner";
import debounce from "lodash.debounce";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Pagination from "rc-pagination";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import {
  showMessageNotification,
  showModalNotification,
} from "../../Utils/Functions";
import API from "../../Api/Routes";
import { Layout } from "../../Components/Layout";

import { vistaRoles } from "../../Store/Actions/User";
import { callApi } from "../../Api";
import "antd/dist/antd.css";

/******************* 
@Purpose : Used for Access managements view
@Parameter : props
@Author : INIC
******************/
function AccessManagement(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [rolesList, setRolesList] = useState([]);
  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  const [total, setTotal] = useState(1);

  const [loading, setLoading] = useState(true);

  const [searchText, setSearchtext] = useState("");

  const [listSpin, setListSpin] = useState(false);

  /******************* 
  @Purpose : React hook used for get roles
  @Parameter : props
  @Author : INIC
  ******************/
  useEffect(() => {
    getVistaRoles();
  }, [page, pagesize]);
  /******************* 
  @Purpose : Used for debounce for the search
  @Parameter : {}
  @Author : INIC
  ******************/
  const handleDebouncedChange = React.useMemo(
    () => debounce((evt) => searchField(evt), 1000),
    []
  );
  /******************* 
  @Purpose : Used for get all celeb req
  @Parameter : page, pagesize
  @Author : INIC
  ******************/
  const getVistaRoles = async (e) => {
    setListSpin(true);
    setLoading(true);
    let body = {
      page: page,
      pagesize: pagesize,
    };

    try {
      dispatch(vistaRoles(body)).then((response) => {
        if (response.status === 1) {
          setListSpin(false);
          setRolesList(response?.data?.listing);

          setTotal(response.total);
          setLoading(false);
        } else if (response.status === 0) {
          setListSpin(false);
          showModalNotification(response?.message, "error");
        }
      });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const handleDeleteRole = async (id) => {
    if (id) {
      swal({
        title: "Are you sure,You Want To Delete ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let body = { ids: [id] };
          const response = await callApi(
            API.DELETE_ROLE,
            body,
            "post",
            null,
            true,
            "",
            "",
            history
          );
          if (response.status === 1) {
            showMessageNotification("Deleted successfully", "success");
            getVistaRoles();
          }
        }
      });
    }
  };

  /******************* 
  @Purpose : Used for search fields
  @Parameter : {evtKey}
  @Author : INIC
  ******************/
  const searchField = async (evtKey) => {
    setLoading(true);
    try {
      if (evtKey) {
        let body = {
          page: page,
          pagesize: pagesize,
          searchText: evtKey,
        };

        dispatch(vistaRoles(body)).then((response) => {
          if (response.status === 1) {
            setRolesList(response?.data?.listing);

            setTotal(response.total);
            setLoading(false);
          } else if (response.status === 0) {
            showModalNotification(response.message, "error");
          }
        });
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  /******************* 
  @Purpose : Used for clear form field data
  @Parameter : {}
  @Author : INIC
  ******************/
  const clearInput = () => {
    setSearchtext("");
    getVistaRoles();
  };

  /******************* 
  @Purpose : Used for toggle change
  @Parameter : {}
  @Author : INIC
  ******************/

  function paginationChange(page, pagesize) {
    setPage(page);
    setPagesize(pagesize);
  }

  /******************* 
  @Purpose : Used for Bread crumb
  @Parameter : {}
  @Author : INIC
  ******************/
  const breadcrumb = [
    {
      title: "Roles & Permissions",
      url: "/rolesList",
    },
  ];
  /******************* 
  @Purpose : Used for search on change
  @Parameter : {e}
  @Author : INIC
  ******************/
  const handleSearchChange = (e) => {
    e.persist();
    if (e.target.value.length === 0) {
      clearInput();
    } else {
      setLoading(true);
      setSearchtext(e.target.value);
      handleDebouncedChange(e.target.value);
    }
  };
  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <Layout breadcrumb={breadcrumb} breadcrumbTitle="Roles & Permissions">
      <div className="dashboard-container">
        <div className="main-content-area">
          <div className="overlay" />
          <div className="main-content-block">
            <div className="card">
              <div className="card-header d-flex justify-content-between">
                <div className="left-buttons d-flex align-items-center flex-wrap">
                  <div className="search position-relative has-icon-left">
                    <input
                      type="search"
                      className="form-control text-capitalize"
                      placeholder="search"
                      value={searchText}
                      onChange={(evt) => {
                        handleSearchChange(evt);
                      }}
                    />
                    {searchText && searchText.length > 0 ? (
                      <div className="clear">
                        <span onClick={() => clearInput()}>
                          <em className="icon-close"></em>
                        </span>
                      </div>
                    ) : null}
                    <div className="form-control-position">
                      <em className="icon-magnifier"></em>
                    </div>
                  </div>
                </div>
                <div className="right-buttons">
                  <button
                    id="addNewUser"
                    type="button"
                    className="btn glow-primary btn-primary mr-0"
                    onClick={() => props.history.push("/addRole")}
                  >
                    <i className="icon-user-plus d-lg-none" />
                    <span className="d-none d-sm-none d-lg-inline-block">
                      Add Role
                    </span>
                  </button>
                </div>
              </div>

              <div className="card-body">
                {listSpin ? (
                  <div className="d-flex align-center justify-content-center">
                    <Spinner />
                  </div>
                ) : null}
                <table
                  className="table row-border nowrap common-datatable mb-0"
                  id="role-permission-listing"
                >
                  <thead>
                    <tr>
                      <th>
                        <b>Role Name</b>
                      </th>
                      <th>
                        <b>Description</b>
                      </th>

                      <th className="all">
                        <b>Action</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {rolesList && rolesList.length === 0 ? (
                      <tr className="text-center text-danger not-found-txt">
                        <td colSpan="6">
                          {!loading ? (
                            <h6
                              className="text-center text-danger not-found-txt"
                              colSpan="6"
                            >
                              No Records found!
                            </h6>
                          ) : null}
                        </td>
                      </tr>
                    ) : (
                      rolesList &&
                      Array.isArray(rolesList) &&
                      rolesList.map((item) => {
                        return (
                          <tr key={item?.role}>
                            <td>
                              <div className="d-flex align-items-center">
                                <span>{item?.role}</span>
                              </div>
                            </td>
                            <td>
                              <span>{item?.description}</span>
                            </td>

                            <td>
                              <div className="d-flex">
                                <OverlayTrigger
                                  placement="left"
                                  overlay={<Tooltip id="edit">Edit</Tooltip>}
                                >
                                  <Link
                                    className="cursor-pointer me-3"
                                    onClick={() => {
                                      props.history.push(
                                        `/editRole/${item._id}`
                                      );
                                    }}
                                  >
                                    <i className="icon-note" />
                                  </Link>
                                </OverlayTrigger>

                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="delete">Delete</Tooltip>
                                  }
                                >
                                  <a
                                    className="cursor-pointer me-3"
                                    onClick={() => handleDeleteRole(item._id)}
                                  >
                                    <i className="icon-trash" />
                                  </a>
                                </OverlayTrigger>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                {rolesList && rolesList.length < 10 ? (
                  <div>
                    <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                     
                      <p className="mb-0 align-self-center text-primary-light fs-14">
                        Showing <b> {rolesList.length} </b> Out of{" "}
                        <b> {rolesList.length} </b> Roles
                      </p>
                      <div className="pagination-list ms-2">
                        <Pagination
                          className="ant-pagination"
                          pageSize={pagesize}
                          current={page}
                          total={total}
                          onChange={paginationChange}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="table-footer d-flex flex-wrap align-items-center justify-content-between mt-4">
                    <p className="mb-0 align-self-center text-primary-light fs-14">
                      Showing <b>10</b> Out of{" "}
                      <b> {rolesList.length} </b> Roles
                    </p>
                    <div className="pagination-list ms-2">
                      <Pagination
                        className="ant-pagination"
                        pageSize={pagesize}
                        current={page}
                        total={total}
                        onChange={paginationChange}
                      />
                    </div>
                  </div>
                )}
                <footer>
                  <div className="footer-text d-flex align-items-centerf justify-content-between">
                    <span className="">2023 © Vista Admin Panel</span>
                    <span>All Rights Reserved</span>
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default AccessManagement;
