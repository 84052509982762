import React, { useState, useEffect } from "react";
import Spinner from "../../Components/Loader/Spinner";
import { useDispatch } from "react-redux";
import moment from "moment";
import { getSubscriptionData } from "../../Store/Actions/User";
import { showModalNotification } from "../../Utils/Functions";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
    },
  },
};

const EarningChart = (props) => {
  const [spin, setSpin] = useState(false);
  const { subscriptionData, setsubscriptionData, setEarningClear } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    getVideosubscriptionData();
  }, []);
  useEffect(() => {
    if (props.clearData === "clear data") {
      clearVideoDataSubscription();
    }
  }, [props.date, props.filterValues, props.clearData, props]);

  // to get the month
  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString([], { month: "short" });
  }
  // usage of get month
  const labels = subscriptionData?.map((data) =>
    getMonthName(data?.month ?? null)
  );

  //
  const subscriptionAmount = subscriptionData?.map(
    (data) => data?.subscription ?? null
  );
  const videoAdAmount = subscriptionData?.map((data) => data?.videoAd ?? null);
  const data = {
    labels,
    datasets: [
      {
        label: "Subscription (₹)",
        data: subscriptionAmount,
        backgroundColor: "#faad14",
        borderRadius: 10,
        barThickness: 30,
      },

      {
        label: "Video Ad  (₹)",
        data: videoAdAmount,
        backgroundColor: "#ff4d4f",
        borderRadius: 10,
        barThickness: 30,
      },
    ],
  };

  const getVideosubscriptionData = async (e) => {
    setSpin(true);
    try {
      let body = {
        year: Number(moment(props.date).format("YYYY")),
        filter: props?.filterValues || [],
      };
      dispatch(getSubscriptionData(body)).then((response) => {
        if (response.status === 1) {
          setSpin(false);
          setsubscriptionData(response?.paymentData);
        } else {
          setSpin(false);
          setsubscriptionData([]);
          showModalNotification(response?.message, "error");
        }
      });
    } catch (error) {
      console.error();
    }
  };
  // clear data
  const clearVideoDataSubscription = async (e) => {
    try {
      setEarningClear(false);
      let body = {
        year: Number(moment(props.date).format("YYYY")),
        filter: [],
      };
      dispatch(getSubscriptionData(body)).then((response) => {
        if (response.status === 1) {
          setsubscriptionData(response?.paymentData);
        } else {
          showModalNotification(response?.message, "error");
        }
      });
    } catch (error) {
      console.error();
    }
  };
  return (
    <>
      {spin ? (
        <div className="d-flex align-center justify-content-center">
          <Spinner />
        </div>
      ) : null}
      <Bar options={options} data={data} />
    </>
  );
};

export default EarningChart;
