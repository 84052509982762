/******************* 
@Purpose : Used for environment configuration
@Parameter : {API_URL, IMG_URL, PORT}
@Author : INIC
******************/
module.exports = {};

const env = {
  production: {
    API_URL: " https://vistaadminqa.devpress.net",
    IMG_URL: "https://d2lfc46helbwci.cloudfront.net/",
    PUBLIC_FILE_URL:
      "http://universaladminapiv2.node.indianic.com/public/upload",
    PORT: process.env.PORT,
  },
  uat: {
    API_URL: "https://api.vistareels.com/api",
    IMG_URL: "https://d2lfc46helbwci.cloudfront.net/",
    PUBLIC_FILE_URL:
      "http://universaladminapiv2.node.indianic.com/public/upload",
    PORT: process.env.PORT,
  },
  development: {
    API_URL: "https://vistaapidev.devpress.net/api",

    IMG_URL: "https://d2lfc46helbwci.cloudfront.net/",
    PUBLIC_FILE_URL:
      "http://universaladminapiv2.node.indianic.com/public/upload",
    PORT: process.env.PORT,
  },
  qa: {
    API_URL: "https://vistaapiqa.devpress.net/api",
    IMG_URL: "https://d2lfc46helbwci.cloudfront.net/",
    PUBLIC_FILE_URL:
      "http://universaladminapiv2.node.indianic.com/public/upload",
    PORT: process.env.PORT,
  },
  local: {
    API_URL: "https://vistaapidev.devpress.net/api",
    IMG_URL: "https://d2lfc46helbwci.cloudfront.net",
    PUBLIC_FILE_URL:
      "http://universaladminapiv2.node.indianic.com/public/upload",
    PORT: process.env.PORT,
  },
};

module.exports = env[process.env.REACT_APP_NODE_ENV];
