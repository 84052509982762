import React, { useState } from "react";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import { ChangeBrandStatus } from "../../Store/Actions/Brand";
import errorMessages from "../../Utils/ErrorMessages";

/******************* 
  @Purpose : Used for status change of brand
  @Parameter : {showModel, onHide, getAllBrand, dataId}
  @Author : INIC
  ******************/
function ChangeStatusModel({ showModel, onHide, dataId, getAllBrand }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState("");
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  /******************* 
  @Purpose : Used for status change of brand api calling
  @Parameter : {e}
  @Author : INIC
  ******************/
  const ChangeStatus = async (e) => {
    if (validateForm()) {
      let body = {
        brandId: dataId,
        reason: reason,
      };
      setLoading(true);
      try {
        //await is required
        const res = await dispatch(ChangeBrandStatus(body));
        if (res) {
          setReason("");
          setLoading(false);
          onHide();
          dispatch(getAllBrand());
        }
      } catch (error) {
        console.error();
      }
    }
  };

  /******************* 
  @Purpose : Used for validation for status modal
  @Parameter : {}
  @Author : INIC
  ******************/
  const validateForm = () => {
    let errors = {
      reason: "",
    };

    if (isEmpty(reason)) errors.reason = errorMessages.PROVIDE_REASON;
    else errors.reason = "";

    setErrors(errors);

    return isEmpty(errors.reason);
  };

  /*******************
  @purpose : Render HTML/ React Components
  @Author : INIC
  ******************/
  return (
    <>
      <Modal
        show={showModel}
        onHide={() => {
          onHide(false);
          setReason("");
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Enter Comments here"
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value);
                  let errors1 = Object.assign(errors, {
                    reason: "",
                  });
                  setErrors(errors1);
                }}
              />
              <span className="error-msg" style={{ color: "red" }}>
                {errors.reason}
              </span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              onHide(false);
              setReason("");
            }}
          >
            Close
          </Button>

          <Button
            variant="primary"
            onClick={() => ChangeStatus()}
            disabled={loading}
          >
            {loading ? (
              <>
                {" "}
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{" "}
                Saving...
              </>
            ) : (
              "Save"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChangeStatusModel;
